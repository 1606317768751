import { Box, useRadio } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  QRCode,
  Button,
  Modal,
  Input,
  Popconfirm,
  Tooltip,
  Tour,
  Flex,
  InputNumber,
} from "antd";
import { OrderService } from "services/OrdersService";
import FormAdd from "./FormAdd";
import { CategoryService } from "services/CategoryService";
import { Format_Datetime } from "layouts/ultis/common";
import { TourService } from "services/TourService";
import {
  MdEdit,
  MdOutlineAirlineSeatReclineNormal,
  MdOutlineTableBar,
} from "react-icons/md";
import { CiTrash } from "react-icons/ci";
import { IoQrCodeOutline } from "react-icons/io5";
import { ExclamationCircleFilled } from "@ant-design/icons";
import FormImport from "./FormImport";
const { confirm } = Modal;

export default function Settings() {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [tabelName, setTableName] = useState("");
  const refDetail = useRef(null);
  const refImport = useRef(null);
  useEffect(() => {
    filter();
  }, []);
  const filter = async () => {
    let _res = await CategoryService.Khac.getTable({});
    if (Array.isArray(_res.data)) setData(_res.data);
  };
  const showDeleteConfirm = (item) => {
    confirm({
      title: "Bạn có chắc muốn đóng bàn không?",
      icon: <ExclamationCircleFilled />,
      content: "Sau khi đóng bàn, bạn sẽ được mở bàn này cho giải đấu mới!",
      okText: "Đồng ý",
      okType: "danger",
      cancelText: "Không",
      onOk() {
        return new Promise(async (resolve, reject) => {
          let _res = await TourService.Table.close({
            MaBan: item.ID,
            MaBanGD: item.MaBanGD,
          });
          if (_res.status === 2000) {
            resolve();
            filter();
          } else {
            reject();
          }
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };
  const columns = [
    {
      title: "Tên bàn",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Tên tour",
      dataIndex: "TenGD",
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "ThoiGianBD",
      render: (item) => item && Format_Datetime(item),
    },
    {
      title: "Player/Max",
      render: (item) => (
        <p>
          {item.Player}/{item.SoLuongMax}
        </p>
      ),
    },
    {
      title: "",
      key: "QRCode",
      render: (item) => {
        return (
          <div id={`myqrcode_${item.ID}`} style={{ display: "none" }}>
            <QRCode
              size={200}
              value={
                `https://${
                  window.location.hostname
                }/#/orders?mact=${localStorage.getItem("MaCTDK")}&table=${
                  item.ID
                }` || "-"
              }
              bordered={true}
            />
          </div>
        );
      },
    },
    {
      title: "",
      width: "200px",
      render: (item) => {
        return (
          <Flex gap={5}>
            <Tooltip title="Sửa">
              <Button
                type="primary"
                ghost
                onClick={() => {
                  setOpen(true);
                  setDataEdit(item);
                  // setTableName(item.Name);
                  // setId(item.ID);
                }}
                icon={<MdEdit />}
              />
            </Tooltip>
            <Tooltip title="Tải QRCode">
              <Button
                type="primary"
                ghost
                onClick={() => downloadQRCode(`myqrcode_${item.ID}`)}
                icon={<IoQrCodeOutline />}
              />
            </Tooltip>
            <Tooltip title="Cài đặt seat">
              <Button
                type="primary"
                ghost
                onClick={() => refDetail.current.show(item)}
                icon={<MdOutlineAirlineSeatReclineNormal />}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá bàn"
              description="Bạn có chắc chắn muốn xoá bàn?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => removeTable(item.ID)}
            >
              <Tooltip title="Xoá bàn">
                <Button danger ghost icon={<CiTrash />} />
              </Tooltip>
            </Popconfirm>
            {item.TenGD && (
              // <Popconfirm
              //   title="Đóng bàn"
              //   description="Bạn có chắc chắn muốn đóng bàn?"
              //   okText="Đồng ý"
              //   cancelText="Không"
              //   onConfirm={() => closeTable(item)}
              // >
              <Tooltip title="Đóng bàn">
                <Button
                  onClick={() => showDeleteConfirm(item)}
                  danger
                  ghost
                  icon={<MdOutlineTableBar />}
                />
              </Tooltip>
              // {/* </Popconfirm> */}
            )}
          </Flex>
        );
      },
    },
  ];
  const [dataEdit, setDataEdit] = useState({});
  const closeTable = async (value) => {
    let _res = await TourService.Table.close({
      MaBan: value.ID,
      MaBanGD: value.MaBanGD,
    });
    if (_res.status === 2000) {
      filter();
      // openNotification("success", "topRight", "Xoá giải đấu thành công");
    } else {
      // openNotification("error", "topRight", _res?.message);
    }
  };
  const removeTable = async (value) => {
    await OrderService.removeTable({ ID: Number(value) });
    filter();
  };
  const downloadQRCode = (id) => {
    const canvas = document.getElementById(id)?.querySelector("canvas");
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "QRCode.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = async () => {
    let _res = await OrderService.addTable({
      ...dataEdit,
      QRCode:
        "https://" +
        window.location.hostname +
        "/#/orders?mact=" +
        localStorage.getItem("MaCTDK"),
    });
    if (_res.status === 2000) {
      filter();
      setOpen(false);
      setDataEdit({});
    }
  };
  const handleCancel = () => {
    setOpen(false);
    setDataEdit({});
  };
  const applyChange = (key, value) => {
    setDataEdit({ ...dataEdit, [key]: value });
  };
  const onAfterSubmit = async () => {
    filter();
  };
  return (
    <Box>
      <Flex gap={10}>
        <Button type="primary" style={{ marginBottom: 10 }} onClick={showModal}>
          Thêm bàn
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          onClick={() => refImport.current.show()}
        >
          Import
        </Button>
      </Flex>
      <Table dataSource={data} columns={columns} pagination={false} />
      <Modal
        open={open}
        title="Thêm bàn"
        onOk={handleOk}
        okText="Lưu"
        cancelText="Huỷ"
        onCancel={handleCancel}
        width={350}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <p>Tên bàn</p>
        <Input
          placeholder="Nhập tên bàn"
          value={dataEdit?.Name}
          onChange={(e) => applyChange("Name", e.target.value)}
        />
        <p style={{ marginTop: 10 }}>Thời gian</p>
        <InputNumber
          style={{ width: "100%" }}
          value={dataEdit?.ThoiGian}
          onChange={(v) => applyChange("ThoiGian", v)}
        />
      </Modal>
      <FormAdd ref={refDetail} />
      <FormImport ref={refImport} onAfterSubmit={onAfterSubmit} />
    </Box>
  );
}
