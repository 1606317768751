import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Tooltip,
  Badge,
  Row,
  Col,
  Flex,
  Tag,
  Checkbox,
  Image,
} from "antd";
import "./index.scss";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import { Format_Date } from "layouts/ultis/common";
import {
  MdEdit,
  MdNotificationsActive,
  MdOutlinePendingActions,
} from "react-icons/md";
import FormDetail from "./FormDetail";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { TinTuc } from "services/TinTuc";

export default function Index() {
  const [dataAll, setDataAll] = useState([]);
  const [data, setData] = useState([]);
  const [maLoaiTin, setMaLoaiTin] = useState([]);
  const [stateActive, setStateActive] = useState({
    MaTT: 0,
    TenTT: "Tất cả",
    Color: "gray",
  });
  const [api, contextHolder] = notification.useNotification();
  const refDetail = useRef(null);
  const refAdd = useRef(null);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({});

  useEffect(() => {
    loadCategories();
    // load(filterCondition);
  }, []);

  const loadCategories = async () => {
    let _mLT = await TinTuc.maLoaiTin();
    if (_mLT?.status === 2000) {
      setMaLoaiTin(_mLT?.data);
    }
    let _res = await TinTuc.TinTuc.get();
    if (_res?.status === 2000) {
      setData(_res?.data);
    }
  };

  const columns = [
    {
      title: "Tiêu đề",
      dataIndex: "TieuDe",
      ellipsis: true,
    },

    {
      title: "Mô tả",
      dataIndex: "MoTa",
      ellipsis: true,
    },
    {
      title: "Loại tin",
      dataIndex: "MaLTin",
      ellipsis: true,
      render: (item) => maLoaiTin.find((news) => news.MaLoai === item)?.TenLoai,
    },
    {
      title: "View",
      dataIndex: "isView",
      ellipsis: true,
      render: (item) => <Checkbox checked={item} />,
    },
    {
      title: "Banner",
      dataIndex: "IsBanner",
      ellipsis: true,
      render: (item) => <Checkbox checked={item} />,
    },

    {
      title: "Ngày tạo",
      dataIndex: "NgayNhap",
      ellipsis: true,
      render: (item) => Format_Date(item),
    },
    {
      title: "Hình ảnh",
      dataIndex: "Image",
      ellipsis: true,
      render: (item) => <Image src={item}> </Image>,
    },
    {
      title: "",
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex gap={10} justify="center">
            {/* <Tooltip placement="topRight" title="Gửi thông báo">
              <Button
                type="link"
                icon={<MdNotificationsActive size={18} />}
                onClick={() => sendThongBao(item)}
              />
            </Tooltip> */}
            <Tooltip placement="topRight" title="Sửa">
              <Button
                type="link"
                icon={<MdEdit size={18} color="" />}
                onClick={() => refAdd.current.update(item)}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá yêu cầu"
              description="Bạn có chắc chắn muốn xoá hay không?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Button type="text" danger icon={<CiTrash size={18} />} />
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const remove = async (item) => {
    let _res = await TinTuc.TinTuc.delete({ ID: item });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(item));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const sendThongBao = async (item) => {
    setLoading(true);
    let _res;
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Gửi thông báo thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onAfterSubmit = () => {
    loadCategories(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    loadCategories(_filter);
    setFilterCondition(_filter);
  };
  const changeState = (state) => {
    if (state === 0) {
      setData(dataAll);
    } else {
      let _data = dataAll.filter((v) => v.MaTT === state);
      setData(_data);
    }
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = () => {
    const fieldsToExport = columns.map((col) => col.dataIndex);
    const filteredData = data.map((item) => {
      let filteredItem = {};
      fieldsToExport.forEach((field) => {
        filteredItem[field] = item[field];
      });
      return filteredItem;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Danh sách khách hàng thông báo" + fileExtension);
  };
  return (
    <Row  gutter={[10, 10]}>
      {/* <Col span={4}>
        <FilterModal
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
          options={{}}
        />
      </Col> */}
      <Col span={24}>
        <Toolbar
          left={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
              }}
            ></div>
          )}
          right={() => (
            <Flex gap={10}>
              <Button
                type="primary"
                onClick={() => refAdd.current.show(filterCondition?.MaTN)}
              >
                Thêm
              </Button>
              <Button onClick={() => exportToCSV()}>Export</Button>
            </Flex>
          )}
        />

        <Table
          style={{ height: 100 }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            // x: 2000,
          }}
          size="small"
        />
      </Col>

      <FormAdd
        ref={refAdd}
        onAfterSubmit={onAfterSubmit}
        options={{}}
        maLoaiTin={maLoaiTin}
      />
      <FormDetail ref={refDetail} onAfterSubmit={onAfterSubmit} options={{}} />
      {contextHolder}
    </Row>
  );
}
