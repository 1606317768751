import axiosApi from "./api_helper";
export const CategoryService = {
  Khac: {
    getCatDatMayIn: async () => {
      return await axiosApi()
        .post("api/category/other/get-caidatmayin")
        .then((res) => res.data);
    },
    getThaoTacIn: async () => {
      return await axiosApi()
        .post("api/category/other/get-thaotacin")
        .then((res) => res.data);
    },
    addCatDatMayIn: async (payload) => {
      return await axiosApi()
        .post("api/category/other/coru-caidatmayin", payload)
        .then((res) => res.data);
    },
    deleteCatDatMayIn: async (payload) => {
      return await axiosApi()
        .post("api/category/other/delete-caidatmayin", payload)
        .then((res) => res.data);
    },
    getTable: async (payload) => {
      return await axiosApi()
        .post("api/category/get-table", payload)
        .then((res) => res.data);
    },
    getQuocGia: async () => {
      return await axiosApi()
        .post("api/category/get-quocgia")
        .then((res) => res.data);
    },
  },
  Seat: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/category/get-seat", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/category/add-seat", payload)
        .then((res) => res.data);
    },
    addNhieu: async (payload) => {
      return await axiosApi()
        .post("api/category/add-seats", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/category/delete-seat", payload)
        .then((res) => res.data);
    },
  },
  GiaiDau: {
    getEntry: async () => {
      return await axiosApi()
        .post("api/category/giaidau/get-entry")
        .then((res) => res.data);
    },
    addEntry: async (payload) => {
      return await axiosApi()
        .post("api/category/giaidau/coru-entry", payload)
        .then((res) => res.data);
    },
    importEntry: async (payload) => {
      return await axiosApi()
        .post("api/category/giaidau/import-entry", payload)
        .then((res) => res.data);
    },
    deleteEntry: async (payload) => {
      return await axiosApi()
        .post("api/category/giaidau/delete-entry", payload)
        .then((res) => res.data);
    },
  },
};
