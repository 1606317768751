import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from "react";
import {
  Button,
  Input,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  ConfigProvider,
  DatePicker,
  Flex,
} from "antd";
import dayjs from "dayjs";
import vi from "dayjs/locale/vi";
import "dayjs/locale/vi";
import viVN from "antd/es/locale/vi_VN";
import { BuyinService } from "services/BuyinService";
import _ from "lodash";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
import { GiaiDauService } from "services/GiaiDauService";
import { useEffect } from "react";
import { DateTime } from "luxon";
const { TextArea } = Input;
const FormAddVoucher = (props, ref) => {
  const { onAfterSubmit } = props;
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");
  const defaultData = {
    TuNgay:  dateInHoChiMinh.toISO(),
    DenNgay: dateInHoChiMinh.toISO(),
  };
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [imgs, setImgs] = useState([]);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
    },
    update: async (data) => {
      setOpen(true);
      setData(data);
      setImgs(data.Image ? [data.Image] : []);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
    setImgs([]);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const onSubmit = async () => {
    let _data = _.cloneDeep(data);
    setLoading(true);
    _data.Image = imgs.length > 0 ? imgs[0] : "";
    let _res = await GiaiDauService.LichThiDau.add(_data);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Lưu thành công!");
      onClose();
      onAfterSubmit({
        ..._data,
        ID: _res.data ? _res.data : _data.ID,
        isEdit: _data.ID ? true : false,
      });
      setLoading(false);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const affterUpload = useCallback(
    async (file) => {
      if (file.length > 0) {
        setImgs([URL_UPLOAD + file[0]]);
      }
    },
    [setImgs]
  );
  const onRemove = useCallback(
    async (file) => {
      // let _file = files.find((v) => v.Link === file.url);
      // await VisaService.HoSo.deleteTaiLieu({
      //   ID: _file?.ID,
      // });
    },
    [setImgs]
  );
  return (
    <Drawer
      title="Thêm lịch thi đấu"
      width={520}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button loading={loading} onClick={() => onSubmit()}>
            Lưu
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 8]}>
        <Col span={24}>
          <p style={{ fontWeight: "600" }}>Tên lịch</p>
          <Input
            value={data?.TenLich}
            onChange={(e) => applyChange("TenLich", e.target.value)}
            style={{ marginTop: 10 }}
            placeholder="Nhập tên lịch"
          />
        </Col>
        {/* <Col span={24}>
          <p style={{ fontWeight: "600" }}>Địa chỉ</p>
          <Input
            value={data?.DiaChi}
            onChange={(e) => applyChange("DiaChi", e.target.value)}
            style={{ marginTop: 10 }}
            placeholder="Nhập địa chỉ"
          />
        </Col> */}
        <Col span={24}>
          <Flex
            className="date-picker-custom"
            align="center"
            justify="flex-start"
          >
            <p style={{ fontWeight: "600", width: "35%" }}>Từ ngày</p>
            <ConfigProvider locale={viVN}>
              <DatePicker
                allowClear={false}
                format="DD-MM-YYYY"
                style={{ width: "65% ", border: "none", paddingLeft: "8px" }}
                value={dayjs(data?.TuNgay)}
                placeholder={"Chọn từ ngày"}
                locale={vi}
                onChange={(v) =>
                  typeof applyChange === "function" && applyChange("TuNgay", v)
                }
              />
            </ConfigProvider>
          </Flex>
        </Col>

        <Col span={24}>
          <Flex
            className="date-picker-custom"
            align="center"
            justify="flex-start"
          >
            <p style={{ fontWeight: "600", width: "35%" }}>Đến ngày</p>
            <ConfigProvider locale={viVN}>
              <DatePicker
                allowClear={false}
                format="DD-MM-YYYY"
                style={{ width: "65% ", border: "none", paddingLeft: "8px" }}
                value={dayjs(data?.DenNgay)}
                placeholder={"Chọn đến ngày"}
                locale={vi}
                onChange={(v) =>
                  typeof applyChange === "function" && applyChange("DenNgay", v)
                }
              />
            </ConfigProvider>
          </Flex>
        </Col>

        <Col span={24}>
          <p style={{ fontWeight: "600", marginBottom: "10px" }}>Thêm ảnh</p>
          <UploadComponent
            files={imgs}
            multiple={false}
            affterUpload={affterUpload}
            onRemove={onRemove}
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAddVoucher);
