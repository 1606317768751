import axios from "axios";
import { post } from "./api_helper";
import axiosApi from "./api_helper";
export const TinTuc = {
  maLoaiTin: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-loaitin", payload)
      .then((res) => res.data);
  },
  TinTuc: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/poker/get-news", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/poker/add-news", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/poker/delete-news", payload)
        .then((res) => res.data);
    },
    put: async (payload) => {
      return await axiosApi()
        .post("api/poker/get-news-byid", payload)
        .then((res) => res.data);
    },
  },
};
