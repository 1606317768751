import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Checkbox,
  Tooltip,
  Flex,
  Popconfirm,
} from "antd";
import { BuyinService } from "services/BuyinService";
import _, { flatMap, set } from "lodash";
import DebounceSelect from "components/DebounceSelect";
import BuyinToPrint from "./BuyinToPrint";
import { useReactToPrint } from "react-to-print";
import { IoIosAdd } from "react-icons/io";
import { MdDelete, MdOutlineEdit } from "react-icons/md";
import FormAddVoucher from "./FormAddVoucher";
import { CardService } from "services/CardService";
import { TourService } from "services/TourService";
import { OrderService } from "services/OrdersService";
import { Format_Currency } from "layouts/ultis/common";
import { GiaiDauService } from "services/GiaiDauService";

const { TextArea } = Input;
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState({});
  const [banks, setBanks] = useState([]);
  const [banAll, setBanAll] = useState([]);
  const [seats, setSeats] = useState([]);
  const [giaiDau, setGiaiDau] = useState([]);
  const [voucher, setVoucher] = useState([]);
  const [khoangEntry, setKhoangEntry] = useState([]);
  const [paymentType, setPaymentType] = useState([]);
  const refPrint = useRef(null);
  const refAddVoucher = useRef(null);
  const [card, setCard] = useState({});
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const [type, setType] = useState("BUYIN");
  const loadCategories = async (date) => {
    let _ban = await OrderService.getTable({});
    let _banks = await BuyinService.getBanks();
    let _pm = await BuyinService.getPaymentType();
    // let _gd = await BuyinService.getGiaiDau();
    let _gd = await BuyinService.getGiaiDauByDate({
      ...date,
    });
    let _vc = await BuyinService.getVoucher();
    let _cp = await BuyinService.getCompanyDetail();
    let _res3 = await TourService.getKhoangEntry();

    setKhoangEntry(_res3?.data ?? []);
    setBanAll(_ban?.data ?? []);
    setBanks(_banks?.data ?? []);
    setPaymentType(_pm?.data ?? []);
    setGiaiDau(_gd?.data?.filter((v) => v.isHienThiBuyIn) ?? []);
    setVoucher(_vc?.data ?? []);
    setCompany(_cp?.data[0] ?? {});
  };
  const checkBuyIn = async (MaGD, MaKH) => {
    let _res = await BuyinService.checkBuyin({ MaGD: MaGD, MaKHMT: MaKH });
    if (_res.data?.length > 0) {
      setType("REBUY");
      return "REBUY";
    } else {
      setType("BUYIN");
      return "BUYIN";
    }
  };
  useImperativeHandle(ref, () => ({
    show: async (filter) => {
      setOpen(true);
      loadCategories(filter);
    },
    // update: async (data) => {
    //   setOpen(true);
    //   loadCategories();
    //   setData(data);
    // },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
    setType("BUYIN");
  };
  const [tables, setTables] = useState([]);
  const loadBan = async (magd, _data) => {
    let _resBan = await TourService.Table.get({ MaGD: magd });
    let _res = await TourService.Table.getBanTrongNhieu({
      MaGD: magd,
    });
    if (_res.data) {
      let _tb = _resBan?.data
        ?.filter((v) => v.isOpen && v.Player < v.SoLuongMax)
        ?.map((v) => {
          return {
            ...v,
            Name: banAll.find((d) => d.ID === v.MaBan).Name,
          };
        });
      if (_tb?.length > 0) {
        setTables(_tb);
        _data.TenBan = _tb?.find((d) => d.ID === _res.data.ID)?.Name;
        _data.MaBan = _res.data.MaBan;
        _data.MaBanGD = _res.data.ID;
        loadSeat(_res.data.MaBan, _res.data.ID);
      }
    } else {
      setTables([]);
      setSeats([]);
    }
  };
  function getRandomValueFromArray(arr) {
    if (arr.length === 0) return undefined;
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  }
  const loadSeat = async (maban, mabangd) => {
    let _res = await TourService.Table.getSeatTrong({
      MaBan: maban,
      MaBanGD: mabangd,
    });
    if (_res.data?.length > 0) {
      let maSeat = getRandomValueFromArray(_res?.data).ID;
      let tenSeat = _res.data?.find((v) => v.ID === maSeat)?.Name;
      setSeats(_res?.data ?? []);
      setData((prev) => ({
        ...prev,
        MaSeat: maSeat,
        TenSeat: tenSeat,
      }));
    }
  };
  const loadCard = async (value) => {
    let _res = await CardService.getCardByMaKH({
      MaKH: value,
    });
    setCard(_res?.data);
  };
  // const loadNguoiChoi = async (value) => {
  //   let _res = await BuyinService.getDiemTichLuyByMaGD({
  //     MaGD: value,
  //   });
  // };
  const waitFor10Seconds = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("Done waiting for 10 seconds!");
      }, 5000); // 10,000 milliseconds = 10 seconds
    });
  };
  const SumTongTien = (_data) => {
    _data.TongTien =
      _data.Tien -
      (_data.TienCk ?? 0) -
      (_data.TienVoucher ?? 0) -
      (_data.TienTru ?? 0) -
      (_data.TruTienGiai ?? 0);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    setLoading(true);
    switch (p) {
      case "MaKH":
        setOpenSelect(false);
        if (v?.length > 1) {
          _data[p] = v.splice(1);
          _data.MaKHMT = _data[p][0].value;
          _data.TenCongTy = _data?.MaKH[0].label.split("-")[0];
          _data.DiDong = _data?.MaKH[0].label.split("-")[1];
        } else if (v?.length === 0) {
          _data[p] = null;
        } else {
          _data[p] = v;
          _data.MaKHMT = _data[p][0].value;
          _data.TenCongTy = _data?.MaKH[0].label.split("-")[0];
          _data.DiDong = _data?.MaKH[0].label.split("-")[1];
        }
        if (v?.length > 0) {
          let _res = await BuyinService.getCardByMaKH({
            MaKH: _data[p][0].value,
          });
          loadCard(_data[p][0].value);
          checkBuyIn(_data.MaGD, _data.MaKHMT);
          _data.MaVach = _res?.data?.maVach;
          _data.MaThe = _res?.data?.id;
          _data.SoThe = _res?.data?.soThe;
          _data.Diem = _res?.data?.diemThe;
          _data.MaLT = _res?.data?.maLT;
          _data.TyLeCK = 0;
          _data.DiemTru = 0;
        }
        break;
      case "MaGD":
        let _gd = giaiDau?.find((d) => d.ID === v);
        _data[p] = v;
        _data.TenBan = null;
        _data.TenSeat = null;
        _data.MaBan = null;
        _data.MaBanGD = null;
        _data.MaSeat = null;
        _data.IDEntry = _gd?.IDEntry;
        _data.PTTichDiem = _gd?.PTTichDiem;
        _data.ThoiGianBD = _gd?.ThoiGianBD;
        _data.TenGD = _gd?.TenGD;
        _data.KieuXepCho = _gd?.KieuXepCho;
        _data.isAutoTinhGiai = _gd?.isAutoTinhGiai;
        let restype = await checkBuyIn(v, _data.MaKHMT);
        let tienGiai = 0;
        let tienPhi = 0;
        let tienBounty = 0;
        if (_data.isAddon === true) {
          tienGiai = _gd.TienGiaiAddon;
          tienPhi = _gd.GiaAddon;
          tienBounty = _gd.TienBountyAddon;
        } else if (restype === "BUYIN") {
          tienGiai = _gd.TienGiaiBuyIn;
          tienPhi = _gd.GiaBuyin;
          tienBounty = _gd.TienBountyBuyIn;
        } else if (restype === "REBUY") {
          tienGiai = _gd.TienGiaiRuby;
          tienPhi = _gd.GiaRebuy;
          tienBounty = _gd.TienBountyRebuy;
        }
        _data.TienGiai = tienGiai;
        _data.TienPhi = tienPhi;
        _data.TienBounty = tienBounty;
        _data.TienJackport = tienGiai / 100;
        _data.Tien = tienGiai + tienPhi + tienBounty;
        SumTongTien(_data);
        let _check = await TourService.Seat.getSeatWaiting({ MaGD: v });
        if (
          _gd?.KieuXepCho !== "WAITING" &&
          _check.data?.length == 0 &&
          _data.isOnline !== true
        ) {
          await loadBan(v, _data);
        }
        break;
      case "isOnline":
        _data[p] = v;
        _data.TenBan = null;
        _data.TenSeat = null;
        _data.MaBan = null;
        _data.MaBanGD = null;
        _data.MaSeat = null;
        break;
      case "isAddon":
        _data[p] = v;
        let _gd2 = giaiDau?.find((d) => d.ID === _data?.MaGD);
        let tienGiai1 = 0;
        let tienPhi1 = 0;
        let tienBounty1 = 0;
        if (v === true) {
          tienGiai1 = _gd2.TienGiaiAddon;
          tienPhi1 = _gd2.GiaAddon;
          tienBounty1 = _gd2.TienBountyAddon;
        } else if (type === "BUYIN") {
          tienGiai1 = _gd2.TienGiaiBuyIn;
          tienPhi1 = _gd2.GiaBuyin;
          tienBounty1 = _gd2.TienBountyBuyIn;
        } else if (type === "REBUY") {
          tienGiai1 = _gd2.TienGiaiRuby;
          tienPhi1 = _gd2.GiaRebuy;
          tienBounty1 = _gd2.TienBountyRebuy;
        }
        _data.TienGiai = tienGiai1;
        _data.TienPhi = tienPhi1;
        _data.TienBounty = tienBounty1;
        _data.TienJackport = tienGiai1 / 100;
        _data.Tien = tienGiai1 + tienPhi1 + tienBounty1;
        SumTongTien(_data);
        break;
      case "TyLeCK":
        _data[p] = v;
        _data.TienCk = ((_data?.TienGiai + _data?.TienPhi) * v) / 100;
        SumTongTien(_data);
        break;
      case "MaVoucher":
        _data[p] = v;
        let _vc = voucher?.find((d) => d.ID === v);
        _data.TienVoucher = _vc.SoTien;
        _data.TruTienGiai = _vc.TruTienGiai ?? 0;
        SumTongTien(_data);
        break;
      case "DiemTru":
        _data[p] = v;
        let _reslt = await CardService.Type.getDetail({ MaLT: _data.MaLT });
        _data.TienTru = v * (_reslt?.data?.MotDiemTrenSoTien ?? 0);
        SumTongTien(_data);
        break;
      case "MaHinhThuc":
        let ht = paymentType.find((d) => d.ID === v);
        _data[p] = v;
        _data.TenHT = ht?.TenHinhThuc;
        break;

      default:
        _data[p] = v;
        break;
    }
    setLoading(false);
    setData(_data);
  };
  async function fetchUserList(username) {
    return BuyinService.filterCustomer({ InputSearch: username }).then(
      (res) => {
        return res?.data?.map((item) => ({
          label: `${item.TenCongTy} - ${item.DiDong} - ${new Date(
            item.NgaySinh
          ).getDate()}/${new Date(item.NgaySinh).getMonth() + 1}/${new Date(
            item.NgaySinh
          ).getFullYear()}`,
          value: item.MaKH,
          TenKH: item.TenCongTy,
          DiDong: item.DiDong,
        }));
      }
    );
  }
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
      duration: 0.4,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const [soTT, setSoTT] = useState(0);
  const [isBuyin, setIsBuyin] = useState(0);
  const [soLien, setSoLien] = useState(1);
  const tinhLaiGiai = async (data) => {
    let _entryHienTai = khoangEntry.find((v) => v.ID === data.IDEntry);
    let _resDiemTichLuy = await BuyinService.getDiemTichLuyByMaGD({
      MaGD: data?.MaGD,
    });
    let soNguoiChoi = _resDiemTichLuy?.data?.length;
    let _gd = giaiDau?.find((d) => d.ID === data.MaGD);
    const saveGiai = async (idEntryNew) => {
      let _res = await TourService.getTyLeGT({ ID: idEntryNew });
      let TongTienGiaiDau = (_gd.TienGiaiBuyIn ?? 0) * soNguoiChoi;
      let TongTienTraoGiai = 0;
      if (TongTienGiaiDau < _gd.GTD) {
        TongTienTraoGiai =
          (_gd.GTD ?? 0) * (_gd.PhanTramTraoGiai ?? 0) * 0.01 +
          (_gd.TienCongThem ?? 0) -
          (_gd.TienHighHand ?? 0);
      } else {
        TongTienTraoGiai =
          TongTienGiaiDau * (_gd.PhanTramTraoGiai ?? 0) * 0.01 +
          (_gd.TienCongThem ?? 0) -
          (_gd.TienHighHand ?? 0);
      }
      let GiaiThuong = [];
      _res?.data?.map((v) => {
        GiaiThuong.push({
          MaGD: data.MaGD,
          TenGiai: v.TenGiai,
          isHienThi: v.isHienThi,
          SoLuongGiai: v.SoLuongGiai,
          PhanTram: v.PhanTram,
          ThanhTien: (v.PhanTram ?? 0) * TongTienTraoGiai * 0.01,
          HienThi: Format_Currency((v.PhanTram ?? 0) * TongTienTraoGiai * 0.01),
          DiemThuong: v.DiemThuong,
          TongTien:
            (v.PhanTram ?? 0) * (v.SoLuongGiai ?? 0) * TongTienTraoGiai * 0.01,
        });
      });
      if (GiaiThuong.length > 0) {
        let _resadd = await GiaiDauService.updateCoCauGiaiThuong({
          MaGD: data.MaGD,
          IDEntry: idEntryNew,
          ListGiaiThuong: GiaiThuong,
        });
        if (_resadd.status === 2000) {
          let _giaidau = _.cloneDeep(giaiDau);
          let item = _giaidau.find((d) => d.ID === data.MaGD);
          item.IDEntry = idEntryNew;
          setGiaiDau(_giaidau);
          openNotification(
            "success",
            "topRight",
            "Cân lại giải thưởng thành công!"
          );
        }
      }
    };
    if (_entryHienTai?.SoLuongDen < soNguoiChoi) {
      let idEntryNew = khoangEntry.find(
        (item) =>
          soNguoiChoi >= item.SoLuongTu && soNguoiChoi <= item.SoLuongDen
      );
      if (idEntryNew) {
        saveGiai(idEntryNew?.ID);
      }
    } else if (soNguoiChoi > _gd?.GTDEntry) {
      saveGiai(_gd.IDEntry);
    }
  };
  const onSubmit = async (print, soLien) => {
    setSoLien(soLien);
    setLoading(true);
    let _data = _.cloneDeep(data);
    if (!_data.MaSeat && _data?.KieuXepCho === "MANUAL") {
      openNotification(
        "error",
        "topRight",
        "Chưa mở bàn hoặc một số vị trí đã hết. Vui lòng kiểm tra lại!"
      );
      setLoading(false);
      return;
    }
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    _data.Diem =
      ((card?.TyLeBuy + _data.PTTichDiem ?? 0) * _data?.TienGiai) / 100;
    let _res = await BuyinService.addBuyin(_data);
    if (_res.status === 2000) {
      if (_data.isAutoTinhGiai === true) await tinhLaiGiai(_data);
      setSoTT(_res.data?.soTT);
      setIsBuyin(_res.data?.isBuyIn);
      if (print === "quickprint") {
        await BuyinService.print({ LinkID: _res.data?.id });
      } else if (print) {
        handlePrint();
      }
      openNotification("success", "topRight", "BUYIN thành công!");
      setLoading(false);
      setData({
        MaKHMT: _data.MaKHMT,
      });
      onAfterSubmit();
    } else {
      setLoading(false);
      openNotification("error", "topRight", _res?.message);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => refPrint.current,
  });
  const onAfterSubmitVoucher = (v) => {
    let _voucher = _.cloneDeep(voucher);
    if (v.isEdit) {
      let index = _voucher.findIndex((d) => d.ID === v.ID);
      _voucher[index] = v;
    } else {
      _voucher.push(v);
    }
    setVoucher(_voucher);
    let _data = _.cloneDeep(data);
    _data.MaVoucher = v.ID;
    _data.TruTienGiai = v.TruTienGiai;
    setData(_data);
  };
  const removeVoucher = async () => {
    let _data = _.cloneDeep(data);
    let _res = await BuyinService.deleteVoucher({ ID: _data.MaVoucher });
    if (_res.status === 2000) {
      let _voucher = _.cloneDeep(voucher);
      let fil = _voucher.filter((v) => v.ID !== _data.MaVoucher);
      setVoucher(fil);
      _data.MaVoucher = null;
      setData(_data);
      openNotification("success", "topRight", "Xoá thành công!");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const [openSelect, setOpenSelect] = useState(false);
  return (
    <Drawer
      title="BUYIN"
      width={900}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <p style={{ color: "red", fontWeight: "bold", marginRight: 20 }}>
            {data?.isAddon ? "ADDON" : type}
          </p>
          <Button loading={loading} onClick={() => onSubmit()}>
            Lưu
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit("print", 1)}
            type="primary"
          >
            Lưu & in 1 liên
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit("print", 2)}
            type="primary"
          >
            Lưu & in 2liên
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit("quickprint", 2)}
            type="primary"
          >
            Lưu & in nhanh
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 8]}>
        <Col span={12}>
          <Row gutter={[24, 8]}>
            <Col span={24} className="gutter-row">
              <p>
                Khách hàng <span style={{ color: "red" }}>*</span>
              </p>
              <DebounceSelect
                mode="multiple"
                value={data?.MaKH}
                status={data?.MaKH ? "" : "error"}
                placeholder="Tìm kiểm khách hàng"
                fetchOptions={fetchUserList}
                open={openSelect}
                onDropdownVisibleChange={(open) => setOpenSelect(open)}
                onChange={(newValue) => applyChange("MaKH", newValue)}
                style={{
                  width: "100%",
                }}
              />
              <p style={{ color: "red" }}>{errors?.MaKH}</p>
            </Col>

            <Col span={data?.MaHinhThuc === 2 ? 12 : 24} className="gutter-row">
              <p>
                Hình thức thanh toán <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                style={{
                  width: "100%",
                }}
                status={data?.MaHinhThuc ? "" : "error"}
                value={data?.MaHinhThuc}
                placeholder="Chọn hình thức"
                onChange={(v) => applyChange("MaHinhThuc", v)}
                options={paymentType}
                fieldNames={{ label: "TenHinhThuc", value: "ID" }}
              />
              <p style={{ color: "red" }}>{errors?.MaHinhThuc}</p>
            </Col>
            {data?.MaHinhThuc === 2 && (
              <Col span={12} className="gutter-row">
                <p>Ngân hàng</p>
                <Select
                  // defaultValue={banks?.length > 0 && banks[0].ID}
                  style={{
                    width: "100%",
                  }}
                  value={data?.MaNH}
                  placeholder="Chọn ngân hàng"
                  showSearch
                  filterOption={(input, option) =>
                    (option?.TenNHVT?.toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  onChange={(v) => applyChange("MaNH", v)}
                  options={banks}
                  fieldNames={{ label: "TenNHVT", value: "ID" }}
                />
              </Col>
            )}
            <Col span={24} className="gutter-row">
              <p>Voucher</p>
              <Flex align="center">
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={data?.MaVoucher}
                  placeholder="Chọn voucher"
                  onChange={(v) => applyChange("MaVoucher", v)}
                  options={voucher}
                  fieldNames={{ label: "LyDo", value: "ID" }}
                />
                <Tooltip title="Thêm">
                  <Button
                    onClick={() => refAddVoucher.current.show()}
                    type="text"
                    icon={<IoIosAdd size={25} />}
                  />
                </Tooltip>

                {data?.MaVoucher && (
                  <>
                    <Tooltip title="Sửa">
                      <Button
                        onClick={() =>
                          refAddVoucher.current.update(
                            voucher?.find((d) => d.ID === data?.MaVoucher)
                          )
                        }
                        type="text"
                        icon={<MdOutlineEdit size={18} />}
                      />
                    </Tooltip>
                    <Tooltip title="Xoá">
                      <Popconfirm
                        title="Xoá voucher"
                        description="Bạn có chắc chắn muốn xoá voucher hay không?"
                        okText="Đồng ý"
                        cancelText="Không"
                        onConfirm={() => removeVoucher()}
                      >
                        <Button type="text" icon={<MdDelete size={18} />} />
                      </Popconfirm>
                    </Tooltip>
                  </>
                )}
              </Flex>
            </Col>
            <Col span={24}>
              <TextArea
                onChange={(e) => applyChange("GhiChu", e.target.value)}
                style={{ marginTop: 15 }}
                placeholder="Ghi chú"
              />
            </Col>
            <Col span={12} className="gutter-row">
              <p>Bàn</p>
              <Select
                style={{ width: "100%" }}
                value={data?.MaBanGD}
                onChange={(v) => applyChange("MaBanGD", v)}
                options={tables}
                disabled
                fieldNames={{ label: "Name", value: "ID" }}
              />
            </Col>
            <Col span={12} className="gutter-row">
              <p>Seat</p>
              <Select
                style={{ width: "100%" }}
                value={data?.MaSeat}
                onChange={(v) => applyChange("MaSeat", v)}
                disabled
                // disabled={data?.KieuXepCho !== "MANUAL"}
                options={seats}
                fieldNames={{ label: "Name", value: "ID" }}
              />
            </Col>
            <Col span={12}>
              <Checkbox
                checked={data?.isAddon}
                onChange={(v) => applyChange("isAddon", v.target.checked)}
              >
                Addon
              </Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox
                checked={data?.isOnline}
                onChange={(v) => applyChange("isOnline", v.target.checked)}
              >
                Đặt online
              </Checkbox>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={[24, 8]}>
            <Col span={24} className="gutter-row">
              <p>
                Giải đấu <span style={{ color: "red" }}>*</span>{" "}
              </p>
              <Select
                style={{
                  width: "100%",
                }}
                value={data?.MaGD}
                status={data?.MaGD ? "" : "error"}
                showSearch
                filterOption={(input, option) =>
                  (option?.TenGD?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                onChange={(v) => applyChange("MaGD", v)}
                options={giaiDau}
                fieldNames={{ label: "TenGD", value: "ID" }}
              />
              <p style={{ color: "red" }}>{errors?.MaGD}</p>
            </Col>
            <Col span={12}>
              <p>Tiền giải</p>
              <InputNumber
                style={{ width: "100%" }}
                disabled
                addonAfter="vnđ"
                value={data?.TienGiai}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }
              />
            </Col>
            <Col span={12}>
              <p>Tiền phí</p>
              <InputNumber
                style={{ width: "100%" }}
                value={data?.TienPhi}
                disabled
                addonAfter="vnđ"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }
              />
            </Col>
            <Col span={12}>
              <p>Tiền bounty</p>
              <InputNumber
                style={{ width: "100%" }}
                disabled
                value={data?.TienBounty}
                addonAfter="vnđ"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }
              />
            </Col>
            <Col span={12}>
              <p>Tổng tiền</p>
              <InputNumber
                style={{ width: "100%", fontSize: "100px" }}
                className="input-number-custom"
                disabled
                value={data?.Tien}
                addonAfter="vnđ"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }
              />
            </Col>
            <Col span={12}>
              <p>Điểm trừ</p>
              <InputNumber
                addonAfter="điểm"
                style={{ width: "100%" }}
                value={data?.DiemTru}
                max={data?.Diem}
                onChange={(newValue) => applyChange("DiemTru", newValue)}
              />
            </Col>
            <Col span={12}>
              <p>Điểm đã tích</p>
              <InputNumber
                style={{ width: "100%" }}
                value={data?.Diem}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }
                disabled
                addonAfter="điểm"
              />
            </Col>

            <Col span={12}>
              <p>Tiền trừ</p>
              <InputNumber
                style={{ width: "100%" }}
                value={data?.TienTru}
                disabled
                addonAfter="vnđ"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }
              />
            </Col>
            <Col span={12}>
              <p>Tiền jackport</p>
              <InputNumber
                style={{ width: "100%" }}
                value={data?.TienJackPort}
                disabled
                addonAfter="vnđ"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </Col>
            <Col span={12}>
              <p>Tiền voucher</p>
              <InputNumber
                style={{ width: "100%" }}
                value={data?.TienVoucher}
                disabled
                addonAfter="vnđ"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </Col>
            <Col span={12}>
              <p>Trừ tiền giải</p>
              <InputNumber
                style={{ width: "100%" }}
                value={data?.TruTienGiai}
                disabled
                addonAfter="vnđ"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </Col>

            <Col span={24}>
              <p>Còn lại</p>
              <InputNumber
                className="input-number-custom"
                style={{ width: "100%" }}
                disabled
                value={data?.TongTien}
                addonAfter="vnđ"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <div style={{ display: "none" }}>
        <BuyinToPrint
          ref={refPrint}
          data={{
            ...data,
            isBuyIn: isBuyin,
            soLien: soLien,
            Type: data?.isAddon ? "ADDON" : type,
          }}
          company={company}
          soTT={soTT}
        />
      </div>
      <FormAddVoucher
        ref={refAddVoucher}
        onAfterSubmit={onAfterSubmitVoucher}
      />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
