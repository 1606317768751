import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Divider,
  Checkbox,
  Table,
  Tabs,
  Form,
  Popconfirm,
  Upload,
  message,
  Image,
  Flex,
  Tooltip,
} from "antd";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { TourService } from "services/TourService";
import { CiTrash } from "react-icons/ci";
import { FileUpload } from "primereact/fileupload";
import { HomeService } from "services/HomeService";
import { UploadService } from "services/UploadService";
import { URL_UPLOAD } from "services/UploadService";
import { BuyinService } from "services/BuyinService";
import { Format_Currency } from "layouts/ultis/common";
import { IoIosAdd } from "react-icons/io";
import { MdDelete, MdOutlineEdit, MdOutlineRemoveRoad } from "react-icons/md";
import FormAddLichTD from "../DanhSachGiai/LichThiDau/FormAdd";
import { GiaiDauService } from "services/GiaiDauService";
import FormImport from "./FormImport";
const { TextArea } = Input;
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    NgayNhap: new Date(),
    PhanTramTraoGiai: 96,
    PhanTramJackpot: 1,
    ptService: 3,
    TienBountyBuyIn: 0,
    KieuXepCho: "AUTO",
    isHienThiBuyIn: true,
    isTichDiem: false,
    isTourMau: false,
    // isSeatCard: false,
    isAutoTinhGiai: true,
  };
  const [files, setFiles] = useState([]);

  const [data, setData] = useState(defaulData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tourMau, setTourMau] = useState([]);
  const [coCau, setCoCau] = useState([]);
  const [merges, setMerges] = useState([]);
  const [lichThiDau, setLichThiDau] = useState([]);
  const [khoangEntry, setKhoangEntry] = useState([]);
  const defaultErrors = {
    TenGD: null,
    PhanTramTraoGiai: null,
    TienGiaiBuyIn: null,
    SoChipBuyin: null,
    GTD: null,
    GTDEntry: null,
    RegEndStart: null,
    SoSeatMax: null,
  };
  const refAddLichTD = useRef(null);
  const refImportCoCau = useRef(null);
  const [errors, setErrors] = useState(defaultErrors);
  const getCoCauGiaiThuong = async (id) => {
    let _res = await TourService.getCoCauGiaiThuong({ ID: id });
    setCoCau(_res?.data ?? []);
  };
  const loadCategories = async () => {
    let _res = await TourService.getGiaiDauMau();
    let _resMerge = await TourService.getListMerge();
    let _res3 = await TourService.getKhoangEntry();
    let _resLTD = await GiaiDauService.LichThiDau.get();
    setLichThiDau(_resLTD?.data ?? []);
    setKhoangEntry(
      _res3?.data?.map((item) => ({
        ...item,
        Name:
          item.TenKhoang + ". Từ " + item.SoLuongTu + " đến " + item.SoLuongDen,
      })) ?? []
    );
    setMerges(_resMerge?.data ?? []);
    setTourMau(_res?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      loadCategories();
      setIsUpdate(true);
      let _res = await TourService.getDetail({ ID: data.ID });
      getCoCauGiaiThuong(data.ID);
      if (_res.status === 2000) setData(_res?.data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setErrors(defaultErrors);
    setData(defaulData);
    onAfterSubmit();
    setCoCau([]);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    setLoading(true);
    switch (p) {
      case "MaGDM":
        let gd = tourMau.find((t) => t.ID === v);
        _data = {
          ..._data,
          ...gd,
          ...defaulData,
          isChange: true,
          TienCongThem: 0,
        };
        break;
      case "PhanTramJackpot":
        _data[p] = v;
        _data.PhanTramTraoGiai =
          100 - (_data.PhanTramJackpot ?? 0) - (_data.ptService ?? 0);
        _data.TienJackPort = (v / 100) * (_data.TienGiaiBuyIn ?? 0);
      case "ptService":
        _data[p] = v;
        _data.PhanTramTraoGiai =
          100 - (_data.PhanTramJackpot ?? 0) - (_data.ptService ?? 0);
        break;
      case "IDEntry":
        let _res = await TourService.getTyLeGT({ ID: v });
        setCoCau(_res?.data ?? []);
        _data[p] = v;
        await handleCanGiai(_data, _res?.data ?? []);
        break;
      case "GTD":
        _data[p] = v;
        _data.GTDEntry = v / _data?.TienGiaiBuyIn;
        _data.isChange = true;
        break;
      case "TienGiaiBuyIn":
        _data[p] = v;
        _data.TienGiaiRuby = v;
        _data.GTDEntry = _data.GTD / v;
        _data.isChange = true;
        _data.TienJackPort =
          ((_data.PhanTramJackpot ?? 0) / 100) * (_data.TienGiaiBuyIn ?? 0);
        break;
      case "GTDEntry":
        _data[p] = v;
        _data.isChange = true;
        break;
      case "GiaBuyin":
        _data[p] = v;
        _data.GiaRebuy = v;
        break;
      case "TienBountyBuyIn":
        _data[p] = v;
        _data.TienBountyRebuy = v;
        break;
      case "SoChipBuyin":
        _data[p] = v;
        _data.SoChipRebuy = v;
        break;
      case "SoLanMuaToiDaBuyin":
        _data[p] = v;
        _data.SoLanMuaToiDaRebuy = v;
        break;
      default:
        _data[p] = v;
        break;
    }
    setLoading(false);
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
      duration: 0.7,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      if (
        item === "GTDEntry" ||
        item === "RegEndStart" ||
        item === "SoSeatMax"
      ) {
        _errors[item] = data[item] && data[item] > 0 ? null : "Lớn hơn 0";
      } else {
        _errors[item] = data[item] ? null : "Không để trống";
      }
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    let _data = _.cloneDeep(data);
    let check = coCau?.some((v) => !v.SoLuongGiai || !v.ThanhTien);
    if (check === true) {
      openNotification(
        "error",
        "topRight",
        "Vui lòng nhập số lượng giải và thành tiền!"
      );
      return;
    }
    setLoading(true);
    _data.ListGiaiThuong = coCau;
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    if (!isupdate && files?.length == 0 && !data?.UrlImage) {
      setLoading(false);
      openNotification("error", "topRight", "Vui lòng chọn background!");
      return;
    }
    if (files?.length > 0) {
      var formData = new FormData();
      formData.append("Image", files[0]);
      formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
      let _resupload = await UploadService.upload(formData);
      if (_resupload.status === 2000) {
        _data.UrlImage = URL_UPLOAD + _resupload.data;
        setData((pre) => {
          return { ...pre, UrlImage: _data.UrlImage };
        });
      }
    }
    let _res = null;
    if (isupdate) {
      _res = await TourService.updateGiaiDau(_data);
    } else {
      _res = await TourService.addGiaiDau(_data);
      if (_res.status === 2000) {
        let _resBlind = await TourService.getBlind({ MaGD: _data.ID });
        if (Array.isArray(_resBlind.data) && _resBlind.data?.length > 0) {
          let payload = {
            ListBlind: _resBlind.data,
            MaGD: _res.daTa,
          };
          await TourService.addBlind(payload);
        }
      }
      setData((pre) => {
        return { ...pre, ID: _res.daTa, UrlImage: _data.UrlImage };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} giải đấu thành công!`
      );
      setIsUpdate(true);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onBeforeUpload = (v) => {
    setFiles(v.files);
  };
  const onMerge = async () => {
    setLoading(true);
    let _res = await TourService.mergeGiaiDau({
      MaGD: data.ID,
      ListGiai: data.GiaiGop,
    });
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Gộp giải đấu thành công!`);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
    setLoading(false);
  };
  const onAfterSubmitLichTD = (v) => {
    let _LichTD = _.cloneDeep(lichThiDau);
    if (v.isEdit) {
      let index = _LichTD.findIndex((d) => d.ID === v.ID);
      _LichTD[index] = v;
    } else {
      _LichTD.push(v);
    }
    setLichThiDau(_LichTD);
    let _data = _.cloneDeep(data);
    _data.MaLichThiDau = v.ID;
    setData(_data);
  };
  const removeLichThiDau = async () => {
    let _data = _.cloneDeep(data);
    let _res = await GiaiDauService.LichThiDau.delete({
      ID: _data.MaLichThiDau,
    });
    if (_res.status === 2000) {
      let _LichTD = _.cloneDeep(lichThiDau);
      let fil = _LichTD.filter((v) => v.ID !== _data.MaLichThiDau);
      setLichThiDau(fil);
      _data.MaLichThiDau = null;
      setData(_data);
      openNotification("success", "topRight", "Xoá thành công!");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderInfo = () => {
    return (
      <>
        <Row gutter={[24, 12]}>
          <Col span={8} className="gutter-row">
            <p>Giải đấu mẫu</p>
            <Select
              style={{
                width: "100%",
              }}
              showSearch
              filterOption={(input, option) =>
                (option?.TenGD?.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              value={data?.MaGDM}
              onChange={(v) => applyChange("MaGDM", v)}
              options={tourMau}
              placeholder="Chọn giải đấu mẫu"
              fieldNames={{ label: "TenGD", value: "ID" }}
            />
          </Col>

          <Col span={8}>
            <p>
              Tên giải <span style={{ color: "red" }}>*</span>
            </p>
            <Input
              status={data?.TenGD?.length > 0 ? "" : "error"}
              value={data?.TenGD}
              placeholder="Nhập tên giải đấu"
              onChange={(e) => applyChange("TenGD", e.target.value)}
            />
            <p style={{ color: "red" }}>{errors?.TenGD}</p>
          </Col>
          <Col span={8} className="gutter-row">
            <p>Lịch thi đấu</p>
            <Flex align="center">
              <Select
                style={{
                  width: "100%",
                }}
                value={data?.MaLichThiDau}
                placeholder="Chọn lịch thi đấu"
                onChange={(v) => applyChange("MaLichThiDau", v)}
                options={lichThiDau}
                fieldNames={{ label: "TenLich", value: "ID" }}
              />
              <Tooltip title="Thêm">
                <Button
                  onClick={() => refAddLichTD.current.show()}
                  type="text"
                  icon={<IoIosAdd size={25} />}
                />
              </Tooltip>

              {data?.MaLichThiDau && (
                <>
                  <Tooltip title="Sửa">
                    <Button
                      onClick={() =>
                        refAddLichTD.current.update(
                          lichThiDau?.find((d) => d.ID === data?.MaLichThiDau)
                        )
                      }
                      type="text"
                      icon={<MdOutlineEdit size={18} />}
                    />
                  </Tooltip>
                  <Tooltip title="Xoá">
                    <Popconfirm
                      title="Xoá voucher"
                      description="Bạn có chắc chắn muốn xoá voucher hay không?"
                      okText="Đồng ý"
                      cancelText="Không"
                      onConfirm={() => removeLichThiDau()}
                    >
                      <Button type="text" icon={<MdDelete size={18} />} />
                    </Popconfirm>
                  </Tooltip>
                </>
              )}
            </Flex>
          </Col>
          <Col span={12}>
            <p>Ghi chú 1</p>
            <TextArea
              value={data?.GhiChu}
              onChange={(e) => applyChange("GhiChu", e.target.value)}
              placeholder="Nhập ghi chú 1"
            />
          </Col>
          <Col span={12}>
            <p>Ghi chú 2</p>
            <TextArea
              value={data?.GhiChu2}
              onChange={(e) => applyChange("GhiChu2", e.target.value)}
              placeholder="Nhập ghi chú 2"
            />
          </Col>

          <Col span={6}>
            <p>
              Entry Fee <span style={{ color: "red" }}>*</span>
            </p>
            <InputNumber
              style={{ width: "100%" }}
              addonAfter="%"
              max={100}
              status={data?.PhanTramTraoGiai > 0 ? "" : "error"}
              value={data?.PhanTramTraoGiai}
              onChange={(v) => applyChange("PhanTramTraoGiai", v)}
              // formatter={(value) =>
              //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "")
              // }
            />
            <p style={{ color: "red" }}>{errors?.PhanTramTraoGiai}</p>
          </Col>
          <Col span={6}>
            <p>PT Jackpot</p>
            <InputNumber
              style={{ width: "100%" }}
              addonAfter="%"
              max={100}
              value={data?.PhanTramJackpot}
              onChange={(v) => applyChange("PhanTramJackpot", v)}
              // formatter={(value) =>
              //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              // }
            />
          </Col>
          <Col span={6}>
            <p>Service charge</p>
            <InputNumber
              style={{ width: "100%" }}
              addonAfter="%"
              max={100}
              value={data?.ptService}
              onChange={(v) => applyChange("ptService", v)}
              // formatter={(value) =>
              //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              // }
            />
          </Col>
          <Col span={6}>
            <p>Tiền High Hand</p>
            <InputNumber
              style={{ width: "100%" }}
              value={data?.TienHighHand}
              onChange={(v) => applyChange("TienHighHand", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
        </Row>
        <Row gutter={[24, 12]}>
          <Divider
            orientation="left"
            style={{ margin: "25px 0px 0px", fontSize: 14 }}
          >
            Thông tin khác
          </Divider>
          <Col span={2}>
            <p style={{ marginTop: 25, fontWeight: "bold" }}>Buyin:</p>
            <p style={{ marginTop: 15, fontWeight: "bold" }}>Rebuy:</p>
            <p style={{ marginTop: 15, fontWeight: "bold" }}>Addon:</p>
          </Col>
          <Col span={6}>
            <p>
              Phí buyin <span style={{ color: "red" }}>*</span>
            </p>
            <InputNumber
              style={{ width: "100%" }}
              value={data?.TienGiaiBuyIn}
              status={data?.TienGiaiBuyIn > 0 ? "" : "error"}
              onChange={(v) => applyChange("TienGiaiBuyIn", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <p style={{ color: "red" }}>{errors?.TienGiaiBuyIn}</p>
            <InputNumber
              style={{ width: "100%", marginTop: 5 }}
              value={data?.TienGiaiRuby}
              status={data?.TienGiaiRuby > 0 ? "" : "error"}
              onChange={(v) => applyChange("TienGiaiRuby", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <InputNumber
              style={{ width: "100%", marginTop: 5 }}
              value={data?.TienGiaiAddon}
              onChange={(v) => applyChange("TienGiaiAddon", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
          <Col span={5}>
            <p>Tiền phí</p>
            <InputNumber
              style={{ width: "100%" }}
              value={data?.GiaBuyin}
              onChange={(v) => applyChange("GiaBuyin", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <InputNumber
              style={{ width: "100%", marginTop: 5 }}
              value={data?.GiaRebuy}
              onChange={(v) => applyChange("GiaRebuy", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <InputNumber
              style={{ width: "100%", marginTop: 5 }}
              value={data?.GiaAddon}
              onChange={(v) => applyChange("GiaAddon", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
          <Col span={5}>
            <p>Tiền bounty</p>
            <InputNumber
              style={{ width: "100%" }}
              value={data?.TienBountyBuyIn}
              onChange={(v) => applyChange("TienBountyBuyIn", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <InputNumber
              style={{ width: "100%", marginTop: 5 }}
              value={data?.TienBountyRebuy}
              onChange={(v) => applyChange("TienBountyRebuy", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <InputNumber
              style={{ width: "100%", marginTop: 5 }}
              value={data?.TienBountyAddon}
              onChange={(v) => applyChange("TienBountyAddon", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
          <Col span={3}>
            <p>
              Số chip <span style={{ color: "red" }}>*</span>
            </p>
            <InputNumber
              value={data?.SoChipBuyin}
              onChange={(v) => applyChange("SoChipBuyin", v)}
              style={{ width: "100%" }}
              status={data?.SoChipBuyin > 0 ? "" : "error"}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <p style={{ color: "red" }}>{errors?.SoChipBuyin}</p>
            <InputNumber
              value={data?.SoChipRebuy}
              onChange={(v) => applyChange("SoChipRebuy", v)}
              style={{ width: "100%", marginTop: 5 }}
              status={data?.SoChipRebuy > 0 ? "" : "error"}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <InputNumber
              style={{ width: "100%", marginTop: 5 }}
              value={data?.SoChipAddon}
              onChange={(v) => applyChange("SoChipAddon", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
          <Col span={3}>
            <p>Mua tối đa</p>
            <InputNumber
              style={{ width: "100%" }}
              defaultValue={1}
              value={data?.SoLanMuaToiDaBuyin}
              onChange={(v) => applyChange("SoLanMuaToiDaBuyin", v)}
            />
            <InputNumber
              style={{ width: "100%", marginTop: 5 }}
              defaultValue={1}
              value={data?.SoLanMuaToiDaRebuy}
              onChange={(v) => applyChange("SoLanMuaToiDaRebuy", v)}
            />
            <InputNumber
              style={{ width: "100%", marginTop: 5 }}
              defaultValue={50}
              value={data?.SoLanMuaToiDaAddon}
              onChange={(v) => applyChange("SoLanMuaToiDaAddon", v)}
            />
          </Col>

          <Col span={6}>
            <p>Ngày bắt đầu</p>
            <DatePicker
              allowClear={false}
              showTime={{ use12Hours: false }}
              format="DD-MM-YYYY HH:mm"
              style={{ width: "100%" }}
              value={dayjs(data?.NgayNhap)}
              // placeholder="Ngày hiệu lực"
              locale={vi}
              showD
              onChange={(v) => applyChange("NgayNhap", v)}
            />
          </Col>
          <Col span={6}>
            <p>Tiền cộng thêm</p>
            <InputNumber
              style={{ width: "100%" }}
              value={data?.TienCongThem}
              onChange={(v) => applyChange("TienCongThem", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
          <Col span={6}>
            <p>Starting chips</p>
            <Input
              value={data?.StartingChips}
              placeholder="Nhập starting chips"
              onChange={(e) => applyChange("StartingChips", e.target.value)}
            />
          </Col>
          <Col span={6}>
            <p>Chip cộng thêm</p>
            <InputNumber
              style={{ width: "100%" }}
              value={data?.ChipBonus}
              onChange={(v) => applyChange("ChipBonus", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
          <Col span={6}>
            <p>
              GTD <span style={{ color: "red" }}>*</span>
            </p>
            <InputNumber
              style={{ width: "100%" }}
              value={data?.GTD}
              status={data?.GTD > 0 ? "" : "error"}
              onChange={(v) => applyChange("GTD", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <p style={{ color: "red" }}>{errors?.GTD}</p>
          </Col>
          <Col span={6}>
            <p>GTD Entry</p>
            <InputNumber
              style={{ width: "100%" }}
              value={data?.GTDEntry}
              onChange={(v) => applyChange("GTDEntry", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <p style={{ color: "red" }}>{errors?.GTDEntry}</p>
          </Col>
          {data?.ID && (
            <Col span={12} className="gutter-row">
              <p>Gộp giải</p>
              <Flex gap={10}>
                <Select
                  style={{
                    width: "100%",
                  }}
                  mode="multiple"
                  // filterOption={(input, option) =>
                  //   (option?.TenGD?.toLowerCase() ?? "").includes(
                  //     input.toLowerCase()
                  //   )
                  // }
                  value={data?.GiaiGop}
                  onChange={(v) => applyChange("GiaiGop", v)}
                  options={merges.filter((v) => v.ID !== data.ID)}
                  placeholder="Chọn giải đấu"
                  fieldNames={{ label: "TenGD", value: "ID" }}
                />
                <Popconfirm
                  title="Gộp giải đấu"
                  description="Bạn có chắc chắn muốn gộp giải đấu?"
                  okText="Đồng ý"
                  cancelText="Không"
                  onConfirm={() => onMerge()}
                >
                  <Button loading={loading} type="primary" ghost>
                    Gộp giải
                  </Button>
                </Popconfirm>
              </Flex>
            </Col>
          )}

          <Col span={6}>
            <p>Xếp chỗ</p>
            <Select
              style={{
                width: "100%",
              }}
              value={data?.KieuXepCho}
              onChange={(v) => applyChange("KieuXepCho", v)}
              options={[
                { ID: "AUTO", Name: "AUTO" },
                { ID: "WAITING", Name: "WAITING LIST" },
                { ID: "NOSEAT", Name: "NO SEAT" },
              ]}
              fieldNames={{ label: "Name", value: "ID" }}
            />
          </Col>
          <Col span={6}>
            <p>PT tích điểm</p>
            <InputNumber
              style={{ width: "100%" }}
              value={data?.PTTichDiem}
              onChange={(v) => applyChange("PTTichDiem", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
          <Col span={6}>
            <p>Reg End Start</p>
            <InputNumber
              style={{ width: "100%" }}
              value={data?.RegEndStart}
              onChange={(v) => applyChange("RegEndStart", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
            <p style={{ color: "red" }}>{errors?.RegEndStart}</p>
          </Col>
          <Col span={6}>
            <p>Số seat max</p>
            <InputNumber
              style={{ width: "100%" }}
              value={data?.SoSeatMax}
              onChange={(v) => applyChange("SoSeatMax", v)}
            />
            <p style={{ color: "red" }}>{errors?.SoSeatMax}</p>
          </Col>
        </Row>
        <Row gutter={[10, 10]} style={{ marginTop: 20 }}>
          <Col span={4}>
            <p>Hiển thị thu ngân</p>
            <Checkbox
              checked={data?.isHienThiBuyIn === true}
              onChange={() => applyChange("isHienThiBuyIn", true)}
            >
              Có
            </Checkbox>
            <Checkbox
              checked={data?.isHienThiBuyIn === false}
              onChange={() => applyChange("isHienThiBuyIn", false)}
            >
              Không
            </Checkbox>
          </Col>
          <Col span={4}>
            <p>Tích điểm</p>
            <Checkbox
              checked={data?.isTichDiem === true}
              onChange={() => applyChange("isTichDiem", true)}
            >
              Có
            </Checkbox>
            <Checkbox
              checked={data?.isTichDiem === false}
              onChange={() => applyChange("isTichDiem", false)}
            >
              Không
            </Checkbox>
          </Col>
          <Col span={4}>
            <p>Tour mẫu</p>
            <Checkbox
              checked={data?.isTourMau === true}
              onChange={() => applyChange("isTourMau", true)}
            >
              Có
            </Checkbox>
            <Checkbox
              checked={data?.isTourMau === false}
              onChange={() => applyChange("isTourMau", false)}
            >
              Không
            </Checkbox>
          </Col>
          {/* <Col span={4}>
            <p>Seat card</p>
            <Checkbox
              checked={data?.isSeatCard === true}
              onChange={() => applyChange("isSeatCard", true)}
            >
              Có
            </Checkbox>
            <Checkbox
              checked={data?.isSeatCard === false}
              onChange={() => applyChange("isSeatCard", false)}
            >
              Không
            </Checkbox>
          </Col> */}
          <Col span={8}>
            <p>Auto tính giải</p>
            <Checkbox
              checked={data?.isAutoTinhGiai === true}
              onChange={() => applyChange("isAutoTinhGiai", true)}
            >
              Có
            </Checkbox>
            <Checkbox
              checked={data?.isAutoTinhGiai === false}
              onChange={() => applyChange("isAutoTinhGiai", false)}
            >
              Không
            </Checkbox>
          </Col>
          <Col span={8}>
            {data.UrlImage && (
              <Image src={data.UrlImage} style={{ width: "100%" }} />
            )}
            <FileUpload
              mode="basic"
              name="demo[]"
              url="/api/upload"
              accept="image/*"
              maxFileSize={1000000}
              onSelect={(e) => onBeforeUpload(e)}
              chooseLabel="Tải background"
            />
          </Col>
        </Row>
      </>
    );
  };
  useEffect(() => {
    let time = null;
    setLoading(true);
    if (!time && data.GTDEntry > 0 && data.isAutoTinhGiai && data?.isChange) {
      time = setTimeout(() => {
        let _idEntry = khoangEntry.find(
          (item) =>
            data.GTDEntry >= item.SoLuongTu && data.GTDEntry <= item.SoLuongDen
        );
        if (_idEntry) {
          applyChange("IDEntry", _idEntry.ID);
        } else {
          openNotification(
            "error",
            "topRight",
            "Không tìm thấy khoảng entry phù hợp với GTD Entry này!"
          );
        }
        setLoading(false);
      }, 500);
    } else {
      setLoading(false);
    }
    return () => clearTimeout(time);
  }, [data.GTDEntry, data.isAutoTinhGiai, data.isChange]);
  const handleCanGiai = async (dataGiaiDau, dataCoCau) => {
    let _dataGD = dataGiaiDau ?? data;
    let _dataCC = _.cloneDeep(dataCoCau ?? coCau);
    setLoading(true);
    let _res = await BuyinService.getDiemTichLuyByMaGD({ MaGD: _dataGD.ID });
    // let TongNguoiChoi = _res?.data?.length;
    // let NguoiBuyin = _res?.data?.filter(
    //   (v) => v.isBuyin === null && v.isAddon === null && v.isFreeRoll === false
    // ).length;
    // let NguoiRebuy = _res?.data?.filter((v) => v.isBuyin).length;
    // let NguoiAddon = _res?.data?.filter((v) => v.isAddon).length;
    // let TongTienGiaiDau =
    //   (_dataGD.TienGiaiBuyIn ?? 0) * NguoiBuyin +
    //   (_dataGD.TienGiaiBuyIn ?? 0) * NguoiRebuy +
    //   (_dataGD.TienGiaiAddon ?? 0) * NguoiAddon;
    let SoNguoiChoi = _res?.data?.length;
    let TongTienGiaiDau = (_dataGD.TienGiaiBuyIn ?? 0) * SoNguoiChoi;
    let TongTienTraoGiai = 0;
    if (TongTienGiaiDau < _dataGD.GTD) {
      TongTienTraoGiai =
        (_dataGD.GTD ?? 0) * (_dataGD.PhanTramTraoGiai ?? 0) * 0.01 +
        (_dataGD.TienCongThem ?? 0) -
        (_dataGD.TienHighHand ?? 0);
    } else {
      TongTienTraoGiai =
        TongTienGiaiDau * (_dataGD.PhanTramTraoGiai ?? 0) * 0.01 +
        (_dataGD.TienCongThem ?? 0) -
        (_dataGD.TienHighHand ?? 0);
    }
    // let _cocau = _.cloneDeep(coCau);
    _dataCC.map((v) => {
      v.ThanhTien = (v.PhanTram ?? 0) * TongTienTraoGiai * 0.01;
      v.HienThi = Format_Currency((v.PhanTram ?? 0) * TongTienTraoGiai * 0.01);
      v.TongTien =
        (v.PhanTram ?? 0) * (v.SoLuongGiai ?? 0) * TongTienTraoGiai * 0.01;
    });
    setCoCau(_dataCC);
    // if (_res.status === 2000) {
    //   openNotification("success", "topRight", `Cân giải thành công!`);
    // } else {
    //   openNotification("error", "topRight", _res?.message);
    // }
    setLoading(false);
  };
  const onAfterSubmitImport = (value) =>{
    console.log(value );
    
     let _cocau =[];
     value?.map((item)=>{
      _cocau.push({
        isHienThi: false,
        ...item,
        TongTien: item.SoLuongGiai * item.ThanhTien,
      });
     })
     setCoCau(_cocau)
  }
  const renderCoCauGiai = () => {
    const addCoCau = () => {
      let _cocau = _.cloneDeep(coCau);
      _cocau.push({
        isHienThi: false,
        TenGiai: "",
        HienThi: "",
      });
      setCoCau(_cocau);
    };
    const onChangeCoCau = (p, v, index) => {
      let _cocau = _.cloneDeep(coCau);
      let item = _cocau.slice(index, index + 1);
      if (item?.length > 0) {
        _cocau.splice(index, 1, {
          ...item[0],
          [p]: v,
        });
      }

      setCoCau(_cocau);
    };
    const deleteCoCau = (v, index) => {
      let _cocau = _.cloneDeep(coCau);
      _cocau.splice(index, 1);
      setCoCau(_cocau);
      if (v.ID) {
        TourService.deleteCoCauGiaiThuong({ ID: v.ID });
      }
    };
    const deleteCoCauAll = ()=>{
      setCoCau([]);
    }

    return (
      <>
        <Flex gap={10} justify="space-between">
          <Flex gap={10}>
            <Button
              loading={loading}
              onClick={() => addCoCau()}
              type="primary"
              ghost
              style={{ marginBottom: 10 }}
            >
              Thêm
            </Button>
            <Button
              loading={loading}
              onClick={() => refImportCoCau.current.show()}
              type="primary"
              ghost
              style={{ marginBottom: 10 }}
            >
              Import
            </Button>
          </Flex>

          <Flex gap={10}>
            <Select
              style={{
                width: "200px",
              }}
              value={data?.IDEntry}
              onChange={(v) => applyChange("IDEntry", v)}
              options={khoangEntry}
              placeholder="Chọn khoảng entry"
              fieldNames={{ label: "Name", value: "ID" }}
            />
            <Button
              loading={loading}
              onClick={() => handleCanGiai()}
              ghost
              type="primary"
            >
              Cân giải
            </Button>
          </Flex>
        </Flex>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={coCau}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            header="Bỏ hiển thị"
            style={{ width: "100px" }}
            body={(item, row) => {
              return (
                <Checkbox
                  checked={item.isHienThi}
                  onChange={() =>
                    onChangeCoCau("isHienThi", !item.isHienThi, row.rowIndex)
                  }
                />
              );
            }}
          />
          <Column
            header="Tên giải"
            body={(item, row) => {
              return (
                <Input
                  value={item?.TenGiai}
                  placeholder="Nhập tên giải"
                  onChange={(e) =>
                    onChangeCoCau("TenGiai", e.target.value, row.rowIndex)
                  }
                />
              );
            }}
          />
          <Column
            header={() => <p style={{ color: "red" }}>Số lượng giải</p>}
            body={(item, row) => {
              return (
                <InputNumber
                  value={item?.SoLuongGiai}
                  onChange={(v) =>
                    onChangeCoCau("SoLuongGiai", v, row.rowIndex)
                  }
                />
              );
            }}
          />
          <Column
            header="Điểm"
            body={(item, row) => {
              return (
                <InputNumber
                  value={item?.DiemThuong}
                  onChange={(v) => onChangeCoCau("DiemThuong", v, row.rowIndex)}
                />
              );
            }}
          />
          <Column
            header="Phần trăm"
            body={(item, row) => {
              return (
                <InputNumber
                  value={item?.PhanTram}
                  onChange={(v) => onChangeCoCau("PhanTram", v, row.rowIndex)}
                />
              );
            }}
          />
          <Column
            header={() => <p style={{ color: "red" }}>Thành tiền</p>}
            body={(item, row) => {
              return (
                <InputNumber
                  value={item?.ThanhTien}
                  onChange={(v) => onChangeCoCau("ThanhTien", v, row.rowIndex)}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              );
            }}
          />

          <Column
            header="Tổng tiền"
            body={(item, row) => {
              return (
                <InputNumber
                  value={item?.TongTien}
                  onChange={(v) => onChangeCoCau("TongTien", v, row.rowIndex)}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                />
              );
            }}
          />
          <Column
            header="Hiển thị"
            body={(item, row) => {
              return (
                <Input
                  value={item?.HienThi}
                  placeholder="Nhập hiển thị"
                  onChange={(e) =>
                    onChangeCoCau("HienThi", e.target.value, row.rowIndex)
                  }
                />
              );
            }}
          />
          <Column
            header={
              <Popconfirm
                title="Xoá tất cả"
                description="Bạn có chắc chắn muốn xoá tất cả?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => deleteCoCauAll()}
              >
                <Tooltip title="Xóa tất cả">
                  <Button danger ghost icon={<MdOutlineRemoveRoad />} />
                </Tooltip>
              </Popconfirm>
            }
            body={(item, row) => {
              return (
                <Button
                  onClick={() => deleteCoCau(item, row.rowIndex)}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              );
            }}
          />
        </DataTable>
        <Flex justify="space-between" align="center" style={{ width: 825 }}>
          <p style={{ fontWeight: "bold" }}>Tổng tiền</p>
          <p style={{ fontWeight: "bold" }}>
            {Format_Currency(_.sumBy(coCau, "TongTien"))}
          </p>
        </Flex>
      </>
    );
  };
  const items = [
    {
      key: "1",
      label: "Thông tin chung",
      children: renderInfo(),
    },
    {
      key: "2",
      label: "Cơ cấu giải thưởng",
      children: renderCoCauGiai(),
    },
  ];

  return (
    <Drawer
      title="THÊM GIẢI"
      width={1120}
      onClose={onClose}
      open={open}
      className="giai-dau-detail"
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(false)}
            type="primary"
            ghost
          >
            Lưu
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Tabs defaultActiveKey="1" items={items} />
      <FormAddLichTD ref={refAddLichTD} onAfterSubmit={onAfterSubmitLichTD} />
      <FormImport ref={refImportCoCau} onAfterSubmit={onAfterSubmitImport} />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
