import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import "./scss/index.scss";
import { HomeService } from "services/HomeService";
import { Format_Currency } from "layouts/ultis/common";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import ringer from "../../assets/audio/beepspark.wav";
import { TourService } from "services/TourService";
import SignalrContext from "contexts/SignalrContext";
import { GiaiDauService } from "services/GiaiDauService";

export default function Portal() {
  let params = useLocation();
  const { hubConnection } = useContext(SignalrContext);
  const [hubConnectionGD, setHubConnectionGD] = useState(null);
  const [time, setTime] = useState({
    elapsed: 0,
    nextBreak: 0,
    time: 0,
  });
  const [isPause, setIsPause] = useState(true);
  const [isStart, setIsStart] = useState(false);
  const [background, setBackground] = useState("");
  const [screen, setScreen] = useState(1);
  const [data, setData] = useState({});
  const [giai, setGiai] = useState([]);
  const [jackport, setJackport] = useState(0);
  const initData = async () => {
    window.scrollTo(0, 0);
    let indextype = params?.search?.indexOf("&type");
    let indexmact = params?.search?.indexOf("&mact");
    let id = params?.search.substring(6, indextype);
    let type = params?.search.substring(indextype + 6, indexmact);
    let mact = params?.search.substring(indexmact + 6);
    (async () => {
      setScreen(Number(type));
      if (type == 3) {
        let _jp = await GiaiDauService.getJackpot({ TenCTDK: mact });
        setJackport(_jp?.data?.gtd);
      }
      let _res = await HomeService.ManHinh.getDetailNoAuth({
        ID: Number(id),
        TenCTDK: mact,
      });
      let _resCoCau = await HomeService.ManHinh.getCoCauGiaiThuongNoAuth({
        IDGD: Number(id),
        TrangThai: 2,
        TenCTDK: mact,
      });
      setGiai(_resCoCau.data);
      if (_res.data) {
        setTime({
          elapsed: _res.data?.tongThoiGian,
          nextBreak: _res.time?.nextBreak,
          time:
            _res.data?.thoiGianBD > 0
              ? _res.data?.thoiGianBD
              : _res.time?.thoiGianConLai,
        });
        setBackground(_res?.data.uri);
        setIsPause(_res.data?.thoiGianBD > 0 ? false : _res?.data?.isPause);
        setIsStart(_res.data?.thoiGianBD > 0 ? true : _res?.data?.isStart);
        setData(_res);
      }
    })();
    const startConnection = async () => {
      try {
        const connection = new HubConnectionBuilder()
          .withUrl("https://api-poker.beesky.vn/tour")
          .withAutomaticReconnect([
            0, 1000, 2000, 3000, 6000, 10000, 15000, 30000,
          ])
          .configureLogging(LogLevel.Information)
          .build();

        connection.onreconnecting((error) => {
          console.log(`Connection lost due to error "${error}". Reconnecting.`);
        });

        connection.onreconnected(async (connectionId) => {
          console.log(
            `Connection reestablished. Connected with connectionId "${connectionId}".`
          );
          try {
            await connection.invoke(
              "JoinGroup",
              mact.toLowerCase() + String(id)
            );
            console.log(`Rejoined group: ${mact.toLowerCase() + String(id)}`);
          } catch (err) {
            console.error(`Failed to rejoin group: ${err}`);
          }
        });

        await connection.start();
        console.log("SignalR Connected.");
        await connection.invoke("JoinGroup", mact.toLowerCase() + String(id));
        console.log(`Joined group: ${mact.toLowerCase() + String(id)}`);
        setHubConnectionGD(connection);
      } catch (err) {
        console.error(`Connection failed: ${err}`);
        setTimeout(() => startConnection(), 5000); // Retry every 5 seconds
      }
    };
    startConnection();
  };

  useEffect(() => {
    (async () => await initData())();

    return () => {
      if (hubConnectionGD) {
        hubConnectionGD.stop();
      }
    };
  }, [params]);

  useEffect(() => {
    let _time = null;
    if (isStart && isPause === false) {
      _time = setInterval(() => {
        setTime((pre) => {
          return {
            elapsed: pre.elapsed + 1,
            nextBreak: pre.nextBreak - 1,
            time: pre.time - 1,
          };
        });
      }, 1000);
    }
    return () => clearInterval(_time);
  }, [isStart, isPause]);
  useEffect(() => {
    if (time.time < 0 && time.time > -2) {
      let e = document.querySelector("#playAudio");
      e.loop = false;
      let indextype = params?.search?.indexOf("&type");
      let indexmact = params?.search?.indexOf("&mact");
      let id = params?.search.substring(6, indextype);
      let mact = params?.search.substring(indexmact + 6);
      if (data?.data?.thoiGianBD > 0) {
        TourService.startGiaiDau({ ID: Number(id) }).then(() => {
          HomeService.ManHinh.getDetailNoAuth({
            ID: Number(id),
            TenCTDK: mact,
          }).then((_res) => {
            if (_res.time) {
              setData(_res);
              setTime({
                elapsed: _res?.data?.tongThoiGian + 1,
                nextBreak: _res?.time?.nextBreak + 1,
                time: _res.time?.thoiGianConLai + 1,
              });
            }
          });
        });
      } else {
        HomeService.ManHinh.getDetailNoAuth({
          ID: Number(id),
          TenCTDK: mact,
        }).then((_res) => {
          if (_res.time) {
            setData(_res);
            setTime({
              elapsed: _res?.data?.tongThoiGian + 1,
              nextBreak: _res?.time?.nextBreak + 1,
              time: _res.time?.thoiGianConLai + 1,
            });
          }
        });
      }
    } else if (time.time < 5 && time.time > 2) {
      let e = document.querySelector("#playAudio");
      e.play();
    }
  }, [time.time]);

  useEffect(() => {
    if (hubConnectionGD) {
      hubConnectionGD.on("ActionChange", async (response) => {
        // let indextype = params?.search?.indexOf("&type");
        // let id = params?.search.substring(6, indextype);
        // let indexmact = params?.search?.indexOf("&mact");
        // let mact = params?.search.substring(indexmact + 6);
        // if (
        //   Number(response?.data?.id) === Number(id) &&
        //   response?.tenCTDK?.toLowerCase() === mact?.toLowerCase()
        // ) {
        if (response?.time?.reloadTime === true) {
          setTime({
            elapsed: response?.data?.tongThoiGian,
            nextBreak: response?.time?.nextBreak ?? 0,
            time:
              response?.data?.thoiGianBD > 0
                ? response?.data?.thoiGianBD
                : response.time?.thoiGianConLai,
          });
        }
        //setGiai(response.giaiThuong);
        setIsPause(
          response?.data?.thoiGianBD > 0 ? false : response?.data?.isPause
        );
        setIsStart(
          response?.data?.thoiGianBD > 0 ? true : response?.data?.isStart
        );
        setData(response);
        // }
      });
      const tangNguoiChoi = (response) => {
        setData((pre) => {
          let conlai = pre.data.conLai + response?.soLuongTang;
          return {
            ...pre,
            data: {
              ...pre.data,
              conLai: conlai,
              avgStack: pre.data.toTalChip / conlai,
            },
          };
        });
      };
      const giamNguoiChoi = (response) => {
        setData((pre) => {
          let conlai = pre.data.conLai - response?.soLuongGiam;
          return {
            ...pre,
            data: {
              ...pre.data,
              conLai: conlai,
              avgStack: conlai > 0 && pre.data.toTalChip / conlai,
            },
          };
        });
      };
      const pauseContinue = (response) => {
        setIsPause(response.pause);
        setData((pre) => {
          return {
            ...pre,
            data: {
              ...pre.data,
              isPause: response.pause,
            },
          };
        });
      };
      const showGiai = (response) => {
        console.log("showgiai", response);
        const newGiais = response?.listGiai?.map((giai) => ({
          id: giai?.ID,
          tenGiai: giai?.TenGiai,
          hienThi: giai?.HienThi,
        }));
        setGiai(newGiais);
        setData((pre) => {
          return {
            ...pre,
            data: {
              ...pre.data,
              isShowGiai: response.isShowGiai,
            },
          };
        });
      };
      const thayDoiGiai = (response) => {
        console.log(response);

        const newGiais = response?.data?.map((giai) => ({
          id: giai?.ID,
          tenGiai: giai?.TenGiai,
          hienThi: giai?.HienThi,
        }));
        setGiai(newGiais);
      };
      const tatCoCauGiai = (response) => {
        setGiai((pre) => pre.filter((v) => v.id !== response.id));
      };
      hubConnectionGD.on("HienThiGiai", showGiai);
      hubConnectionGD.on("PauseContinue", pauseContinue);
      hubConnectionGD.on("TangNguoiChoi", tangNguoiChoi);
      hubConnectionGD.on("GiamNguoiChoi", giamNguoiChoi);
      hubConnectionGD.on("ThayDoiGiai", thayDoiGiai);
      hubConnectionGD.on("TatCoCauGiai", tatCoCauGiai);
      return () => {
        hubConnectionGD.off("HienThiGiai", showGiai);
        hubConnectionGD.off("PauseContinue", pauseContinue);
        hubConnectionGD.off("TangNguoiChoi", tangNguoiChoi);
        hubConnectionGD.off("GiamNguoiChoi", giamNguoiChoi);
        hubConnectionGD.off("ThayDoiGiai", thayDoiGiai);
        hubConnectionGD.off("TatCoCauGiai", tatCoCauGiai);
      };
    }
  }, [hubConnectionGD]);
  useEffect(() => {
    if (hubConnection) {
      const openGiaiDau = async (response) => {
        if (response.deviceID === localStorage.getItem("deviceID")) {
          // let indextype = params?.search?.indexOf("&type");
          let indexmact = params?.search?.indexOf("&mact");
          // let id = params?.search.substring(6, indextype);
          // let type = params?.search.substring(indextype + 6, indexmact);
          let mact = params?.search.substring(indexmact + 6);

          window.location.href =
            window.location.origin +
            `#/portal?magd=${response.maGD}&type=${
              response.manHinh ?? 2
            }&mact=${mact}`;
        }
      };
      const changeJackport = async (response) => {
        setJackport(response?.gtd);
      };
      hubConnection.on("ActionChangeJackpot", changeJackport);
      hubConnection.on("OpenGiaiDau", openGiaiDau);
      return () => {
        hubConnection.off("ActionChangeJackpot", changeJackport);
        hubConnection.off("OpenGiaiDau", openGiaiDau);
      };
    }
  }, [hubConnection]);
  const getValueTime = (prop, type) => {
    let hour = Math.floor((time[prop] % (60 * 60 * 24)) / (60 * 60));
    let min = Math.floor((time[prop] % (60 * 60)) / 60);
    let second = Math.floor(time[prop] % 60);
    if (type === "hour") {
      return `${hour < 10 ? "0" + (hour < 0 ? "0" : hour) : hour}:${
        min < 10 ? "0" + (min < 0 ? "0" : min) : min
      }:${second < 10 ? "0" + (second < 0 ? "0" : second) : second}`;
    }
    return `${min < 10 ? "0" + (min < 0 ? "0" : min) : min}:${
      second < 10 ? "0" + (second < 0 ? "0" : second) : second
    }`;
  };
  const getTime = (prop, type) => {
    let hour = Math.floor((time[prop] % (60 * 60 * 24)) / (60 * 60));
    let min = Math.floor((time[prop] % (60 * 60)) / 60) + hour * 60;
    let second = Math.floor(time[prop] % 60);
    if (type === "hour") {
      return `${hour < 10 ? "0" + (hour < 0 ? "0" : hour) : hour}:${
        min < 10 ? "0" + (min < 0 ? "0" : min) : min
      }:${second < 10 ? "0" + (second < 0 ? "0" : second) : second}`;
    }
    return `${min < 10 ? "0" + (min < 0 ? "0" : min) : min}:${
      second < 10 ? "0" + (second < 0 ? "0" : second) : second
    }`;
  };
  const fullScreen = () => {
    if (screen === 1) {
      let e = document.querySelector(".container-screen1");
      e?.requestFullscreen();
    } else if (screen === 2) {
      let e = document.querySelector(".container-screen2");
      e?.requestFullscreen();
    } else {
      let e = document.querySelector(".container-screen3");
      e?.requestFullscreen();
    }
  };
  const renderScreen1 = () => {
    return (
      <div
        className="container-screen1"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="center">
          <h1 className="title">{data?.data?.tenGD}</h1>
          <div style={{ textAlign: "center" }}>
            <h2 className="time">{getValueTime("time", "min")}</h2>
            <h4 className="level">{data?.time?.tenLevel}</h4>
            {/* {isPause ? ( */}

            <>
              <div className="bigsmall">
                <div style={{ paddingRight: 120 }}>
                  <h1 className="textvalue" style={{ textAlign: "center" }}>
                    {Format_Currency(data?.time?.small)}
                  </h1>
                  <h1 className="textContent">SMALL BLIND</h1>
                </div>
                <div style={{ paddingLeft: 120 }}>
                  <h1 className="textvalue" style={{ textAlign: "center" }}>
                    {Format_Currency(data?.time?.big)}
                  </h1>
                  <h1 className="textContent">BIG BLIND</h1>
                </div>
              </div>
              <h1 className="textvalue">{Format_Currency(data?.time?.ante)}</h1>
              <h1 className="textContent">ANTE</h1>
            </>
          </div>
          <p className="nextblind" style={{ paddingBottom: 50 }}>
            NEXT BLIND: {data?.time?.nextBlind}
          </p>
        </div>
        <div className="right">
          <div className="right-center">
            <div>
              <p className="rigth-text">NEXT BREAK</p>
              <p className="rigth-value">{getValueTime("nextBreak", "hour")}</p>

              <p className="rigth-text">PLAYER</p>
              <p className="rigth-value">
                {data?.data?.conLai}/{data?.data?.soNguoiChoi}
              </p>

              <p className="rigth-text">REBUYS/TOTAL BUYIN</p>
              <p className="rigth-value">
                {data?.data?.rebuy}/{data?.data?.totalEntry}
              </p>

              <p className="rigth-text">TOTAL CHIP</p>
              <p className="rigth-value">
                {Format_Currency(data?.data?.toTalChip)}
              </p>

              <p className="rigth-text">AVG STACK</p>
              <p className="rigth-value">
                {Format_Currency(data?.data?.avgStack)}
                <span style={{ fontSize: "25px" }}>
                  (
                  {Format_Currency(
                    Number(data?.data?.avgStack / data?.time?.big).toFixed(0)
                  )}
                  BB)
                </span>
              </p>
            </div>
            <div>
              <p className="rigth-text">ELAPSED TIME</p>
              <p className="rigth-value">{getValueTime("elapsed", "hour")}</p>
            </div>
          </div>
        </div>
        <div className="box-left">
          <div className="centerLeft">
            {data?.data?.isShowGiai === true && (
              <>
                <div style={{ padding: "25px 0" }}>
                  <p className="totalPrize">TOTAL PRIZE POOL</p>
                  <p className="totalPrizeValue">
                    {Format_Currency(data?.data?.prizepool)}
                  </p>
                </div>
                <div>
                  {giai?.length >= 12 ? (
                    <marquee direction="up">
                      {giai?.map((item) => {
                        return (
                          <p key={item.id} className="textRank">
                            {item.tenGiai}: <span>{item.hienThi}</span>
                          </p>
                        );
                      })}
                    </marquee>
                  ) : (
                    giai?.map((item) => {
                      return (
                        <p key={item.id} className="textRank">
                          {item.tenGiai}: <span>{item.hienThi}</span>
                        </p>
                      );
                    })
                  )}
                </div>
              </>
            )}
            <p></p>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            left: 10,
            top: 10,
          }}
        >
          <p style={{ color: "white", fontWeight: "bold", fontSize: 30 }}>
            {new Date().getHours()}:
            {new Date().getMinutes() < 10
              ? "0" + new Date().getMinutes()
              : new Date().getMinutes()}
            :
            {new Date().getSeconds() < 10
              ? "0" + new Date().getSeconds()
              : new Date().getSeconds()}
          </p>
        </div>
        <div
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            zIndex: 2,
          }}
        >
          <p
            onClick={() => fullScreen()}
            style={{ color: "white", cursor: "pointer", fontSize: 15 }}
          >
            [ - ]
          </p>
        </div>
        {data?.data?.isPause && (
          <div style={{ position: "absolute", bottom: 80, width: "100%" }}>
            <h2
              style={{
                color: "white",
                textAlign: "center",
                fontSize: 120,
                fontWeight: "bold",
              }}
            >
              PAUSE
            </h2>
          </div>
        )}
      </div>
    );
  };
  const renderScreen2 = () => {
    return (
      <div
        className="container-screen2"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="timenow">
          {new Date().getHours()}:
          {new Date().getMinutes() < 10
            ? "0" + new Date().getMinutes()
            : new Date().getMinutes()}
          :
          {new Date().getSeconds() < 10
            ? "0" + new Date().getSeconds()
            : new Date().getSeconds()}
        </div>
        <div className="top">
          <div style={{ width: "20%" }}></div>
          <div style={{ width: "60%" }}>
            <p className="title">{data?.data?.tenGD}</p>
            <p className="sub-title">{data?.data?.ghiChu}</p>
            <p className="sub-title">{data?.data?.ghiChu2}</p>
          </div>
          <p className="level" style={{ width: "20%" }}>
            {data?.time?.tenLevel}
          </p>
        </div>
        <div className="middle">
          <div className="left">
            {giai?.length >= 12 ? (
              <marquee direction="up">
                <div className="top-left">
                  {data?.data?.isShowGiai === true &&
                    giai &&
                    giai?.map((item) => {
                      return (
                        <p className="giai">
                          {item.tenGiai}: <span>{item.hienThi}</span>
                        </p>
                      );
                    })}
                </div>
              </marquee>
            ) : (
              <div className="top-left">
                {data?.data?.isShowGiai === true &&
                  giai &&
                  giai?.map((item) => {
                    return (
                      <p className="giai">
                        {item.tenGiai}: <span>{item.hienThi}</span>
                      </p>
                    );
                  })}
              </div>
            )}
            {data?.data?.isShowGiai === true && (
              <div className="footer-left">
                <p className="total-prize-text">TOTAL PRIZE POOL</p>
                <p className="total-prize">
                  {Format_Currency(data?.data?.prizepool)}
                </p>
              </div>
            )}
          </div>
          <div className="center">
            <div className="top-center">
              {data?.data?.thoiGianBD > 0 && (
                <p
                  style={{
                    fontSize: "60px",
                    fontWeight: "bold",
                    color: "yellow",
                  }}
                >
                  REGISTRATION
                </p>
              )}
              <p className="time">{getTime("time", "min")}</p>
              <div className="blinds">
                <p className="text-blind">BLINDS:</p>
                {data?.data?.thoiGianBD <= 0 && (
                  <p className="text-blind">
                    {Format_Currency(data?.time?.small)}/
                    {Format_Currency(data?.time?.big)}
                  </p>
                )}
              </div>
              <div className="blinds">
                <p className="text-blind">ANTE:</p>
                {data?.data?.thoiGianBD <= 0 && (
                  <p className="text-blind">
                    {Format_Currency(data?.time?.ante)}
                  </p>
                )}
              </div>
              <div className="blinds">
                <p className="text-level">NEXT LEVEL:</p>
                {data?.data?.thoiGianBD <= 0 && (
                  <p className="text-level">{data?.time?.nextBlind}</p>
                )}
              </div>
            </div>
            <div className="footer-center">
              <div>
                <p className="starting-text">STARTING</p>
                <p className="starting">{data?.data?.startingChip}</p>
              </div>
              <div>
                <p className="starting-text">TOTAL CHIPS</p>
                <p className="starting">
                  {Format_Currency(data?.data?.toTalChip)}
                </p>
              </div>
            </div>
          </div>
          <div className="right">
            <p className="right-text">NEXT BREAK</p>
            <p className="right-value">
              {data?.data?.thoiGianBD > 0
                ? ""
                : getValueTime("nextBreak", "hour")}
            </p>

            <p className="right-text">AVG STACK</p>
            <p className="right-value">
              {Format_Currency(data?.data?.avgStack)}
              <span style={{ fontSize: "25px" }}>
                (
                {Format_Currency(
                  Number(data?.data?.avgStack / data?.time?.big).toFixed(0)
                )}
                BB)
              </span>
            </p>

            <p className="right-text">PLAYERS</p>
            <p className="right-value">
              {data?.data?.conLai}/{data?.data?.player}
            </p>
            <div style={{ display: "flex" }}>
              <div>
                <p className="right-text">REBUY</p>
                <p className="right-value">{data?.data?.rebuy}</p>
              </div>
              <div style={{ paddingLeft: 20 }}>
                <p className="right-text">ADDON</p>
                <p className="right-value">{data?.data?.addon}</p>
              </div>
            </div>
            <p className="right-text">TOTAL ENTRIES</p>
            <p className="right-value">{data?.data?.totalEntry}</p>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            zIndex: 2,
          }}
        >
          <p
            onClick={() => fullScreen()}
            style={{ color: "white", cursor: "pointer", fontSize: 15 }}
          >
            [ - ]
          </p>
        </div>
        {data?.data?.isPause && data?.data?.thoiGianBD < 0 && (
          <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 100,
                color: "yellow",
              }}
            >
              PAUSE
            </p>
          </div>
        )}
      </div>
    );
  };
  const renderScreen3 = () => {
    return (
      <div
        className="container-screen3"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="timenow">
          {new Date().getHours()}:
          {new Date().getMinutes() < 10
            ? "0" + new Date().getMinutes()
            : new Date().getMinutes()}
          :
          {new Date().getSeconds() < 10
            ? "0" + new Date().getSeconds()
            : new Date().getSeconds()}
        </div>
        <div className="top">
          <div style={{ width: "20%" }}></div>
          <div style={{ width: "60%" }}>
            <p className="title">{data?.data?.tenGD}</p>
            <p className="sub-title">{data?.data?.ghiChu}</p>
            <p className="sub-title">{data?.data?.ghiChu2}</p>
          </div>
          <div style={{ width: "20%" }}>
            <p className="name-jackpot">JACKPOT</p>
            <p className="level">
              {/* {data?.time?.tenLevel} */}
              {Format_Currency(jackport)}
            </p>
          </div>
        </div>
        <div className="middle">
          <div className="left">
            {giai?.length >= 12 ? (
              <marquee direction="up">
                <div className="top-left">
                  {data?.data?.isShowGiai === true &&
                    giai &&
                    giai?.map((item) => {
                      return (
                        <p className="giai">
                          {item.tenGiai}: <span>{item.hienThi}</span>
                        </p>
                      );
                    })}
                </div>
              </marquee>
            ) : (
              <div className="top-left">
                {data?.data?.isShowGiai === true &&
                  giai &&
                  giai?.map((item) => {
                    return (
                      <p className="giai">
                        {item.tenGiai}: <span>{item.hienThi}</span>
                      </p>
                    );
                  })}
              </div>
            )}
            {data?.data?.isShowGiai === true && (
              <div className="footer-left">
                <p className="total-prize-text">TOTAL PRIZE POOL</p>
                <p className="total-prize">
                  {Format_Currency(data?.data?.prizepool)}
                </p>
              </div>
            )}
          </div>
          <div className="center">
            <div className="top-center">
              {data?.data?.thoiGianBD > 0 && (
                <p
                  style={{
                    marginTop: "-30px",
                    fontSize: "50px",
                    fontWeight: "bold",
                    color: "yellow",
                  }}
                >
                  REGISTRATION
                </p>
              )}
              {data?.data?.thoiGianBD <= 0 && (
                <p className="level">{data?.time?.tenLevel}</p>
              )}
              <p className="time">{getTime("time", "min")}</p>
              <div className="blinds">
                <p className="text-blind">BLINDS:</p>
                {data?.data?.thoiGianBD <= 0 && (
                  <p className="text-blind">
                    {Format_Currency(data?.time?.small)}/
                    {Format_Currency(data?.time?.big)}
                  </p>
                )}
              </div>
              <div className="blinds">
                <p className="text-blind">ANTE:</p>
                {data?.data?.thoiGianBD <= 0 && (
                  <p className="text-blind">
                    {Format_Currency(data?.time?.ante)}
                  </p>
                )}
              </div>
              <div className="blinds">
                <p className="text-level">NEXT LEVEL:</p>
                {data?.data?.thoiGianBD <= 0 && (
                  <p className="text-level">{data?.time?.nextBlind}</p>
                )}
              </div>
            </div>
            <div className="footer-center">
              <div>
                <p className="starting-text">STARTING</p>
                <p className="starting">{data?.data?.startingChip}</p>
              </div>

              <div>
                <p className="starting-text">TOTAL CHIPS</p>
                <p className="starting">
                  {Format_Currency(data?.data?.toTalChip)}
                </p>
              </div>
            </div>
          </div>
          <div className="right">
            <p className="right-text">NEXT BREAK</p>
            <p className="right-value">
              {data?.data?.thoiGianBD > 0
                ? ""
                : getValueTime("nextBreak", "hour")}
            </p>

            <p className="right-text">AVG STACK</p>
            <p className="right-value">
              {Format_Currency(data?.data?.avgStack)}
              <br />
              <span style={{ fontSize: "25px" }}>
                (
                {Format_Currency(
                  Number(data?.data?.avgStack / data?.time?.big).toFixed(0)
                )}
                BB)
              </span>
            </p>

            <p className="right-text">PLAYERS</p>
            <p className="right-value">
              {data?.data?.conLai}/{data?.data?.player}
            </p>
            <div style={{ display: "flex" }}>
              <div>
                <p className="right-text">REBUY</p>
                <p className="right-value">{data?.data?.rebuy}</p>
              </div>
              <div style={{ paddingLeft: 20 }}>
                <p className="right-text">TOTALBUY</p>
                <p className="right-value">{data?.data?.totalEntry}</p>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            zIndex: 2,
          }}
        >
          <p
            onClick={() => fullScreen()}
            style={{ color: "white", cursor: "pointer", fontSize: 15 }}
          >
            [ - ]
          </p>
        </div>
        {data?.data?.isPause && data?.data?.thoiGianBD < 0 && (
          <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 100,
                color: "yellow",
              }}
            >
              PAUSE
            </p>
          </div>
        )}
      </div>
    );
  };
  if (data?.data?.end === true) {
    return (
      <div
        // className="container-screen2"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            background: "white",
            padding: "40px",
            borderRadius: 10,
            boxShadow: "rgba(100, 100, 111) 0px 7px 29px 10px",
          }}
        >
          <p
            style={{ textAlign: "center", fontSize: "20px", fontWeight: "600" }}
          >
            {data?.data?.tenGD}
          </p>
          <p
            style={{ textAlign: "center", fontSize: "40px", fontWeight: "900" }}
          >
            Giải đấu đã kết thúc
          </p>
        </div>
      </div>
    );
  }
  return (
    <>
      {screen === 1
        ? renderScreen1()
        : screen === 2
        ? renderScreen2()
        : renderScreen3()}
      <audio loop={false} id="playAudio">
        <source src={ringer} />
      </audio>
    </>
  );
}
