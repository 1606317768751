import axios from "axios";
import { post } from "./api_helper";
import axiosApi from "./api_helper";
export const OtherService = {
  getDevice: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-device", payload)
      .then((res) => res.data);
  },
  addDevice: async (payload) => {
    return await axiosApi()
      .post("api/poker/add-new-device", payload)
      .then((res) => res.data);
  },
  updateDevice: async (payload) => {
    return await axiosApi()
      .post("api/poker/update-device", payload)
      .then((res) => res.data);
  },
  deleteDevice: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-device", payload)
      .then((res) => res.data);
  },
  sendZaloOtp: async (payload) => {
    return await axiosApi()
      .post("api/crm/send-zalo-otp", payload)
      .then((res) => res.data);
  },
  addHistory: async (payload) => {
    return await axiosApi()
      .post("api/other/add-history", payload)
      .then((res) => res.data);
  },
  getHistoryThaoTac: async (payload) => {
    return await axiosApi()
      .post("api/other/get-lsthaotac", payload)
      .then((res) => res.data);
  },
  getMauIn: async () => {
    return await axiosApi()
      .post("api/poker/get-mauin")
      .then((res) => res.data);
  },
};
