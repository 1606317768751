import {
  BankOutlined,
  CloseOutlined,
  CreditCardOutlined,
  DollarOutlined,
  FileTextOutlined,
  LeftOutlined,
  LoadingOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import {
  Button,
  Drawer,
  Flex,
  Image,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Spin,
} from "antd";
import { Format_Currency } from "layouts/ultis/common";
import React, { useEffect, useRef, useState } from "react";
import { OrderService } from "services/OrdersService";
import "./index.scss";
const FoodOeder = ({
  setLayout,
  setActiveTab,
  carts,
  onBack,
  setVisibleOrder,
  editFood,
  setCarts,
  table,
  valueSeat,
  seat,
  khachHang,
  point,
  maCT,
  setPoint,
  hTTT,
  idSeat,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [idFood, setIdFood] = useState();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [valueRadio, setValueRadio] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [payment, setPayment] = useState(
    hTTT[0] ? hTTT[0] : { TenHinhThuc: "Tiền mặt" }
  );
  const [doiDiem, setDoiDiem] = useState(0);
  const inputRef = useRef(null);

  useEffect(() => {
    if (loading2 && inputRef.current) {
      inputRef.current.focus();
    }
  }, [loading2]);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type) => {
    api.warning({
      message: "Bạn không đủ điểm đổi!",
    });
  };
  const onChangeRadio = (e) => {
    setValueRadio(e.target.value);
    const valueHTTT = hTTT?.find((item) => item.ID === e.target.value);
    setPayment(valueHTTT);

    onClose();
  };
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const showDrawer2 = () => {
    setVisible2(true);
  };

  const onClose2 = () => {
    setVisible2(false);
  };

  const showModal = (value) => {
    if (value === "xoa") {
      setIdFood({
        id: "123456789xoa",
        tenSP: "Tất cả trong giỏ hàng",
      });
      setIsModalVisible(true);
    } else {
      setIdFood(value);
      setIsModalVisible(true);
    }
  };

  const handleOk = () => {
    if (idFood?.id === "123456789xoa") {
      setCarts([]);
      setLayout("home");
      setActiveTab("2");
    } else {
      const updatedCarts = carts.filter((item) => item.id !== idFood.id);
      if (updatedCarts.length > 0) {
        setCarts(updatedCarts);
      } else {
        setCarts([]);
        setLayout("home");
        setActiveTab("2");
      }
    }

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const showModal2 = (value) => {
    setIsModalVisible2(true);
  };

  const handleOk2 = () => {
    setIsModalVisible2(false);
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };

  const seatValue = seat.find((item) => item?.Name === valueSeat);
  const deduction = point?.tyLeQuyDoiDiem * doiDiem;
  const submit = async () => {
    setLoading(true);
    let _products = [];
    carts?.map((item) => {
      _products.push({
        quantity: item?.quantity,
        maSP: item?.id,
        giaBan: item?.giaBan,
        note: item?.ghiChu,
        tenSP: item?.tenSP,
        linkImage: item?.linkImage,
      });
    });
    const now = new Date();
    let _payload = {
      MaHTTT: payment?.ID,
      TenHTTT: payment?.TenHinhThuc,
      MaThe: khachHang?.MaThe,
      MaKH: khachHang?.MaKH,
      DiemTru: doiDiem,
      TienTru: deduction,
      TenCTDK: localStorage.getItem("tenCTDK"),
      TenBan: table?.Name,
      TenSeat: seatValue?.Name,
      MaBan: Number(table?.ID),
      MaSeat: Number(seatValue?.ID),
      Date: new Date(now.getTime() + 7 * 60 * 60 * 1000),
      // TotalPrice: carts?.reduce((pre, current) => {
      //   return (pre += current?.quantity * current.giaBan);
      // }, -deduction),
      TotalPrice: carts?.reduce((pre, current) => {
        return (pre += current?.quantity * current.giaBan);
      }),
      Products: _products,
    };
    let _res = await OrderService.checkout(_payload);
    if (localStorage.getItem("orders")) {
      let _orders = JSON.parse(localStorage.getItem("orders"));
      _orders.push(_payload);
      localStorage.setItem("orders", JSON.stringify(_orders));
    } else {
      let _orders = [];
      _orders.push(_payload);
      localStorage.setItem("orders", JSON.stringify(_orders));
    }
    setLoading(false);
    onBack(_res);
    let _res10 = await OrderService.getDiemTich({
      MaThe: khachHang?.MaThe,
      MaLT: khachHang?.MaLT,
      TenCTDK: maCT,
    });
    if (_res10.status === 2000) {
      setPoint(_res10.data);
    }
  };
  const handleQuyDoiDiem = () => {
    const sumOrder = carts?.reduce(
      (total, item) => total + item.giaBan * item.quantity,
      0
    );

    const sumDiemDoi = Math.ceil(sumOrder / point?.tyLeQuyDoiDiem);
    if (sumDiemDoi > point?.tongDiem) {
      // setDoiDiem(point?.tongDiem);
      openNotification();
      return;
    } else {
      setDoiDiem(sumDiemDoi);
    }

    setLoading2(true);
  };

  return (
    <div>
      <Drawer
        title="Chọn phương thức thanh toán?"
        placement="bottom"
        closable={false}
        onClose={onClose}
        open={visible}
        key="bottom"
        height="350px"
      >
        <div style={{ marginTop: "-10px" }}>
          {hTTT?.map((item) => {
            return (
              <div style={{ paddingBottom: "15px" }} key={item.ID}>
                <Radio.Group onChange={onChangeRadio} value={valueRadio}>
                  <Radio value={item?.ID}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {item.TenHinhThuc}
                    </div>
                  </Radio>
                </Radio.Group>
              </div>
            );
          })}
        </div>
      </Drawer>
      <Drawer
        title="Mã giảm giá"
        placement="bottom"
        closable={false}
        onClose={onClose2}
        open={visible2}
        key="bottom"
        height="500px"
      >
        <div>
          <Flex>
            <Input
              className="input-order"
              placeholder="Bạn có mã giảm giá?"
              prefix={
                <PercentageOutlined
                  style={{ color: "rgba(0,0,0,.25)", marginRight: "8px" }}
                />
              }
              style={{
                textAlign: "center",
                marginBottom: "15px",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
              }}
            />
            <Button style={{ height: "50px", marginLeft: "10px" }}>
              Áp dụng
            </Button>
          </Flex>

          <div>
            <p>Mã của bạn</p>
          </div>
        </div>
      </Drawer>
      <Modal
        centered
        visible={isModalVisible}
        onCancel={handleCancel}
        closable={false}
        footer={
          <div style={{ textAlign: "center" }}>
            <Button
              key="cancel"
              onClick={handleCancel}
              style={{
                marginRight: "10px",
                backgroundColor: "#f5f5f5",
                color: "#808080",
                border: "none",
              }}
            >
              Không
            </Button>
            <Button
              key="ok"
              type="primary"
              onClick={handleOk}
              style={{ color: "#fff", backgroundColor: "#f7941e" }}
            >
              Đồng ý
            </Button>
          </div>
        }
      >
        <p
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "15px",
          }}
        >
          {`Bạn có chắc loại bỏ sản phẩm ${idFood?.tenSP}`}
        </p>
      </Modal>
      <Modal
        centered
        visible={isModalVisible2}
        onCancel={handleCancel2}
        closable={false}
        footer={
          <div style={{ textAlign: "center" }}>
            <Button
              key="cancel"
              onClick={handleCancel2}
              style={{
                marginRight: "10px",
                backgroundColor: "#f5f5f5",
                color: "#808080",
                border: "none",
              }}
            >
              Không
            </Button>
            <Button
              key="ok"
              type="primary"
              onClick={handleOk2}
              style={{ color: "#fff", backgroundColor: "#f7941e" }}
            >
              Đồng ý
            </Button>
          </div>
        }
      >
        <p
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontSize: "15px",
          }}
        >
          Nhập số điểm bạn muốn đổi
        </p>
        <Input
          className="input-order"
          placeholder="Nhập số điểm cần đổi"
          prefix={
            <FileTextOutlined
              style={{ color: "rgba(0,0,0,.25)", marginRight: "8px" }}
            />
          }
          style={{
            textAlign: "center",
            marginBottom: "15px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
          }}
        />
      </Modal>
      <Flex style={{ borderBottom: "1px solid #fff" }} justify="space-between">
        <div style={{ width: "20%" }}>
          <LeftOutlined
            onClick={() => {
              setLayout("home");
              setActiveTab("2");
            }}
            style={{
              backgroundColor: "#f5f5f5",
              padding: "8px",
              margin: "10px",
              zIndex: 1,
              color: "black",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          />
        </div>
        <p
          style={{
            textAlign: "center",
            margin: "15px",
            fontSize: "17px",
            width: "60%",
          }}
        >
          Các món đang chọn
        </p>
        <div style={{ width: "20%", margin: "15px" }}>
          <span
            onClick={() => showModal("xoa")}
            style={{
              color: "#d75454",
              fontSize: "13px",
              backgroundColor: "rgba(215, 84, 84, .1)",
              padding: "5px 10px",
              borderRadius: "8px",
            }}
          >
            Xoá giỏ
          </span>
        </div>
      </Flex>

      {carts?.map((item) => {
        return (
          <div
            key={item.id}
            style={{
              margin: "10px",
              marginTop: "15px",
              padding: "15px 15px 0px 15px",
              backgroundColor: "#fff",
              border: "1px solid #eee",
              borderRadius: "10px",
            }}
          >
            <Flex style={{ alignItems: "", marginBottom: "15px" }}>
              <Image
                style={{ borderRadius: "10px" }}
                width={"20%"}
                height={"auto"}
                preview={false}
                src={item.linkImage}
              />
              <div style={{ marginLeft: "15px", width: "80%" }}>
                <Flex>
                  <span
                    style={{
                      display: "inline-block",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                      marginTop: "5px",
                      // opacity: "0.5",
                    }}
                  >
                    <span
                      style={{
                        color: "#f7941e",
                        fontWeight: "bold",
                        fontSize: "15px",
                        paddingRight: "5px",
                      }}
                    >
                      {item.quantity} x{" "}
                    </span>{" "}
                    {item.tenSP}
                  </span>
                  <CloseOutlined
                    onClick={() => showModal(item)}
                    style={{
                      padding: "6px",
                      color: "grey",
                      borderRadius: "4px",
                      backgroundColor: "rgba(0, 0, 0, .0392156863)",
                    }}
                  />
                </Flex>
                <p style={{ marginTop: "0px", color: "#808080" }}>
                  {" "}
                  {item.seat}
                </p>
                <Flex style={{ marginTop: "10px" }} justify="space-between">
                  <p>{Format_Currency(item.giaBan * item.quantity)}</p>
                  <span
                    onClick={() => editFood(item)}
                    style={{ color: "#f7941e" }}
                  >
                    Chỉnh sửa
                  </span>
                </Flex>
              </div>
            </Flex>
          </div>
        );
      })}
      <div style={{ margin: "10px" }}>
        {/* <Input
          className="input-order"
          placeholder="Bạn có nhắn gì với nhà hàng không?"
          prefix={
            <FileTextOutlined
              style={{ color: "rgba(0,0,0,.25)", marginRight: "8px" }}
            />
          }
          style={{
            textAlign: "center",
            marginBottom: "15px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
          }}
        /> */}
      </div>
      <div style={{ margin: "25px 20px" }}>
        <Flex
          style={{
            marginBottom: "10px",
            fontSize: "15px",
            borderBottom: "1px solid #eee",
            cursor: "pointer",
            paddingBottom: "10px",
          }}
          justify="space-between"
        >
          <p>Tổng cộng {carts?.length} phần </p>
          <p>
            {Format_Currency(
              carts?.reduce(
                (total, item) => total + item.giaBan * item.quantity,
                0
              )
            )}
          </p>
        </Flex>
        <Flex
          onClick={() => showDrawer()}
          style={{
            marginBottom: "15px",
            fontSize: "15px",
            borderBottom: "1px solid #eee",
            cursor: "pointer",
            paddingBottom: "10px",
          }}
          justify="space-between"
        >
          <p>Phương thức thanh toán </p>
          <p>{payment?.TenHinhThuc}</p>
        </Flex>
        <Flex
          className="container-input"
          onClick={() => handleQuyDoiDiem()}
          style={{
            marginBottom: "10px",
            fontSize: "15px",
            borderBottom: "1px solid #eee",
            cursor: "pointer",
            paddingBottom: "10px",
          }}
          justify="space-between"
        >
          <p>Số điểm của bạn ({Format_Currency(point?.tongDiem)}) </p>

          {!loading2 ? (
            <p>Đổi điểm</p>
          ) : (
            <InputNumber
              max={point?.tongDiem}
              value={doiDiem}
              onChange={(value) => setDoiDiem(value)}
              ref={inputRef}
              placeholder="Nhập số điểm cần đổi"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }
              parser={(value) => value.replace(/\.\s?|(,*)/g, "")}
              style={{
                textAlign: "right",
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "50px",
                marginTop: "-1px",
                marginLeft: "10px",
                fontSize: "16px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                display: "flex",
                alignItems: "flex-end",
              }}
            />
          )}
        </Flex>

        {doiDiem > 0 && (
          <Flex
            style={{
              marginBottom: "10px",
              fontSize: "15px",
              borderBottom: "1px solid #eee",
              cursor: "pointer",
              paddingBottom: "10px",
            }}
            justify="space-between"
          >
            <p>Số tiền trừ</p>
            <p>{Format_Currency(deduction)}</p>
          </Flex>
        )}

        {/* <Flex
          onClick={() => showDrawer2()}
          style={{
            marginBottom: "10px",
            fontSize: "15px",
            borderBottom: "1px solid #eee",
            cursor: "pointer",
            paddingBottom: "10px",
          }}
          justify="space-between"
        >
          <p>Mã giảm giá </p>
          <p>Chọn</p>
        </Flex> */}

        <Flex
          style={{
            marginBottom: "10px",
            fontSize: "15px",
          }}
          justify="space-between"
        >
          <p>Tổng tiền </p>
          <p style={{ fontWeight: "bold", fontSize: "18px" }}>
            {Format_Currency(
              carts?.reduce(
                (total, item) => total + item.giaBan * item.quantity,
                0
              ) - (doiDiem && point?.tyLeQuyDoiDiem ? deduction : 0)
            )}
          </p>
        </Flex>
      </div>

      <div style={{}}>
        <div
          onClick={() => !loading && submit()}
          style={{
            // position: "fixed",
            // bottom: "20px",
            // width: "92%",
            backgroundColor: "rgb(247, 148, 30)",
            borderRadius: "10px",
            cursor: "pointer",
            margin: "50px 15px 20px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
              padding: "10px",
            }}
          >
            {loading ? (
              <Spin indicator={<LoadingOutlined spin />} />
            ) : (
              "Thanh toán"
            )}
          </p>
        </div>
      </div>
      {contextHolder}
    </div>
  );
};

export default FoodOeder;
