import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Flex,
  Tooltip,
  Popconfirm,
  Checkbox,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { JackpotService } from "services/JackpotService";
import { DateTime } from "luxon";
import { RecapService } from "services/ReacpService";
import { MdDelete, MdOutlineEdit } from "react-icons/md";
import { GiaiDauService } from "services/GiaiDauService";
import { IoIosAdd } from "react-icons/io";
import FormAddLichTD from "../DanhSachGiai/LichThiDau/FormAdd";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
const { TextArea } = Input;

const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const currentDateTime = DateTime.utc();
  const dateInHoChiMinh = currentDateTime.setZone("Asia/Ho_Chi_Minh");

  const defaultData = {
    NgayNhap: dateInHoChiMinh.toISO(),
  };
  const [data, setData] = useState(defaultData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lichThiDau, setLichThiDau] = useState([]);
  const [imgs, setImgs] = useState([]);
  const defaultErrors = {};
  const [errors, setErrors] = useState(defaultErrors);
  const refAddLichTD = useRef(null);

  useImperativeHandle(ref, () => ({
    show: async (LTD) => {
      setOpen(true);
      setIsUpdate(false);
      setLichThiDau(LTD ?? []);
    },
    update: async (data, LTD) => {
      setOpen(true);
      setIsUpdate(true);
      setData(data);
      setImgs([data.Image]);
      setLichThiDau(LTD ?? []);
    },
  }));
  const onClose = () => {
    setImgs([]);
    setOpen(false);
    setData(defaultData);
    onAfterSubmit();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    let _res = null;
    // if (isupdate) {
    //   _res = await JackpotService.updatePhieuThu(_data);
    // } else {
    //   _res = await JackpotService.addPhieuThu(_data);
    //   setData((pre) => {
    //     return { ...pre, ID: _res.data };
    //   });
    // }
    _data.Image = imgs.length > 0 ? imgs[0] : "";
    _res = await RecapService.add(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} danh sách recap thành công!`
      );
      setIsUpdate(true);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onAfterSubmitLichTD = (v) => {
    let _LichTD = _.cloneDeep(lichThiDau);
    if (v.isEdit) {
      let index = _LichTD.findIndex((d) => d.ID === v.ID);
      _LichTD[index] = v;
    } else {
      _LichTD.push(v);
    }
    setLichThiDau(_LichTD);
    let _data = _.cloneDeep(data);
    _data.MaLTD = v.ID;
    setData(_data);
  };
  const removeLichThiDau = async () => {
    let _data = _.cloneDeep(data);
    let _res = await GiaiDauService.LichThiDau.delete({
      ID: _data.MaLTD,
    });
    if (_res.status === 2000) {
      let _LichTD = _.cloneDeep(lichThiDau);
      let fil = _LichTD.filter((v) => v.ID !== _data.MaLTD);
      setLichThiDau(fil);
      _data.MaLTD = null;
      setData(_data);
      openNotification("success", "topRight", "Xoá thành công!");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const affterUpload = useCallback(
    async (file) => {
      if (file.length > 0) {
        setImgs([URL_UPLOAD + file[0]]);
      }
    },
    [setImgs]
  );
  const onRemove = useCallback(
    async (file) => {
      // let _file = files.find((v) => v.Link === file.url);
      // await VisaService.HoSo.deleteTaiLieu({
      //   ID: _file?.ID,
      // });
    },
    [setImgs]
  );
  return (
    <Drawer
      title="DANH SÁCH RECAP"
      width={620}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 12]}>
        <Col span={24}>
          <p>Tiêu đề</p>
          <Input
            // style={{ width: "100%" }}
            value={data?.TieuDe}
            onChange={(e) => applyChange("TieuDe", e.target.value)}
          />
        </Col>
        <Col span={24} className="gutter-row">
          <p>Lịch thi đấu</p>
          <Flex align="center">
            <Select
              style={{
                width: "100%",
              }}
              value={data?.MaLTD}
              placeholder="Chọn lịch thi đấu"
              onChange={(v) => applyChange("MaLTD", v)}
              options={lichThiDau}
              fieldNames={{ label: "TenLich", value: "ID" }}
            />
            <Tooltip title="Thêm">
              <Button
                onClick={() => refAddLichTD.current.show()}
                type="text"
                icon={<IoIosAdd size={25} />}
              />
            </Tooltip>

            {data?.MaLTD && (
              <>
                <Tooltip title="Sửa">
                  <Button
                    onClick={() =>
                      refAddLichTD.current.update(
                        lichThiDau?.find((d) => d.ID === data?.MaLTD)
                      )
                    }
                    type="text"
                    icon={<MdOutlineEdit size={18} />}
                  />
                </Tooltip>
                <Tooltip title="Xoá">
                  <Popconfirm
                    title="Xoá voucher"
                    description="Bạn có chắc chắn muốn xoá voucher hay không?"
                    okText="Đồng ý"
                    cancelText="Không"
                    onConfirm={() => removeLichThiDau()}
                  >
                    <Button type="text" icon={<MdDelete size={18} />} />
                  </Popconfirm>
                </Tooltip>
              </>
            )}
          </Flex>
        </Col>
        <Col span={24}>
          <p>Ngày nhập</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.NgayNhap)}
            locale={vi}
            onChange={(v) => applyChange("NgayNhap", v)}
          />
        </Col>

        <Col span={24}>
          <Flex>
            <div>
              <p style={{ fontWeight: "600", marginBottom: "10px" }}>
                Thêm ảnh
              </p>
              <UploadComponent
                files={imgs}
                multiple={false}
                affterUpload={affterUpload}
                onRemove={onRemove}
              />
            </div>

            {/* <div>
              <p style={{ marginBottom: "10px" }}>Hiển thị recap</p>
              <Checkbox
                checked={data?.IsRecap === true}
                onChange={() => applyChange("IsRecap", true)}
              >
                Có
              </Checkbox>
              <Checkbox
                checked={data?.IsRecap === false}
                onChange={() => applyChange("IsRecap", false)}
              >
                Không
              </Checkbox>
            </div> */}
          </Flex>
        </Col>
      </Row>
      <FormAddLichTD ref={refAddLichTD} onAfterSubmit={onAfterSubmitLichTD} />
    </Drawer>
  );
};
export default forwardRef(FormAdd);
