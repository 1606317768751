import axiosApi from "./api_helper";
export const PermissionService = {
  getMenuLevel1: async (payload) => {
    return await axiosApi()
      .post("api/permission/get-modules", payload)
      .then((res) => res.data);
  },
  getForm: async (payload) => {
    return await axiosApi()
      .post("api/permission/get-form", payload)
      .then((res) => res.data);
  },
  getNhomQuyen: async () => {
    return await axiosApi()
      .post("api/poker/get-nhom-quyen")
      .then((res) => res.data);
  },
  // addNhomQuyen: async (payload) => {
  //   return await axiosApi()
  //     .post("api/poker/add-update-permission", payload)
  //     .then((res) => res.data);
  // },
  addNhomQuyen: async (payload) => {
    return await axiosApi()
      .post("api/permission/add-update", payload)
      .then((res) => res.data);
  },
  deleteNhomQuyen: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-permission", payload)
      .then((res) => res.data);
  },

  updateAccessData: async (payload) => {
    return await axiosApi()
      .post("api/permission/update-accessdata", payload)
      .then((res) => res.data);
  },
  addAction: async (payload) => {
    return await axiosApi()
      .post("api/poker/add-action", payload)
      .then((res) => res.data);
  },
  deleteAction: async (payload) => {
    return await axiosApi()
      .post("api/poker/remove-action", payload)
      .then((res) => res.data);
  },
  addAccessData: async (payload) => {
    return await axiosApi()
      .post("api/permission/add-accessdata", payload)
      .then((res) => res.data);
  },
  TheoCongTy: {
    getCongTyDangKy: async () => {
      return await axiosApi()
        .post("api/cong-ty/get")
        .then((res) => res.data);
    },
    addForm: async (payload) => {
      return await axiosApi()
        .post("api/cong-ty/permission/add-form", payload)
        .then((res) => res.data);
    },
    getForm: async (payload) => {
      return await axiosApi()
        .post("api/cong-ty/permission/get-form", payload)
        .then((res) => res.data);
    },
    getMenu: async () => {
      return await axiosApi()
        .post("api/cong-ty/get-menu")
        .then((res) => res.data);
    },
    getTinhThanh: async () => {
      return await axiosApi()
        .post("api/core/get-tinh")
        .then((res) => res.data);
    },
  },
  TheoNhomModules: {
    get: async () => {
      return await axiosApi()
        .post("api/nhom-modules/get")
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/nhom-modules/add", payload)
        .then((res) => res.data);
    },
    addForm: async (payload) => {
      return await axiosApi()
        .post("api/nhom-modules/add-form", payload)
        .then((res) => res.data);
    },
    getForm: async (payload) => {
      return await axiosApi()
        .post("api/nhom-modules/get-form", payload)
        .then((res) => res.data);
    },
    getFormByMaNhom: async (payload) => {
      return await axiosApi()
        .post("api/nhom-modules/get-form-bymanhom", payload)
        .then((res) => res.data);
    },
  },
};
