import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Space,
  Drawer,
  notification,
  Popconfirm,
  UploadProps,
  Upload,
  Table,
  Form,
  Typography,
  InputNumber,
  Flex,
  Tooltip,
  Menu,
  Dropdown,
} from "antd";
import { DownOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import _ from "lodash";
import { TourService } from "services/TourService";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdCancel, MdEdit } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";
import { BuyinService } from "services/BuyinService";
import { AiOutlineToTop, AiOutlineVerticalAlignBottom } from "react-icons/ai";
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const SettingBlind = (props, ref) => {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [newFile, setNewFile] = useState(false);
  const [maGD, setMaGD] = useState(null);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.ID === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.ID);
  };
  const cancel = (record) => {
    setEditingKey("");
    if (record.IsAdd) {
      const index = data.findIndex((item) => record.ID === item.ID);
      if (index > -1) {
        form.setFieldsValue({});
        let newData = _.cloneDeep(data);
        newData.splice(index, 1);
        setData(newData);
      }
    }
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.ID);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        if (newData[index].IsAdd) {
          let isBreak = newData[index].TenLevel.toLowerCase().includes("break");
          let Small = newData[index].Small;
          newData[index].isBreak = isBreak || Small === 0;
          await BuyinService.addOneBlind(newData[index]);
          loadCategories(maGD);
        } else {
          let isBreak = newData[index].TenLevel.toLowerCase().includes("break");
          let Small = newData[index].Small;
          newData[index].isBreak = isBreak || Small === 0;
          await BuyinService.editBlind(newData[index]);
          setData(newData);
        }
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
    }
  };
  const loadCategories = async (magd) => {
    let _res = await TourService.getBlind({ MaGD: magd });
    setData(_res?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async (magd) => {
      setOpen(true);
      setMaGD(magd);
      loadCategories(magd);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setNewFile(false);
    setEditingKey("");
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let payload = {
      ListBlind: data,
      MaGD: maGD,
    };
    if (data?.length === 0) {
      setLoading(false);
      return;
    }
    let _res = await TourService.addBlind(payload);
    setLoading(false);
    if (_res.status === 2000) {
      setNewFile(false);
      openNotification("success", "topRight", `Cập nhật blind thành công!`);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const removeBlind = async () => {
    setLoading(true);
    let _res = await TourService.removeBlind({ MaGD: maGD });
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Xoá blind thành công!`);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
    setLoading(false);
  };
  const loadDataImport = (data) => {
    let res = [];
    data?.map((item, i) => {
      if (i > 0) {
        res.push({
          STT: i,
          TenLevel: item[0],
          Duration: item[1],
          Small: item[2],
          Big: item[3],
          Ante: item[4],
          isBreak: item[5]?.toString().toLowerCase() === "true" ? true : false,
        });
      }
    });
    setData(res);
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
      editable: true,
    },
    {
      title: "Level",
      dataIndex: "TenLevel",
      editable: true,
      render: (item, record) => (
        <p
          style={{
            fontWeight: record.isBreak ? "900" : "500",
            color: record.isBreak ? "red" : "black",
          }}
        >
          {item}
        </p>
      ),
    },
    {
      title: "Duration (m)",
      dataIndex: "Duration",
      editable: true,
    },
    {
      title: "Small",
      dataIndex: "Small",
      editable: true,

      render: (item) => Format_Currency(item),
    },
    {
      title: "BIG",
      dataIndex: "Big",
      editable: true,

      render: (item) => Format_Currency(item),
    },
    {
      title: "Ante",
      dataIndex: "Ante",
      editable: true,

      render: (item) => Format_Currency(item),
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record, index) => {
        const editable = isEditing(record);
        return editable ? (
          <Flex gap={10}>
            <Button
              type="primary"
              ghost
              icon={<FaRegSave />}
              onClick={() => save(record.ID)}
            />
            <Button
              type="primary"
              danger
              ghost
              icon={<MdCancel />}
              onClick={() => cancel(record)}
            />
          </Flex>
        ) : (
          <Flex gap={10}>
            <Button
              type="text"
              ghost
              disabled={editingKey !== ""}
              icon={<AiOutlineToTop />}
              onClick={() => {
                refAddRow.current = record;
                refIndex.current = index;
                handleMenuClick({ key: "addRowTop" });
              }}
            />
            <Button
              type="text"
              ghost
              disabled={editingKey !== ""}
              icon={<AiOutlineVerticalAlignBottom />}
              onClick={() => {
                refAddRow.current = record;
                refIndex.current = index;
                handleMenuClick({ key: "addRowBot" });
              }}
            />
            <Button
              type="primary"
              ghost
              disabled={editingKey !== ""}
              icon={<MdEdit />}
              onClick={() => edit(record)}
            />

            <Popconfirm
              title="Xoá"
              description="Bạn có chắc chắn muốn xoá không?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(record)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  type="primary"
                  danger
                  ghost
                  icon={<CiTrash />}
                  disabled={editingKey !== ""}
                />
              </Tooltip>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await TourService.removeBlindByID({
      ID: value.ID,
      STT: value.STT,
      MaGD: maGD,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== value.ID);
      setData(fil);
      openNotification("success", "topRight", "Xoá thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    let _data = [];
    data?.map((item) => {
      _data.push({
        TenLevel: item.TenLevel,
        Duration: item.Duration,
        Small: item.Small,
        Big: item.Big,
        Ante: item.Ante,
        isBreak: item.isBreak,
      });
    });
    if (_data?.length === 0) {
      _data = [
        {
          TenLevel: "Level 1",
          Duration: 25,
          Small: 100,
          Big: 100,
          Ante: 0,
          isBreak: false,
        },
        {
          TenLevel: "Break",
          Duration: 0,
          Small: 0,
          Big: 0,
          Ante: 0,
          isBreak: true,
        },
      ];
    }
    const ws = XLSX.utils.json_to_sheet(_data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Blind templete" + fileExtension);
  };
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "TenLevel" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const refAddRow = useRef(null);
  const refIndex = useRef(null);
  const handleRightClick = (event, record, index) => {
    event.preventDefault();
    refAddRow.current = record;
    refIndex.current = index;
    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
    });
  };
  const addBlind = async (value) => {
    const newData = {
      ID: Date.now(), // Tạo một key tạm thời
      IsAdd: true,
      Index: data?.length,
      STT: data?.length + 1,
      MaGD: maGD,
    };
    let _data = _.cloneDeep(data);
    _data.splice(data?.length, 0, newData);
    setData(_data);
    setEditingKey(newData.ID);
  };
  const handleMenuClick = (action) => {
    if (action.key === "addRowTop") {
      const newData = {
        ID: Date.now(), // Tạo một key tạm thời
        IsAdd: true,
        Index: refIndex.current,
        STT: refAddRow.current?.STT,
        MaGD: maGD,
      };
      let _data = _.cloneDeep(data);
      _data.splice(refIndex.current, 0, newData);
      setData(_data);
      setEditingKey(newData.ID);
    } else {
      const newData = {
        ID: Date.now(), // Tạo một key tạm thời
        IsAdd: true,
        Index: refIndex.current,
        STT: refAddRow.current?.STT + 1,
        MaGD: maGD,
      };
      let _data = _.cloneDeep(data);
      _data.splice(refIndex.current + 1, 0, newData);
      setData(_data);
      setEditingKey(newData.ID);
    }
    setContextMenu({ visible: false, x: 0, y: 0 });
  };
  const menu = (
    <Menu onClick={(e) => handleMenuClick(e)}>
      <Menu.Item key="addRowTop">
        <Flex gap={10} align="center">
          <AiOutlineToTop /> <p>Thêm dòng trên</p>
        </Flex>
      </Menu.Item>
      <Menu.Item key="addRowBot">
        <Flex gap={10} align="center">
          <AiOutlineVerticalAlignBottom /> <p> Thêm dòng dưới</p>
        </Flex>
      </Menu.Item>
    </Menu>
  );
  return (
    <Drawer
      title="Cài đặt blind"
      width={1120}
      onClose={onClose}
      open={open}
      className="giai-dau-detail"
      styles={{
        body: {
          // paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button
            icon={<PlusOutlined />}
            onClick={() => addBlind()}
            disabled={editingKey !== ""}
          >
            Thêm
          </Button>
          <Upload
            accept=".txt, .csv, .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                loadDataImport(data);
                setNewFile(true);
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <Button icon={<UploadOutlined />} onClick={() => exportToCSV()}>
            Export
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              setData([]);
              removeBlind();
            }}
            type="primary"
            danger
            ghost
          >
            Xoá tất cả
          </Button>
          {newFile && (
            <Button
              loading={loading}
              onClick={() => onSubmit(true)}
              type="primary"
            >
              Lưu & Đóng
            </Button>
          )}
        </Space>
      }
    >
      {contextHolder}
      <Form form={form} component={false}>
        <Table
          loading={loading}
          dataSource={data}
          // columns={columns}
          columns={mergedColumns}
          pagination={false}
          rowClassName="editable-row"
          onRow={(record, index) => ({
            onContextMenu: (event) => handleRightClick(event, record, index),
          })}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          scroll={{
            y: window.innerHeight - 150,
          }}
          size="small"
        />
        {contextMenu.visible && (
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            visible={true}
            onVisibleChange={(flag) =>
              setContextMenu({ ...contextMenu, visible: flag })
            }
          >
            <div
              style={{
                position: "fixed",
                left: contextMenu.x,
                top: contextMenu.y,
              }}
            >
              <DownOutlined />
            </div>
          </Dropdown>
        )}
      </Form>
    </Drawer>
  );
};
export default forwardRef(SettingBlind);
