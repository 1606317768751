import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Divider,
  Checkbox,
  Table,
  Tabs,
  Form,
  Popconfirm,
  Upload,
  message,
  Image,
  Flex,
  Modal,
} from "antd";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { TourService } from "services/TourService";
import { CiTrash } from "react-icons/ci";
import { FileUpload } from "primereact/fileupload";
import { HomeService } from "services/HomeService";
import "./scss/formDetail.scss";
import { Format_Currency } from "layouts/ultis/common";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { GiaiDauService } from "services/GiaiDauService";

const FormDetail = (props, ref) => {
  const { onAfterSubmit } = props;
  const [hubConnection, setHubConnection] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [giai, setGiai] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [background, setBackground] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataAction, setDataAction] = useState({});
  const refAction = useRef(null);
  const [time, setTime] = useState({
    elapsed: 0,
    nextBreak: 0,
    time: 0,
  });
  const [isPause, setIsPause] = useState(true);
  const [isStart, setIsStart] = useState(false);
  useEffect(() => {
    let _time = null;
    if (isStart && isPause === false) {
      _time = setInterval(() => {
        setTime((pre) => {
          return {
            elapsed: pre.elapsed + 1,
            nextBreak: pre.nextBreak - 1,
            time: pre.time - 1,
          };
        });
      }, 1000);
    }
    return () => clearInterval(_time);
  }, [isStart, isPause]);

  const load = async (magd) => {
    let _res = await HomeService.ManHinh.getDetail({
      ID: Number(magd),
    });
    let _resg = await TourService.getCoCauGiaiThuong({ ID: magd });
    setGiai(_resg.data);
    if (_res.data) {
      setTime({
        elapsed: _res.data?.tongThoiGian,
        nextBreak: _res.time?.nextBreak,
        time:
          _res.data?.thoiGianBD > 0
            ? _res.data?.thoiGianBD
            : _res.time?.thoiGianConLai,
      });
      setIsPause(_res.data?.thoiGianBD > 0 ? false : _res?.data?.isPause);
      setIsStart(_res.data?.thoiGianBD > 0 ? true : _res?.data?.isStart);
      setData(_res);
    }
  };
  useImperativeHandle(ref, () => ({
    show: async (data) => {
      setOpen(true);
      load(data?.ID);
      setBackground(data.uri);
      let tenctdkvt = localStorage.getItem("MaCTDK");
      try {
        let connection = new HubConnectionBuilder()
          .withUrl("https://api-poker.beesky.vn/tour")
          .withAutomaticReconnect()
          .configureLogging(LogLevel.Information)
          .build();
        await connection.start();
        connection.invoke(
          "JoinGroup",
          tenctdkvt.toLowerCase() + String(data?.ID)
        );
        setHubConnection(connection);
      } catch {}
    },
  }));
  const onClose = () => {
    setOpen(false);
    onAfterSubmit();
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(() => {
    if (time.time < 0) {
      if (data?.data?.thoiGianBD > 0) {
        TourService.startGiaiDau({ ID: data?.data?.id }).then(() => {
          HomeService.ManHinh.getDetai({
            ID: data?.data?.id,
          }).then((_res) => {
            if (_res.time) {
              setData(_res);
              setTime({
                elapsed: _res?.data?.tongThoiGian + 1,
                nextBreak: _res?.time?.nextBreak + 1,
                time: _res.time?.thoiGianConLai + 1,
              });
            }
          });
        });
      } else {
        HomeService.ManHinh.getDetail({
          ID: data?.data?.id,
        }).then((_res) => {
          if (_res.time) {
            setData(_res);
            setTime({
              elapsed: _res?.data?.tongThoiGian + 1,
              nextBreak: _res?.time?.nextBreak + 1,
              time: _res.time?.thoiGianConLai + 1,
            });
          }
        });
      }
    }
  }, [time.time]);
  useEffect(() => {
    if (hubConnection) {
      hubConnection.on("ActionChange", async (response) => {
        if (response?.time?.reloadTime === true) {
          setTime({
            elapsed: response?.data?.tongThoiGian,
            nextBreak: response?.time?.nextBreak ?? 0,
            time:
              response?.data?.thoiGianBD > 0
                ? response?.data?.thoiGianBD
                : response.time?.thoiGianConLai,
          });
        }
        setGiai(response.giaiThuong);
        setIsPause(
          response?.data?.thoiGianBD > 0 ? false : response?.data?.isPause
        );
        setIsStart(
          response?.data?.thoiGianBD > 0 ? true : response?.data?.isStart
        );
        setData(response);
      });
      const tangNguoiChoi = (response) => {
        // if (response?.maGD === data?.data?.id) {
        setData((pre) => {
          let conlai = pre.data.conLai + response?.soLuongTang;
          return {
            ...pre,
            data: {
              ...pre.data,
              conLai: conlai,
              avgStack: pre.data.toTalChip / conlai,
            },
          };
        });
        // }
      };
      const giamNguoiChoi = (response) => {
        // if (response?.maGD === data?.data?.id) {
        setData((pre) => {
          let conlai = pre.data.conLai - response?.soLuongGiam;
          return {
            ...pre,
            data: {
              ...pre.data,
              conLai: conlai,
              avgStack: conlai > 0 && pre.data.toTalChip / conlai,
            },
          };
        });
        // }
      };
      const pauseContinue = (response) => {
        setIsPause(response.pause);
        setData((pre) => {
          return {
            ...pre,
            data: {
              ...pre.data,
              isPause: response.pause,
            },
          };
        });
      };
      const showGiai = (response) => {
        setData((pre) => {
          return {
            ...pre,
            data: {
              ...pre.data,
              isShowGiai: response.isShowGiai,
            },
          };
        });
      };

      hubConnection.on("HienThiGiai", showGiai);
      hubConnection.on("PauseContinue", pauseContinue);
      hubConnection.on("TangNguoiChoi", tangNguoiChoi);
      hubConnection.on("GiamNguoiChoi", giamNguoiChoi);
      return () => {
        hubConnection.off("HienThiGiai", showGiai);
        hubConnection.off("PauseContinue", pauseContinue);
        hubConnection.off("TangNguoiChoi", tangNguoiChoi);
        hubConnection.off("GiamNguoiChoi", giamNguoiChoi);
      };
    }
  }, [hubConnection]);

  const onPauseContinue = async (ispause) => {
    setLoading(true);
    if (ispause) {
      await GiaiDauService.Actions.tamDung({
        ID: data?.data?.id,
        TrangThai: data?.data?.trangThai,
      });
    } else {
      await GiaiDauService.Actions.tiepTuc({
        ID: data?.data?.id,
        TrangThai: data?.data?.trangThai,
      });
    }
    setLoading(false);
  };
  const onShowGiai = async (isShow) => {
    setLoading(true);
    if (isShow) {
      await GiaiDauService.Actions.showGiai({
        ID: data?.data?.id,
        TrangThai: data?.data?.trangThai,
      });
    } else {
      await GiaiDauService.Actions.hideGiai({
        ID: data?.data?.id,
        TrangThai: data?.data?.trangThai,
      });
    }
    setLoading(false);
  };

  const onBackLevel = async () => {
    setLoading(true);
    await GiaiDauService.Actions.backLevel({
      ID: data?.data?.id,
      TrangThai: data?.data?.trangThai,
    });
    setLoading(false);
  };
  const onNextLevel = async () => {
    setLoading(true);
    await GiaiDauService.Actions.nextLevel({
      ID: data?.data?.id,
      TrangThai: data?.data?.trangThai,
    });
    setLoading(false);
  };
    const getValueTime = (prop, type) => {
      let hour = Math.floor((time[prop] % (60 * 60 * 24)) / (60 * 60));
      let min = Math.floor((time[prop] % (60 * 60)) / 60);
      let second = Math.floor(time[prop] % 60);
      if (type === "hour") {
        return `${hour < 10 ? "0" + (hour < 0 ? "0" : hour) : hour}:${
          min < 10 ? "0" + (min < 0 ? "0" : min) : min
        }:${second < 10 ? "0" + (second < 0 ? "0" : second) : second}`;
      }
      return `${min < 10 ? "0" + (min < 0 ? "0" : min) : min}:${
        second < 10 ? "0" + (second < 0 ? "0" : second) : second
      }`;
    };
    const getTime = (prop, type) => {
      let hour = Math.floor((time[prop] % (60 * 60 * 24)) / (60 * 60));
      let min = Math.floor((time[prop] % (60 * 60)) / 60) + hour * 60;
      let second = Math.floor(time[prop] % 60);
      if (type === "hour") {
        return `${hour < 10 ? "0" + (hour < 0 ? "0" : hour) : hour}:${
          min < 10 ? "0" + (min < 0 ? "0" : min) : min
        }:${second < 10 ? "0" + (second < 0 ? "0" : second) : second}`;
      }
      return `${min < 10 ? "0" + (min < 0 ? "0" : min) : min}:${
        second < 10 ? "0" + (second < 0 ? "0" : second) : second
      }`;
    };
  const renderModal = () => {
    const applyChange = (p, v) => {
      let _data = _.cloneDeep(dataAction);
      _data[p] = v;
      setDataAction(_data);
    };
    const onSubmitAction = () => {
      if (refAction.current === 1) {
        GiaiDauService.Actions.giamNguoiChoi({
          ID: data?.data?.id,
          SoLuong: dataAction.SoLuong,
          TrangThai: data?.data?.trangThai,
        });
      } else if (refAction.current === 2) {
        GiaiDauService.Actions.tangNguoiChoi({
          ID: data?.data?.id,
          SoLuong: dataAction.SoLuong,
          TrangThai: data?.data?.trangThai,
        });
      } else if (refAction.current === 3) {
        GiaiDauService.Actions.tangGiamTG({
          ID: data?.data?.id,
          ThoiGianGiam: dataAction.SoLuong,
          TrangThai: data?.data?.trangThai,
        });
      } else if (refAction.current === 4) {
        GiaiDauService.Actions.tangGiamTG({
          ID: data?.data?.id,
          ThoiGianTang: dataAction.SoLuong,
          TrangThai: data?.data?.trangThai,
        });
      }
      setIsModalOpen(false);
      setDataAction({});
    };
    return (
      <Modal
        width={300}
        title={
          refAction.current === 1
            ? "Giảm người chơi"
            : refAction.current === 2
            ? "Tăng người chơi"
            : refAction.current === 3
            ? "Giảm thời gian"
            : "Tăng thời gian"
        }
        open={isModalOpen}
        onOk={onSubmitAction}
        onCancel={() => setIsModalOpen(false)}
        okText="Xác nhận"
        cancelText="Hủy"
      >
        <InputNumber
          style={{ width: "100%" }}
          placeholder="Nhập số lượng"
          value={dataAction?.SoLuong ?? 0}
          onChange={(v) => applyChange("SoLuong", v)}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
        />
      </Modal>
    );
  };
  const renderScreen1 = () => {
    const getValueTime = (prop, type) => {
      let hour = Math.floor((time[prop] % (60 * 60 * 24)) / (60 * 60));
      let min = Math.floor((time[prop] % (60 * 60)) / 60);
      let second = Math.floor(time[prop] % 60);
      if (type === "hour") {
        return `${hour < 10 ? "0" + (hour < 0 ? "0" : hour) : hour}:${
          min < 10 ? "0" + (min < 0 ? "0" : min) : min
        }:${second < 10 ? "0" + (second < 0 ? "0" : second) : second}`;
      }
      return `${min < 10 ? "0" + (min < 0 ? "0" : min) : min}:${
        second < 10 ? "0" + (second < 0 ? "0" : second) : second
      }`;
    };
    return (
      <div
        className="container-detail-gd"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="center">
          <h1 className="title">{data?.data?.tenGD}</h1>
          <div style={{ textAlign: "center" }}>
            <h2 className="time">{getValueTime("time", "min")}</h2>
            <h4 className="level">{data?.time?.tenLevel}</h4>
            <>
              <div className="bigsmall">
                <div style={{ paddingRight: 120 }}>
                  <h1 className="textvalue" style={{ textAlign: "center" }}>
                    {Format_Currency(data?.time?.small)}
                  </h1>
                  <h1 className="textContent">SMALL BLIND</h1>
                </div>
                <div style={{ paddingLeft: 120 }}>
                  <h1 className="textvalue" style={{ textAlign: "center" }}>
                    {Format_Currency(data?.time?.big)}
                  </h1>
                  <h1 className="textContent">BIG BLIND</h1>
                </div>
              </div>
              <h1 className="textvalue">{Format_Currency(data?.time?.ante)}</h1>
              <h1 className="textContent">ANTE</h1>
            </>
          </div>
          <p className="nextblind" style={{ paddingBottom: 50 }}>
            NEXT BLIND: {data?.time?.nextBlind}
          </p>
        </div>
        <div className="right">
          <div className="right-center">
            <div>
              <p className="rigth-text">NEXT BREAK</p>
              <p className="rigth-value">{getValueTime("nextBreak", "hour")}</p>

              <p className="rigth-text">PLAYER</p>
              <p className="rigth-value">
                {data?.data?.conLai}/{data?.data?.soNguoiChoi}
              </p>

              <p className="rigth-text">REBUYS/TOTAL BUYIN</p>
              <p className="rigth-value">
                {data?.data?.rebuy}/{data?.data?.totalEntry}
              </p>

              <p className="rigth-text">TOTAL CHIP</p>
              <p className="rigth-value">
                {Format_Currency(data?.data?.toTalChip)}
              </p>

              <p className="rigth-text">AVG STACK</p>
              <p className="rigth-value">
                {Format_Currency(data?.data?.avgStack)}
                <span style={{ fontSize: "15px" }}>
                  (
                  {Format_Currency(
                    Number(data?.data?.avgStack / data?.time?.big).toFixed(0)
                  )}
                  BB)
                </span>
              </p>
            </div>
            <div>
              <p className="rigth-text">ELAPSED TIME</p>
              <p className="rigth-value">{getValueTime("elapsed", "hour")}</p>
            </div>
          </div>
        </div>
        <div className="box-left">
          <div className="centerLeft">
            <div style={{ padding: "25px 0" }}>
              <p className="totalPrize">TOTAL PRIZE POOL</p>
              <p className="totalPrizeValue">
                {Format_Currency(data?.data?.prizepool)}
              </p>
            </div>
          </div>
        </div>

        <div className="timenow">
          <p>
            {new Date().getHours()}:
            {new Date().getMinutes() < 10
              ? "0" + new Date().getMinutes()
              : new Date().getMinutes()}
            :
            {new Date().getSeconds() < 10
              ? "0" + new Date().getSeconds()
              : new Date().getSeconds()}
          </p>
        </div>
        {data?.data?.isPause && (
          <div style={{ position: "absolute", bottom: 80, width: "100%" }}>
            <h2
              style={{
                color: "white",
                textAlign: "center",
                fontSize: 120,
                fontWeight: "bold",
              }}
            >
              PAUSE
            </h2>
          </div>
        )}
      </div>
    );
  };
  const renderScreen2 = () => {
    return (
      <div
        className="container-detail-gd2"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="timenow">
          {new Date().getHours()}:
          {new Date().getMinutes() < 10
            ? "0" + new Date().getMinutes()
            : new Date().getMinutes()}
          :
          {new Date().getSeconds() < 10
            ? "0" + new Date().getSeconds()
            : new Date().getSeconds()}
        </div>
        <div className="top">
          <div style={{ width: "20%" }}></div>
          <div style={{ width: "60%" }}>
            <p className="title">{data?.data?.tenGD}</p>
            <p className="sub-title">{data?.data?.ghiChu2}</p>
          </div>
          <p className="level" style={{ width: "20%" }}>
            {data?.time?.tenLevel}
          </p>
        </div>
        <div className="middle">
          <div className="left">
            {giai?.length >= 14 ? (
              <marquee direction="up">
                <div className="top-left">
                  {data?.data?.isShowGiai === true &&
                    giai &&
                    giai?.map((item) => {
                      return (
                        <p className="giai">
                          {item.tenGiai}: <span>{item.hienThi}</span>
                        </p>
                      );
                    })}
                </div>
              </marquee>
            ) : (
              <div className="top-left">
                {data?.data?.isShowGiai === true &&
                  giai &&
                  giai?.map((item) => {
                    return (
                      <p className="giai">
                        {item.tenGiai}: <span>{item.hienThi}</span>
                      </p>
                    );
                  })}
              </div>
            )}
            {data?.data?.isShowGiai === true && (
              <div className="footer-left">
                <p className="total-prize-text">TOTAL PRIZE POOL</p>
                <p className="total-prize">
                  {Format_Currency(data?.data?.prizepool)}
                </p>
              </div>
            )}
          </div>
          <div className="center">
            <div className="top-center">
              {data?.data?.thoiGianBD > 0 && (
                <p
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "yellow",
                  }}
                >
                  REGISTRATION
                </p>
              )}
              <p className="time">{getTime("time", "min")}</p>
              <div className="blinds">
                <p className="text-blind">BLINDS:</p>
                {data?.data?.thoiGianBD <= 0 && (
                  <p className="text-blind">
                    {Format_Currency(data?.time?.small)}/
                    {Format_Currency(data?.time?.big)}
                  </p>
                )}
              </div>
              <div className="blinds">
                <p className="text-blind">ANTE:</p>
                {data?.data?.thoiGianBD <= 0 && (
                  <p className="text-blind">
                    {Format_Currency(data?.time?.ante)}
                  </p>
                )}
              </div>
              <div className="blinds">
                <p className="text-level">NEXT LEVEL:</p>
                {data?.data?.thoiGianBD <= 0 && (
                  <p className="text-level">{data?.time?.nextBlind}</p>
                )}
              </div>
            </div>
            <div className="footer-center">
              <div>
                <p className="starting-text">STARTING</p>
                <p className="starting">{data?.data?.startingChip}</p>
              </div>
              <div>
                <p className="starting-text">TOTAL CHIPS</p>
                <p className="starting">
                  {Format_Currency(data?.data?.toTalChip)}
                </p>
              </div>
            </div>
          </div>
          <div className="right">
            <p className="right-text">NEXT BREAK</p>
            <p className="right-value">
              {data?.data?.thoiGianBD > 0
                ? ""
                : getValueTime("nextBreak", "hour")}
            </p>

            <p className="right-text">AVG STACK</p>
            <p className="right-value">
              {Format_Currency(data?.data?.avgStack)}
              <span style={{ fontSize: "15px" }}>
                (
                {Format_Currency(
                  Number(data?.data?.avgStack / data?.time?.big).toFixed(0)
                )}
                BB)
              </span>
            </p>

            <p className="right-text">PLAYERS</p>
            <p className="right-value">
              {data?.data?.conLai}/{data?.data?.player}
            </p>
            <div style={{ display: "flex" }}>
              <div>
                <p className="right-text">REBUY</p>
                <p className="right-value">{data?.data?.rebuy}</p>
              </div>
              <div style={{ paddingLeft: 20 }}>
                <p className="right-text">ADDON</p>
                <p className="right-value">{data?.data?.addon}</p>
              </div>
            </div>
            <p className="right-text">TOTAL ENTRIES</p>
            <p className="right-value">{data?.data?.totalEntry}</p>
          </div>
        </div>
        {data?.data?.isPause && data?.data?.thoiGianBD < 0 && (
          <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 100,
                color: "yellow",
              }}
            >
              PAUSE
            </p>
          </div>
        )}
      </div>
    );
  };
  const renderScreen3 = () => {
    return (
      <div
        className="container-detail-gd3"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="timenow">
          {new Date().getHours()}:
          {new Date().getMinutes() < 10
            ? "0" + new Date().getMinutes()
            : new Date().getMinutes()}
          :
          {new Date().getSeconds() < 10
            ? "0" + new Date().getSeconds()
            : new Date().getSeconds()}
        </div>
        <div className="top">
          <div style={{ width: "20%" }}></div>
          <div style={{ width: "60%" }}>
            <p className="title">{data?.data?.tenGD}</p>
            <p className="sub-title">{data?.data?.ghiChu2}</p>
          </div>
          <p className="level" style={{ width: "20%" }}>
            {/* {data?.time?.tenLevel}/ */}
          </p>
        </div>
        <div className="middle">
          <div className="left">
            {giai?.length >= 14 ? (
              <marquee direction="up">
                <div className="top-left">
                  {data?.data?.isShowGiai === true &&
                    giai &&
                    giai?.map((item) => {
                      return (
                        <p className="giai">
                          {item.tenGiai}: <span>{item.hienThi}</span>
                        </p>
                      );
                    })}
                </div>
              </marquee>
            ) : (
              <div className="top-left">
                {data?.data?.isShowGiai === true &&
                  giai &&
                  giai?.map((item) => {
                    return (
                      <p className="giai">
                        {item.tenGiai}: <span>{item.hienThi}</span>
                      </p>
                    );
                  })}
              </div>
            )}
            {data?.data?.isShowGiai === true && (
              <div className="footer-left">
                <p className="total-prize-text">TOTAL PRIZE POOL</p>
                <p className="total-prize">
                  {Format_Currency(data?.data?.prizepool)}
                </p>
              </div>
            )}
          </div>
          <div className="center">
            <div className="top-center">
              {data?.data?.thoiGianBD > 0 && (
                <p
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "yellow",
                  }}
                >
                  REGISTRATION
                </p>
              )}
              <p className="time">{getTime("time", "min")}</p>
              <div className="blinds">
                <p className="text-blind">BLINDS:</p>
                {data?.data?.thoiGianBD <= 0 && (
                  <p className="text-blind">
                    {Format_Currency(data?.time?.small)}/
                    {Format_Currency(data?.time?.big)}
                  </p>
                )}
              </div>
              <div className="blinds">
                <p className="text-blind">ANTE:</p>
                {data?.data?.thoiGianBD <= 0 && (
                  <p className="text-blind">
                    {Format_Currency(data?.time?.ante)}
                  </p>
                )}
              </div>
              <div className="blinds">
                <p className="text-level">NEXT LEVEL:</p>
                {data?.data?.thoiGianBD <= 0 && (
                  <p className="text-level">{data?.time?.nextBlind}</p>
                )}
              </div>
              <p className="level">{data?.time?.tenLevel}</p>
            </div>
            <div className="footer-center">
              <div>
                <p className="starting-text">STARTING</p>
                <p className="starting">{data?.data?.startingChip}</p>
              </div>

              <div>
                <p className="starting-text">TOTAL CHIPS</p>
                <p className="starting">
                  {Format_Currency(data?.data?.toTalChip)}
                </p>
              </div>
            </div>
          </div>
          <div className="right">
            <p className="right-text">NEXT BREAK</p>
            <p className="right-value">
              {data?.data?.thoiGianBD > 0
                ? ""
                : getValueTime("nextBreak", "hour")}
            </p>

            <p className="right-text">AVG STACK</p>
            <p className="right-value">
              {Format_Currency(data?.data?.avgStack)}
              <span style={{ fontSize: "15px" }}>
                (
                {Format_Currency(
                  Number(data?.data?.avgStack / data?.time?.big).toFixed(0)
                )}
                BB)
              </span>
            </p>

            <p className="right-text">PLAYERS</p>
            <p className="right-value">
              {data?.data?.conLai}/{data?.data?.player}
            </p>
            <div style={{ display: "flex" }}>
              <div>
                <p className="right-text">REBUY</p>
                <p className="right-value">{data?.data?.rebuy}</p>
              </div>
              <div style={{ paddingLeft: 20 }}>
                <p className="right-text">TOTALBUY</p>
                <p className="right-value">{data?.data?.totalEntry}</p>
              </div>
            </div>
          </div>
        </div>

        {data?.data?.isPause && data?.data?.thoiGianBD < 0 && (
          <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 100,
                color: "yellow",
              }}
            >
              PAUSE
            </p>
          </div>
        )}
      </div>
    );
  };
  return (
    <Drawer title="Chi tiết giải đấu" width={920} onClose={onClose} open={open}>
      {data?.data?.loaiManHinh === 1
        ? renderScreen1()
        : data?.data?.loaiManHinh === 2
        ? renderScreen2()
        : renderScreen3()}
      <Row gutter={[20, 20]} style={{ marginTop: 20 }}>
        <Col span={24}>
          <Flex align="center" justify="center" gap={10}>
            <Button
              loading={loading}
              onClick={() => {
                refAction.current = 1;
                setIsModalOpen(true);
              }}
              type="primary"
              danger
              ghost
            >
              Giảm người chơi
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                refAction.current = 2;
                setIsModalOpen(true);
              }}
              type="primary"
              ghost
            >
              Tăng người chơi
            </Button>
          </Flex>
        </Col>
        <Col span={24}>
          <Flex align="center" justify="center" gap={10}>
            <Button
              loading={loading}
              onClick={() => {
                refAction.current = 3;
                setIsModalOpen(true);
              }}
              type="primary"
              danger
              ghost
            >
              Giảm thời gian
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                refAction.current = 4;
                setIsModalOpen(true);
              }}
              type="primary"
              ghost
            >
              Tăng thời gian
            </Button>
          </Flex>
        </Col>

        <Col span={24}>
          <Flex align="center" justify="center" gap={10}>
            <Popconfirm
              title="Back level"
              description="Bạn có chắc chắn muốn next back giải đấu?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => onBackLevel()}
            >
              <Button loading={loading} type="primary" danger ghost>
                Back Level
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Next level"
              description="Bạn có chắc chắn muốn next level giải đấu?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => onNextLevel()}
            >
              <Button loading={loading} type="primary" ghost>
                Next Level
              </Button>
            </Popconfirm>
          </Flex>
        </Col>
        <Col span={24}>
          <Flex align="center" justify="center" gap={10}>
            {data?.data?.isPause ? (
              <Popconfirm
                title="Tiếp tục"
                description="Bạn có chắc chắn muốn tiếp tục giải đấu?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => onPauseContinue(false)}
              >
                <Button loading={loading} type="primary" ghost>
                  Tiếp tục
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Tạm dừng"
                description="Bạn có chắc chắn muốn tạm dừng giải đấu?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => onPauseContinue(true)}
              >
                <Button loading={loading} type="primary" danger ghost>
                  Tạm dừng
                </Button>
              </Popconfirm>
            )}
            {data?.data?.isShowGiai ? (
              <Popconfirm
                title="Tắt hiển thị"
                description="Bạn có chắc chắn muốn tắt hiển thị giải?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => onShowGiai(false)}
              >
                <Button loading={loading} type="primary" ghost danger>
                  Tắt hiển thị giải
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Hiển thị giải"
                description="Bạn có chắc chắn muốn hiển thị giải?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => onShowGiai(true)}
              >
                <Button loading={loading} type="primary" ghost>
                  Hiển thị giải
                </Button>
              </Popconfirm>
            )}
          </Flex>
        </Col>
      </Row>
      {renderModal()}
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormDetail);
