import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Row,
  Col,
  Input,
  Flex,
  Tooltip,
  Modal,
  InputNumber,
  Select,
} from "antd";
import { BuyinService } from "services/BuyinService";
import _, { set } from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import Toolbar from "components/Toolbar";
import { OtherService } from "services/OtherService";
import { GiaiDauService } from "services/GiaiDauService";
import { DateTime } from "luxon";
import FormSeatDraw from "./FormSeatDraw";
import { MdEdit, MdOutlineTableBar } from "react-icons/md";
import { Format_Currency } from "layouts/ultis/common";
import { Format_Date } from "layouts/ultis/common";
import { Format_Datetime } from "layouts/ultis/common";
import { CategoryService } from "services/CategoryService";
import { TourService } from "services/TourService";
import ExcelJS from "exceljs";
import { UploadOutlined } from "@ant-design/icons";

const { confirm } = Modal;
export default function Settings() {
  const [data, setData] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const refSeatDraw = useRef(null);
  // const _tuNgay = DateTime.fromJSDate(_active.Date.TuNgay, { zone: "utc" });
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: DateTime.now(),
    DenNgay: DateTime.now(),
    MaGD: 0,
    MaBan: ",,",
    InputSearch: "",
    State: "PLAY",
  });
  const [quocGia, setQuocGia] = useState([]);
  useEffect(() => {
    CategoryService.Khac.getQuocGia().then((res) => setQuocGia(res.data));
  }, []);
  const loadBuyin = async (_filter) => {
    setLoading(true);
    let _res = await GiaiDauService.RemainingPlayer.get(_filter);
    setData(_res.data);
    setLoading(false);
  };
  const [dataEdit, setDataEdit] = useState({});
  const columns = [
    {
      title: "Table/Seat",
      width: "120px",
      fixed: "left",
      render: (item) =>
        item.TenSeat ? `${item.TenBan} / ${item.TenSeat}` : "Đang trống",
    },
    {
      title: "Tên player",
      fixed: "left",
      width: "160px",

      dataIndex: "TenCongTy",
    },
    {
      title: "Biệt danh",
      dataIndex: "BietDanh",
    },
    {
      title: "Di động",
      dataIndex: "DiDong",
    },
    {
      title: "Quốc gia",
      dataIndex: "MaQG",
      render: (item) => quocGia?.find((v) => v.ID === item)?.TenQG,
    },
    {
      title: "Số chip",
      dataIndex: "SoChip",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tên giải hiện tại",
      width: "200px",
      dataIndex: "TenGD",
    },
    {
      title: "Tên giải cũ",
      dataIndex: "TenGDCu",
    },

    {
      title: "ID BUYIN ",
      dataIndex: "IDBuyIn",
    },
    {
      title: "Ngày Buyin",
      dataIndex: "NgayBuyin",
      width: "140px",
      render: (item) => Format_Datetime(item),
    },
    {
      title: "",
      fixed: "right",
      width: "100px",
      render: (item) => {
        return (
          <Flex gap={5} justify="center">
            <Tooltip title="Sửa">
              <Button
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => {
                  setOpen(true);
                  setDataEdit(item);
                }}
              />
            </Tooltip>
            {!item.TenSeat && (
              <Tooltip title="Thêm người chơi vào bàn">
                <Button
                  type="primary"
                  ghost
                  icon={<MdOutlineTableBar />}
                  onClick={() => {
                    setOpenAddUser(true);
                    setDataEdit(item);
                    loadBan(item.MaGD);
                  }}
                />
              </Tooltip>
            )}
          </Flex>
        );
      },
    },
  ];
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onAfterSubmit = () => {
    loadBuyin(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    loadBuyin(_filter);
    setFilterCondition(_filter);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        loadBuyin(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const [open, setOpen] = useState(false);

  const hideModal = () => {
    setOpen(false);
  };
  const renderModalEdit = () => {
    const applyChange = (key, value) => {
      let _data = _.cloneDeep(dataEdit);
      _data[key] = value;
      setDataEdit(_data);
    };
    const onSubmit = async () => {
      let _res = await GiaiDauService.RemainingPlayer.update(dataEdit);
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data);
        let item = _data.find((v) => v.ID === dataEdit.ID);
        item.BietDanh = dataEdit.BietDanh;
        item.SoChip = dataEdit.SoChip;
        item.MaQG = dataEdit.MaQG;
        setData(_data);
        setOpen(false);
        openNotification("success", "topRight", "Cập nhật thành công");
      } else {
        openNotification("error", "topRight", _res?.message);
      }
    };
    return (
      <Modal
        title="Sửa thông tin"
        open={open}
        onOk={onSubmit}
        onCancel={hideModal}
        okText="Đồng ý"
        width={400}
        cancelText="Huỷ"
      >
        <Row>
          <Col span={24}>
            <p>Số chip</p>
            <InputNumber
              style={{ width: "100%" }}
              value={dataEdit?.SoChip}
              onChange={(v) => applyChange("SoChip", v)}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            />
          </Col>
          <Col span={24}>
            <p>Biệt danh</p>
            <Input
              style={{ width: "100%" }}
              value={dataEdit?.BietDanh}
              onChange={(v) => applyChange("BietDanh", v.target.value)}
            />
          </Col>
          <Col span={24}>
            <p>Quốc tịch</p>
            <Select
              placeholder="Chọn quốc gia"
              value={dataEdit.MaQG}
              onChange={(v) => applyChange("MaQG", v)}
              style={{
                width: "100%",
              }}
              options={quocGia}
              fieldNames={{ label: "TenQG", value: "ID" }}
            />
            {/* <Input
              style={{ width: "100%" }}
              value={dataEdit?.QuocGia}
              onChange={(v) => applyChange("QuocGia", v.target.value)}
            /> */}
          </Col>
        </Row>
      </Modal>
    );
  };
  const [openAddUser, setOpenAddUser] = useState(false);
  const [tables, setTables] = useState([]);
  const loadBan = async (maGD) => {
    let _res = await TourService.Table.get({ MaGD: maGD });
    setTables(_res.data?.filter(v=>v.isOpen));
  };
  const [seats, setSeats] = useState([]);
  const loadSeat = async (maban, mabangd) => {
    let _res = await TourService.Table.getSeatTrong({
      MaBan: maban,
      MaBanGD: mabangd,
    });
    setSeats(_res.data);
  };
  const renderAddVaoBan = () => {
    const handleOk = async () => {
      if (!dataEdit.MaBanGD || !dataEdit.MaSeat) {
        openNotification("error", "topRight", `Vui lòng chọn bàn và seat!`);
        return;
      }
      setLoading(true);
      let _tb = tables.find((v) => v.ID === dataEdit.MaBanGD);
      let _seat = seats.find((v) => v.ID === dataEdit.MaSeat);
      let _payload = {
        ID: dataEdit.ID,
        MaBanGD: dataEdit.MaBanGD,
        MaSeat: dataEdit.MaSeat,
        TenSeat: _seat?.Name,
        MaKH: dataEdit?.MaKH,
        MaThe: 0,
        TenCongTy: dataEdit?.TenCongTy,
        MaBan: _tb?.MaBan,
      };
      let _res = await GiaiDauService.RemainingPlayer.addUserToSeat(_payload);
      setLoading(false);
      if (_res.status === 2000) {
        loadBuyin(filterCondition);
        openNotification("success", "topRight", `Thêm vào seat thành công!`);
        setDataEdit({});
        setOpenAddUser(false);
      }
    };
    const handleCancel = () => {
      setDataEdit({});
      setOpenAddUser(false);
    };

    const applyChange = async (p, v) => {
      let _data = _.cloneDeep(dataEdit);
      switch (p) {
        case "MaBanGD":
          let ban = tables.find((a) => a.ID === v);
          _data[p] = v;
          _data.MaBan = ban?.MaBan;
          if (ban?.SoLuongMax <= ban?.Player) {
            setSeats([]);
            openNotification("error", "topRight", `Bàn đã đủ người chơi!`);
          } else {
            loadSeat(ban?.MaBan, v);
          }
          break;
        default:
          _data[p] = v;
          break;
      }
      setDataEdit(_data);
    };
    return (
      <Modal
        open={openAddUser}
        title="Thêm người chơi vào bàn bàn"
        onOk={handleOk}
        okText="Lưu"
        width={400}
        cancelText="Huỷ"
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <p>Người chơi: {dataEdit?.TenCongTy}</p>
        {/* <DebounceSelect
          showSearch
          placeholder="Tìm kiểm khách hàng"
          fetchOptions={fetchUserList}
          onChange={(newValue) => applyChange("MaKH", newValue)}
          style={{
            width: "100%",
          }}
        /> */}
        <p style={{ marginTop: 15 }}>Chọn bàn</p>
        <Select
          style={{
            width: "100%",
          }}
          value={dataEdit?.MaBanGD}
          placeholder="Chọn  bàn"
          onChange={(v) => applyChange("MaBanGD", v)}
          options={tables}
          fieldNames={{ label: "TenBan", value: "ID" }}
        />
        <p style={{ marginTop: 15 }}>Chọn ghế</p>
        <Select
          style={{
            width: "100%",
          }}
          value={dataEdit?.MaSeat}
          placeholder="Chọn seat"
          onChange={(v) => applyChange("MaSeat", v)}
          options={seats}
          fieldNames={{ label: "Name", value: "ID" }}
        />
      </Modal>
    );
  };
  const exportExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("LichSuBuyIn");
    sheet.properties.defaultRowHeight = 20;
    sheet.columns = [
      {
        header: "Table / seat",
        key: "table",
        width: 15,
        // style: {
        //   font: {
        //     bold: true,
        //     size: 14, // Adjust size
        //     color: { argb: "FF0000FF" }, // Set color (blue)
        //   },
        // },
      },
      {
        header: "Tên player",
        key: "tenplayer",
        width: 20,
      },
      {
        header: "Biệt danh",
        key: "bietdanh",
        width: 15,
      },
      {
        header: "Di động",
        key: "didong",
        width: 15,
      },
      {
        header: "Quốc gia",
        key: "quocgia",
        width: 15,
      },
      {
        header: "Số chip",
        key: "sochip",
        width: 20,
        numFmt: "#.##0",
      },
      {
        header: "Tên giải hiện tại",
        key: "tengiaihientai",
        width: 30,
      },
      {
        header: "Tên giải cũ",
        key: "tengiaicu",
        width: 30,
      },
      {
        header: "Ngày buyin",
        key: "ngaybuyin",
        width: 30,
      }
    ];
    data?.map((item) => {
      sheet.addRow({
        table: item.TenBan + "/" + item.TenSeat,
        tenplayer: item.TenCongTy,
        bietdanh: item.BietDanh,
        didong: item.DiDong,
        quocgia: quocGia?.find((v) => v.ID === item.MaQG)?.TenQG,
        sochip: item.SoChip,
        tengiaihientai: item.TenGD,
        tengiaicu: item.TenGDCu,
        ngaybuyin: item.NgayBuyin,
      });
    });
    // sheet.getCell("A1").numFmt = "# ?/?";
    sheet.addRow({
      table: "Tổng: " + data.length,
      sochip: _.sumBy(data, (o) => o.SoChip)
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "lichsubuyin.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          companies={companies}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Input
              placeholder="Tìm kiếm"
              style={{
                width: "300px",
                padding: "4px 11px",
                borderRadius: 10,
              }}
              onChange={(e) => onSearch(e.target.value)}
            />
          )}
          right={() => (
            <Flex>
              {(filterCondition?.MaGD && data.length > 0) > 0 && (
                <Button
                  type="primary"
                  onClick={() =>
                    refSeatDraw.current.show(data, filterCondition?.MaGD)
                  }
                >
                  Seat Draw
                </Button>
              )}

              <Button
                icon={<UploadOutlined />}
                onClick={() => exportExcel()}
                style={{ marginLeft: 10 }}
              >
                Export
              </Button>
            </Flex>
          )}
        />
        <Table
          // style={{ height: 100 }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 1500,
          }}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={5}>
                    Tổng: {pageData.length}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.SoChip),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TienPhi),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell> */}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
          // virtual={true}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 20,
            pageSizeOptions: ["20", "50", "500"],
            position: ["bottomRight"],
          }}
        />
      </Col>
      {renderModalEdit()}
      {renderAddVaoBan()}
      <FormSeatDraw ref={refSeatDraw} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Row>
  );
}
