import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Image,
  Popconfirm,
  Table,
  Tooltip,
  notification,
} from "antd";
import FilterModal from "./FilterModal";
import { Format_Date } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { BuyinService } from "services/BuyinService";
import { RecapService } from "services/ReacpService";
import { GiaiDauService } from "services/GiaiDauService";
import FormAdd from "./FormAdd";
import { DateTime } from "luxon";
export default function Index() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [lichThiDau, setLichThiDau] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    ListMaCT: ",,",
  });
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(() => {
    filter(filterCondition);
  }, []);
  const filter = async (_filter) => {
    setLoading(true);
    let _res = await GiaiDauService.LichThiDau.get();
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const columns = [
    {
      title: "Tên",
      dataIndex: "TenLich",
    },
    {
      title: "Từ ngày",
      dataIndex: "TuNgay",
      render: (item) => DateTime.fromISO(item).toFormat("yyyy-MM-dd"),
    },
    {
      title: "Đến ngày",
      dataIndex: "DenNgay",
      render: (item) => DateTime.fromISO(item).toFormat("yyyy-MM-dd"),
    },
    {
      title: "Ảnh",
      render: (item) => (
        <Image width={"100px"} height={"60px"} src={item?.Image} />
      ),
    },
    {
      title: "",
      fixed: "right",
      width: "100px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, lichThiDau)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá"
              description="Bạn có chắc chắn muốn xoá?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await GiaiDauService.LichThiDau.delete({
      ID: value,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Box>
      <Toolbar
        left={() => (
          <Flex>
            <Button
              style={{ marginRight: "10px" }}
              type="primary"
              onClick={() => refDetail.current.show(lichThiDau)}
            >
              Thêm
            </Button>
            {/* <FilterModal
              filterCondition={filterCondition}
              onChangeFilter={onChangeFilter}
              companies={companies}
            /> */}
          </Flex>
        )}
        // right={() => (
        //   <FilterModal
        //     filterCondition={filterCondition}
        //     onChangeFilter={onChangeFilter}
        //     companies={companies}
        //   />
        // )}
      />

      <Table
        style={{ height: 100 }}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          // x: 2500
        }}
        size="small"
      />
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Box>
  );
}
