import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect,useRef,useState } from "react";
import { HomeService } from "services/HomeService";
import { Button, Popconfirm, Table,Tooltip,notification } from "antd";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import { IoEyeSharp, IoSettingsOutline } from "react-icons/io5";
import { TourService } from "services/TourService";
import _ from 'lodash'
import Toolbar from "components/Toolbar";
import { CongTyService } from "services/CongTyService";
export default function Settings() {
  const [api, contextHolder] = notification.useNotification();

  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
  });
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(()=>{
    filter();
  },[])
  const filter =async () =>{
    setLoading(true);
    let _res = await CongTyService.getCongTy()
    if(_res.data) setData(_res.data);
    setLoading(false);
  }
  const onChangeFilter = (_filter) =>{
    filter(_filter);
    setFilterCondition(_filter);
  }
  const onAfterSubmit = ()=>{
    filter();
  }
  const columns = [
    {
      title: "Tên viết tắt",
      dataIndex: "TenCTVT",
    },
    {
      title: "Tên đầy đủ",
      dataIndex: "TenCT",
    },

    {
      title: "Địa chỉ",
      dataIndex: "DiaChi",
    },

    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Website",
      dataIndex: "Website",
    },
    // {
    //   title: "Ngày tạo",
    //   dataIndex: "NgaySinh",
    //   render: (item) => Format_Date(item),
    // },
    {
      title: "",
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá công ty"
              description="Bạn có chắc chắn muốn xoá công ty?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await CongTyService.deleteCompany({ ID: Number(value) });
    if(_res.status === 2000){
      let _data = _.cloneDeep(data);
      let fil = _data.filter(v=>v.ID !==Number(value) );
      setData(fil);
      openNotification(
        "success",
        "topRight",
        "Xoá công ty thành công"
      );
    }else{
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Box >
      <Toolbar
        left={() => (
          <Button type="primary" onClick={() => refDetail.current.show()}>
            Thêm
          </Button>
        )}
      />
      <Table
        style={{ height: 100 }}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          // x: 2500
        }}
        size="small"
      />
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Box>
  );
}
