import React, { useState, useEffect } from "react";
import { Select } from "antd";
import _, { set } from "lodash";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import { BuyinService } from "services/BuyinService";
import { NhanVienService } from "services/NhanVienService";
const FilterModal = ({ ...props }) => {
  const { filterCondition, onChangeFilter } = props;
  const [nhanVien, setNhanVien] = useState([]);

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "NhanVien":
      case "ThaoTac":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
    }
    onChangeFilter(_filter);
  };
  useEffect(() => {
    NhanVienService.getNhanVien().then((res) => {
      setNhanVien(res?.data);
    });
  }, []);
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />

      <div className="box-search">
        <p className="label">Nhân viên</p>
        <Select
          mode="multiple"
          placeholder="Chọn nhân viên"
          onChange={(v) => applyChangeFilter("NhanVien", v)}
          style={{
            width: "100%",
          }}
          options={nhanVien}
          fieldNames={{ label: "HoTen", value: "MaNV" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Thao tác</p>
        <Select
          mode="multiple"
          placeholder="Chọn thao tác"
          onChange={(v) => applyChangeFilter("ThaoTac", v)}
          style={{
            width: "100%",
          }}
          options={[
            {
              ID: "ADD",
              Name: "Thêm mới",
            },
            {
              ID: "UPDATE",
              Name: "Cập nhật",
            },
            {
              ID: "DELETE",
              Name: "Xóa",
            },
          ]}
          fieldNames={{ label: "Name", value: "ID" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
