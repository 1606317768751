import axios from "axios";
import { post } from "./api_helper";
import axiosApi from "./api_helper";
export const RecapService = {
  get: async (payload) => {
    return await axiosApi()
      .post("api/giai-dau/get-recap", payload)
      .then((res) => res.data);
  },
  add: async (payload) => {
    return await axiosApi()
      .post("api/giai-dau/add-recap", payload)
      .then((res) => res.data);
  },
  delete: async (payload) => {
    return await axiosApi()
      .post("api/giai-dau/delete-recap", payload)
      .then((res) => res.data);
  },
};
