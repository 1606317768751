import { extendTheme } from "@chakra-ui/react";
import { globalStyles } from "./styles";
import { buttonStyles } from "./components/button";
import { inputStyles } from "./components/input";
export default extendTheme(
  // { breakpoints }, // Breakpoints
  globalStyles,
  // linkStyles, // link styles
  // progressStyles, // progress styles
  // sliderStyles, // slider styles
  inputStyles, // input styles
  buttonStyles // button styles

  // textareaStyles, // textarea styles
  // switchStyles, // switch styles
);
