import React, { useEffect, useState, useMemo } from "react";
import "./scss/index.scss";
import { Format_Currency } from "layouts/ultis/common";
import _ from "lodash";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Button, Col, Flex, Image, Row } from "antd";
import {
  BarcodeOutlined,
  ClockCircleOutlined,
  CreditCardOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { Format_Date } from "layouts/ultis/common";
import { OrderService } from "services/OrdersService";

export default function History({ ...props }) {
  const {
    onBack,
    setActiveTab,
    setLayout,
    showDrawerDG,
    setCarts,
    carts,
    congTy,
    maCT,
    setHTTT,
    setValueRadio,
  } = props;
  const [data, setData] = useState([]);
  const [tab, setTab] = useState("0");
  const [valueFood, setValueFood] = useState();

  let totalQuantity = 0;
  let totalPrice = 0;

  const panelStyle = {
    marginBottom: 14,
    background: "#B0D1AE",
    borderRadius: 10,
    border: "none",
  };
  useEffect(() => {
    let _ors = localStorage.getItem("orders");
    if (_ors) setData(JSON.parse(_ors));
  }, []);


  const handleOrderAgain = async () => {
    setValueRadio(valueFood?.TenSeat);

    const value = valueFood.Products.map((item) => ({
      ...item,
      id: item.maSP,
    }));
    let _res6 = await OrderService.getHTTT({
      TenCTDK: maCT,
    });
    if (_res6.status === 2000) {
      setHTTT(_res6.data);
    }
    setActiveTab("2");
    setLayout("foodOrder");
    setCarts(value);
  };

  const renderItem = () => {
    const date2 = new Date(valueFood?.Date);
    const hours2 = date2.getUTCHours().toString().padStart(2, "0");
    const minutes2 = date2.getUTCMinutes().toString().padStart(2, "0");
    return (
      <>
        {tab === "0" ? (
          <>
            {data
              .slice()
              .reverse()
              .map((item) => {
                const date = new Date(item.Date);
                const hours = date.getUTCHours().toString().padStart(2, "0");
                const minutes = date
                  .getUTCMinutes()
                  .toString()
                  .padStart(2, "0");
                return (
                  <div
                    onClick={() => {
                      setTab("1");
                      setValueFood(item);
                    }}
                    style={{ marginTop: "15px" }}
                  >
                    <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                      {Format_Date(item.Date)}
                    </p>
                    <Row
                      style={{
                        marginTop: "10px",
                        padding: "10px",
                        backgroundColor: "#e4ebdd",
                        borderRadius: "10px",
                        boxShadow: " 0 0 15px rgba(45, 45, 48, .1)",
                      }}
                    >
                      <Col span={12}>
                        <ClockCircleOutlined /> {hours}:{minutes}
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        Trả đồ tại bàn
                      </Col>
                      {item.Products.map((value) => (
                        <>
                          <Col style={{ marginTop: "15px" }} span={18}>
                            <Flex style={{ alignItems: "" }}>
                              <Image
                                style={{ borderRadius: "10px" }}
                                width={"20%"}
                                height={"auto"}
                                preview={false}
                                src={
                                  value?.linkImage
                                    ? value?.linkImage
                                    : "https://o2o.ipos.vn/static/images/default_food.svg?width=160"
                                }
                              />
                              <div style={{ marginLeft: "15px", width: "80%" }}>
                                <p>{congTy?.TenCT}</p>
                                <span
                                  style={{
                                    display: "inline-block",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "100%",
                                    marginTop: "5px",
                                    opacity: "0.5",
                                  }}
                                >
                                  <span style={{ color: "#f7941e" }}>
                                    {value.quantity} X{" "}
                                  </span>{" "}
                                  {value.tenSP}
                                </span>
                              </div>
                            </Flex>
                          </Col>
                          <Col span={6}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                height: "100%",
                              }}
                            >
                              {Format_Currency(value.quantity * value.giaBan)}
                            </div>
                          </Col>
                        </>
                      ))}
                      <Col span={24}>
                        <p
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            marginTop: "15px",
                            color: "#608f27",
                          }}
                        >
                          Đã xác nhận
                        </p>
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </>
        ) : (
          <div style={{ paddingBottom: "50px" }}>
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#fec613",
                borderRadius: "10px",
                marginTop: "15px",
                padding: "20px 0",
              }}
            >
              <Image
                preview={false}
                src="https://o2o.ipos.vn/static/images/order_confirmed.gif"
              />
              <p style={{ fontWeight: "500" }}>Đồ của bạn đang được chuẩn bị</p>
            </div>
            <div
              style={{
                marginTop: "15px",
                padding: "15px",
                backgroundColor: "#e4f5f1",
                border: "1px solid #eee",
                borderRadius: "15px",
              }}
            >
              <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>
                {congTy?.TenCT}
              </h3>
              <p style={{ opacity: "0.7", fontSize: "14px" }}>
                <ClockCircleOutlined
                  style={{ marginRight: "8px", padding: "10px 0" }}
                />
                Gọi món lúc: {hours2}:{minutes2} -{" "}
                {Format_Date(valueFood?.Date)}
              </p>
              {/* <p style={{ opacity: "0.7", fontSize: "14px" }}>
                <FileOutlined style={{ marginRight: "8px" }} />
                Đơn hàng: Nhận món tại bàn 10
              </p>
              <p style={{ opacity: "0.7", fontSize: "14px" }}>
                <BarcodeOutlined
                  style={{ marginRight: "8px", padding: "10px 0" }}
                />
                Mã đơn hàng: ST_SIYRVKO5
              </p> */}
              <p style={{ opacity: "0.7", fontSize: "14px" }}>
                <CreditCardOutlined
                  style={{ marginRight: "8px", paddingBottom: "25px" }}
                />
                Mã thanh toán: {valueFood?.TenHTTT}
              </p>
              <Flex justify="space-between">
                <Button
                  onClick={showDrawerDG}
                  style={{
                    width: "35%",
                    color: "#f7941e",
                    borderRadius: "10px",
                    backgroundColor: "rgba(247,148,30,.15)",
                    border: "none",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  Đánh giá
                </Button>
                <Button
                  onClick={() => handleOrderAgain()}
                  style={{
                    width: "45%",
                    color: "#f7941e",
                    borderRadius: "10px",
                    backgroundColor: "rgba(247,148,30,.15)",
                    border: "none",
                    fontWeight: "bold",
                  }}
                >
                  Đặt lại đơn này
                </Button>
              </Flex>
            </div>
            <h5 style={{ fontWeight: "500", margin: "15px 0" }}>
              Danh sách món đã chọn
            </h5>
            <div
              style={{
                marginTop: "15px",
                padding: "15px 15px 0px 15px",
                backgroundColor: "#e4f5f1",
                border: "1px solid #eee",
                borderRadius: "15px",
              }}
            >
              {valueFood.Products.map((item) => {
                totalQuantity += item.quantity;
                totalPrice += item.giaBan * item.quantity;
                return (
                  <Flex
                    key={item.maSP}
                    style={{ alignItems: "", marginBottom: "15px" }}
                  >
                    <Image
                      style={{ borderRadius: "10px" }}
                      width={"20%"}
                      height={"auto"}
                      preview={false}
                      src={
                        item?.linkImage
                          ? item?.linkImage
                          : "https://o2o.ipos.vn/static/images/default_food.svg?width=160"
                      }
                    />
                    <div style={{ marginLeft: "15px", width: "80%" }}>
                      <span
                        style={{
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                          marginTop: "5px",
                          // opacity: "0.5",
                        }}
                      >
                        <span
                          style={{
                            color: "#f7941e",
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          {item.quantity} x{" "}
                        </span>{" "}
                        {item.tenSP}
                      </span>
                      <p>{Format_Currency(item.quantity * item.giaBan)}</p>
                    </div>
                  </Flex>
                );
              })}
            </div>
            <div
              style={{
                marginTop: "15px",
                padding: "15px 15px 0px 15px",
                backgroundColor: "#e4f5f1",
                border: "1px solid #eee",
                borderRadius: "15px",
              }}
            >
              <Flex
                justify="space-between"
                style={{ alignItems: "", marginBottom: "15px" }}
              >
                <p>Tổng cộng {totalQuantity} phần</p>
                <p>{Format_Currency(totalPrice)}</p>
              </Flex>
              <Flex
                justify="space-between"
                style={{ alignItems: "", marginBottom: "15px" }}
              >
                <p>Phương thức thanh toán</p>
                <p>{valueFood?.TenHTTT}</p>
              </Flex>
              <Flex
                justify="space-between"
                style={{ alignItems: "", marginBottom: "15px" }}
              >
                <p>Tiền trừ</p>
                <p>{valueFood?.TienTru}</p>
              </Flex>
              <Flex
                justify="space-between"
                style={{ alignItems: "", marginBottom: "15px" }}
              >
                <p>Tổng tiền</p>
                <p style={{ fontWeight: "bold" }}>
                  {Format_Currency(valueFood?.TotalPrice)}
                </p>
              </Flex>
            </div>
            <div
              style={{
                marginTop: "15px",
                padding: "15px 15px 0px 15px",
                backgroundColor: "#e4f5f1",
                border: "1px solid #eee",
                borderRadius: "15px",
              }}
            >
              <Flex style={{ alignItems: "", marginBottom: "15px" }}>
                <FileOutlined style={{ marginRight: "15px" }} />
                <p>[O2O-ST_SIYRVKO5-STORE-18-2024-08-29 12:14:09] </p>
              </Flex>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <div
        style={{ padding: "0px 10px", background: "white", height: "100vh" }}
      >
        <div
          style={{
            marginTop: "10px",
            position: "sticky",
            background: "white",
            zIndex: 99,
            top: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            if (tab === "0") {
              setActiveTab("1");
            } else {
              setTab("0");
            }
          }}
        >
          <div
            style={{
              padding: "0 3px",
              borderRadius: "5px",
              border: "1px solid #e2e8f0",
              marginRight: "auto",
            }}
          >
            <ChevronLeftIcon boxSize={8} />
          </div>
          <p
            style={{
              margin: 0,
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            {tab === "0" ? "Lịch sử đơn " : "Chi tiết đơn hàng"}
          </p>
        </div>

        {data.length > 0 ? (
          <div>{renderItem()}</div>
        ) : (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <Image
              style={{ margin: "30px 0" }}
              preview={false}
              src="https://o2o.ipos.vn/static/images/history_empty.jpg"
            />
            <h3
              style={{ fontWeight: "600", margin: "10px 0", fontSize: "20px" }}
            >
              Không có đơn hàng nào
            </h3>
            <p style={{ fontSize: "16px", color: "363636" }}>
              Hãy chọn món và tạo đơn hàng ngay nào!
            </p>
            <Button
              onClick={() => {
                setLayout("home");
                // setActiveTab('2')
              }}
              style={{
                backgroundColor: "rgb(247, 148, 30)",
                marginTop: "20px",
                color: "#fff",
                height: "38px",
              }}
            >
              Chọn món từ Menu ngay !
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
