import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdLock,
  MdOutlineChecklist,
  MdOutlineLocalBar,
  MdPlaylistAddCheck,
} from "react-icons/md";
import { IoFastFood, IoStatsChartOutline } from "react-icons/io5";
import { FaIdCard } from "react-icons/fa";
import { LuGitBranchPlus, LuListTodo } from "react-icons/lu";
import { MdTour } from "react-icons/md";
import { BsPersonFillLock } from "react-icons/bs";
import { BiCategoryAlt, BiListMinus } from "react-icons/bi";
import { CgPlayListAdd } from "react-icons/cg";
import { GoDot, GoDotFill } from "react-icons/go";

// Admin Imports
import DanhSachGiai from "views/admin/DanhSachGiai/index";
import DanhSachRecap from "views/admin/DanhSachRecap/index";
import DanhSachLichThiDau from "views/admin/DanhSachGiai/LichThiDau/index";
import DanhSachRemainingPlayer from "views/admin/DanhSachGiai/RemainingPlayer/index";
import ListITM from "views/admin/DanhSachGiai/ListITM/index";
import DanhSachBan from "views/admin/HeThong/DanhSachBan/index";
import DanhSachOrder from "views/admin/DanhSachOrders/index";
import DanhSachBuyin from "views/admin/ThuNgan/Buyin/index";
import DanhSachThe from "views/admin/DanhSachThe/index";
import LichSuRaVao from "views/admin/HeThong/LichSuRaVao/index";
import DanhSachAgent from "views/admin/DanhSachThe/DanhSachAgent/index";

import DanhSachNhanVien from "views/admin/HeThong/NhanVien/index";
import TinTuc from "views/admin/HeThong/TinTuc/index";
import PhanQuyen from "views/admin/HeThong/PhanQuyen/index";
import PhanQuyenCongTy from "views/admin/HeThong/PhanQuyen/TheoCongTy/index";
import LichSu from "views/admin/HeThong/LichSu/index";

import DanhSachPhieuThu from "views/admin/PhieuThu/index";
import DanhSachPhieuChi from "views/admin/PhieuChi/index";
import DanhSachCongTy from "views/admin/HeThong/CongTy/index";
import DanhSachSanPham from "views/admin/HeThong/SanPham/index";
import DanhSachManHinh from "views/admin/HeThong/DanhSachManHinh/index";
import DangKy from "views/auth/register";

// danh muc
import DanhMucSanPham from "views/admin/DanhMuc/SanPham/index";
import DanhMucKhac from "views/admin/DanhMuc/Khac/index";
import DanhMucGiaiDau from "views/admin/DanhMuc/GiaiDau/index";

//báo cáo
import BaoCaoRPT from "views/admin/BaoCao/RPT/index";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import { PiUserList } from "react-icons/pi";
import { TbTournament } from "react-icons/tb";
import { BsCashCoin } from "react-icons/bs";
import { GrSystem } from "react-icons/gr";

const routes = [
  {
    label: "Thành viên",
    layout: "/admin",
    icon: <PiUserList />,
    id: 41,
    children: [
      {
        key: "danh-sach-the",
        label: "Danh sách thành viên",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-the",
        component: DanhSachThe,
        id: 142,
      },
      {
        key: "lich-su-ra-vao",
        label: "Lịch sử ra vào",
        layout: "/admin",
        icon: <GoDot />,
        path: "/lich-su-ra-vao",
        component: LichSuRaVao,
        id: 114,
      },
      {
        key: "danh-sach-agent",
        label: "Danh sách Agent",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-agent",
        component: DanhSachAgent,
        id: 211,
      },
    ],
  },
  {
    label: "Giải đấu",
    layout: "/admin",
    icon: <TbTournament />,
    id: 49,
    children: [
      {
        key: "danh-sach-giai",
        label: "Quản lý giải đấu",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-giai",
        component: DanhSachGiai,
        id: 193,
      },
      {
        key: "danh-sach-recap",
        label: "Recap",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-recap",
        component: DanhSachRecap,
        id: 193,
      },
      {
        key: "danh-sach-lichthidau",
        label: "Lịch thi đấu",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-lichthidau",
        component: DanhSachLichThiDau,
        id: 193,
      },
      {
        key: "danh-sach-remaining-player",
        label: "Remaining Player",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-remaining-player",
        component: DanhSachRemainingPlayer,
        id: 193,
      },
      {
        key: "danh-sach-itm",
        label: "List ITM",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-itm",
        component: ListITM,
        id: 193,
      },
      // {
      //   key: "danh-sach-giai",
      //   label: "Lịch sử giải đấu",
      //   layout: "/admin",
      //   icon: <GoDot />,
      //   // path: "/danh-sach-giai",
      //   // component: DanhSachGiai,
      //   id: 194,
      // },
      // {
      //   key: "danh-sach-giai",
      //   label: "Lịch sử Payout",
      //   layout: "/admin",
      //   icon: <GoDot />,
      //   // path: "/danh-sach-giai",
      //   // component: DanhSachGiai,
      //   id: 196,
      // },
    ],
  },
  {
    label: "Báo cáo",
    layout: "/admin",
    icon: <IoStatsChartOutline />,
    id: 28,
    children: [
      // {
      //   key: "bao-cao-loi-nhuan-tour",
      //   label: "Báo cáo lợi nhuận tour",
      //   layout: "/admin",
      //   icon: <GoDot />,
      //   path: "/bao-cao-loi-nhuan-tour",
      //   component: DanhSachNhanVien,
      //   id: 203,
      // },
      // {
      //   key: "bao-cao-tang-giam-bar-ngay",
      //   label: "Báo cáo tăng giảm bar ngày",
      //   layout: "/admin",
      //   icon: <GoDot />,
      //   path: "/bao-cao-tang-giam-bar-ngay",
      //   component: DanhSachNhanVien,
      //   id: 204,
      // },
      // {
      //   key: "bao-cao-top-san-pham-ban",
      //   label: "Báo cáo top sản phẩm bán",
      //   layout: "/admin",
      //   icon: <GoDot />,
      //   path: "/bao-cao-top-san-pham-ban",
      //   component: DanhSachNhanVien,
      //   id: 205,
      // },
      {
        key: "bao-cao-tour",
        label: "Báo cáo Tour",
        layout: "/admin",
        icon: <GoDot />,
        path: "/bao-cao-tour",
        component: BaoCaoRPT,
        id: 210,
      },
    ],
  },
  {
    label: "Quầy bar",
    layout: "/admin",
    icon: <MdOutlineLocalBar />,
    id: 36,
    children: [
      {
        key: "danh-sach-order",
        label: "Danh sách order",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-order",
        component: DanhSachOrder,
        id: 114,
      },
    ],
  },
  {
    label: "Jackport",
    layout: "/admin",
    icon: <LuListTodo />,
    id: 50,
    children: [
      // {
      //   key: "cai-dat-jackport",
      //   label: "Cài đặt jackport",
      //   layout: "/admin",
      //   icon: <GoDot />,
      //   path: "/cai-dat-jackport",
      //   component: DanhSachPhieuThu,
      //   id: 198,
      // },
      {
        key: "danh-sach-phieu-thu",
        label: "Phiếu thu jackport",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-phieu-thu",
        component: DanhSachPhieuThu,
        id: 199,
      },
      {
        key: "danh-sach-phieu-chi",
        label: "Phiếu chi jackport",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-phieu-chi",
        component: DanhSachPhieuChi,
        id: 200,
      },
    ],
  },
  {
    label: "Thu ngân",
    layout: "/admin",
    icon: <BsCashCoin />,
    id: 38,
    children: [
      {
        key: "danh-sach-buyin",
        label: "Lịch sử buyin",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-buyin",
        component: DanhSachBuyin,
        id: 162,
      },
    ],
  },
  {
    label: "Danh mục",
    layout: "/admin",
    icon: <BiCategoryAlt />,
    id: 40,
    children: [
      {
        key: "danh-muc-sanpham",
        label: "Sản phẩm",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-muc-sanpham",
        component: DanhMucSanPham,
        id: 209,
      },
      {
        key: "danh-muc-giaidau",
        label: "Giải đấu",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-muc-giaidau",
        component: DanhMucGiaiDau,
        id: 209,
      },
      {
        key: "danh-muc-khac",
        label: "Khác",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-muc-khac",
        component: DanhMucKhac,
        id: 208,
      },
    ],
  },
  {
    label: "Hệ thống",
    layout: "/admin",
    icon: <GrSystem />,
    id: 42,
    children: [
      {
        key: "tin-tuc",
        label: "Tin Tức",
        layout: "/admin",
        icon: <GoDot />,
        path: "/tin-tuc",
        component: TinTuc,
        id: 15,
      },
      {
        key: "danh-sach-nhan-vien",
        label: "Nhân viên",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-nhan-vien",
        component: DanhSachNhanVien,
        id: 15,
      },
      {
        key: "danh-sach-cong-ty",
        label: "Trụ sở, chi nhánh",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-cong-ty",
        component: DanhSachCongTy,
        id: 148,
      },
      {
        key: "danh-sach-san-pham",
        label: "Sản phẩm",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-san-pham",
        component: DanhSachSanPham,
        id: 75,
      },

      {
        key: "danh-sach-ban",
        label: "Danh sách bàn",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-ban",
        component: DanhSachBan,
        id: 20,
      },
      {
        key: "danh-sach-man-hinh",
        label: "Danh sách màn hình",
        layout: "/admin",
        icon: <GoDot />,
        path: "/danh-sach-man-hinh",
        component: DanhSachManHinh,
        id: 206,
      },
      {
        key: "phan-quyen",
        label: "Phân quyền",
        layout: "/admin",
        icon: <GoDot />,
        path: "/phan-quyen",
        component: PhanQuyen,
        id: 62,
      },
      {
        key: "quyen-cong-ty",
        label: "Phân quyền công ty",
        layout: "/admin",
        icon: <GoDot />,
        path: "/quyen-cong-ty",
        component: PhanQuyenCongTy,
        id: 207,
      },
      {
        key: "lich-su",
        label: "Lịch sử",
        layout: "/admin",
        icon: <GoDot />,
        path: "/lich-su",
        component: LichSu,
        id: 15,
      },
    ],
  },
  {
    label: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    label: "Đăng ký",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: DangKy,
  },
];

export default routes;
