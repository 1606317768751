import React, { useContext, useEffect, useRef, useState } from "react";
import { HomeService } from "services/HomeService";
import {
  Button,
  Popconfirm,
  Table,
  Tooltip,
  notification,
  Row,
  Flex,
  Tag,
  Popover,
  Checkbox,
} from "antd";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { VscDebugStart } from "react-icons/vsc";
import { SiTableau } from "react-icons/si";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { TourService } from "services/TourService";
import _ from "lodash";
import "./scss/index.scss";
import SettingBlind from "./SettingBlind";
import Toolbar from "components/Toolbar";
import { LuView } from "react-icons/lu";
import FormDetail from "./FormDetail";
import { Format_Datetime } from "layouts/ultis/common";
import FormTable from "./FormTable";
import { IoMdMore } from "react-icons/io";
import SignalrContext from "contexts/SignalrContext";
import ringer from "../../../assets/audio/beepspark.wav";
import { NhanVienService } from "services/NhanVienService";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const { hubConnection } = useContext(SignalrContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
  });
  const refAdd = useRef(null);
  const refDetail = useRef(null);
  const refSettingBlind = useRef(null);
  const refTable = useRef(null);
    const [action, setAction] = useState([]);

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
      duration: 0.7,
    });
  };
  useEffect(() => {
    NhanVienService.getAction({ FormID: 193 }).then((res) =>
      setAction(res?.data)
    );
    filter(filterCondition);
  }, []);
   const checkAction = (id) => {
     return action?.some((v) => v.FeatureID === id);
   };
  const filter = async (_date) => {
    setLoading(true);
    let _res = await HomeService.ManHinh.filter(_date);
    if (_res.daTa) setData(_res.daTa);
    setLoading(false);
  };

  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter(filterCondition);
  };
  const content = (item) => {
    return (
      <Flex gap={5}>
        {checkAction(3) && (
          <Popconfirm
            title="Xoá giải đấu"
            description="Bạn có chắc chắn muốn xoá giải đấu?"
            okText="Đồng ý"
            cancelText="Không"
            onConfirm={() => remove(item.ID)}
          >
            <Tooltip placement="topRight" title="Xoá">
              <Button danger ghost icon={<CiTrash />} />
            </Tooltip>
          </Popconfirm>
        )}
      </Flex>
    );
  };
  const columns = [
    {
      title: "Tên giải đấu",
      width: "300px",
      render: (item) => (
        <p
          style={{ fontWeight: "bold", cursor: "pointer" }}
          onClick={() => refDetail.current.show(item)}
        >
          {item?.TenGD}
        </p>
      ),
    },
    {
      title: "Thời gian bắt đầu",
      dataIndex: "ThoiGianBD",
      render: (item) => item && Format_Datetime(item),
    },
    {
      title: "Start",
      dataIndex: "isStart",
      render: (item) => (
        <Tag color={item ? "green" : "red"}>
          {item ? "Đã bắt đầu" : "Chưa bắt đầu"}
        </Tag>
      ),
    },
    {
      title: "Pause",
      render: (item) =>
        item.isStart && (
          <Tag color={item.isPause === true ? "red" : "green"}>
            {item.isPause === true ? "Tạm dừng" : "Đang chạy"}
          </Tag>
        ),
    },
    {
      title: "Hiển thị giải",
      dataIndex: "isShowGiai",
      render: (item) => (
        <Tag color={item === true ? "green" : "red"}>
          {item === true ? "Có" : "Không"}
        </Tag>
      ),
    },
    {
      title: "Màn hình",
      dataIndex: "LoaiManHinh",
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      render: (item) => Format_Date(item),
    },
    {
      title: "Xếp chỗ",
      width: "140px",
      render: (item) => (
        <Flex vertical gap={5}>
          <Checkbox
            checked={item.KieuXepCho === "AUTO"}
            onChange={() =>
              item.KieuXepCho !== "AUTO" && hanldeXepCho(item.ID, "AUTO")
            }
          >
            AUTO
          </Checkbox>
          {/* <Checkbox
            checked={item.KieuXepCho === "WAITING"}
            onChange={() =>
              item.KieuXepCho !== "WAITING" && hanldeXepCho(item.ID, "WAITING")
            }
          >
            WAITING
          </Checkbox> */}
          <Checkbox
            checked={item.KieuXepCho === "NOSEAT"}
            onChange={() =>
              item.KieuXepCho !== "NOSEAT" && hanldeXepCho(item.ID, "NOSEAT")
            }
          >
            NO SEAT
          </Checkbox>
        </Flex>
      ),
    },
    {
      title: "",
      fixed: "right",
      width: "180px",
      render: (item) => {
        return (
          <Flex gap={5} align="center">
            {!item.isStart && checkAction(57) && (
              <Popconfirm
                title="Bắt đầu giải đấu"
                description="Bạn có chắc chắn muốn bắt đầu giải đấu?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => startTour(item.ID)}
              >
                <Tooltip placement="topRight" title="Bắt đầu giải đấu">
                  <Button type="primary" ghost icon={<VscDebugStart />} />
                </Tooltip>
              </Popconfirm>
            )}
            {checkAction(61) && (
              <Tooltip placement="topRight" title="Xem giải">
                <Button
                  type="primary"
                  ghost
                  icon={<LuView />}
                  onClick={() => openInNewTab(item)}
                />
              </Tooltip>
            )}
            {checkAction(74) && (
              <Tooltip placement="topRight" title="Bàn">
                <Button
                  type="primary"
                  ghost
                  icon={<SiTableau />}
                  onClick={() => refTable.current.show(item)}
                />
              </Tooltip>
            )}
            {checkAction(75) && (
              <Tooltip placement="topRight" title="Cài đặt blind">
                <Button
                  type="primary"
                  ghost
                  icon={<IoSettingsOutline />}
                  onClick={() => refSettingBlind.current.show(item.ID)}
                />
              </Tooltip>
            )}
            {checkAction(2) && (
              <Tooltip placement="topRight" title="Sửa">
                <Button
                  type="primary"
                  ghost
                  icon={<MdEdit />}
                  onClick={() => refAdd.current.update(item)}
                />
              </Tooltip>
            )}
            <Popover content={() => content(item)}>
              <Button type="primary" icon={<IoMdMore />} />
            </Popover>
          </Flex>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await TourService.deleteGiaiDau({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá giải đấu thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const hanldeXepCho = async (value, type) => {
    let _res = await TourService.updateXepCho({
      ID: Number(value),
      KieuXepCho: type,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let item = _data.find((v) => v.ID === Number(value));
      item.KieuXepCho = type;
      setData(_data);
      openNotification("success", "topRight", "Thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const startTour = async (value) => {
    let _res = await TourService.startGiaiDau({ ID: Number(value) });
    if (_res.status === 2000) {
      filter(filterCondition);
      openNotification("success", "topRight", "Bạn đã bắt đầu giải đấu!");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const openInNewTab = (value) => {
    let url =
      "/#/portal?magd=" +
      value.ID +
      "&type=" +
      value.LoaiManHinh +
      "&mact=" +
      localStorage.getItem("MaCTDK");
    const newWindow = window.open(url, "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  useEffect(() => {
    if (hubConnection) {
      const openGiaiDau = async (response) => {
        console.log("response",response);
        if (response.deviceID === localStorage.getItem("deviceID")) {
          let url = "/#/portal?magd=" + response.maGD + "&type=" + (response.manHinh ?? 2)+
            "&mact=" +
            localStorage.getItem("MaCTDK");
          const newWindow = window.open(url, "_blank", "noopener,noreferrer");
          if (newWindow) newWindow.opener = null;
        }
      };
      hubConnection.on("OpenGiaiDau", openGiaiDau);
      return () => {
        hubConnection.off("OpenGiaiDau", openGiaiDau);
      };
    }
  }, [hubConnection]);
    const [isPopupVisible, setIsPopupVisible] = useState(true);

  return (
    <Row>
      <Toolbar
        left={() => (
          <Button
            style={{ marginRight: "10px" }}
            type="primary"
            onClick={() => refAdd.current.show()}
          >
            Thêm
          </Button>
        )}
        right={() => (
          <FilterModal
            filterCondition={filterCondition}
            onChangeFilter={onChangeFilter}
          />
        )}
      />
      <Table
        style={{ height: 100 }}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          // x: 2500
        }}
        size="small"
      />
      <FormAdd ref={refAdd} onAfterSubmit={onAfterSubmit} />
      <SettingBlind ref={refSettingBlind} />
      <FormDetail ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormTable ref={refTable} />
      {contextHolder}
     
    </Row>
  );
}
