import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Row,
  Flex,
  Tour,
  Col,
} from "antd";
import _ from "lodash";
import { TourService } from "services/TourService";
import { IoSaveOutline } from "react-icons/io5";
import { MdAirlineSeatReclineNormal } from "react-icons/md";
import { GiaiDauService } from "services/GiaiDauService";
import { Format_Currency } from "layouts/ultis/common";
const FormSeatDraw = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [droppedBoxes, setDroppedBoxes] = useState({ 0: [] });
  const [users, setUsers] = useState([]);
  const loadSeat = async (maban, mabangd) => {
    let _res = await TourService.Table.getSeatTrong({
      MaBan: maban,
      MaBanGD: mabangd,
    });
    return _res.data;
  };
  const findKeyWithMaxValue = (obj) => {
    let maxKey = null;
    let maxValue = -Infinity;

    // Iterate through the object
    for (const [key, value] of Object.entries(obj)) {
      if (value > maxValue) {
        maxValue = value;
        maxKey = key;
      }
    }

    return maxKey;
  };
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
  function getRandomValueFromArray(arr) {
    if (arr.length === 0) return undefined;
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
  }
  // useImperativeHandle(ref, () => ({
  //   show: async (user, MaGD) => {
  //     setLoading(true);
  //     setOpen(true);

  //     // Fetch table data
  //     const { data: tablerun } = await TourService.Table.get({ MaGD });
  //     console.log(user);

  //     const userAdd = {};
  //     const tb = {};
  //     const tableEmptySlot = {};
  //     const tableChipCount = {}; // Theo dõi tổng chip của mỗi bàn

  //     // Khởi tạo số lượng slot trống và tổng chip của mỗi bàn
  //     tablerun.forEach((item) => {
  //       tableEmptySlot[item.ID] = item.SoLuongMax;
  //       tb[item.ID] = [];
  //       tableChipCount[item.ID] = 0; // Tổng chip ban đầu của mỗi bàn là 0
  //     });

  //     // Sắp xếp player theo số chip giảm dần
  //     const shuffledUsers = shuffleArray(user).sort((a, b) => b.Chip - a.Chip);

  //     // Phân bổ player vào các bàn
  //     for (const u of shuffledUsers) {
  //       // Tìm bàn có tổng chip thấp nhất và còn slot trống
  //       const availableTables = Object.keys(tableEmptySlot).filter(
  //         (key) => tableEmptySlot[key] > 0
  //       );

  //       if (availableTables.length === 0) break;

  //       const tableWithMinChips = availableTables.reduce(
  //         (minTable, currentTable) => {
  //           return tableChipCount[currentTable] < tableChipCount[minTable]
  //             ? currentTable
  //             : minTable;
  //         },
  //         availableTables[0]
  //       );

  //       const findTable = tablerun.find(
  //         (v) => v.ID === Number(tableWithMinChips)
  //       );

  //       // Load available seats for the table
  //       let seats = await loadSeat(findTable?.MaBan, findTable?.ID);
  //       seats = seats.filter(
  //         (v) => !tb[tableWithMinChips].some((seat) => seat.MaSeatNew === v.ID)
  //       );

  //       if (seats.length > 0) {
  //         const seat = getRandomValueFromArray(seats);
  //         userAdd[u.ID] = true;

  //         // Thêm player vào bàn
  //         tb[tableWithMinChips].push({
  //           ...u,
  //           MaBan: findTable?.MaBan,
  //           MaSeatNew: seat.ID,
  //           TenSeatNew: seat.Name,
  //           Chip: seat.SoChip,
  //         });

  //         // Cập nhật số slot trống và tổng chip của bàn
  //         tableEmptySlot[tableWithMinChips] -= 1;
  //         tableChipCount[tableWithMinChips] += u.Chip;
  //       }
  //     }

  //     // Cập nhật state
  //     setDroppedBoxes(tb);
  //     setTables(tablerun);

  //     // Xác định player chưa được phân bàn
  //     const userThua = shuffledUsers.filter((v) => !userAdd[v.ID]);
  //     setUsers(userThua);

  //     setLoading(false);
  //     setData({ MaGD });
  //   },
  // }));
  const seatRedraw = async (user, MaGD) => {
    setLoading(true);
    const { data: tablerun } = await TourService.Table.get({ MaGD });
    console.log(user);

    const userAdd = {};
    const tb = {};
    const tableEmptySlot = {};
    const tableChipCount = {}; // Theo dõi tổng số chip của mỗi bàn

    // Initialize tableEmptySlot và tb
    tablerun.forEach((item) => {
      tableEmptySlot[item.ID] = item.SoLuongMax;
      tb[item.ID] = [];
      tableChipCount[item.ID] = 0; // Khởi tạo tổng số chip cho mỗi bàn
    });

    // Sắp xếp user theo số chip giảm dần
    const shuffledUsers = user.sort((a, b) => b.SoChip - a.SoChip);

    // Phân bổ từng user
    for (const u of shuffledUsers) {
      // Tìm bàn có tổng số chip thấp nhất và vẫn còn slot trống
      const targetTableId = tablerun.reduce((prevId, curr) => {
        const prevSlot = tableEmptySlot[prevId];
        const currSlot = tableEmptySlot[curr.ID];

        // Nếu cả hai bàn đều có slot trống, ưu tiên bàn có tổng chip thấp hơn
        if (prevSlot > 0 && currSlot > 0) {
          return tableChipCount[prevId] < tableChipCount[curr.ID]
            ? prevId
            : curr.ID;
        }

        // Nếu một trong hai bàn không còn slot, chọn bàn còn slot
        return prevSlot > 0 ? prevId : curr.ID;
      }, tablerun[0].ID);

      // Kiểm tra xem bàn có còn slot trống không
      if (tableEmptySlot[targetTableId] === 0) continue;

      const findTable = tablerun.find((v) => v.ID === Number(targetTableId));

      // Load available seats
      let seats = await loadSeat(findTable?.MaBan, findTable?.ID);
      seats = seats.filter(
        (v) => !tb[targetTableId].some((seat) => seat.MaSeatNew === v.ID)
      );

      if (seats.length > 0) {
        const seat = getRandomValueFromArray(seats);
        userAdd[u.ID] = true;

        tb[targetTableId].push({
          ...u,
          MaBan: findTable?.MaBan,
          MaSeatNew: seat.ID,
          TenSeatNew: seat.Name,
          Chip: seat.SoChip,
        });

        tableEmptySlot[targetTableId] -= 1;
        tableChipCount[targetTableId] += u.SoChip; // Cập nhật tổng chip của bàn
      }
    }

    // Update state
    setDroppedBoxes(tb);
    setTables(tablerun);

    // Identify users without seats
    const userThua = shuffledUsers.filter((v) => !userAdd[v.ID]);
    setLoading(false);
    setUsers(userThua);
  };
  const [players, setPlayers] = useState([]);
  useImperativeHandle(ref, () => ({
    show: async (user, MaGD) => {
      setOpen(true);
      seatRedraw(user, MaGD);
      setPlayers(user);
      setData({ MaGD });
    },
  }));

  // useImperativeHandle(ref, () => ({
  //   show: async (user, MaGD) => {
  //     setLoading(true);
  //     setOpen(true);
  //     // let _resseat = await TourService.Table.getSeatByMaGD({ MaGD: MaGD });
  //     // console.log(_resseat);
  //     let _res = await TourService.Table.get({ MaGD: MaGD });
  //     console.log(user);

  //     let tablerun = _res.data;
  //     let userAdd = {};
  //     let tb = {};
  //     let tableEmptySlot = {};
  //     tablerun.map((item) => {
  //       tableEmptySlot[item.ID] = item.SoLuongMax;
  //       tb[item.ID] = [];
  //     });
  //     let shuffledUsers = shuffleArray(user);
  //     for (let i = 0; i < shuffledUsers?.length; i++) {
  //       const u = shuffledUsers[i];
  //       const maxKey = findKeyWithMaxValue(tableEmptySlot);
  //       let findTable = tablerun.find((v) => v.ID === Number(maxKey));
  //       if (tableEmptySlot[maxKey] === 0) continue;
  //       let seats = await loadSeat(findTable?.MaBan, findTable?.ID);
  //       seats = seats.filter(
  //         (v) => _.map(tb[maxKey], "MaSeatNew").includes(v.ID) === false
  //       );
  //       if (seats.length > 0) {
  //         let seat = getRandomValueFromArray(seats);
  //         userAdd[u.ID] = true;
  //         tb[maxKey].push({
  //           ...u,
  //           MaBan: findTable?.MaBan,
  //           MaSeatNew: seat.ID,
  //           TenSeatNew: seat.Name,
  //           Chip: seat.SoChip,
  //         });
  //         tableEmptySlot[maxKey] -= 1;
  //       }
  //     }
  //     setDroppedBoxes(tb);
  //     setTables(tablerun);
  //     var userThua = shuffledUsers?.filter(
  //       (v) => Object.keys(userAdd).includes(String(v.ID)) === false
  //     );
  //     setUsers(userThua);
  //     setLoading(false);
  //     setData({ MaGD: MaGD });
  //   },
  // }));
  const onClose = () => {
    setOpen(false);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async () => {
    let seats = [];
    if (users.length > 0) {
      openNotification(
        "error",
        "topRight",
        "Số seat chưa đủ để đóng, vui lòng kiểm tra lại!"
      );
      return;
    }
    setLoading(true);
    Object.keys(droppedBoxes).forEach((zone) => {
      // let item = tables.find((v) => v.ID === Number(zone));
      droppedBoxes[zone].forEach((v) => {
        seats.push({
          ID: v.ID,
          MaSeat: v.MaSeatNew,
          TenSeat: v.TenSeatNew,
          MaBanGD: Number(zone),
          // MaSeatNew: v.MaSeatNew,
          // MaSeat: v.MaSeat,
          // TenSeat: v.Name,
          // TenSeatNew: v.TenSeatNew,
          // MaThe: v.MaThe,
          // MaKH: v.MaKH,
          // MaBanGDNew: Number(zone),
          // DienGiai: `Chuyển sang ${item.Name}/${v.TenSeatNew}`,
          // DienGiaiNew: `Chuyển từ ${data.Name}/${v.Name}`,
        });
      });
    });

    let _payload = {
      ...data,
      List: seats,
    };
    let _res = await GiaiDauService.RemainingPlayer.seatDraw(_payload);
    setLoading(false);
    if (_res.status === 2000) {
      onAfterSubmit();
      openNotification("success", "topRight", "Seat draw thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Drawer
      title="Seat Draw"
      width={920}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <p style={{}}>
            Tổng chip: {Format_Currency(_.sumBy(players, "SoChip"))}
          </p>
          <p style={{ fontWeight: "900", marginRight: 40, marginLeft: 20 }}>
            AVG Chip/Table:{" "}
            {Format_Currency(
              _.sumBy(players, "SoChip") / Object.keys(droppedBoxes).length
            )}
          </p>
          <Button
            loading={loading}
            // type="primary"
            icon={<IoSaveOutline />}
            onClick={() => seatRedraw(players, data?.MaGD)}
          >
            Redraw
          </Button>
          <Button
            loading={loading}
            type="primary"
            icon={<IoSaveOutline />}
            onClick={() => onSubmit()}
          >
            Xác nhận
          </Button>
        </Space>
      }
    >
      {/* <DndProvider backend={HTML5Backend}> */}
      <Row gutter={[10, 10]}>
        {Object.keys(droppedBoxes)?.map((zone) => {
          let tb = tables?.find((v) => v.ID === Number(zone));
          if (!tb) {
            console.warn(`No table found for zone: ${zone}`);
            return null;
          }
          return (
            <Col span={12}>
              <p style={{ textAlign: "center", fontWeight: "bold" }}>
                {`${tb?.TenBan} (Max ${tb?.SoLuongMax} ghế)`}
              </p>
              <div
                style={{
                  background: "white",
                  padding: "8px",
                  border: "1px solid #d9d9d9",
                  minHeight: "100px",
                  marginBottom: "8px",
                  borderRadius: "20px",
                }}
              >
                {_.orderBy(droppedBoxes[zone], ["TenSeatNew", "asc"])?.map(
                  (box) => (
                    <div
                      key={box.ID}
                      style={{
                        opacity: 1,
                        padding: "3px",
                        border: "1px solid #d9d9d9",
                        borderRadius: "10px",
                        marginBottom: "4px",
                      }}
                    >
                      <Flex justify="space-between" gap={5}>
                        <p>{box.TenCongTy}</p>
                        <p>{box.SoChip}</p>
                        <Flex align="center">
                          <MdAirlineSeatReclineNormal />
                          <p>{box.TenSeatNew}</p>
                        </Flex>
                      </Flex>
                    </div>
                  )
                )}
                Tổng chip: {_.sumBy(droppedBoxes[zone], "SoChip")}
              </div>
            </Col>
          );
        })}
      </Row>
      <div style={{ marginTop: "16px" }}>
        <p style={{ color: "red" }}>Player chưa có seat để chuyển bàn</p>
        {users.map((box) => (
          <div
            style={{
              opacity: 1,
              padding: "3px",
              border: "1px solid #d9d9d9",
              borderRadius: "10px",
              marginBottom: "4px",
            }}
          >
            <Flex justify="space-between" gap={5}>
              <p>{box.TenCongTy}</p>
            </Flex>
          </div>
          // <DraggableBox key={box.ID} id={box.ID} content={`${box.TenCongTy}`} />
        ))}
      </div>
      {/* </DndProvider> */}
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormSeatDraw);
