import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Flex, Popconfirm, Table, Tooltip, notification } from "antd";
import FormAdd from "./FormAdd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import { TourService } from "services/TourService";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import { NhanVienService } from "services/NhanVienService";
import { FaUserLock } from "react-icons/fa";
import { TbPasswordUser } from "react-icons/tb";
export default function Index() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const refDetail = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(() => {
    filter();
  }, []);
  const filter = async () => {
    setLoading(true);
    let _res = await NhanVienService.getNhanVien();
    if (_res.data) setData(_res.data);
    setLoading(false);
  };

  const onAfterSubmit = () => {
    filter();
  };
  const lockUser = async (value) => {
    let _res = await NhanVienService.updateNhanVien({
      ...value,
      ID: value.MaNV,
      Lock: !value.Lock,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let item = _data.find((v) => v.MaNV === Number(value.MaNV));
      item.Lock = !item.Lock;
      setData(_data);
      openNotification("success", "topRight", "Thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const resetPass = async (value) => {
    let _res = await NhanVienService.resetPass({
      MaNV: value,
    });
    if (_res.status === 2000) {
      openNotification("success", "topRight", _res?.message);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const columns = [
    {
      title: "Mã số",
      dataIndex: "MaSo",
    },
    {
      title: "Họ và tên",
      dataIndex: "HoTen",
    },
    {
      title: "Ngày sinh",
      dataIndex: "NgaySinh",
      render: (item) => Format_Date(item),
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChi",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Chi nhánh",
      dataIndex: "ChiNhanh",
    },
    {
      title: "Khoá",
      dataIndex: "Lock",
      render: (item) => (item ? "Có" : "Không"),
    },

    {
      title: "",
      fixed: "right",
      width: "180px",
      render: (item) => {
        return (
          <Flex align="center" justify="center" gap={5}>
            <Popconfirm
              title={`${item.Lock ? "Mở" : "Khoá"} tài khoản`}
              description={`Bạn có chắc chắn muốn ${
                item.Lock ? "mở khoá" : "Khoá"
              } tài khoản này?`}
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => lockUser(item)}
            >
              <Tooltip
                placement="topRight"
                title={`${item.Lock ? "Mở" : "Khoá"} tài khoản`}
              >
                <Button type="primary" ghost icon={<FaUserLock />} />
              </Tooltip>
            </Popconfirm>
            <Popconfirm
              title="Khôi phục mật khẩu"
              description="Bạn có chắc chắn muốn khôi phục mật khẩu này?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => resetPass(item.MaNV)}
            >
              <Tooltip placement="topRight" title="Khôi phục mật khẩu">
                <Button type="primary" ghost icon={<TbPasswordUser />} />
              </Tooltip>
            </Popconfirm>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá nhân viên"
              description="Bạn có chắc chắn muốn xoá nhân viên này?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaNV)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button danger ghost icon={<CiTrash />} />
              </Tooltip>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await NhanVienService.deleteNhanVien({ ID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.MaNV !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá nhân viên thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Box>
      <Toolbar
        left={() => (
          <Button type="primary" onClick={() => refDetail.current.show()}>
            Thêm
          </Button>
        )}
      />
      <Table
        style={{ height: 100 }}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          // x: 2500
        }}
        size="small"
      />
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Box>
  );
}
