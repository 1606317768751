import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Row,
  Col,
  Input,
  Flex,
  Badge,
  Tag,
  Tooltip,
} from "antd";
import { BuyinService } from "services/BuyinService";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import Toolbar from "components/Toolbar";
import { UploadOutlined } from "@ant-design/icons";
import { IoMdPrint } from "react-icons/io";
import BuyinToPrint from "./BuyinToPrint";
import { useReactToPrint } from "react-to-print";
import ExcelJS from "exceljs";
import { OtherService } from "services/OtherService";
import { Format_Datetime } from "layouts/ultis/common";
import { TourService } from "services/TourService";
import { GiaiDauService } from "services/GiaiDauService";
import { NhanVienService } from "services/NhanVienService";
import { AiOutlineMenuFold } from "react-icons/ai";
import "./scss/index.scss";
import { MdOutlineNoteAlt } from "react-icons/md";
import FormEditNote from "./FormEditNote";
import { LuFileEdit } from "react-icons/lu";
import FormEdit from "./FormEdit";

export default function Settings() {
  const [data, setData] = useState([]);
  const [dataPrint, setDataPrint] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const refPrint = useRef(null);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const refDetail = useRef(null);
  const refEdit = useRef(null);
  const refNote = useRef(null);
  const [action, setAction] = useState([]);
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    ListMaCT: ",,",
    HinhThuc: ",,",
    GiaiDau: ",,",
    Voucher: ",,",
    AgentID: 0,
    InputSearch: "",
  });
  useEffect(() => {
    NhanVienService.getAction({ FormID: 162 }).then((res) =>
      setAction(res?.data)
    );
    loadCategories();
    // loadBuyin(filterCondition);
  }, []);
  const checkAction = (id) => {
    return action?.some((v) => v.FeatureID === id);
  };
  const loadBuyin = async (_filter) => {
    setLoading(true);
    let _res = await BuyinService.getBuyin(_filter);
    setData(_res.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    let _resCT = await BuyinService.getCompany();
    setCompanies(_resCT.data);
  };
  const columns = [
    {
      title: "Số HD",
      width: 60,
      render: (item) => {
        return <span>{item.SoHoaDon}</span>;
      },
     
    },
    {
      title: "Loại buyin",
      width: 80,
      dataIndex: "Type",
      render: (item) => (
        <Tag
          color={
            item === "BUYIN" ? "green" : item === "REBUY" ? "gold" : "magenta"
          }
        >
          {item}
        </Tag>
      ),
    },

    // {
    //   title: "Loại thẻ",
    //   dataIndex: "TenLT",
    // },
    // {
    //   title: "Mã thẻ",
    //   dataIndex: "SoThe",
    // },
    {
      title: "Tên khách hàng",
      dataIndex: "TenCongTy",
      width: 170,
    },
    {
      title: "Di động",
      dataIndex: "DiDong",
    },
    {
      title: "Hình thức TT",
      dataIndex: "HinhThucTT",
      width: 150,
    },
    {
      title: "Tiền giải",
      dataIndex: "TienGiai",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tiền phí",
      dataIndex: "TienPhi",
      render: (item) => Format_Currency(item),
    },

    // {
    //   title: "Tên giải hiện tại",
    //   dataIndex: "TenGDEnd",
    //   width: "400px",
    // },

    // {
    //   title: "Tiền bounty",
    //   dataIndex: "TienBounty",
    //   render: (item) => Format_Currency(item),
    // },

    {
      title: "Tổng tiền",
      dataIndex: "Tien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tiền voucher",
      dataIndex: "TienVouCher",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Thực thu",
      dataIndex: "TongTien",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      render: (item) => Format_Datetime(item),
      width: 130,
    },
    {
      title: "Agent",
      dataIndex: "TenAgent",
    },
    // {
    //   title: "Tiền CK",
    //   dataIndex: "TienCK",
    //   render: (item) => Format_Currency(item),
    // },

    {
      title: "Tiền Jackport",
      dataIndex: "TienJackPort",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Điểm",
      dataIndex: "Diem",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tour",
      dataIndex: "TenGD",
      width: 400,
    },
    {
      title: "Ghi chú",
      dataIndex: "GhiChu",
    },
    {
      title: "Người nhập",
      dataIndex: "HoTen",
    },

    {
      title: "ID Buyin",
      dataIndex: "ID",
    },
    {
      title: "",
      fixed: "right",
      width: "90px",
      render: (item) => {
        return (
          <Flex gap={3} justify="center">
            <Tooltip title="Sửa ghi chú">
              <Button
                type="primary"
                ghost
                icon={<MdOutlineNoteAlt />}
                onClick={() => refNote.current.show(item)}
              />
            </Tooltip>
            <Button
              type="primary"
              ghost
              icon={<IoMdPrint />}
              onClick={() => {
                setDataPrint({ ...item, TenHT: item.HinhThucTT });
              }}
            />
            {checkAction(2) && (
              <Tooltip title="Sửa hoá đơn">
                <Button
                  type="primary"
                  ghost
                  icon={<LuFileEdit />}
                  onClick={() => refEdit.current.show(item)}
                />
              </Tooltip>
            )}

            {checkAction(3) && (
              <Popconfirm
                title="Xoá buyin"
                description="Bạn có chắc chắn muốn xoá buyin hay không?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => remove(item)}
              >
                <Button danger ghost icon={<CiTrash />} />
              </Popconfirm>
            )}
          </Flex>
        );
      },
    },
  ];
  useEffect(() => {
    if (dataPrint) handlePrint();
  }, [dataPrint]);
  const handlePrint = useReactToPrint({
    content: () => refPrint.current,
  });
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
      duration: 0.7,
    });
  };
  const remove = async (item) => {
    let _res = await BuyinService.deleteBuyin({
      MaGD: Number(item.MaGD),
      ID: item.ID,
    });
    if (_res.status === 2000) {
      tinhLaiGiai(item.MaGD, 0);
      OtherService.addHistory({
        FormID: 0,
        Form: "THUNGAN",
        NoiDung: `Xoá buyin giải ${item.TenGD}. Khách hàng ${item.TenCongTy}. Di động ${item.DiDong}`,
        Action: "DELETE",
      });
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(item.ID));
      setData(fil);
      openNotification("success", "topRight", "Xoá buyin thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const tinhLaiGiai = async (maGD) => {
    let _resgd = await TourService.getDetail({ ID: maGD });
    let _res3 = await TourService.getKhoangEntry();
    let _gd = _resgd.data;
    let _khoangEntry = _res3.data;
    let _entryHienTai = _khoangEntry.find((v) => v.ID === _gd?.IDEntry);
    let _resDiemTichLuy = await BuyinService.getDiemTichLuyByMaGD({
      MaGD: maGD,
    });
    let soNguoiChoi = _resDiemTichLuy?.data?.length;
    if (_entryHienTai?.SoLuongTu > soNguoiChoi) {
      let idEntryNew = _khoangEntry.find(
        (item) =>
          soNguoiChoi >= item.SoLuongTu && soNguoiChoi <= item.SoLuongDen
      );
      if (idEntryNew) {
        let _res = await TourService.getTyLeGT({ ID: idEntryNew.ID });
        // let _gd = giaiDau?.find((d) => d.ID === maGD);
        let TongTienGiaiDau = (_gd.TienGiaiBuyIn ?? 0) * soNguoiChoi;
        let TongTienTraoGiai = 0;
        if (TongTienGiaiDau < _gd.GTD) {
          TongTienTraoGiai =
            (_gd.GTD ?? 0) * (_gd.PhanTramTraoGiai ?? 0) * 0.01 +
            (_gd.TienCongThem ?? 0) -
            (_gd.TienHighHand ?? 0);
        } else {
          TongTienTraoGiai =
            TongTienGiaiDau * (_gd.PhanTramTraoGiai ?? 0) * 0.01 +
            (_gd.TienCongThem ?? 0) -
            (_gd.TienHighHand ?? 0);
        }
        let GiaiThuong = [];
        _res?.data?.map((v) => {
          GiaiThuong.push({
            MaGD: maGD,
            TenGiai: v.TenGiai,
            isHienThi: v.isHienThi,
            SoLuongGiai: v.SoLuongGiai,
            PhanTram: v.PhanTram,
            ThanhTien: (v.PhanTram ?? 0) * TongTienTraoGiai * 0.01,
            HienThi: Format_Currency(
              (v.PhanTram ?? 0) * TongTienTraoGiai * 0.01
            ),
            DiemThuong: v.DiemThuong,
            TongTien:
              (v.PhanTram ?? 0) *
              (v.SoLuongGiai ?? 0) *
              TongTienTraoGiai *
              0.01,
          });
        });
        if (GiaiThuong.length > 0) {
          let _resadd = await GiaiDauService.updateCoCauGiaiThuong({
            MaGD: maGD,
            IDEntry: idEntryNew.ID,
            ListGiaiThuong: GiaiThuong,
          });
          if (_resadd.status === 2000) {
            // let _giaidau = _.cloneDeep(giaiDau);
            // let item = _giaidau.find((d) => d.ID === maGD);
            // item.IDEntry = idEntryNew.ID;
            // setGiaiDau(_giaidau);
            openNotification(
              "success",
              "topRight",
              "Cân lại giải thưởng thành công!"
            );
          }
        }
      }
    }
  };
  const onAfterSubmit = () => {
    loadBuyin(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    loadBuyin(_filter);
    setFilterCondition(_filter);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        loadBuyin(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const exportExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("LichSuBuyIn");
    sheet.properties.defaultRowHeight = 20;
    sheet.columns = [
      {
        header: "Số hoá đơn",
        key: "sohd",
        width: 15,
        font: {
          bold: true,
          size: 24,
        },
      },
      {
        header: "Tour",
        key: "tengd",
        width: 20,
      },
      {
        header: "Loại thẻ",
        key: "loaithe",
        width: 15,
      },
      {
        header: "Mã thẻ",
        key: "mathe",
        width: 15,
      },
      {
        header: "Khách hàng",
        key: "khachhang",
        width: 15,
      },
      {
        header: "Tiền giải",
        key: "tiengiai",
        width: 20,
        numFmt: "#.##0",
      },
      {
        header: "Tên giải hiện tại",
        key: "tengdend",
        width: 10,
      },
      {
        header: "Tiền phí",
        key: "tienphi",
        width: 20,
        numFmt: "#.##0",
      },
      {
        header: "Tiền bounty",
        key: "tienbounty",
        width: 20,
        numFmt: "#.##0",
      },
      {
        header: "Tiền voucher",
        key: "tienvoucher",
        width: 20,
        numFmt: "#.##0",
      },
      {
        header: "Tổng tiền",
        key: "tongtien",
        width: 20,
        numFmt: "#.##0",
      },

      {
        header: "Tỷ lệ CK",
        key: "tyleck",
        width: 20,
      },
      {
        header: "Tiền CK",
        key: "tienck",
        width: 20,
      },
      {
        header: "Thực thu",
        key: "thucthu",
        width: 20,
      },
      {
        header: "Hình thức TT",
        key: "hinhthuctt",
        width: 30,
      },
    ];
    data?.map((item) => {
      sheet.addRow({
        sohd: item.SoHoaDon,
        tengd: item.TenGD,
        loaithe: item.TenLT,
        mathe: item.SoThe,
        khachhang: item.TenCongTy,
        tiengiai: item.TienGiai,
        tengdend: item.TenGDEnd,
        tienphi: item.TienPhi,
        tienbounty: item.TienBounty,
        tienvoucher: item.TienVouCher,
        tongtien: item.TongTien,
        tyleck: item.TyLeCK,
        tienck: item.TienCK,
        thucthu: item.ThucThu,
        hinhthuctt: item.HinhThucTT,
      });
    });
    // sheet.getCell("A1").numFmt = "# ?/?";
    sheet.addRow({
      sohd: "Tổng: " + data.length,
      tiengiai: _.sumBy(data, (o) => o.TienGiai),
      tienphi: _.sumBy(data, (o) => o.TienPhi),
      tienbounty: _.sumBy(data, (o) => o.TienBounty),
      tienvoucher: _.sumBy(data, (o) => o.TienVouCher),
      tongtien: _.sumBy(data, (o) => o.TongTien),
      tienck: _.sumBy(data, (o) => o.TienCK),
      thucthu: _.sumBy(data, (o) => o.ThucThu),
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "lichsubuyin.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  return (
    <Row gutter={15}>
      <Col span={collapsed ? 0 : 4}>
        <FilterModal
          companies={companies}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                color="primary"
                className="icon-filter"
                onClick={() => onHandleHide()}
              />
              <Input
                placeholder="Tìm kiếm"
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                onChange={(e) => onSearch(e.target.value)}
              />
            </Flex>
          )}
          right={() => (
            <Flex>
              <Button
                type="primary"
                onClick={() => refDetail.current.show(filterCondition)}
              >
                Thêm
              </Button>
              <Button
                icon={<UploadOutlined />}
                onClick={() => exportExcel()}
                style={{ marginLeft: 10 }}
              >
                Export
              </Button>
            </Flex>
          )}
        />
        <Table
          style={{ height: 100 }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            x: 2500,
          }}
          size="small"
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={5}>
                    Tổng: {pageData.length}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TienGiai),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TienPhi),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.Tien),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TienVouCher),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} colSpan={3}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TongTien),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={6}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TienCK),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell> */}
                  <Table.Summary.Cell index={2} colSpan={7}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TienJackPort),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>

                  {/* <Table.Summary.Cell index={4}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TienBounty),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell> */}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
          virtual={true}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 1000,
            pageSizeOptions: ["20", "50", "500", "1000", "5000"],
            position: ["bottomRight"],
          }}
        />
      </Col>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormEditNote ref={refNote} onAfterSubmit={onAfterSubmit} />
      <FormEdit ref={refEdit} onAfterSubmit={onAfterSubmit} />
      <div style={{ display: "none" }}>
        <BuyinToPrint
          ref={refPrint}
          data={dataPrint}
          company={companies?.length > 0 && companies[0]}
          soTT={dataPrint?.SoTT}
        />
      </div>
      {contextHolder}
    </Row>
  );
}
