import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Checkbox,
} from "antd";
import { BuyinService } from "services/BuyinService";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { NhanVienService } from "services/NhanVienService";
import { CategoryService } from "services/CategoryService";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaultData = {
    NgaySinh: new Date(),
    NgayCap: new Date(),
    Lock: false
  };
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [nhomQuyen, setNhomQuyen] = useState([]);
  const [quyDanh, setQuyDanh] = useState([]);
  const [ban, setBan] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [mayIn,setMayIn] = useState([])
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const loadCategories = async () => {
    let _qd = await NhanVienService.getQuyDanh();
    let _nq = await NhanVienService.getNhomQuyen();
    let _ct = await BuyinService.getCompany();
    let _res = await CategoryService.Khac.getTable({});
    let _resMI = await CategoryService.Khac.getCatDatMayIn();
    setMayIn(_resMI.data ?? []);
    setCompanies(_ct.data);
    setNhomQuyen(_nq?.data ?? []);
    setQuyDanh(_qd?.data ?? []);
    setBan(_res?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      setIsUpdate(true);
      loadCategories();
      data.NgaySinh = new Date(data.NgaySinh);
      data.NgayCap = new Date(data.NgayCap);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    let _res = null;
    if (isUpdate) {
      _data.ID = _data.MaNV;
      _res = await NhanVienService.updateNhanVien(_data);
    } else {
      _res = await NhanVienService.addNhanVien(_data);
      setData((pre) => {
        return { ...pre, MaNV: _res.data };
      });
    }
    setLoading(false);
   if (_res.status === 2000) {
     openNotification(
       "success",
       "topRight",
       `${isUpdate ? "Sửa" : "Thêm"} nhân viên thành công!`
     );
     setIsUpdate(true);
     if (close) onClose();
     onAfterSubmit();
   } else {
     openNotification("error", "topRight", _res?.message);
   }
  };
  return (
    <Drawer
      title="Nhân viên"
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button loading={loading} onClick={() => onSubmit()}>
            Lưu
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 20]}>
        <Col span={18} className="gutter-row">
          <p style={{ color: "red" }}>Họ và tên *</p>
          <Row gutter={[10]}>
            <Col span={6}>
              <Select
                style={{
                  width: "100%",
                }}
                value={data?.MaQD}
                placeholder="Chọn danh xưng"
                onChange={(v) => applyChange("MaQD", v)}
                options={quyDanh}
                fieldNames={{ label: "TenQD", value: "MaQD" }}
              />
            </Col>
            <Col span={18}>
              <Input
                value={data?.HoTen}
                placeholder="Nhập họ và tên"
                onChange={(v) => applyChange("HoTen", v.target.value)}
              />
            </Col>
          </Row>
          <p style={{ color: "red" }}>{errors?.HoTen}</p>
        </Col>
        <Col span={6}>
          <p>Mã số</p>
          <Input
            value={data?.MaSo}
            placeholder="Nhập mã số"
            onChange={(v) => applyChange("MaSo", v.target.value)}
          />
        </Col>
        {isUpdate === false && (
          <>
            <Col span={12}>
              <p style={{ color: "red" }}>Mật khẩu</p>
              <Input
                value={data?.Password}
                status={data?.Password ? "" : "error"}
                placeholder="Nhập mật khẩu"
                onChange={(v) => applyChange("Password", v.target.value)}
              />
            </Col>
            <Col span={12}>
              <p style={{ color: "red" }}>Xác nhận mật khẩu</p>
              <Input
                value={data?.RePassword}
                status={data?.Password === data?.RePassword ? "" : "error"}
                placeholder="Nhập lại mật khẩu"
                onChange={(v) => applyChange("RePassword", v.target.value)}
              />
            </Col>
          </>
        )}
        <Col span={12}>
          <p>Ngày sinh</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.NgaySinh)}
            placeholder="Ngày sinh"
            locale={vi}
            onChange={(v) => applyChange("NgaySinh", v)}
          />
        </Col>
        <Col span={12}>
          <p>Số CMND</p>
          <Input
            value={data?.SoCMND}
            placeholder="Nhập số cmnd/cccd"
            onChange={(v) => applyChange("SoCMND", v.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Ngày cấp</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.NgayCap)}
            placeholder="Ngày cấp"
            locale={vi}
            onChange={(v) => applyChange("NgayCap", v)}
          />
        </Col>
        <Col span={12}>
          <p>Nơi cấp</p>
          <Input
            value={data?.NoiCap}
            placeholder="Nhập nơi cấp"
            onChange={(v) => applyChange("NoiCap", v.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Địa chỉ</p>
          <Input
            value={data?.DiaChi}
            placeholder="Nhập địa chỉ"
            onChange={(v) => applyChange("DiaChi", v.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Email</p>
          <Input
            value={data?.Email}
            placeholder="Nhập email"
            onChange={(v) => applyChange("Email", v.target.value)}
          />
        </Col>
        <Col span={6}>
          <p style={{ color: "red" }}>Trụ sở chính</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaCT}
            placeholder="Chọn trụ sở chính"
            onChange={(v) => applyChange("MaCT", v)}
            options={companies}
            fieldNames={{ label: "TenCT", value: "ID" }}
          />
        </Col>
        <Col span={6}>
          <p style={{ color: "red" }}>Quyền truy cập</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.PerID}
            placeholder="Chọn quyền truy cập"
            onChange={(v) => applyChange("PerID", v)}
            options={nhomQuyen}
            fieldNames={{ label: "PerName", value: "PerID" }}
          />
        </Col>
        <Col span={6}>
          <p style={{ color: "red" }}>Bàn</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaBan}
            placeholder="Chọn bàn"
            onChange={(v) => applyChange("MaBan", v)}
            options={ban}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>
        <Col span={6}>
          <p>Máy in</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaMayIn}
            placeholder="Chọn máy in"
            onChange={(v) => applyChange("MaMayIn", v)}
            options={mayIn}
            fieldNames={{ label: "TenMayIn", value: "ID" }}
          />
        </Col>

        <Col span={8}>
          <Checkbox
            checked={data?.IsDealer === true}
            onChange={() => applyChange("IsDealer", !data?.IsDealer)}
          >
            Dealer
          </Checkbox>
        </Col>

        <Col span={8}>
          <Checkbox
            checked={data?.Lock === true}
            onChange={() => applyChange("Lock", !data?.Lock)}
          >
            Khoá tài khoản
          </Checkbox>
        </Col>
        <Col span={8}>
          <Checkbox
            checked={data?.isNotiBar === true}
            onChange={() => applyChange("isNotiBar", !data?.isNotiBar)}
          >
            Nhận thông báo bar
          </Checkbox>
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
