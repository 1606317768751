import axios from "axios";
import { post } from "./api_helper";
import axiosApi from "./api_helper";
export const GiaiDauService = {
  getDetail: async (payload) => {
    return await axiosApi()
      .post("api/Poker/DanhSachGiaiDau_ChiTietHIenThi", payload)
      .then((res) => res.data);
  },
  getDetailNoAuth: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/Poker/DanhSachGiaiDau_ChiTietHIenThi2", payload)
      .then((res) => res.data);
    // return await post("/Poker/DanhSachGiaiDau_ChiTietHIenThi2",payload)
  },
  getCoCauGiaiThuong: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/Poker/ListGiaiThuong", payload)
      .then((res) => res.data);
    // return await post("/Poker/ListGiaiThuong",payload)
  },
  updateCoCauGiaiThuong: async (payload) => {
    return await axiosApi()
      .post("api/giaidau/update-cocaugiai", payload)
      .then((res) => res.data);
  },
  getCoCauGiaiThuongNoAuth: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/Poker/ListGiaiThuong2", payload)
      .then((res) => res.data);
    // return await post("/Poker/ListGiaiThuong2",payload)
  },
  getJackpot: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-jackpot", payload)
      .then((res) => res.data);
  },

  Actions: {
    tangNguoiChoi: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/tang-nguoi-choi", payload)
        .then((res) => res.data);
    },
    giamNguoiChoi: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/giam-nguoi-choi", payload)
        .then((res) => res.data);
    },
    tangGiamTG: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/tang-giam-thoi-gian", payload)
        .then((res) => res.data);
    },
    tiepTuc: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/tiep-tuc", payload)
        .then((res) => res.data);
    },
    tamDung: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/tam-dung", payload)
        .then((res) => res.data);
    },
    nextLevel: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/next-level", payload)
        .then((res) => res.data);
    },
    backLevel: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/back-level", payload)
        .then((res) => res.data);
    },
    hideGiai: async (payload) => {
      return await axiosApi()
        .post("api/Poker/TatHienThiGiai", payload)
        .then((res) => res.data);
    },
    showGiai: async (payload) => {
      return await axiosApi()
        .post("api/Poker/HienThiGiai", payload)
        .then((res) => res.data);
    },
  },
  LichThiDau: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/giai-dau/get-lichthidau", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/giai-dau/add-lichthidau", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/giai-dau/delete-lichthidau", payload)
        .then((res) => res.data);
    },
  },
  TyLeGiaiThuong: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/get-tylegiaithuong", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/add-tylegiaithuong", payload)
        .then((res) => res.data);
    },
    edit: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/edit-tylegiaithuong", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/delete-tylegiaithuong", payload)
        .then((res) => res.data);
    },
    deleteByKhoangEntry: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/delete-tylegiaithuong-bykhoangentry", payload)
        .then((res) => res.data);
    },
    import: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/import-tylegiaithuong", payload)
        .then((res) => res.data);
    },
  },
  RemainingPlayer: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/get-remaining-player", payload)
        .then((res) => res.data);
    },
    seatDraw: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/seat-draw", payload)
        .then((res) => res.data);
    },
    update: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/update-remaining-player", payload)
        .then((res) => res.data);
    },
    addUserToSeat: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/update-user-play", payload)
        .then((res) => res.data);
    },
  },
  ITM: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/get-itm", payload)
        .then((res) => res.data);
    },
  },
};
