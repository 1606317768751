import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Table,
  Tooltip,
  notification,
  Tabs,
  Flex,
  Checkbox,
} from "antd";
import TabPane from "antd/es/tabs/TabPane";

import { IoSettingsOutline } from "react-icons/io5";
import _ from "lodash";
import "../scss/index.scss";
import FormAddPer from "./FormAddPer";
import FormAdd from "./FormAdd";
import Toolbar from "components/Toolbar";
import { PermissionService } from "services/PermissionService";
import { CongTyService } from "services/CongTyService";
import { MdEdit } from "react-icons/md";
import FormAddPerNhomModules from "./FormAddPerNhomModules";
import { Format_Currency } from "layouts/ultis/common";
export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [activeTab, setActiveTab] = useState("0");
  const tabs = [
    {
      title: "Phân quyền công ty",
      key: "0",
    },
    {
      title: "Nhóm các module",
      key: "1",
    },
  ];
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const refDetail = useRef(null);
  const refDetailPer = useRef(null);
  const refDetailPerNhom = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(() => {
    filter(activeTab);
  }, []);
  const filter = async (tab) => {
    setLoading(true);
    if (tab === "0") {
      let _res = await PermissionService.TheoCongTy.getCongTyDangKy();
      if (_res.data) setData(_res.data);
    } else if (tab === "1") {
      let _res = await PermissionService.TheoNhomModules.get();
      setData(_res.data ?? []);
    }
    setLoading(false);
  };
  const onAfterSubmit = () => {
    filter(activeTab);
  };
  const columns = [
    {
      title: "Mã công ty",
      dataIndex: "MaCT",
    },
    {
      title: "Tên công ty",
      dataIndex: "TenCT",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "DiaChi",
      dataIndex: "DiaChi",
    },
    {
      title: "Server",
      dataIndex: "Server",
    },
    {
      title: "Mã CTDK",
      dataIndex: "MaCTDK",
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "100px",
      render: (item) => {
        return (
          <Flex align="center" justify="center">
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginRight: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Phân quyền">
              <Button
                type="primary"
                ghost
                icon={<IoSettingsOutline />}
                onClick={() => refDetailPer.current.show(item)}
              />
            </Tooltip>
          </Flex>
        );
      },
    },
  ];
  const columns2 = [
    {
      title: "Tên sản phẩm",
      dataIndex: "TenSanPham",
    },
    {
      title: "Số tiền trên tháng",
      dataIndex: "SoTienTrenThang",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Áp dụng",
      dataIndex: "ApDung",
      render: (item) => <Checkbox checked={item} />,
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "100px",
      render: (item) => {
        return (
          <Flex align="center" justify="center">
            <Tooltip placement="topRight" title="Chọn các module">
              <Button
                type="primary"
                ghost
                icon={<IoSettingsOutline />}
                onClick={() => refDetailPerNhom.current.show(item)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>
            {/* 
            <Popconfirm
              title="Xoá nhóm quyền"
              description="Bạn có chắc chắn muốn xoá nhóm quyền?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.PerID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm> */}
          </Flex>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await PermissionService.deleteNhomQuyen({
      PerID: Number(value),
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.PerID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá nhóm quyền thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
    filter(newActiveKey);
    // if (newActiveKey === "2") loadCateTab3();
  };
  return (
    <Box>
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              <Table
                loading={loading}
                dataSource={data}
                columns={
                  activeTab === "0"
                    ? columns
                    : activeTab === "1"
                    ? columns2
                    : null
                }
                scroll={{
                  y: window.innerHeight - 350,
                  // x: 2500
                }}
                size="small"
              />
            </TabPane>
          );
        })}
      </Tabs>
      {contextHolder}
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormAddPer ref={refDetailPer} onAfterSubmit={onAfterSubmit} />
      <FormAddPerNhomModules
        ref={refDetailPerNhom}
        onAfterSubmit={onAfterSubmit}
      />
    </Box>
  );
}
