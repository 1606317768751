import axiosApi from "./api_helper";
export const TourService = {
  getDetail: async (payload) => {
    return await axiosApi()
      .post("api/Poker/get-giai-dau-detail", payload)
      .then((res) => res.data);
  },
  startGiaiDau: async (payload) => {
    return await axiosApi()
      .post("api/Poker/start-giai-dau", payload)
      .then((res) => res.data);
  },
  addGiaiDau: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/add-giai-dau", payload)
      .then((res) => res.data);
  },

  updateGiaiDau: async (payload) => {
    return await axiosApi()
      .post("api/poker/update-giai-dau", payload)
      .then((res) => res.data);
  },
  updateXepCho: async (payload) => {
    return await axiosApi()
      .post("api/giaidau/update-xep-cho", payload)
      .then((res) => res.data);
  },
  deleteGiaiDau: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-giai-dau", payload)
      .then((res) => res.data);
  },
  deleteCoCauGiaiThuong: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-co-cau-giai", payload)
      .then((res) => res.data);
  },
  getCoCauGiaiThuong: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-co-cau-giai", payload)
      .then((res) => res.data);
  },

  getGiaiDauMau: async () => {
    return await axiosApi()
      .post("api/poker/get-giai-dau-mau")
      .then((res) => res.data);
  },

  getBlind: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-blind", payload)
      .then((res) => res.data);
  },
  addBlind: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/add-blind", payload)
      .then((res) => res.data);
  },
  removeBlind: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/remove-blind", payload)
      .then((res) => res.data);
  },
  removeBlindByID: async (payload) => {
    return await axiosApi()
      .post("api/gaidau/remove-blind-byid", payload)
      .then((res) => res.data);
  },
  getListMerge: async (payload) => {
    return await axiosApi()
      .post("api/giaidau/get-list-merge", payload)
      .then((res) => res.data);
  },
  mergeGiaiDau: async (payload) => {
    return await axiosApi()
      .post("api/giaidau/merge", payload)
      .then((res) => res.data);
  },
  getKhoangEntry: async () => {
    //MaDA
    return await axiosApi()
      .post("api/giaidau/get-khoang-entry")
      .then((res) => res.data);
  },
  getTyLeGT: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/giaidau/get-tyle-giai-thuong", payload)
      .then((res) => res.data);
  },

  Table: {
    openTable: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/open-table", payload)
        .then((res) => res.data);
    },
    get: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/get-table", payload)
        .then((res) => res.data);
    },
    getBanTrongNhieu: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/get-table-trong-nhieu", payload)
        .then((res) => res.data);
    },
    getSeatTrong: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/get-seat-trong", payload)
        .then((res) => res.data);
    },
    edit: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/edit-table", payload)
        .then((res) => res.data);
    },
    ghepBan: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/merge-table", payload)
        .then((res) => res.data);
    },
    closeOpen: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/close-open-table", payload)
        .then((res) => res.data);
    },
    close: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/close-table", payload)
        .then((res) => res.data);
    },
    chuyenBan: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/chuyen-table", payload)
        .then((res) => res.data);
    },
    addUserToTable: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/add-user-to-table", payload)
        .then((res) => res.data);
    },
    getSeat: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/get-seat-by-bangd", payload)
        .then((res) => res.data);
    },
    getSeatHistory: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/get-seat-history", payload)
        .then((res) => res.data);
    },
    getTableHistory: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/get-table-history", payload)
        .then((res) => res.data);
    },
    outUser: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/out-user", payload)
        .then((res) => res.data);
    },
    getSeatByMaGD: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/get-seat-bymagd", payload)
        .then((res) => res.data);
    },
  },
  Seat: {
    getSeatWaiting: async (payload) => {
      return await axiosApi()
        .post("api/giaidau/get-seat-waiting-bymagd", payload)
        .then((res) => res.data);
    },
   
  },
};
