import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  InputNumber,
  Select,
} from "antd";
import _ from "lodash";
import { CardService } from "services/CardService";
import { CategoryService } from "services/CategoryService";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {};
  const [data, setData] = useState(defaulData);
  const [open, setOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    show: async (tab) => {
      setOpen(true);
      setActiveTab(tab);
    },
    update: async (data, tab) => {
      setOpen(true);
      setData(data);
      setActiveTab(tab);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = null;
    if (activeTab === "0") {
      _res = await CategoryService.GiaiDau.addEntry(_data);
    } else if (activeTab === "1") {
      _res = await CardService.Customer.addGroup(_data);
    } else if (activeTab === "2") {
      _res = await CategoryService.Khac.addCatDatMayIn(_data);
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"}  thành công!`
      );
      onAfterSubmit();
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderTab1 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Tên khoảng</p>
          <Input
            value={data?.TenKhoang}
            placeholder="Nhập tên khoảng"
            onChange={(e) => applyChange("TenKhoang", e.target.value)}
          />
        </Col>

        <Col span={24}>
          <p>Số lượng từ</p>
          <InputNumber
            placeholder="Nhập số lượng từ"
            style={{ width: "100%" }}
            value={data?.SoLuongTu}
            onChange={(v) => applyChange("SoLuongTu", v)}
          />
        </Col>
        <Col span={24}>
          <p>Số lượng đến</p>
          <InputNumber
            placeholder="Nhập số lượng đến"
            style={{ width: "100%" }}
            value={data?.SoLuongDen}
            onChange={(v) => applyChange("SoLuongDen", v)}
          />
        </Col>
      </Row>
    );
  };
  const renderTab2 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Tên danh mục</p>
          <Input
            value={data?.Name}
            placeholder="Nhập tên danh mục"
            onChange={(e) => applyChange("Name", e.target.value)}
          />
        </Col>
      </Row>
    );
  };
   const renderTab3 = () => {
     return (
       <Row gutter={[24, 20]}>
         <Col span={24}>
           <p>Tên server</p>
           <Input
             value={data?.TenServer}
             placeholder="Nhập tên server"
             onChange={(e) => applyChange("TenServer", e.target.value)}
           />
         </Col>
         <Col span={24}>
           <p>Tên máy in</p>
           <Input
             value={data?.TenMayIn}
             placeholder="Nhập tên máy in"
             onChange={(e) => applyChange("TenMayIn", e.target.value)}
           />
         </Col>
         <Col span={12}>
           <p>Mẫu in</p>
           <Select
             style={{
               width: "100%",
             }}
             value={data?.MaMauIn}
             placeholder="Chọn mẫu in"
             onChange={(v) => applyChange("MaMauIn", v)}
             options={[{ ID: 1, Name: "Mẫu 1" }]}
             fieldNames={{ label: "Name", value: "ID" }}
           />
         </Col>
         <Col span={12}>
           <p>Số liên</p>
           <InputNumber
             placeholder="Nhập số liên"
             style={{ width: "100%" }}
             value={data?.SoLien}
             onChange={(v) => applyChange("SoLien", v)}
           />
         </Col>
       </Row>
     );
   };
  return (
    <Drawer
      title="DANH MỤC GIẢI ĐẤU"
      width={520}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {activeTab === "0"
        ? renderTab1()
        : activeTab === "1"
        ? renderTab2()
        : activeTab === "2"
        ? renderTab3()
        : null }
     
    </Drawer>
  );
};
export default forwardRef(FormAdd);
