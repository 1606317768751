import axiosApi from "./api_helper";
export const CardService = {
  getCard: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/card/get", payload)
      .then((res) => res.data);
  },
  getCardByMaKH: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/card/get-bymakh", payload)
      .then((res) => res.data);
  },
  getCardByMaVach: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-card-by-mavach", payload)
      .then((res) => res.data);
  },
  getCustomers: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-customers", payload)
      .then((res) => res.data);
  },
  getKhachHangByID: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-khachhang-byid", payload)
      .then((res) => res.data);
  },
  addCard: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/add-card", payload)
      .then((res) => res.data);
  },
  import: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/card/import", payload)
      .then((res) => res.data);
  },
  addLichSuRaVao: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/add-lichsu-ravao", payload)
      .then((res) => res.data);
  },
  getLichSuRaVao: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-lichsu-ravao", payload)
      .then((res) => res.data);
  },
  updateCard: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/update-card", payload)
      .then((res) => res.data);
  },
  deleteCard: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/delete-card", payload)
      .then((res) => res.data);
  },

  getCusByID: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/customer/get-by-id", payload)
      .then((res) => res.data);
  },
  filterCustomer: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-customer-filter-card", payload)
      .then((res) => res.data);
  },

  Type: {
    get: async () => {
      //MaDA
      return await axiosApi()
        .post("api/card/get-type")
        .then((res) => res.data);
    },
    getDetail: async (payload) => {
      //MaDA
      return await axiosApi()
        .post("api/card/get-type-detail", payload)
        .then((res) => res.data);
    },
    add: async (payload) => {
      return await axiosApi()
        .post("api/card/categories/coru-card-type", payload)
        .then((res) => res.data);
    },
    delete: async (payload) => {
      //MaDA
      return await axiosApi()
        .post("api/card/categories/delete-card-type", payload)
        .then((res) => res.data);
    },
  },
  Customer: {
    getGroup: async () => {
      return await axiosApi()
        .post("api/customer/get-group")
        .then((res) => res.data);
    },
    addGroup: async (payload) => {
      return await axiosApi()
        .post("api/customer/add-group", payload)
        .then((res) => res.data);
    },
    deleteGroup: async (payload) => {
      return await axiosApi()
        .post("api/customer/delete-group", payload)
        .then((res) => res.data);
    },
    getSource: async () => {
      return await axiosApi()
        .get("api/customer/get-source")
        .then((res) => res.data);
    },
    getCustomerAgent: async (payload) => {
      //MaDA
      return await axiosApi()
        .post("api/poker/get-customer-agent", payload)
        .then((res) => res.data);
    },
    filterAgent: async (payload) => {
      //MaDA
      return await axiosApi()
        .post("api/card/get-agent", payload)
        .then((res) => res.data);
    },
  },
  Category: {
    getStatus: async () => {
      //MaDA
      return await axiosApi()
        .get("api/card/get-status")
        .then((res) => res.data);
    },
  },
};
