import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  InputNumber,
  Select,
  Checkbox,
} from "antd";
import _ from "lodash";
import { CardService } from "services/CardService";
import { CategoryService } from "services/CategoryService";
import { OtherService } from "services/OtherService";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    MaMauIn : 1
  };
  const [data, setData] = useState(defaulData);
  const [open, setOpen] = useState(false);
  const [thaotacin, setThaoTacIn] = useState([]);
  const [mauIn,setMauIn] = useState([])
  const loadCategory = async () => {
    let _res = await CategoryService.Khac.getThaoTacIn();
    setThaoTacIn(_res.data);
    let _resM = await OtherService.getMauIn();
    setMauIn(_resM.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async (tab) => {
      setOpen(true);
      setActiveTab(tab);
      loadCategory();
    },
    update: async (data, tab) => {
      setOpen(true);
      if (tab === "2") {
        data.ThaoTacIn = JSON.parse(data.MaThaoTacIn);
      }
      setData(data);
      setActiveTab(tab);
      loadCategory();
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = null;
    if (activeTab === "0") {
      _res = await CardService.Type.add(_data);
    } else if (activeTab === "1") {
      _res = await CardService.Customer.addGroup(_data);
    } else if (activeTab === "2") {
      _data.MaThaoTacIn = JSON.stringify(_data.ThaoTacIn);
      _res = await CategoryService.Khac.addCatDatMayIn(_data);
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"}  thành công!`
      );
      onAfterSubmit();
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderTab1 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={8}>
          <p>Tên loại thẻ</p>
          <Input
            value={data?.TenLT}
            placeholder="Nhập tên loại thẻ"
            onChange={(e) => applyChange("TenLT", e.target.value)}
          />
        </Col>

        <Col span={8}>
          <p>Điểm đạt</p>
          <InputNumber
            suffix="vnđ"
            placeholder="Mức điểm đạt"
            style={{ width: "100%" }}
            value={data?.MucDat}
            onChange={(v) => applyChange("MucDat", v)}
          />
        </Col>
        <Col span={8}>
          <p>Điểm duy trì</p>
          <InputNumber
            suffix="vnđ"
            placeholder="Nhập điểm duy trì"
            style={{ width: "100%" }}
            value={data?.DuyTri}
            onChange={(v) => applyChange("DuyTri", v)}
          />
        </Col>

        <Col span={8}>
          <p>Số tiền / 1 điểm</p>
          <InputNumber
            suffix="vnđ"
            placeholder="Số tiền / 1 điểm"
            style={{ width: "100%" }}
            value={data?.TyGiaDiem}
            onChange={(v) => applyChange("TyGiaDiem", v)}
          />
        </Col>
        <Col span={8}>
          <p>Số tiền giới thiệu / điểm</p>
          <InputNumber
            suffix="vnđ"
            placeholder="Số tiền giới thiệu / điểm"
            style={{ width: "100%" }}
            value={data?.TyGiaDiemGioiThieu}
            onChange={(v) => applyChange("TyGiaDiemGioiThieu", v)}
          />
        </Col>
        <Col span={8}>
          <p>1 Điểm / tiền</p>
          <InputNumber
            suffix="vnđ"
            placeholder="1 điểm / tiền"
            style={{ width: "100%" }}
            value={data?.MotDiemTrenSoTien}
            onChange={(v) => applyChange("MotDiemTrenSoTien", v)}
          />
        </Col>

        <Col span={8}>
          <p>Tỷ lệ buy</p>
          <InputNumber
            placeholder="TyLeBuy"
            style={{ width: "100%" }}
            value={data?.TyLeBuy}
            onChange={(v) => applyChange("TyLeBuy", v)}
          />
        </Col>
      </Row>
    );
  };
  const renderTab2 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Tên danh mục</p>
          <Input
            value={data?.Name}
            placeholder="Nhập tên danh mục"
            onChange={(e) => applyChange("Name", e.target.value)}
          />
        </Col>
      </Row>
    );
  };
  const renderTab3 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Tên server</p>
          <Input
            value={data?.TenServer}
            placeholder="Nhập tên server"
            onChange={(e) => applyChange("TenServer", e.target.value)}
          />
        </Col>
        <Col span={24}>
          <p>Tên máy in</p>
          <Input
            value={data?.TenMayIn}
            placeholder="Nhập tên máy in"
            onChange={(e) => applyChange("TenMayIn", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Thao tác in</p>
          <Select
            style={{
              width: "100%",
            }}
            mode="multiple"
            value={data?.ThaoTacIn}
            placeholder="Chọn thao tác in"
            onChange={(v) => applyChange("ThaoTacIn", v)}
            options={thaotacin}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>
        <Col span={6}>
          <p>Số liên</p>
          <InputNumber
            placeholder="Nhập số liên"
            style={{ width: "100%" }}
            value={data?.SoLien}
            onChange={(v) => applyChange("SoLien", v)}
          />
        </Col>
        <Col span={6}>
          <p>Mẫu in</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaMauIn}
            placeholder="Chọn mẫu"
            onChange={(v) => applyChange("MaMauIn", v)}
            options={mauIn}
            fieldNames={{ label: "TenBM", value: "ID" }}
          />
        </Col>
        <Col span={24}>
          <Checkbox
            checked={data?.isPrintIP}
            onChange={(v) => applyChange("isPrintIP", v.target.checked)}
          >
            In qua IP
          </Checkbox>
        </Col>
      </Row>
    );
  };
  return (
    <Drawer
      title="DANH MỤC KHÁC"
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {activeTab === "0"
        ? renderTab1()
        : activeTab === "1"
        ? renderTab2()
        : activeTab === "2"
        ? renderTab3()
        : null}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
