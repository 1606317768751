import axiosApi from "./api_helper";
export const CongTyService = {
  getCongTy: async () => {
    return await axiosApi()
      .post("api/poker/get-company")
      .then((res) => res.data);
  },
  addCompany: async (payload) => {
    return await axiosApi()
      .post("api/poker/add-company", payload)
      .then((res) => res.data);
  },
  updateCompany: async (payload) => {
    return await axiosApi()
      .post("api/poker/update-company", payload)
      .then((res) => res.data);
  },
  deleteCompany: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-company", payload)
      .then((res) => res.data);
  },
  // cong ty dang ky
  addCongTy: async (payload) => {
    return await axiosApi()
      .post("api/cong-ty/add", payload)
      .then((res) => res.data);
  },
  //chi nhanh
  addChiNhanh: async (payload) => {
    return await axiosApi()
      .post("api/chi-nhanh/add", payload)
      .then((res) => res.data);
  },
};
