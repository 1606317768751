import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { HomeService } from "services/HomeService";
import {
  Button,
  Col,
  Input,
  Modal,
  Popconfirm,
  Row,
  Table,
  Tooltip,
  notification,
} from "antd";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import { IoEyeSharp, IoSettingsOutline } from "react-icons/io5";
import { TourService } from "services/TourService";
import _ from "lodash";
import "./scss/index.scss";
import FormAdd from "./FormAdd";
import Toolbar from "components/Toolbar";
import { PermissionService } from "services/PermissionService";
export default function Settings() {
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
  });
  const refDetail = useRef(null);
  const refSettingBlind = useRef(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useEffect(() => {
    filter();
  }, []);
  const filter = async () => {
    setLoading(true);
    let _res = await PermissionService.getNhomQuyen();
    if (_res.data) setData(_res.data);
    setLoading(false);
  };
  const onChangeFilter = (_filter) => {
    filter(_filter);
    setFilterCondition(_filter);
  };
  const onAfterSubmit = () => {
    filter();
  };
  const columns = [
    {
      title: "Tên nhóm quyền",
      dataIndex: "PerName",
    },

    {
      title: "",
      fixed: "right",
      width: "160px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Phân quyền">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<IoSettingsOutline />}
                onClick={() => refDetail.current.show(item.PerID)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => {
                  setDetail(item);
                  showModal()
                }}
              />
            </Tooltip>
            <Popconfirm
              title="Xoá nhóm quyền"
              description="Bạn có chắc chắn muốn xoá nhóm quyền?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.PerID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await PermissionService.deleteNhomQuyen({ PerID: Number(value) });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.PerID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá nhóm quyền thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    let _res = await PermissionService.addNhomQuyen(detail);
    if(_res.status===2000){
      onAfterSubmit();
      setDetail({})
    }
    setIsModalOpen(false);

  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [detail,setDetail] = useState({})
  const onChangeGroup =(v)=>{
    let _detail = _.cloneDeep(detail);
    _detail.PerName = v;
    _detail.Description = v;
    setDetail(_detail);
  }
  return (
    <Box >
      <Toolbar
        left={() => (
          <Button type="primary" onClick={() => showModal()}>
            Thêm
          </Button>
        )}
      />
      <Table
        style={{ height: 100 }}
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          // x: 2500
        }}
        size="small"
      />
      {contextHolder}
      <Modal
        title="Nhóm quyền"
        open={isModalOpen}
        onOk={handleOk}
        okText="Lưu"
        cancelText="Huỷ"
        onCancel={handleCancel}
      >
        {/* <p>Tên nhóm quyền</p> */}
        <Input placeholder="Nhập tên nhóm quyền"
         onChange={(v)=>onChangeGroup(v.target.value)}
         value={detail.PerName}
        />
      </Modal>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
    </Box>
  );
}
