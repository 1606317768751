import axios from "axios"

const API_URL = "https://api-poker.beesky.vn";
const axiosApi = (headers) => {
  return axios.create({
    baseURL: API_URL,//localStorage.getItem("url") ?? API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      ...headers,
    },
  });
}
export default axiosApi;