import React, { useEffect, useContext, useState } from "react";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import PortalLayout from "layouts/portal";
import OrderLayout from "layouts/orders";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { SignalrContextProvider } from "contexts/SignalrContext";
import Jackpot from "views/admin/Jackpot/Jackpot";
// import SignalrContext from "contexts/SignalrContext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./app.scss";
import "./theme/index.scss";
import { ChakraProvider } from "@chakra-ui/react";
import { NhanVienService } from "services/NhanVienService";
import { useHistory } from "react-router-dom";

const App = () => {
  const history = useHistory();

  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("url");
    history.push("/auth/sign-in");
    window.location.reload();
  };
  useEffect(() => {
    var id = "web" + new Date().getTime() + Math.random().toString(8).slice(2);
    if (!localStorage.getItem("deviceID")) {
      localStorage.setItem("deviceID", id);
    }
    NhanVienService.checkAccount().then((res) => {
      if (res?.data === true) {
        logout();
      }
    });
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <SignalrContextProvider>
        <React.StrictMode>
          {/* <ThemeEditorProvider> */}
          <HashRouter>
            <Switch>
              <Route path={`/auth`} component={AuthLayout} />
              <Route path={`/admin`} component={AdminLayout} />
              <Route path={`/portal`} component={PortalLayout} />
              <Route path={`/jackpot`} component={Jackpot} />
              <Route path={`/orders`} component={OrderLayout} />
              <Redirect from="/" to="/admin/danh-sach-giai" />
            </Switch>
          </HashRouter>
          {/* </ThemeEditorProvider> */}
        </React.StrictMode>
      </SignalrContextProvider>
    </ChakraProvider>
  );
};
export default App;
