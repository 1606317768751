import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Image,
} from "antd";
import _ from "lodash";
import { SanPhamService } from "services/SanPhamService";
import { HomeService } from "services/HomeService";
import { FileUpload } from "primereact/fileupload";
import { UploadService } from "services/UploadService";
import { URL_UPLOAD } from "services/UploadService";
const FormAdd = (props, ref) => {
  const [files, setFiles] = useState([]);

  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaiSP, setLoaiSP] = useState([]);
  const [dvt, setDVT] = useState([]);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  const loadCategories = async () => {
    let _lsp = await SanPhamService.getLSP();
    let _dvt = await SanPhamService.getDVT();
    setLoaiSP(_lsp?.data ?? []);
    setDVT(_dvt?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      loadCategories();
      setIsUpdate(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    if (files?.length > 0) {
      var formData = new FormData();
      formData.append("Image", files[0]);
      formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
      let _resupload = await UploadService.upload(formData);
      if (_resupload.status === 2000) {
        _data.LinkImage = URL_UPLOAD + _resupload.data;
        setData((pre) => {
          return { ...pre, LinkImage: _data.LinkImage };
        });
      }
    }
    let _res = null;
    if (isUpdate) {
      _res = await SanPhamService.updateSanPham(_data);
    } else {
      _res = await SanPhamService.addSanPham(_data);
       setData((pre) => {
         return { ...pre, ID: _res.data };
       });
    }
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isUpdate ? "Sửa" : "Thêm"} sản phẩm thành công!`
      );
      if (close) onClose();
      setIsUpdate(true);
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  return (
    <Drawer
      title="Sản phẩm"
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button onClick={() => onSubmit()}>Lưu</Button>
          <Button onClick={() => onSubmit("print")} type="primary">
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[10, 20]}>
        <Col span={6}>
          <p>Mã vạch</p>
          <Input
            value={data?.MaVach}
            placeholder="Nhập mã vạch"
            onChange={(e) => applyChange("MaVach", e.target.value)}
          />
        </Col>
        <Col span={6}>
          <p>Mã sản phẩm</p>
          <Input
            value={data?.MaSP}
            placeholder="Nhập mã sản phẩm"
            onChange={(e) => applyChange("MaSP", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Tên sản phẩm</p>
          <Input
            value={data?.TenSP}
            placeholder="Nhập tên sản phẩm"
            onChange={(e) => applyChange("TenSP", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Loại sản phẩm</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaLSP}
            placeholder="Chọn loại sản phẩm"
            onChange={(v) => applyChange("MaLSP", v)}
            options={loaiSP}
            fieldNames={{ label: "TenLSP", value: "MaLSP" }}
          />
        </Col>
        <Col span={12}>
          <p>Đơn vị tính</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaDVT}
            placeholder="Chọn đơn vị tính"
            onChange={(v) => applyChange("MaDVT", v)}
            options={dvt}
            fieldNames={{ label: "TenDVT", value: "MaDVT" }}
          />
        </Col>
        <Col span={12}>
          <p style={{ color: "red" }}>Giá bán</p>
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Nhập giá bán"
            addonAfter="vnđ"
            value={data?.GiaBan}
            onChange={(v) => applyChange("GiaBan", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
          <p style={{ color: "red" }}>{errors?.GiaBan}</p>
        </Col>
        <Col span={12}>
          <p style={{ color: "red" }}>Giá mua</p>
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Nhập giá mua"
            addonAfter="vnđ"
            value={data?.GiaMua}
            onChange={(v) => applyChange("GiaMua", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
        <Col span={12}>
          <p>Thuế GTGT</p>
          <InputNumber
            style={{ width: "100%" }}
            placeholder="Nhập thuế gtgt"
            addonAfter="%"
            max={100}
            value={data?.ThueGTGT}
            onChange={(v) => applyChange("ThueGTGT", v)}
          />
        </Col>
        <Col span={12}>
          <p>Điểm tích luỹ</p>
          <InputNumber
            placeholder="Nhập điểm tích luỹ"
            style={{ width: "100%" }}
            addonAfter="điểm"
            value={data?.DiemTichLuy}
            onChange={(v) => applyChange("DiemTichLuy", v)}
          />
        </Col>
        <Col span={12}>
          <p>Điểm đổi quà</p>
          <InputNumber
            style={{ width: "100%" }}
            addonAfter="điểm"
            placeholder="Nhập điểm đổi quà"
            value={data?.DiemKM}
            onChange={(v) => applyChange("DiemKM", v)}
          />
        </Col>
        <Col span={12}></Col>
        <Col span={6}>
          {data.LinkImage && <Image src={data.LinkImage} />}
          <FileUpload
            mode="basic"
            accept="image/*"
            maxFileSize={1000000}
            onSelect={(e) => setFiles(e.files)}
            chooseLabel="Tải ảnh"
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
