import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
  useEffect,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Divider,
  Checkbox,
  Table,
  Tabs,
  Form,
  Popconfirm,
  Tooltip,
  Modal,
} from "antd";
import { Tree } from "primereact/tree";
import _ from "lodash";
import { TourService } from "services/TourService";
import { CiTrash } from "react-icons/ci";
import { Format_Date } from "layouts/ultis/common";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaulData = {
    NgayNhap: new Date(),
    isHienThiBuyIn: true,
    isTichDiem: false,
    isTourMau: false,
  };
  const [data, setData] = useState(defaulData);
  const [nhanVien,setNhanVien] = useState([])
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tourMau, setTourMau] = useState([]);
  const [coCau, setCoCau] = useState([]);
  const defaultErrors = {
    TenGD: null,
    PhanTramTraoGiai: null,
    TienGiaiBuyIn: null,
    SoChipBuyin: null,
    GTD: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const getCoCauGiaiThuong = async (id) => {
    let _res = await TourService.getCoCauGiaiThuong({ ID: id });
    setCoCau(_res?.data ?? []);
  };
  const loadCategories = async () => {
    let _res = await TourService.getGiaiDauMau();
    setTourMau(_res?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      loadCategories();
      setIsUpdate(true);
      let _res = await TourService.getDetail({ ID: data.id });
      getCoCauGiaiThuong(data.id);
      if (_res.status === 2000)
        setData(_res?.data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaulData);
    onAfterSubmit();
    setCoCau([]);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaGDM":
        let gd = tourMau.find((t) => t.ID === v);
        _data = {
          ..._data,
          ...gd,
        };
        break;
      case "PhanTramJackpot":
      case "ptService":
        _data[p] = v;
        _data.PhanTramTraoGiai =
          100 - (_data.PhanTramJackpot ?? 0) - (_data.ptService ?? 0);
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    _data.ListGiaiThuong = coCau;
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = null;
    if (isupdate) {
      _res = await TourService.updateGiaiDau(_data);
    } else {
      _res = await TourService.addGiaiDau(_data);
      setData((pre) => {
        return { ...pre, ID: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} giải đấu thành công!`
      );
      setIsUpdate(true);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const [nodes, setNodes] = useState([
    {
      key: "1",
      label: "buyin",
      children: [
        { key: "1-1", label: "them" },
        { key: "1-2", label: "sua" },
      ],
    },
  ]);
  const [selectedKeys, setSelectedKeys] = useState(null);
  const renderInfo = () => {
    return (
      <Tree
        value={nodes}
        selectionMode="checkbox"
        selectionKeys={selectedKeys}
        onSelectionChange={(e) => setSelectedKeys(e.value)}
        className="w-full md:w-30rem"
        // checkboxIcon={
        //   <Checkbox
        //     // checked={data?.isTourMau === true}
        //   />
        // }
      />
    );
  };
  const columns = [
    {
      title: "Mã số",
      dataIndex: "MaSo",
    },
    {
      title: "Họ và tên",
      dataIndex: "HoTen",
    },
    {
      title: "Ngày sinh",
      dataIndex: "NgaySinh",
      render: (item) => Format_Date(item),
    },
    {
      title: "",
      fixed: "right",
      width: "160px",
      render: (item) => {
        return (
          <div>
            

            <Popconfirm
              title="Xoá giải đấu"
              description="Bạn có chắc chắn muốn xoá giải đấu?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.id)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = () =>{

  }
  const renderCoCauGiai = () => {
    const addCoCau = () => {
      let _cocau = _.cloneDeep(coCau);
      _cocau.push({
        isHienThi: true,
        TenGiai: "",
        HienThi: "",
      });
      setCoCau(_cocau);
    };
    const onChangeCoCau = (p, v, index) => {
      let _cocau = _.cloneDeep(coCau);
      let item = _cocau.slice(index, index + 1);
      if (item?.length > 0) {
        _cocau.splice(index, 1, {
          ...item[0],
          [p]: v,
        });
      }

      setCoCau(_cocau);
    };
    const deleteCoCau = (v, index) => {
      let _cocau = _.cloneDeep(coCau);
      _cocau.splice(index, 1);
      setCoCau(_cocau);
      if (v.ID) {
        TourService.deleteCoCauGiaiThuong({ ID: v.ID });
      }
    };
    return (
      <>
        <Button
          loading={loading}
          onClick={() => showModal()}
          type="primary"
          ghost
          style={{ marginBottom: 10 }}
        >
          Thêm
        </Button>
        <Table
          style={{ height: 100 }}
          loading={loading}
          dataSource={nhanVien}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
            // x: 2500
          }}
          size="small"
        />
      </>
    );
  };
  const items = [
    {
      key: "1",
      label: "Quyền chức năng",
      children: renderInfo(),
    },
    {
      key: "2",
      label: "Nhân viên",
      children: renderCoCauGiai(),
    },
  ];
      const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Drawer
      title="PHÂN QUYỀN"
      width={720}
      onClose={onClose}
      open={open}
      className="giai-dau-detail"
      styles={{
        body: {
          // paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(false)}
            type="primary"
            ghost
          >
            Lưu
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Tabs defaultActiveKey="1" items={items} />
      <Modal
        title="Thêm nhân viên vào nhóm quyền"
        open={isModalOpen}
        onOk={handleOk}
        okText="Lưu"
        cancelText="Huỷ"
        onCancel={handleCancel}
      >
        <Select
          style={{
            width: "100%",
          }}
          value={data?.MaHinhThuc}
          placeholder="Tìm kiếm nhân viên"
          // onChange={(v) => applyChange("MaHinhThuc", v)}
          options={[]}
          fieldNames={{ label: "TenHinhThuc", value: "ID" }}
        />
      </Modal>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
