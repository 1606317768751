import axiosApi from "./api_helper";

export const SanPhamService = {
  getSanPham: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-san-pham", payload)
      .then((res) => res.data);
  },
  addSanPham: async (payload) => {
    return await axiosApi()
      .post("api/poker/add-san-pham", payload)
      .then((res) => res.data);
  },
  updateSanPham: async (payload) => {
    return await axiosApi()
      .post("api/poker/update-san-pham", payload)
      .then((res) => res.data);
  },
  deleteSanPham: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-san-pham", payload)
      .then((res) => res.data);
  },
  getLSP: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-loaisp", payload)
      .then((res) => res.data);
  },
  getDVT: async (payload) => {
    return await axiosApi()
      .post("api/poker/get-donvitinh", payload)
      .then((res) => res.data);
  },
  Category: {
    getLSP: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/get-loaisp", payload ? payload : { ApDung: null })
        .then((res) => res.data);
    },
    addLSP: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/loaisp/add", payload)
        .then((res) => res.data);
    },
    deleteLSP: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/loaisp/delete", payload)
        .then((res) => res.data);
    },
    getDVT: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/get-dvt", payload)
        .then((res) => res.data);
    },
    addDVT: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/donvitinh/add", payload)
        .then((res) => res.data);
    },
    deleteDVT: async (payload) => {
      return await axiosApi()
        .post("api/sanpham/donvitinh/delete", payload)
        .then((res) => res.data);
    },
  },
};
