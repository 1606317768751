import React, { useState, useEffect } from "react";
import { Select } from "antd";
import _, { set } from "lodash";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import { BuyinService } from "services/BuyinService";
import { CardService } from "services/CardService";
const FilterModal = ({ ...props }) => {
  const { filterCondition, onChangeFilter } = props;
  const [giaiDau, setGiaiDau] = useState([]);
  const [hinhThuc, setHinhThuc] = useState([]);
  const [voucher, setVoucher] = useState([]);
  const [khachhang, setKhachhang] = useState([]);

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    console.log(v);
    
    switch (p) {
      case "GiaiDau":
      case "HinhThuc":
      case "Voucher":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      case "AgentID":
        _filter[p] = v ?? 0;
        break;
    }
    onChangeFilter(_filter);
  };
  const loadGD = (_filter) =>{
     BuyinService.getGiaiDauByDate({
       ..._filter,
     }).then((res) => {
       setGiaiDau(res?.data);
     });
  }
  useEffect(() => {
   loadGD(filterCondition);
    BuyinService.getPaymentType().then((res) => {
      setHinhThuc(res.data);
    });
    BuyinService.getVoucher().then((res) => {
      setVoucher(res.data);
    });
    // CardService.getCustomers({ InputSearch: "" }).then((res) => {
    //   setKhachhang(res.data);
    // });
    CardService.Customer.getCustomerAgent().then((res) => {
      setKhachhang(res.data);
    });;

  }, []);
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    loadGD(_filter);
    onChangeFilter(_filter);
  };
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
      <div className="box-search">
        <p className="label">Giải đấu</p>
        <Select
          mode="multiple"
          showSearch
          filterOption={(input, option) =>
            (option?.TenGD?.toLowerCase() ?? "").includes(input.toLowerCase())
          }
          placeholder="Chọn giải đấu"
          onChange={(v) => applyChangeFilter("GiaiDau", v)}
          style={{
            width: "100%",
          }}
          options={giaiDau}
          fieldNames={{ label: "TenGD", value: "ID" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Hình thức TT</p>
        <Select
          mode="multiple"
          placeholder="Chọn hình thức TT"
          onChange={(v) => applyChangeFilter("HinhThuc", v)}
          style={{
            width: "100%",
          }}
          options={hinhThuc}
          fieldNames={{ label: "TenHinhThuc", value: "ID" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Voucher</p>
        <Select
          mode="multiple"
          placeholder="Chọn voucher"
          onChange={(v) => applyChangeFilter("Voucher", v)}
          style={{
            width: "100%",
          }}
          options={voucher}
          fieldNames={{ label: "LyDo", value: "ID" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Agent</p>
        <Select
          // mode="multiple"
          allowClear
          showSearch
          filterOption={(input, option) =>
            (option?.TenCongTy?.toLowerCase() ?? "").includes(
              input.toLowerCase()
            )
          }
          placeholder="Chọn Agent"
          onChange={(v) => applyChangeFilter("AgentID", v)}
          style={{
            width: "100%",
          }}
          options={khachhang}
          fieldNames={{ label: "TenCongTy", value: "MaKH" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
