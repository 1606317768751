import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { JackpotService } from "services/JackpotService";
import { BuyinService } from "services/BuyinService";
const { TextArea } = Input;

const FormEditNote = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    GhiChu: null,
  };
  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async (data) => {
      setOpen(true);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
      duration: 0.7,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async () => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let valid = validate();
    if (valid?.length > 0) {
      setLoading(false);
      return;
    }
    let _res = await BuyinService.editNote(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        "Sửa ghi chú thành công!"
      );
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  return (
    <Drawer
      title="Sửa ghi chú"
      width={620}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          {/* <Button
            loading={loading}
            onClick={() => onSubmit(false)}
            type="primary"
            ghost
          >
            Lưu
          </Button> */}
          <Button loading={loading} onClick={() => onSubmit()} type="primary">
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 12]}>
        <Col span={24}>
          <p>Ghi chú</p>
          <TextArea
            value={data?.GhiChu}
            onChange={(e) => applyChange("GhiChu", e.target.value)}
            placeholder="Nhập ghi chú"
          />
          <p style={{ color: "red" }}>{errors?.GhiChu}</p>
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormEditNote);
