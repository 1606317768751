import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback,
} from "react";
import {
  Button,
  Input,
  Select,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  Checkbox,
} from "antd";
// import { Editor } from "primereact/editor";
import "./index.scss";
import _ from "lodash";
import { Editor } from "primereact/editor";
import UploadComponent from "components/UploadComponent";
import { URL_UPLOAD } from "services/UploadService";
import { TinTuc } from "services/TinTuc";
const Option = Select.Option;
const { TextArea } = Input;
const FormAdd = (props, ref) => {
  const { onAfterSubmit, options, maLoaiTin } = props;
  const [api, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMS] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const defaultData = {};
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const defaultErrors = {};
  const [text, setText] = useState(null);
  const [imgs, setImgs] = useState([]);

  const [errors, setErrors] = useState(defaultErrors);

  useImperativeHandle(ref, () => ({
    show: async (matn) => {
      setOpen(true);
      setData({ MaTN: matn });
    },
    update: async (data) => {
      let _res = await TinTuc.TinTuc.put({ ID: data.ID });

      if (_res?.status === 2000) {
        console.log(_res?.data);
        
        setData((prev) => ({
          ...prev,
          ..._res?.data,
        }));
        setText(_res?.data?.NoiDung)
        setImgs([data.Image]);
      }
      setOpen(true);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
    setText("");
  };

  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async () => {
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    _data.NoiDung = text;
    _data.Image = imgs[0];
    setLoading(true);
    let _res = await TinTuc.TinTuc.add(_data);

    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} tin tức thành công!`
      );
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const affterUpload = useCallback(
    async (file) => {
      if (file.length > 0) {
        setImgs([URL_UPLOAD + file[0]]);
      }
    },
    [setImgs]
  );
  const onRemove = useCallback(
    async (file) => {
      // let _file = files.find((v) => v.Link === file.url);
      // await VisaService.HoSo.deleteTaiLieu({
      //   ID: _file?.ID,
      // });
    },
    [setImgs]
  );
  return (
    <Drawer
    className="container-tintuc"
      style={{ padding: "0" }}
      title="Thông báo"
      width="100%"
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <p style={{ fontSize: 14, fontWeight: "bold", marginRight: 20 }}>
            {options?.ToaNha?.find((v) => v.MaTN === data?.MaTN)?.TenTN}
          </p>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolderMS}
      {contextHolder}
      <Row gutter={[20]}>
        <Col span={16}>
          <Editor
            value={text}
            onTextChange={(e) => setText(e.htmlValue)}
            style={{ height: window.innerHeight - 100 }}
          />
        </Col>
        <Col span={8}>
          <Row gutter={[20, 8]} style={{ marginTop: 0 }}>
            <Col span={24} className="gutter-row">
              <p style={{ color: "red" }}>Tiêu đề</p>
              <Input
                value={data?.TieuDe}
                placeholder="Nhập tiêu đề"
                onChange={(e) => applyChange("TieuDe", e.target.value)}
              />
            </Col>
            <Col span={24} className="gutter-row">
              <p style={{ color: "red" }}>Mô tả</p>
              <TextArea
                value={data?.MoTa}
                placeholder="Nhập mô tả"
                onChange={(e) => applyChange("MoTa", e.target.value)}
              />
            </Col>

            <Col span={24}>
              <p>Mã loại tin</p>
              <Select
                style={{
                  width: "100%",
                }}
                value={data?.MaLTin}
                placeholder="Chọn mã loại tin"
                onChange={(v) => applyChange("MaLTin", v)}
                options={maLoaiTin}
                fieldNames={{ label: "TenLoai", value: "MaLoai" }}
              />
            </Col>
            <Col span={8}>
              <p style={{ fontWeight: "600", marginBottom: "10px" }}>
                Thêm ảnh
              </p>
              <UploadComponent
                files={imgs}
                multiple={false}
                affterUpload={affterUpload}
                onRemove={onRemove}
              />
            </Col>

            <Col span={8}>
              <Checkbox
                checked={data?.IsBanner ?? false}
                onChange={(v) => applyChange("IsBanner", v.target.checked)}
              >
                Banner
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox
                checked={data?.isView ?? false}
                onChange={(v) => applyChange("isView", v.target.checked)}
              >
                View
              </Checkbox>
            </Col>
          </Row>
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
