import axios from "axios";
import { post } from "./api_helper";
import axiosApi from "./api_helper";
export const OrderService = {
  getProducts: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-products", payload)
      .then((res) => res.data);
  },
  checkout: async (payload) => {
    return await axiosApi()
      .post("api/poker/checkout", payload)
      .then((res) => res.data);
  },
  getTable: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/order-table", payload)
      .then((res) => res.data);
  },
  getOrders: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/orders", payload)
      .then((res) => res.data);
  },
  getOrderDetail: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/order-detail", payload)
      .then((res) => res.data);
  },
  payOrders: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/order-pay", payload)
      .then((res) => res.data);
  },
  changeStateOrders: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/order-change-state", payload)
      .then((res) => res.data);
  },
  getTableDetail: async (payload) => {
    payload.TenCTDK = localStorage.getItem("tenCTDK");
    return await axiosApi()
      .post("api/poker/order-table-detail", payload)
      .then((res) => res.data);
  },
  // get cong ty
  getCongTy: async (payload) => {
    return await axiosApi()
      .post("api/noauth/get-congtydangky", payload)
      .then((res) => res.data);
  },
  addDanhGia: async (payload) => {
    return await axiosApi()
      .post("api/order/danh-gia", payload)
      .then((res) => res.data);
  },
  getSeat: async (payload) => {
    return await axiosApi()
      .post("api/noauth/get-seat", payload)
      .then((res) => res.data);
  },
  getKhachHang: async (payload) => {
    return await axiosApi()
      .post("api/noauth/get-khachhang-bysdt", payload)
      .then((res) => res.data);
  },

  getDiemTich: async (payload) => {
    return await axiosApi()
      .post("api/noauth/get-diemtich", payload)
      .then((res) => res.data);
  },
  getHTTT: async (payload) => {
    return await axiosApi()
      .post("api/noauth/get-payment-type", payload)
      .then((res) => res.data);
  },

  getLinkCongTy: async (payload) => {
    return await axiosApi()
      .post("api/core/get-congtydangky-bymact", payload)
      .then((res) => res.data);
  },
  addTable: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/add-order-table", payload)
      .then((res) => res.data);
  },
  importTable: async (payload) => {
    return await axiosApi()
      .post("api/ordertable/import-table", payload)
      .then((res) => res.data);
  },
  removeTable: async (payload) => {
    payload.TenCTDK = localStorage.getItem("MaCTDK");
    return await axiosApi()
      .post("api/poker/remove-order-table", payload)
      .then((res) => res.data);
  },
};
