import { Box, useEditable } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Checkbox,
  InputNumber,
} from "antd";
import _ from "lodash";
import { SanPhamService } from "services/SanPhamService";
const FormAdd = (props, ref) => {
  const { onAfterSubmit, options } = props;
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  useImperativeHandle(ref, () => ({
    show: async (tab) => {
      setOpen(true);
      setActiveTab(tab);
    },
    update: async (data, tab) => {
      setOpen(true);
      setData(data);
      setActiveTab(tab);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async () => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = null;
    switch (activeTab) {
      case "0":
        _res = await SanPhamService.Category.addLSP(_data);
        break;
      case "1":
        _res = await SanPhamService.Category.addDVT(_data);
        break;
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${_data.ID ? "Sửa" : "Thêm"} danh mục thành công!`
      );
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderTab1 = () => {
    return (
      <Row gutter={[10, 10]}>
        <Col span={6}>
          <p>Số thứ tự</p>
          <InputNumber
            min={1}
            value={data?.STT}
            placeholder="Nhập STT"
            onChange={(e) => applyChange("STT", e)}
          />
        </Col>
        <Col span={18}>
          <p>Tên loại sản phẩm</p>
          <Input
            value={data?.TenLSP}
            placeholder="Nhập tên loại sản phẩm"
            onChange={(e) => applyChange("TenLSP", e.target.value)}
          />
        </Col>
        <Col span={6}>
          <Checkbox
            checked={data?.ApDung}
            onChange={(v) => applyChange("ApDung", !data?.ApDung)}
          >
            Áp dụng
          </Checkbox>
        </Col>
        <Col span={6}>
          <Checkbox
            checked={data?.isViewApp}
            onChange={(v) => applyChange("isViewApp", !data?.isViewApp)}
          >
            View trên app
          </Checkbox>
        </Col>
      </Row>
    );
  };
  const renderTab2 = () => {
    return (
      <Row gutter={[24, 20]}>
        <Col span={24}>
          <p>Tên đơn vị tính</p>
          <Input
            value={data?.TenDVT}
            placeholder="Nhập tên đơn vị tính"
            onChange={(e) => applyChange("TenDVT", e.target.value)}
          />
        </Col>
      </Row>
    );
  };

  return (
    <Drawer
      title="Danh mục"
      width={activeTab === "0" ? 550 : 450}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          marginTop:20
        },
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit()}
            type="primary"
          >
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {activeTab === "0"
        ? renderTab1()
        : activeTab === "1"
        ? renderTab2() : null}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
