import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Drawer, notification, Segmented, Table } from "antd";
import _ from "lodash";
import { TourService } from "services/TourService";
import { Format_Datetime } from "layouts/ultis/common";
const FormLSDongBan = (props, ref) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const filter = async (maBanGD) => {
    setLoading(true);
    let _res = await TourService.Table.getTableHistory({ MaBan: maBanGD });
    setData(_res?.data ?? []);
    setLoading(false);
  };
  useImperativeHandle(ref, () => ({
    show: async (maBanGD) => {
      setOpen(true);
      filter(maBanGD);
    },
  }));
  const onClose = () => {
    setOpen(false);
  };
  const columns3 = [
    {
      title: "Tên seat",
      render: (item) => item.TenSeat,
    },
    {
      title: "Tên Player",
      dataIndex: "TenCongTy",
    },
    {
      title: "Thời gian",
      dataIndex: "NgayNhap",
      render: (item) => Format_Datetime(item),
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTen",
    },
    {
      title: "Ghi chú",
      dataIndex: "DienGiai",
      width: 300,
    },
  ];
  const [activeTab,setActiveTab] = useState("CLOSE")
  return (
    <Drawer title="Lịch sử" width={920} onClose={() => onClose()} open={open}>
      <Segmented
        defaultValue="CLOSE"
        style={{
          marginBottom: 8,
        }}
        options={[
          { value: "CLOSE", label: "Đóng bàn" },
          { value: "ADD", label: "Thêm người chơi" },
          { value: "OUT", label: "OUT" },
          { value: "TRANSFER", label: "Chuyển bàn" },
          // { value: "OTHER", label: "Khác" },
        ]}
        onChange={(value) => setActiveTab(value)}
        // options={["CLOSE", "ADD", "OUT", "OTHER"]}
      />
      <Table
        loading={loading}
        columns={columns3}
        dataSource={data.filter(
          (v) =>
            v.Type === activeTab ||
            (activeTab === "OTHER" &&
              v.Type !== "CLOSE" &&
              v.Type !== "ADD" &&
              v.Type !== "OUT")
        )}
        pagination={false}
        scroll={{
          y: window.innerHeight - 150,
        }}
        size="small"
      />
    </Drawer>
  );
};
export default forwardRef(FormLSDongBan);
