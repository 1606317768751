import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Space,
  Drawer,
  notification,
  Popconfirm,
  UploadProps,
  Upload,
  Table,
  Form,
  Typography,
  InputNumber,
  Flex,
  Tooltip,
  Menu,
  Dropdown,
  Modal,
  Select,
  Tag,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import _ from "lodash";
import { TourService } from "services/TourService";
import { CiInboxOut } from "react-icons/ci";
import { MdCancel, MdEdit } from "react-icons/md";
import { FaLockOpen, FaRegSave } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { OrderService } from "services/OrdersService";
import { BiTransferAlt } from "react-icons/bi";
import { Format_Datetime } from "layouts/ultis/common";
import { RiHistoryFill } from "react-icons/ri";
import FormCloseTable from "./FormCloseTable";
import { GiRoundTable } from "react-icons/gi";
import { BuyinService } from "services/BuyinService";
import DebounceSelect from "components/DebounceSelect";
import FormLSDongBan from "./Ban/FormLSDongBan";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const FormTable = (props, ref) => {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const refCloseTable = useRef(null);
  const refLSDongBan = useRef(null);
  const [users, setUsers] = useState([]);
  const [histories, setHistories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [openChuyenBan, setOpenChuyenBan] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [tables, setTables] = useState([]);
  const [tablesChuyenBan, setTablesChuyenBan] = useState([]);
  const [maGD, setMaGD] = useState(null);
  const [maBanGD, setMaBanGD] = useState(null);
  const [maBan, setMaBan] = useState(null);
  const [dataOpen, setDataOpen] = useState({});
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [seatChuyenBan, setSeatChuyenBan] = useState([]);
  const refChuyenBan = useRef(null);
  const [soSeatMax, setSoSeatMax] = useState(0);
  const isEditing = (record) => record.ID === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.ID);
  };
  const cancel = (record) => {
    setEditingKey("");
    if (record.IsAdd) {
      const index = data.findIndex((item) => record.ID === item.ID);
      if (index > -1) {
        form.setFieldsValue({});
        let newData = _.cloneDeep(data);
        newData.splice(index, 1);
        setData(newData);
      }
    }
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.ID);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        let _res = await TourService.Table.edit(newData[index]);
        if (_res.status === 2000) {
          openNotification("success", "topRight", "Thao tác thành công");
        } else {
          openNotification("error", "topRight", _res?.message);
        }
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  const filter = async (magd) => {
    let _res = await TourService.Table.get({ MaGD: magd });
    setData(_res?.data ?? []);
    let _table = await OrderService.getTable({});
    setTables(_table?.data ?? []);
  };
  const loadUser = async (MaBanGD) => {
    let _res = await TourService.Table.getSeat({ MaBan: MaBanGD });
    setUsers(_res.data);
  };
  useImperativeHandle(ref, () => ({
    show: async (data) => {
      setOpen(true);
      setMaGD(data?.ID);
      filter(data?.ID);
      setSoSeatMax(data?.SoSeatMax);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setEditingKey("");
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const handleDetail = (item) => {
    loadUser(item.ID);
    setOpen3(true);
    setMaBanGD(item.ID);
    setMaBan(item.MaBan);
  };
  const columns = [
    {
      title: "Tên bàn",
      render: (item) => (
        <p
          style={{ cursor: "pointer", fontWeight: "bold" }}
          onClick={() => refLSDongBan.current.show(item.ID)}
        >
          {tables.find((v) => v.ID === item.MaBan)?.Name}
        </p>
      ),
    },
    {
      title: "Trạng thái",
      render: (item) => (
        <Tag color={item.isOpen ? "green" : "red"}>
          {item?.isOpen ? "Đang chạy" : "Đã đóng"}
        </Tag>
      ),

      // dataIndex: "Player",
    },
    {
      title: "Player",
      dataIndex: "Player",
    },
    {
      title: "Số lượng max",
      dataIndex: "SoLuongMax",
      editable: true,
    },
    {
      title: "",
      dataIndex: "operation",
      width: "120px",
      render: (_, record, index) => {
        const editable = isEditing(record);
        if (record.isOpen === false) {
          return (
            <Tooltip placement="topRight" title="Mở lại bàn">
              <Button
                type="primary"
                ghost
                icon={<FaLockOpen />}
                disabled={editingKey !== ""}
                onClick={() => openTable(record)}
              />
            </Tooltip>
          );
        }
        return editable ? (
          <Flex gap={5}>
            <Button
              type="primary"
              ghost
              icon={<FaRegSave />}
              onClick={() => save(record.ID)}
            />
            <Button
              type="primary"
              danger
              ghost
              icon={<MdCancel />}
              onClick={() => cancel(record)}
            />
          </Flex>
        ) : (
          <Flex gap={5}>
            <Tooltip placement="topRight" title="Danh sách người chơi">
              <Button
                type="primary"
                ghost
                disabled={editingKey !== ""}
                icon={<GiRoundTable />}
                onClick={() => handleDetail(record)}
              />
            </Tooltip>
            <Tooltip placement="topRight" title="Sửa số lượng">
              <Button
                type="primary"
                ghost
                disabled={editingKey !== ""}
                icon={<MdEdit />}
                onClick={() => edit(record)}
              />
            </Tooltip>
            {
              <Tooltip placement="topRight" title="Đóng bàn">
                <Button
                  type="primary"
                  danger
                  ghost
                  icon={<IoMdClose />}
                  disabled={editingKey !== ""}
                  onClick={() => remove(record)}
                />
              </Tooltip>
            }
          </Flex>
        );
      },
    },
  ];
  const columns2 = [
    {
      title: "Tên seat",
      render: (item) => item.TenSeat,
    },
    {
      title: "Tên Player",
      dataIndex: "TenCongTy",
    },
    {
      title: "Thời gian buyin",
      dataIndex: "NgayBuyin",
      render: (item) => Format_Datetime(item),
    },
    {
      title: "",
      width: "80px",
      render: (item, record, index) => {
        return (
          <Flex gap={5}>
            <Tooltip placement="topRight" title="Chuyển bàn">
              <Button
                type="primary"
                ghost
                icon={<BiTransferAlt />}
                onClick={() => handleChuyenBan(item)}
              />
            </Tooltip>
            <Popconfirm
              title="Out bàn"
              description="Bạn có chắc chắn muốn out bàn không?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => handleOut(item)}
            >
              <Tooltip placement="topRight" title="Out bàn">
                <Button type="primary" danger ghost icon={<CiInboxOut />} />
              </Tooltip>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const columns3 = [
    {
      title: "Tên seat",
      render: (item) => item.Name,
    },
    {
      title: "Tên Player",
      dataIndex: "TenCongTy",
    },
    {
      title: "Thời gian",
      dataIndex: "NgayNhap",
      render: (item) => Format_Datetime(item),
    },
    {
      title: "Ghi chú",
      dataIndex: "DienGiai",
    },
  ];
  const remove = async (value) => {
    const _vl = {
      ...value,
      Name: tables.find((v) => v.ID === value.MaBan)?.Name,
    };
    let _tb = data
      ?.filter((v) => v.isOpen && v.ID !== value.ID)
      ?.map((item) => {
        return {
          ...item,
          Name: tables.find((v) => v.ID === item.MaBan)?.Name,
        };
      });
    refCloseTable.current.show(_vl, _tb);
  };
  const openTable = async (value) => {
    let _res = await TourService.Table.closeOpen({
      isOpen: true,
      ID: value.ID,
      MaBan: value.MaBan,
      Seats:[]
    });
    if (_res.status === 2000) {
      filter(maGD);
      openNotification("success", "topRight", "Thao tác thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "TenLevel" ? "text" : "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const renderAdd = () => {
    const handleOk = async () => {
      if (!dataOpen.MaBan || !dataOpen.SoLuongMax) {
        openNotification(
          "error",
          "topRight",
          `Vui lòng nhập đầy đủ thông tin!`
        );
        return;
      }
      let _tables = [];
      dataOpen.MaBan?.map((item)=>{
        _tables.push({
          ID: item,
          Name: tables.find(v=>v.ID === item)?.Name,
        });
      });
      let _res = await TourService.Table.openTable({
        // Tables: dataOpen.MaBan,
        Tables: _tables,
        MaGD: maGD,
        SoLuongMax: dataOpen.SoLuongMax,
      });
      if (_res.status === 2000) {
        filter(maGD);
        openNotification("success", "topRight", `Mở bàn thành công!`);
        setOpen2(false);
        setDataOpen({});
      }
    };
    const handleCancel = () => {
      setOpen2(false);
    };
    const applyChange = (p, v) => {
      let _data = _.cloneDeep(dataOpen);
      _data[p] = v;
      setDataOpen(_data);
    };
    return (
      <Modal
        open={open2}
        title="Mở bàn"
        onOk={handleOk}
        okText="Lưu"
        width={400}
        cancelText="Huỷ"
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <Select
          style={{
            width: "100%",
          }}
          mode="multiple"
          value={dataOpen?.MaBan}
          placeholder="Chọn bàn"
          onChange={(v) => applyChange("MaBan", v)}
          options={tables?.filter(
            (v) =>
              // !data.find((v2) => v2.MaBan === v.ID) &&
              (v.isOpen ?? false) === false
          )}
          fieldNames={{ label: "Name", value: "ID" }}
        />
        <p style={{ marginTop: 15 }}>Số lượng max</p>
        <InputNumber
          placeholder="Nhập số lượng"
          value={dataOpen.SoLuongMax}
          style={{ width: "100%" }}
          onChange={(v) => applyChange("SoLuongMax", v)}
        />
      </Modal>
    );
  };
  const handleLichSu = async () => {
    setOpen4(true);
    let _res = await TourService.Table.getSeatHistory({ MaBan: maBanGD });
    setHistories(_res.data);
  };
  const renderHistorySeat = (item) => {
    return (
      <Drawer
        title="Lịch sử"
        width={720}
        onClose={() => setOpen4(false)}
        open={open4}
      >
        <Table
          loading={loading}
          columns={columns3}
          dataSource={histories}
          pagination={false}
          scroll={{
            y: window.innerHeight - 150,
          }}
          size="small"
        />
      </Drawer>
    );
  };
  const handleOut = async (item) => {
    let _res = await TourService.Table.outUser({
      ...item,
      TenSeat: item.Name,
      MaBan: maBan,
    });
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Thao tác thành công");
      loadUser(maBanGD);
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderUser = () => {
    const handleCancel = () => {
      filter(maGD);
      setOpen3(false);
    };
    const addUser = async () => {
      // setOpenAddUser(true);
      // await loadSeat(maBan, maBanGD);
    };
    return (
      <Drawer
        title={`Danh sách người chơi - ${
          tables.find((v) => v.ID === maBan)?.Name
        }`}
        width={720}
        onClose={() => handleCancel()}
        open={open3}
        extra={
          <Space>
            {/* {data?.find((v) => v.ID === maBanGD)?.SoLuongMax >
              data?.find((v) => v.ID === maBanGD)?.Player && (
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => addUser()}
              >
                Thêm
              </Button>
            )} */}

            <Button icon={<RiHistoryFill />} onClick={() => handleLichSu()}>
              Lịch sử
            </Button>
          </Space>
        }
      >
        {contextHolder}
        <Table
          loading={loading}
          columns={columns2}
          dataSource={users}
          pagination={false}
          scroll={{
            y: window.innerHeight - 150,
          }}
          size="small"
        />
        {renderHistorySeat()}
      </Drawer>
    );
  };
  const onAfterSubmit = () => {
    filter(maGD);
  };
  const handleChuyenBan = async (item) => {    
    setOpenChuyenBan(true);
    refChuyenBan.current = item;
    let _resBan = await TourService.Table.get({ MaGD: maGD });
    let _tb = _resBan?.data
      ?.filter(
        (v) => v.isOpen && v.Player < v.SoLuongMax && v.ID !== item.MaBanGD
      )
      ?.map((v) => {
        return {
          ...v,
          Name: tables.find((d) => d.ID === v.MaBan).Name,
        };
      });
    setTablesChuyenBan(_tb);
  };
  const loadSeat = async (maban, mabangd) => {
    let _res = await TourService.Table.getSeatTrong({
      MaBan: maban,
      MaBanGD: mabangd,
    });
    if (_res.data?.length > 0) {
      setSeatChuyenBan(_res.data);
    }
  };
  const renderChuyenBan = () => {
    const handleOk = async () => {
      if (!dataOpen.MaBanGDNew || !dataOpen.MaSeat) {
        openNotification("error", "topRight", `Chọn bàn và seat!`);
        return;
      }
      // let maBan = data?.find(
      //   (v) => v.ID === refChuyenBan.current?.MaBanGD
      // )?.MaBan;
      setLoading(true);
      let tenSeatNew = seatChuyenBan.find(
        (d) => d.ID === dataOpen.MaSeat
      )?.Name;
      let tenBanNew = tablesChuyenBan.find(
        (d) => d.ID === dataOpen.MaBanGDNew
      )?.Name;
      let _payload = {
        ID: refChuyenBan.current?.ID,
        NgayBuyin: refChuyenBan.current?.NgayBuyin,
        MaBanGD: refChuyenBan.current?.MaBanGD,
        MaSeat: refChuyenBan.current?.MaSeat,
        TenSeat: refChuyenBan.current?.Name,
        MaKH: refChuyenBan.current?.MaKH,
        TenCongTy: refChuyenBan.current?.TenCongTy,
        MaThe: refChuyenBan.current?.MaThe,
        DienGiai: `Chuyển sang ${tenBanNew}/${tenSeatNew}`,
        MaBan: maBan,

        MaBanNew: dataOpen.MaBanNew,
        TenBanNew: tenBanNew,
        MaBanGDNew: dataOpen.MaBanGDNew,
        MaSeatNew: dataOpen.MaSeat,
        TenSeatNew: tenSeatNew,
        DienGiaiNew: `Chuyển từ ${tables.find((d) => d.ID === maBan)?.Name}/${
          refChuyenBan.current?.Name
        }`,
      };
      let _res = await TourService.Table.chuyenBan(_payload);
      if (_res.status === 2000) {
        setLoading(false);
        loadUser(maBanGD);
        openNotification("success", "topRight", `Chuyển seat thành công!`);
        setOpenChuyenBan(false);
        setDataOpen({});
      }
    };
    const handleCancel = () => {
      setOpenChuyenBan(false);
      setDataOpen({});
    };

    const applyChange = async (p, v) => {
      let _data = _.cloneDeep(dataOpen);
      switch (p) {
        case "MaBanGDNew":
          let ban = data.find((d) => d.ID === v);
          _data[p] = v;
          _data.MaBanNew = ban?.MaBan;
          await loadSeat(ban.MaBan, v);
          break;
        default:
          _data[p] = v;
          break;
      }
      setDataOpen(_data);
    };
    return (
      <Modal
        open={openChuyenBan}
        title="Chuyển bàn"
        onOk={handleOk}
        okText="Lưu"
        width={400}
        cancelText="Huỷ"
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <p>Chọn bàn cần chuyển tới</p>
        <Select
          style={{
            width: "100%",
          }}
          value={dataOpen?.MaBanGDNew}
          placeholder="Chọn bàn"
          onChange={(v) => applyChange("MaBanGDNew", v)}
          options={tablesChuyenBan}
          fieldNames={{ label: "Name", value: "ID" }}
        />
        <p style={{ marginTop: 15 }}>Chọn ghế cần chuyển tới</p>
        <Select
          style={{
            width: "100%",
          }}
          value={dataOpen?.MaSeat}
          placeholder="Chọn seat"
          onChange={(v) => applyChange("MaSeat", v)}
          options={seatChuyenBan}
          fieldNames={{ label: "Name", value: "ID" }}
        />
      </Modal>
    );
  };
  const renderAddUser = () => {
    const handleOk = async () => {
      if (!dataOpen.MaKH || !dataOpen.MaSeat) {
        openNotification("error", "topRight", `Chọn khách hàng và seat!`);
        return;
      }
      let _payload = {
        TenSeat: seatChuyenBan.find((v) => v.ID === dataOpen.MaSeat)?.Name,
        MaGD: maGD,
        MaSeat: dataOpen.MaSeat,
        MaBanGD: maBanGD,
        MaThe: null,
        MaKH: dataOpen.MaKH?.value,
        TenCongTy: dataOpen.TenCongTy,
        MaBan: maBan,
      };
      let _res = await TourService.Table.addUserToTable(_payload);
      if (_res.status === 2000) {
        loadUser(maBanGD);
        openNotification("success", "topRight", `Thêm vào seat thành công!`);
        setOpenAddUser(false);
        setDataOpen({});
      }
    };
    const handleCancel = () => {
      setOpenAddUser(false);
      setDataOpen({});
    };
    const applyChange = async (p, v) => {
      let _data = _.cloneDeep(dataOpen);
      switch (p) {
        case "MaKH":
          let kh = JSON.parse(v.title);
          _data[p] = v;
          _data.TenCongTy = kh.TenCongTy;
          break;
        default:
          _data[p] = v;
          break;
      }
      setDataOpen(_data);
    };
    async function fetchUserList(username) {
      return BuyinService.filterCustomer({ InputSearch: username }).then(
        (res) => {
          return res?.data?.map((item) => ({
            label: `${item.TenCongTy} - ${item.DiDong}`,
            value: item.MaKH,
            title: JSON.stringify(item),
          }));
        }
      );
    }
    return (
      <Modal
        open={openAddUser}
        title="Thêm người chơi vào bàn bàn"
        onOk={handleOk}
        okText="Lưu"
        width={400}
        cancelText="Huỷ"
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <p>Người chơi</p>
        <DebounceSelect
          showSearch
          placeholder="Tìm kiểm khách hàng"
          fetchOptions={fetchUserList}
          onChange={(newValue) => applyChange("MaKH", newValue)}
          style={{
            width: "100%",
          }}
        />

        <p style={{ marginTop: 15 }}>Chọn ghế cần chuyển tới</p>
        <Select
          style={{
            width: "100%",
          }}
          value={dataOpen?.MaSeat}
          placeholder="Chọn seat"
          onChange={(v) => applyChange("MaSeat", v)}
          options={seatChuyenBan}
          fieldNames={{ label: "Name", value: "ID" }}
        />
      </Modal>
    );
  };

  return (
    <Drawer
      title="Bàn đang chạy"
      width={720}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button icon={<PlusOutlined />} onClick={() => {
            setOpen2(true);
            setDataOpen({
              SoLuongMax: soSeatMax ?? 0,
            });
          }}>
            Mở bàn
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Form form={form} component={false}>
        <Table
          loading={loading}
          dataSource={data}
          // columns={columns}
          columns={mergedColumns}
          pagination={false}
          rowClassName="editable-row"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          scroll={{
            y: window.innerHeight - 150,
          }}
          size="small"
        />
      </Form>
      {renderAdd()}
      {renderUser()}
      {renderChuyenBan()}
      {/* {renderAddUser()} */}
      <FormCloseTable ref={refCloseTable} onAfterSubmit={onAfterSubmit} />
      <FormLSDongBan ref={refLSDongBan} />
    </Drawer>
  );
};
export default forwardRef(FormTable);
