import { Format_Currency } from "layouts/ultis/common";
import React, { useEffect, useState, useContext } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { HomeService } from "services/HomeService";
// import SignalrContext from "contexts/SignalrContext";
import AnimatedNumbers from "react-animated-numbers";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import _ from "lodash";
import { GiaiDauService } from "services/GiaiDauService";
import "./index.scss";
export default function Jackpot() {
  // const { hubConnection } = useContext(SignalrContext);
  const [hubConnection, setHubConnection] = useState(null);
  let params = useLocation();
  const [data, setData] = useState({});

  useEffect(() => {
    let indexmact = params?.search?.indexOf("mact");
    let mact = params?.search.substring(indexmact + 5);
    GiaiDauService.getJackpot({ TenCTDK: mact }).then((res) => {
      setData(res.data);
    });
    (async () => {
      try {
        let connection = new HubConnectionBuilder()
          .withUrl("https://api-poker.beesky.vn/other")
          .withAutomaticReconnect()
          .configureLogging(LogLevel.Information)
          .build();
        connection.onreconnected(async (connectionId) => {
          console.log(
            `Connection reestablished. Connected with connectionId "${connectionId}".`
          );
          try {
            await connection.invoke("JoinGroup", mact.toLowerCase());
            console.log(`Rejoined group: ${mact.toLowerCase()}`);
          } catch (err) {
            console.error(`Failed to rejoin group: ${err}`);
          }
        });
        await connection.start();
        connection.invoke("JoinGroup", mact.toLowerCase());
        console.log(connection);
        setHubConnection(connection);
      } catch {}
    })();
    return () => {
      if (hubConnection) {
        hubConnection.stop();
      }
    }
  }, []);
  useEffect(() => {
    if (hubConnection) {
      hubConnection.on("ActionChangeJackpot", async (response) => {
        console.log(response);
        setData((pre) => {
          let _pre = _.cloneDeep(pre);
          _pre.gtd = response.gtd;
          _pre.hienTai = response.hienTai;
          _pre.giaiThuong = response.giaiThuong;
          return _pre;
        });
      });
    }
  }, [hubConnection]);
  const fullScreen = () => {
    let e = document.querySelector(".container-jackpot");
    e?.requestFullscreen();
  };
  const renderScreen = () => {
    return (
      <div
        className="container-jackpot"
        style={{
          backgroundImage: `url(${data?.background?.replace(
            "https://quanlykinhdoanh.com.vn/httpdocs/Upload/CRM/BEEPOKER/",
            ""
          )})`,
          justifyContent: "center",
        }}
      >
        <div>
          <p
            style={{
              fontSize: 150,
              color: "yellow",
              textAlign: "center",
              lineHeight: 0.9,
              fontWeight: "bold",
            }}
          >
            {/* {Format_Currency(data?.gtd)} */}
            <AnimatedNumbers
              includeComma
              locale="vi-VN"
              transitions={(index) => ({
                type: "spring",
                duration: index / 3,
              })}
              animateToNumber={data?.gtd?.toFixed(0)}
              // fontStyle={{
              //   fontSize: 40,
              //   color: "red",
              // }}
            />
          </p>
          {data?.hienTai > 0 && (
            <p
              style={{
                fontSize: 80,
                color: "yellow",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              {Format_Currency(data?.hienTai)}
            </p>
          )}
        </div>
        <div>
          {data?.giaiThuong?.map((item) => {
            return (
              <p style={{ fontSize: 40, color: "white", textAlign: "center" }}>
                {item.TenGiai}:{" "}
                {Format_Currency((item.PhanTram * data?.gtd) / 100)}
              </p>
            );
          })}
        </div>
        <div
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            zIndex: 2,
          }}
        >
          <p
            onClick={() => fullScreen()}
            style={{ color: "white", cursor: "pointer", fontSize: 15 }}
          >
            [ - ]
          </p>
        </div>
      </div>
    );
  };
  return renderScreen();
}
