import React, { useRef } from "react";

// chakra imports
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
import _ from "lodash";

// Assets
import { IoCloudDownload, IoMenuOutline, IoSettings } from "react-icons/io5";
import {
  Avatar,
  Col,
  Dropdown,
  Input,
  Modal,
  Row,
  Tooltip,
  notification,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import Links from "components/sidebar/components/Links";
import { useHistory } from "react-router-dom";
import FormJackpot from "views/admin/Jackpot/FormAdd";
import { FaGooglePlay } from "react-icons/fa";
import { IoIosAppstore } from "react-icons/io";
import { NhanVienService } from "services/NhanVienService";

function Sidebar(props) {
  const { routes } = props;
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const refDetail = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({});
  const [api, contextHolder] = notification.useNotification();

  let variantChange = "0.2s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarMargins = "0px";
  const items = [
    {
      key: "1",
      label: "Đổi mật khẩu",
      onClick: () => setOpen(true),
    },
    {
      key: "2",
      label: "Đăng xuất",
      onClick: () => logout(),
    },
  ];
    const items2 = [
      {
        key: "1",
        label: "Đổi mật khẩu",
        onClick: () => setOpen(true),
      },
      {
        key: "2",
        label: "Đăng xuất",
        onClick: () => logout(),
      },
    ];
  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("url");
    // sessionStorage.removeItem("token");
    history.push("/auth/sign-in");
    window.location.reload();
  };
  const openJackpot = () => {
    let url = "/#/jackpot?mact=" + localStorage.getItem("MaCTDK");
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const renderModal = () => {
    const hide = () => {
      setOpen(false);
      //  setLydo("");
    };
    const applyChange = (p, v) => {
      let _data = _.cloneDeep(data);
      _data[p] = v;
      setData(_data);
      //  setLydo(v);
    };
    const onSubmit = async () => {
      setLoading(true);
      let _res = await NhanVienService.changePass(data);
      if (_res.status === 2000) {
        openNotification("success", "topRight", `Đổi mật khẩu thành công!`);
        hide();
      } else {
        openNotification("error", "topRight", _res?.message);
      }
      setLoading(false);
    };
    return (
      <Modal
        title="Đổi mật khẩu"
        open={open}
        width={400}
        confirmLoading={loading}
        onOk={() => onSubmit()}
        onCancel={hide}
        okText="Lưu"
        cancelText="Huỷ"
      >
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <p>Mật khẩu cũ</p>
            <Input
              style={{ width: "100%" }}
              value={data.PasswordCurrent}
              onChange={(v) => applyChange("PasswordCurrent", v.target.value)}
            />
          </Col>
          <Col span={24}>
            <p>Mật khẩu mới</p>
            <Input
              style={{ width: "100%" }}
              value={data.PasswordNew}
              onChange={(v) => applyChange("PasswordNew", v.target.value)}
            />
          </Col>
          <Col span={24}>
            <p>Nhập lại mật khẩu mới</p>
            <Input
              style={{ width: "100%" }}
              value={data.PasswordRe}
              onChange={(v) => applyChange("PasswordRe", v.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    );
  };
  // SIDEBAR
  return (
    <Flex
      align="center"
      gap={0}
      style={{ width: "100%", background: "#f1cbb5" }}
    >
      {contextHolder}
      <Links routes={routes} />
      <Flex style={{ padding: "0px 20px" }} gap={2}>
        <Flex align="center" justify="center" gap={2}>
          <Tooltip title="Tải ứng dụng IOS">
            <a
              href="https://apps.apple.com/tr/app/bee-poker-tournament/id6453687138"
              target="_blank"
            >
              <IoIosAppstore size={22} />
            </a>
          </Tooltip>
          <Tooltip title="Tải ứng dụng android">
            <a
              href="https://play.google.com/store/apps/details?id=com.beepoker&pcampaignid=web_share"
              target="_blank"
            >
              <FaGooglePlay />
            </a>
          </Tooltip>
          <Tooltip title="Tải ứng dụng">
            <div
              style={{
                cursor: "pointer",
                padding: "5px 10px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                background: "white",
                borderRadius: 50,
              }}
            >
              {/* <Dropdown menu={{ items }} placement="bottomRight">
                <IoCloudDownload />
              </Dropdown> */}
              <a href="http://crm.quanlykinhdoanh.com.vn/Users/BEE_POKER_TOUR.msi">
                <IoCloudDownload />
              </a>
            </div>
          </Tooltip>
          <Flex
            align="center"
            justify="center"
            gap={2}
            style={{
              cursor: "pointer",
              fontSize: 14,
              padding: "5px 10px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              background: "white",
              borderRadius: 50,
            }}
          >
            <IoSettings onClick={() => refDetail.current.show()} />
            <p onClick={() => openJackpot()}>Jackpot</p>
          </Flex>
        </Flex>
        <Dropdown menu={{ items }} placement="bottomRight">
          <Avatar size={35} icon={<UserOutlined />} />
        </Dropdown>
      </Flex>
      <FormJackpot ref={refDetail} />
      {renderModal()}
    </Flex>
  );
}

// FUNCTIONS
export function SidebarResponsive(props) {
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  let menuColor = useColorModeValue("gray.400", "white");
  // // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const { routes } = props;
  // let isWindows = navigator.platform.startsWith("Win");
  //  BRAND

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems="center">
      <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          my="auto"
          w="20px"
          h="20px"
          me="10px"
          _hover={{ cursor: "pointer" }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent w="285px" maxW="285px" bg={sidebarBackgroundColor}>
          <DrawerCloseButton
            zIndex="3"
            onClose={onClose}
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW="285px" px="0rem" pb="0">
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}
            >
              <Content routes={routes} />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
// PROPS

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;
