import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Select, Drawer, Divider, Checkbox, Row, Col } from "antd";
import _ from "lodash";
import { PermissionService } from "services/PermissionService";
import { Accordion, AccordionTab } from "primereact/accordion";

const FormAddPerNhomModules = (props, ref) => {
  const { onAfterSubmit } = props;
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [nhommodules, setNhommodules] = useState({});
  const [menulv1, setMenulv1] = useState([]);
  const loadCategories = async (MaNhom) => {
    let _res = await PermissionService.TheoNhomModules.getForm({
      MaNhom: MaNhom,
    });
    setData(_res?.data ?? []);
    let _res2 = await PermissionService.getMenuLevel1({});
    setMenulv1(_res2?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async (item) => {
      setOpen(true);
      setNhommodules(item);
      loadCategories(item.ID);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    onAfterSubmit();
  };
  const onChangePer = async (item) => {
    if (item.Per) {
      let payload = {
        MaNhom: nhommodules.ID,
        FormID: item.FormID,
        HavePer: null,
      };
      let _res = await PermissionService.TheoNhomModules.addForm(payload);
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data);
        let _dt = _data.find((v) => v.FormID === item.FormID);
        _dt.Per = null;
        setData(_data);
      }
    } else {
      let payload = {
        MaNhom: nhommodules.ID,
        FormID: item.FormID,
        HavePer: true,
      };
      let _res = await PermissionService.TheoNhomModules.addForm(payload);
      if (_res.status === 2000) {
        let _data = _.cloneDeep(data);
        let _dt = _data.find((v) => v.FormID === item.FormID);
        _dt.Per = item.FormID;
        setData(_data);
      }
    }
  };
  return (
    <Drawer
      title="PHÂN QUYỀN CÔNG TY"
      width={620}
      onClose={onClose}
      open={open}
      className="giai-dau-detail"
      styles={{
        body: {
          paddingTop: 30,
        },
      }}
    >
      <Accordion multiple>
        {menulv1?.map((lv1) => {
          return (
            <AccordionTab key={lv1.ModulID} header={lv1.ModulName}>
              {data
                ?.filter((v) => v.ModulIDLevel1 === lv1.ModulID)
                ?.map((item) => {
                  return (
                    <div key={item.FormID}>
                      <Row gutter={[20]}>
                        <Col span={24}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: 8,
                            }}
                          >
                            <Checkbox
                              onChange={() => onChangePer(item)}
                              checked={item.Per}
                            />
                            <p style={{ fontWeight: "500", fontSize: "16px" }}>
                              {item.FormName}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Divider dashed style={{ margin: "8px 0px" }} />
                    </div>
                  );
                })}
            </AccordionTab>
          );
        })}
      </Accordion>
    </Drawer>
  );
};
export default forwardRef(FormAddPerNhomModules);
