import axios from "axios";
import { post } from "./api_helper";
import axiosApi from "./api_helper";
export const HomeService = {
  ManHinh: {
    filter: async (payload) => {
      //MaDA
      return await axiosApi()
        .post("api/poker/DanhSachGiaiDau", payload)
        .then((res) => res.data);
    },
    getDetail: async (payload) => {
      //MaDA
      return await axiosApi()
        .post("api/Poker/DanhSachGiaiDau_ChiTietHIenThi", payload)
        .then((res) => res.data);
      // return await post("/Poker/DanhSachGiaiDau_ChiTietHIenThi",payload)
    },
    getDetailNoAuth: async (payload) => {
      //MaDA
      return await axiosApi()
        .post("api/Poker/DanhSachGiaiDau_ChiTietHIenThi2", payload)
        .then((res) => res.data);
      // return await post("/Poker/DanhSachGiaiDau_ChiTietHIenThi2",payload)
    },
    getCoCauGiaiThuong: async (payload) => {
      //MaDA
      return await axiosApi()
        .post("api/Poker/ListGiaiThuong", payload)
        .then((res) => res.data);
      // return await post("/Poker/ListGiaiThuong",payload)
    },
    getCoCauGiaiThuongNoAuth: async (payload) => {
      //MaDA
      return await axiosApi()
        .post("api/Poker/ListGiaiThuong2", payload)
        .then((res) => res.data);
      // return await post("/Poker/ListGiaiThuong2",payload)
    },
  },
  Auth: {
    CheckKeyConnect: async (payload) => {
      //MaDA
      return await axios
        .post("https://api-client.mybeeland.com/api/CheckKeyConnect", payload)
        .then((res) => res.data);
    },
    login: async (payload) => {
      //MaDA
      return await axiosApi()
        .post("api/Login", payload)
        .then((res) => res.data);
    },
    getMenu: async () => {
      return await axiosApi()
        .post("api/poker/get-menu")
        .then((res) => res.data);
    },
    getAction: async (payload) => {
      //FormID
      return await axiosApi()
        .post("api/poker/get-action", payload)
        .then((res) => res.data);
    },
  },
  upload: async (payload) => {
    return await axiosApi({
      "Content-Type": "multipart/form-data",
    })
      .post("api/poker/upload", payload)
      .then((res) => res.data);
  },
};
