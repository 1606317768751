import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import "./scss/index.scss";
import { Badge, Col, Drawer, Flex, Image, Radio, Rate, Row, Tabs } from "antd";
import { OrderService } from "services/OrdersService";
import { Format_Currency } from "layouts/ultis/common";
import _ from "lodash";
import CheckOut from "./CheckOut";
import History from "./History";
import { Input, Button, notification } from "antd";
import LoginOrder from "./loginOrder";
import HomeOrder from "./homeOrder";
import {
  FileTextOutlined,
  LeftOutlined,
  MinusOutlined,
  PhoneOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import FoodOeder from "./foodOrder";
import RenderFood from "./renderFood";
export default function Portal() {
  let params = useLocation();
  const queryString = params.search;
  const params2 = new URLSearchParams(queryString);
  const maCT = params2.get("mact");
  const tableValue = params2.get("table");
  const [layout, setLayout] = useState("home");
  const [data, setData] = useState([]);
  const [carts, setCarts] = useState([]);
  const [activeTab, setActiveTab] = useState("0");
  const [visibleDG, setVisibleDG] = useState(false);
  const [value, setValue] = useState(0);
  const [rate, setRate] = useState("");
  const [rate2, setRate2] = useState("Bạn có điều gì chưa hà lòng phải không?");
  const [visibleOrder, setVisibleOrder] = useState(false);
  const [valueOrder, setValueOrder] = useState({});
  const [countFood, setCountFood] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [noiDungDG, setNoiDungDG] = useState("");
  const [sDTDG, setSDTDG] = useState("");
  const [congTy, setCongTy] = useState();
  const [table, setTable] = useState();
  const [seat, setSeat] = useState([]);
  const [valueRadio, setValueRadio] = useState();
  const [errSeat, setErrSeat] = useState("");
  const [ghiChu, setGhiChu] = useState("");
  const [khachHang, setKhachHang] = useState();
  const [point, setPoint] = useState();
  const [hTTT, setHTTT] = useState();

  const incrementCount = () => {
    setCountFood((prevCount) => (prevCount < 99 ? prevCount + 1 : prevCount));
  };
  const decrementCount = () => {
    setCountFood((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));
  };
  const onChangeRadio = (value) => {
    setValueRadio(value);
    setErrSeat("");
  };
  const onCloseOrder = () => {
    setCountFood(1);

    setVisibleOrder(false);
  };
  const showDrawerDG = () => {
    setVisibleDG(true);
  };
  const onCloseDG = () => {
    setVisibleDG(false);
  };
  const handleChange = (value) => {
    setValue(value);
    switch (value) {
      case 1:
        setRate("Rất thất vọng");
        setRate2("Bạn có điều gì chưa hà lòng phải không?");
        break;
      case 2:
        setRate("Thất vọng");
        setRate2("Bạn có điều gì chưa hà lòng phải không?");
        break;
      case 3:
        setRate("Bình thường");
        setRate2("Bạn có điều gì chưa hà lòng phải không?");
        break;
      case 4:
        setRate("Hài lòng");
        setRate2("Bạn có điều gì chưa hà lòng phải không?");
        break;
      case 5:
        setRate("Quá tuyệt vời");
        setRate2(
          "Bạn cảm thấy hài lòng nhất ở điều gì có thể chia sẻ được không?"
        );
        break;
      default:
        setRate("");
        setRate2("Bạn có điều gì chưa hà lòng phải không?");
        break;
    }
  };
  const handleDetailOrderFood = (value) => {
    if (carts?.some((v) => v.id === value.id)) {
      setVisibleOrder(false);
      setValueOrder(value);
    } else {
      setVisibleOrder(true);
      setValueOrder(value);
    }
  };
  const editFood = (value) => {
    setValueOrder(value);
    setCountFood(value.quantity);
    setVisibleOrder(true);
    if (value.seat) {
      setValueRadio(value.seat);
    }
  };
  const filterHTTT = async () => {
    let _res6 = await OrderService.getHTTT({
      TenCTDK: maCT,
    });
    if (_res6.status === 2000) {
      setHTTT(_res6.data);
    }
    setLayout("foodOrder");
  };
  const filter = async () => {
    let _res3 = await OrderService.getLinkCongTy({
      MaCT: maCT,
    });
    if (_res3.status === 2000) {
      let _res2 = await OrderService.getCongTy({
        Server: _res3.data.Server,
        MaCTDK: _res3.data.MaCTDK,
      });
      if (_res2.status === 2000) {
        setCongTy(_res2.data);
      }
    }
    let _res = await OrderService.getProducts({
      TenCTDK: localStorage.getItem("tenCTDK"),
    });
    if (_res.status === 2000) setData(_res.data);
    let _res5 = await OrderService.getTableDetail({
      ID: Number(tableValue),
    });
    if (_res5.status === 2000) {
      setTable(_res5.data);
    }
    let _res6 = await OrderService.getSeat({
      MaBan: Number(tableValue),
      TenCTDK: maCT,
    });
    if (_res6.status === 2000) {
      setSeat(_res6.data);
    }
  };
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api.success({
      message: "Đặt đồ ăn thành công!",
    });
  };
  const openNotificationWithIconDG = (type) => {
    api.success({
      message: "Giửi đánh giá thành công!",
    });
  };
  useEffect(() => {
    let indexmact = params?.search?.indexOf("&mact");
    let indextable = params?.search?.indexOf("&table");
    let _mact = params?.search.substring(indexmact + 7, indextable);
    let _table = params?.search.substring(indextable + 7);

    let _domain = "";
    if (_mact?.toLocaleLowerCase() === "crownpoker") {
      _domain = "https://api.crownpokerclub.com/";
    } else if (_mact?.toLocaleLowerCase() === "galaxy") {
      _domain = "https://apigalaxy.appbeesky.com/";
    } else if (_mact?.toLocaleLowerCase() === "royalpoker") {
      _domain = "https://apiroyalpoker.appbeesky.com/";
    } else if (
      _mact?.toLocaleLowerCase() === "centerpoker" ||
      _mact?.toLocaleLowerCase() === "hongvan"
    ) {
      _domain = "https://apicenterpoker.appbeesky.com/";
    } else {
      _domain = "https://apicenterpoker.appbeesky.com/";
    }
    localStorage.setItem("tenCTDK", _mact);
    localStorage.setItem("url", _domain);
    filter();
  }, []);
  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };
  const increaseCount = (value) => {
    const _carts = _.cloneDeep(carts);
    let cart = _carts.find((v) => v.id === value.id);
    if (cart) {
      cart.quantity += 1;
    }
    setCarts(_carts);
  };
  const addProduct = (value) => {
    const _carts = _.cloneDeep(carts);
    let cart = _carts.find((v) => v.id === value.id);
    _carts.forEach((cart) => {
      cart.seat = valueRadio;
    });
    if (cart) {
      cart.quantity = countFood;
      cart.seat = valueRadio;
    } else {
      _carts.push({ ...value, ghiChu: ghiChu });
    }
    setCarts(_carts);
    setGhiChu("");
  };
  const removeProduct = (value) => {
    const _carts = _.cloneDeep(carts);
    let cart = _carts.find((v) => v.id === value.id);
    if (cart?.quantity > 1) {
      cart.quantity -= 1;
    } else {
      let index = _carts?.findIndex((v) => v.id === value.id);
      _carts.splice(index, 1);
    }
    setCarts(_carts);
  };
  const reunderFooter = () => {
    const totalQuantity = carts?.reduce(
      (total, item) => total + item.quantity,
      0
    );
    const totalPrice = carts?.reduce(
      (total, item) => total + item.giaBan * item.quantity,
      0
    );
    return (
      carts?.length > 0 && (
        <div
          onClick={() => {
            filterHTTT();
          }}
          style={{ position: "fixed", bottom: 0, width: "100%" }}
        >
          <div
            style={{
              backgroundColor: "rgb(247, 148, 30)",
              margin: "15px",
              padding: "12px 20px",
              borderRadius: "7px",
            }}
          >
            <Flex justify="space-between">
              <div style={{}}>
                <Badge count={totalQuantity} overflowCount={99}>
                  {/* <Button shape="round" icon={<ShoppingCartOutlined />} /> */}
                  <ShoppingCartOutlined
                    style={{ fontSize: "25px", color: "#fff" }}
                  />
                </Badge>
              </div>
              <p
                style={{ fontWeight: "bold", color: "#fff", fontSize: "16px" }}
              >
                Đơn hàng
              </p>
              <p
                style={{ fontWeight: "bold", color: "#fff", fontSize: "16px" }}
              >
                {" "}
                {Format_Currency(totalPrice)}
              </p>
            </Flex>
          </div>
        </div>
      )
    );
  };
  const onGoback = (v) => {
    setLayout("home");
    if (v?.status === 2000) {
      setCarts([]);
      openNotificationWithIcon();
    }
  };
  const onChangeNote = (value, item) => {
    let _carts = _.cloneDeep(carts);
    let _cart = _carts.find((v) => v.id === item.id);
    _cart.note = value;
    setCarts(_carts);
  };
  const handleAddToCart = () => {
    if (valueRadio === undefined) {
      setErrSeat("Vui lòng chọn seat để tiếp tục!");
    } else {
      addProduct({
        ...valueOrder,
        quantity: countFood,
        seat: valueRadio,
      });
      onCloseOrder();
    }
  };
  const SubmitReview = async () => {
    try {
      const response = await OrderService.addDanhGia({
        Sao: value,
        TenCTDK: maCT,
        DiDong: sDTDG,
        NoiDung: noiDungDG,
      });
      if (response.status === 2000) {
        openNotificationWithIconDG();
        setNoiDungDG("");
        setSDTDG("");
        setValue(0);
        setVisibleDG(false);
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  };

  return (
    <div className="container-order">
      <Drawer
        className="drawer-DG"
        title="Trải nghiệm của bạn hôm nay thế nào?"
        placement="bottom"
        closable={false}
        onClose={onCloseDG}
        open={visibleDG}
        key="bottom"
        height="500px"
      >
        <div>
          <div style={{ borderBottom: "1px solid #eee" }}>
            <Rate
              style={{ fontSize: "35px" }}
              onChange={handleChange}
              value={value}
            />
            <p
              style={{
                paddingTop: "10px",
                fontSize: "16px",
                paddingBottom: "15px",
              }}
            >
              {rate}
            </p>
          </div>
          <div style={{ borderBottom: "1px solid #eee" }}>
            <p style={{ fontSize: "16px", padding: "15px 0" }}>{rate2}</p>
            <Input
              value={noiDungDG}
              onChange={(e) => setNoiDungDG(e.target.value)}
              className="input-order"
              placeholder="Chia sẻ cho nhà hàng trải nghiệm của bạn nhé"
              prefix={
                <PhoneOutlined
                  style={{
                    color: "rgba(0,0,0,.25)",
                    marginRight: "8px",
                    fontSize: "16px",
                  }}
                />
              }
              style={{
                textAlign: "center",
                marginBottom: "15px",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
              }}
            />
          </div>
          <div>
            <p style={{ fontSize: "15px", marginTop: "15px" }}>
              Nhà hàng rất trân trọng và mong muốn phản hồi lại đánh giá trên,
              bạn vui lòng để lại số điện thoại nhé
            </p>
            <Flex>
              <Input
                value={sDTDG}
                onChange={(e) => setSDTDG(e.target.value)}
                className="input-order"
                placeholder="0999999999"
                prefix={
                  <PhoneOutlined
                    style={{ color: "rgba(0,0,0,.25)", marginRight: "8px" }}
                  />
                }
                style={{
                  marginTop: "15px",
                  marginRight: "20px",
                  textAlign: "center",
                  height: "42px",
                  display: "flex",
                  alignItems: "center",
                }}
              />
              <Button
                onClick={() => SubmitReview()}
                disabled={value === 0 ? true : false}
                style={{ width: "35%" }}
                className="button-order"
              >
                Gửi đánh giá
              </Button>
            </Flex>
          </div>
        </div>
      </Drawer>
      <Drawer
        className="drawer-DG"
        title={null}
        placement="bottom"
        closable={false}
        onClose={onCloseOrder}
        visible={visibleOrder}
        height="100%"
        bodyStyle={{ padding: 0 }}
      >
        <div style={{ height: "100vh", overflow: "auto" }}>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              preview={false}
              src={
                valueOrder?.linkImage
                  ? valueOrder?.linkImage
                  : "https://o2o.ipos.vn/static/images/default_food.svg?width=160"
              }
              style={{ display: "block" }}
            />
            <LeftOutlined
              style={{
                position: "absolute",
                top: 10,
                left: 10,
                backgroundColor: "#f5f5f5",
                padding: "8px",
                margin: "10px",
                zIndex: 1,
                color: "black",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={onCloseOrder}
            />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                backgroundColor: "rgba(45, 45, 48, .6)",
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
                zIndex: 2,
              }}
            >
              <p
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  fontSize: "16px",
                  padding: "15px",
                }}
              >
                {valueOrder?.tenSP}
              </p>
            </div>
          </div>
          <div style={{ padding: "20px 10px", borderBottom: "1px solid #eee" }}>
            <Flex justify="space-between">
              <h4 style={{ fontWeight: "bold", fontSize: "18px" }}>Lựa chọn</h4>
              <div>
                <p
                  style={{
                    backgroundColor: "rgba(45, 45, 48, .6)",
                    color: "#fff",
                    padding: "5px 8px",
                    borderRadius: "15px",
                  }}
                >
                  Chọn 1
                </p>
              </div>
            </Flex>
            <div
              style={{
                marginTop: "10px",
                maxHeight: "300px",
                overflowY: "scroll",
              }}
            >
              {errSeat && (
                <p style={{ color: "red", textAlign: "center" }}>{errSeat}</p>
              )}
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {" "}
                  {seat?.map((item) => {
                    return (
                      // <Radio.Group onChange={onChangeRadio} value={valueRadio}>
                      //   <Radio value={item.Name}>{item.Name}</Radio>
                      // </Radio.Group>
                      <div
                        style={{
                          marginRight: "10px",
                          marginBottom: "5px",
                          fontWeight: "400",
                          borderRadius: "5px",
                          backgroundColor:
                            valueRadio === item.Name ? "#f7941e" : "",
                          // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          transition: "all 0.3s ease-in-out",
                        }}
                      >
                        <p
                          style={{
                            padding: "5px 10px",
                            color: valueRadio === item.Name ? "#fff" : "black",
                          }}
                          onClick={() => onChangeRadio(item.Name)}
                        >
                          Seat {item.Name}
                        </p>
                      </div>
                    );
                  })}
                </div>{" "}
              </div>
            </div>
          </div>
          <div
            style={{ padding: "0 15px 15px ", borderBottom: "1px solid #eee" }}
          >
            <Input
              value={ghiChu}
              onChange={(e) => setGhiChu(e.target.value)}
              className="input-order"
              placeholder="Bạn có muốn nhắn gì với nhà hàng không?"
              prefix={
                <FileTextOutlined
                  style={{ color: "rgba(0,0,0,.25)", marginRight: "8px" }}
                />
              }
              style={{
                marginTop: "15px",
                marginRight: "20px",
                textAlign: "center",
                height: "42px",
                display: "flex",
                alignItems: "center",
              }}
            />
          </div>
          <Flex style={{ padding: "15px" }} justify="space-between">
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <MinusOutlined
                onClick={() => decrementCount()}
                style={{
                  fontSize: "10px",
                  // backgroundColor: "red",
                  border: "1px solid #eee",
                  padding: "15px",
                  borderRadius: "8px",
                }}
              />
              <p style={{ margin: "0 15px" }}>{countFood}</p>

              <PlusOutlined
                onClick={() => incrementCount()}
                style={{
                  fontSize: "10px",
                  backgroundColor: "rgba(248, 149, 35, 0.15)",
                  border: "1px solid rgba(248, 149, 35, 0.15)",
                  padding: "15px",
                  borderRadius: "8px",
                }}
              />
            </div>
            <div
              onClick={() => handleAddToCart()}
              style={{
                textAlign: "center",
                backgroundColor: "#f7941e",
                color: "#fff",
                padding: "3px 45px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              <p style={{ marginBottom: "-6px" }}>Thêm vào giỏ</p>
              <span style={{ fontSize: "11px" }}>
                ( {Format_Currency(valueOrder?.giaBan * countFood)} )
              </span>
            </div>
          </Flex>
        </div>
      </Drawer>
      {activeTab === "0" ? (
        <div>
          <LoginOrder
            setActiveTab={setActiveTab}
            congTy={congTy}
            setKhachHang={setKhachHang}
            setPoint={setPoint}
          />
        </div>
      ) : activeTab === "1" ? (
        <div>
          <HomeOrder
            setActiveTab={setActiveTab}
            setLayout={setLayout}
            showDrawerDG={showDrawerDG}
            congTy={congTy}
            table={table}
            point={point}
            khachHang={khachHang}
          />
        </div>
      ) : (
        <div>
          {layout === "home" ? (
            <div style={{ background: "white" }}>
              <RenderFood
                data={data}
                setActiveTab={setActiveTab}
                handleSearch={handleSearch}
                removeProduct={removeProduct}
                increaseCount={increaseCount}
                searchTerm={searchTerm}
                handleDetailOrderFood={handleDetailOrderFood}
                carts={carts}
              />
              {reunderFooter()}
            </div>
          ) : layout === "checkout" ? (
            <CheckOut
              carts={carts}
              onBack={(v) => onGoback(v)}
              onChangeNote={(v, item) => onChangeNote(v, item)}
              addProduct={addProduct}
              removeProduct={removeProduct}
            />
          ) : layout === "foodOrder" ? (
            <FoodOeder
              setLayout={setLayout}
              setActiveTab={setActiveTab}
              carts={carts}
              onBack={(v) => onGoback(v)}
              setVisibleOrder={setVisibleOrder}
              editFood={editFood}
              setCarts={setCarts}
              onChangeNote={(v, item) => onChangeNote(v, item)}
              addProduct={addProduct}
              removeProduct={removeProduct}
              table={table}
              valueSeat={valueRadio}
              seat={seat}
              khachHang={khachHang}
              point={point}
              maCT={maCT}
              setPoint={setPoint}
              hTTT={hTTT}
            />
          ) : (
            <History
              onBack={(v) => onGoback(v)}
              setActiveTab={setActiveTab}
              setLayout={setLayout}
              showDrawerDG={showDrawerDG}
              setCarts={setCarts}
              carts={carts}
              data2={data}
              congTy={congTy}
              maCT={maCT}
              setHTTT={setHTTT}
              setValueRadio={setValueRadio}
            />
          )}
        </div>
      )}
      {contextHolder}
    </div>
  );
}
