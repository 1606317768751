import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Popconfirm,
  Table,
  Tabs,
  Tooltip,
  notification,
  Flex,
} from "antd";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import TabPane from "antd/es/tabs/TabPane";
import { SanPhamService } from "services/SanPhamService";

export default function Index() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const refDetail = useRef(null);
  const [loaisp, setLoaisp] = useState([]);
  const [khuVuc, setKhuVuc] = useState([]);

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const tabs = [
    {
      title: "Loại sản phẩm",
      key: "0",
    },
    {
      title: "Đơn vị tính",
      key: "1",
    },
  ];
  const [activeTab, setActiveTab] = useState("0");

  useEffect(() => {
    filter(activeTab);
  }, []);
  const filter = async (tab) => {
    setLoading(true);
    if (tab === "0") {
      let _res = await SanPhamService.getLSP();
      if (_res.data) setData(_res.data);
    } else if (tab === "1") {
      let _res = await SanPhamService.getDVT();
      if (_res.data) setData(_res.data);
    }
    setLoading(false);
  };
  const onAfterSubmit = () => {
    filter(activeTab);
  };
  const columns = [
    {
      title: "Tên loại sản phẩm",
      dataIndex: "TenLSP",
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "100px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaLSP)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const columns2 = [
    {
      title: "Tên đơn vị tính",
      dataIndex: "TenDVT",
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.MaDVT)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = null;
    switch (activeTab) {
      case "0":
        _res = await SanPhamService.Category.deleteLSP({ ID: Number(value) });
        break;
      case "1":
        _res = await SanPhamService.Category.deleteDVT({ ID: Number(value) });
        break;
    }
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = [];
      switch (activeTab) {
        case "0":
          fil = _data.filter((v) => v.MaLSP !== Number(value));
          break;
        case "1":
          fil = _data.filter((v) => v.MaDVT !== Number(value));
          break;
      }
      setData(fil);
      openNotification("success", "topRight", "Xoá danh mục thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
    filter(newActiveKey);
  };
  return (
    <Box>
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              <Table
                loading={loading}
                dataSource={data}
                columns={
                  activeTab === "0"
                    ? columns
                    : activeTab === "1"
                    ? columns2 : null
                }
                scroll={{
                  y: window.innerHeight - 350,
                  // x: 2500
                }}
                size="small"
              />
            </TabPane>
          );
        })}
      </Tabs>

      <FormAdd
        ref={refDetail}
        onAfterSubmit={onAfterSubmit}
        options={{ LoaiSP: loaisp, KhuVuc: khuVuc }}
      />
      {/* <FormAddImage ref={refDetailImage}  /> */}
      {contextHolder}
    </Box>
  );
}
