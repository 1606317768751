import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Divider,
  Checkbox,
  Image,
  Flex,
} from "antd";
import _ from "lodash";
import DebounceSelect from "components/DebounceSelect";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { CardService } from "services/CardService";
import { FileUpload } from "primereact/fileupload";
import { HomeService } from "services/HomeService";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const defaultData = {
    NgayHH: new Date(),
    NgayPH: new Date(),
    NgayHL: new Date(),
    NgaySinh: new Date(),
  };
  const [data, setData] = useState(defaultData);
  const [files, setFiles] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [cardType, setCardType] = useState([]);
  const [status, setStatus] = useState([]);
  const [customerSource, setCustomerSource] = useState([]);

  const [customerGroup, setCustomerGroup] = useState([]);
  const defaultErrors = {
    TenCongTy: null,
    MaLT: null,
    DiDong: null,
    MaVach: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const [agents, setAgents] = useState([]);
  const loadCategories = async () => {
    let _restype = await CardService.Type.get();
    let _resstatus = await CardService.Category.getStatus();
    let _resgr = await CardService.Customer.getGroup();
    let _nkh = await CardService.Customer.getSource();
    let _kh = await CardService.Customer.getCustomerAgent();
    setAgents(_kh.data ?? []);
    setCardType(_restype?.data ?? []);
    setCustomerGroup(_resgr?.data ?? []);
    setStatus(_resstatus?.data ?? []);
    setCustomerSource(_nkh?.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      loadCategories();
    },
    update: async (data) => {
      setOpen(true);
      loadCategories();
      setIsUpdate(true);
      if (data?.AgentID) {
        let _res = await CardService.getCusByID({
          MaKH: data?.AgentID,
        });
        data.Agent = {
          key: data?.AgentID,
          value: data?.AgentID,
          title: _res?.data?.TenCongTy,
          label: `${_res?.data?.TenCongTy} - ${_res?.data?.DiDong}`,
        };
      }
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
  };
  const applyChange = async (p, v) => {
    console.log(p);
    console.log(v);
    
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaKH":
        if (v?.length > 1) {
          _data[p] = v.splice(1);
        } else {
          _data[p] = v;
        }
        if (v?.length > 0) {
          let _res = await CardService.getCusByID({
            MaKH: _data[p][0].value,
          });
          _data.TenCongTy = _res?.data?.TenCongTy;
          _data.NgaySinh = new Date(_res?.data?.NgaySinh);
          _data.GioiTinh = _res?.data?.GioiTinh;
          _data.Email = _res?.data?.Email;
          _data.DiDong = _res?.data?.DiDong;
          _data.MaNKH = _res?.data?.MaNKH;
          _data.DiaChiCT = _res?.data?.DiaChiCT;
        } else {
          _data.TenCongTy = "";
          _data.NgaySinh = new Date();
          _data.GioiTinh = null;
          _data.Email = "";
          _data.DiDong = null;
          _data.MaNKH = null;
          _data.DiaChiCT = null;
        }
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  async function fetchUserList(username) {
    return CardService.filterCustomer({ InputSearch: username }).then((res) => {
      return res?.data?.map((item) => ({
        label: `${item.TenCongTy} - ${item.DiDong} - ${new Date(
          item.NgaySinh
        ).getDate()}/${new Date(item.NgaySinh).getMonth() + 1}/${new Date(
          item.NgaySinh
        ).getFullYear()}`,
        value: item.MaKH,
        title: item.TenCongTy,
        DiDong: item.DiDong,
      }));
    });
  }
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      if (item === "TenCongTy" || item === "DiDong") {
        if (data?.MaKH?.length === 0 || data?.MaKH === undefined) {
          _errors[item] = data[item] ? null : "Không để trống";
        }
        //
      } else {
        _errors[item] = data[item] ? null : "Không để trống";
      }
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    let _data = _.cloneDeep(data);

    let valid = validate();
    if (valid?.length > 0) {
      return;
    }
    if (_data.MaKH?.length > 0) {
      _data.MaKH = _data?.MaKH[0].value;
    }
    // _data.AgentID = _data?.Agent?.value;
    let _res = null;
    if (files?.length > 0) {
      var formData = new FormData();
      formData.append("Image", files[0]);
      formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
      let _resupload = await HomeService.upload(formData);
      if (_resupload.status === 2000) {
        _data.HinhAnh2 = localStorage.getItem("url") + _resupload.data;
        setData((pre) => {
          return { ...pre, HinhAnh2: _data.HinhAnh2 };
        });
      }
    }
    if (isupdate) {
      _res = await CardService.updateCard(_data);
    } else {
      _res = await CardService.addCard(_data);
      setData((pre) => {
        return { ...pre, ID: _res.data };
      });
    }
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isupdate ? "Sửa" : "Thêm"} thẻ thành công!`
      );
      if (close) onClose();
      setIsUpdate(true);
      onAfterSubmit();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  // const handlePrint = useReactToPrint({
  //   content: () => refPrint.current,
  // });
  const onBeforeUpload = (v) => {
    setFiles(v.files);
  };
  return (
    <Drawer
      title="THÊM THẺ"
      width={920}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          {/* <Button onClick={()=>onSubmit()}>Lưu</Button> */}
          <Button onClick={() => onSubmit()} type="primary" ghost>
            Lưu
          </Button>
          <Button onClick={() => onSubmit(true)} type="primary">
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <p style={{ margin: "15px 0px", fontSize: 14, fontWeight: "bold" }}>
        Thông tin thẻ
      </p>
      <Row gutter={[24, 8]}>
        <Col span={8}>
          <p>
            Mã vạch <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            status={data?.MaVach ? "" : "error"}
            value={data?.MaVach}
            placeholder="Mã vạch"
            onChange={(e) => applyChange("MaVach", e.target.value)}
          />
          <p style={{ color: "red" }}>{errors?.MaVach}</p>
        </Col>
        <Col span={8}>
          <p>Số thẻ</p>
          <Input
            value={data?.SoThe}
            placeholder="Số thẻ"
            onChange={(e) => applyChange("SoThe", e.target.value)}
          />
        </Col>
        <Col span={8}>
          <p>
            Loại thẻ <span style={{ color: "red" }}>*</span>
          </p>
          <Select
            style={{
              width: "100%",
            }}
            status={data?.MaLT ? "" : "error"}
            value={data?.MaLT}
            placeholder="Chọn loại thẻ"
            onChange={(v) => applyChange("MaLT", v)}
            options={cardType}
            fieldNames={{ label: "TenLT", value: "ID" }}
          />
          <p style={{ color: "red" }}>{errors?.MaLT}</p>
        </Col>
        <Col span={8}>
          <p>Ngày cấp</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            // value={data?.NgayPH}
            value={dayjs(data?.NgayPH)}
            placeholder="Ngày cấp"
            // locale={vi}
            onChange={(v) => applyChange("NgayPH", v)}
          />
        </Col>
        <Col span={8}>
          <p>Ngày hiệu lực</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            // defaultValue={dayjs(filterCondition.TuNgay)}
            value={dayjs(data?.NgayHL)}
            placeholder="Ngày hiệu lực"
            locale={vi}
            onChange={(v) => applyChange("NgayHL", v)}
          />
        </Col>
        <Col span={8}>
          <p>Ngày hết hạn</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            value={dayjs(data?.NgayHH)}
            placeholder="Ngày hết hạn"
            locale={vi}
            onChange={(v) => applyChange("NgayHH", v)}
          />
        </Col>
        <Col span={16}>
          <p>Ghi chú</p>
          <Input
            value={data?.DienGiai}
            placeholder="Ghi chú"
            onChange={(e) => applyChange("DienGiai", e.target.value)}
          />
        </Col>
        <Col span={8}>
          <p>Trạng thái</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaTT}
            placeholder="Chọn trạng thái"
            onChange={(v) => applyChange("MaTT", v)}
            options={status}
            fieldNames={{ label: "TenTT", value: "ID" }}
          />
        </Col>
      </Row>
      <p style={{ margin: "15px 0px", fontSize: 14, fontWeight: "bold" }}>
        Thông tin khách hàng
      </p>
      <Row gutter={[24, 8]}>
        {isupdate === false && (
          <Col span={16} className="gutter-row">
            <p>Khách hàng</p>
            <DebounceSelect
              mode="multiple"
              value={data?.MaKH}
              placeholder="Tìm kiểm khách hàng"
              fetchOptions={fetchUserList}
              onChange={(newValue) => applyChange("MaKH", newValue)}
              style={{
                width: "100%",
              }}
            />
          </Col>
        )}
        <Col span={8}>
          <p>
            Họ tên <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            value={data?.TenCongTy}
            status={data?.TenCongTy ? "" : "error"}
            placeholder="Nhập tên khách hàng"
            onChange={(e) => applyChange("TenCongTy", e.target.value)}
            disabled={data?.MaKH?.length > 0}
          />
          <p style={{ color: "red" }}>{errors?.TenCongTy}</p>
        </Col>
        <Col span={8}>
          <p>Ngày sinh</p>
          <DatePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "100%" }}
            // defaultValue={dayjs(filterCondition.TuNgay)}
            value={data?.NgaySinh ? dayjs(data?.NgaySinh) : null}
            placeholder="Ngày sinh"
            // locale={vi}
            onChange={(v) => applyChange("NgaySinh", v)}
            disabled={data?.MaKH?.length > 0}
          />
        </Col>

        <Col span={8}>
          <p>Giới tính</p>
          <Flex justify="space-between">
            <div>
              <Checkbox
                checked={data?.GioiTinh === true}
                onChange={() => applyChange("GioiTinh", true)}
                disabled={data?.MaKH?.length > 0}
              >
                Nam
              </Checkbox>
              <Checkbox
                checked={data?.GioiTinh === false}
                onChange={() => applyChange("GioiTinh", false)}
                disabled={data?.MaKH?.length > 0}
              >
                Nữ
              </Checkbox>
            </div>
            <Checkbox
              checked={data?.IsAgent === true}
              onChange={() => applyChange("IsAgent", !data?.IsAgent)}
            >
              AGENT
            </Checkbox>
          </Flex>
        </Col>
        <Col span={8}>
          <p>Email</p>
          <Input
            value={data?.Email}
            placeholder="Email"
            onChange={(e) => applyChange("Email", e.target.value)}
            disabled={data?.MaKH?.length > 0}
          />
        </Col>
        <Col span={8}>
          <p>
            Di động <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            value={data?.DiDong}
            placeholder="Di động"
            status={data?.DiDong ? "" : "error"}
            onChange={(e) => applyChange("DiDong", e.target.value)}
            disabled={data?.MaKH?.length > 0}
          />
          <p style={{ color: "red" }}>{errors?.DiDong}</p>
        </Col>

        <Col span={8}>
          <p>Nhóm khách hàng</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaNKH}
            placeholder="Chọn nhóm khách hàng"
            onChange={(v) => applyChange("MaNKH", v)}
            options={customerGroup}
            fieldNames={{ label: "Name", value: "ID" }}
            disabled={data?.MaKH?.length > 0}
          />
        </Col>
        <Col span={8}>
          <p>Số CMND</p>
          <Input
            value={data?.SoCMND}
            placeholder="Nhập số CMND"
            onChange={(e) => applyChange("SoCMND", e.target.value)}
            disabled={data?.MaKH?.length > 0}
          />
        </Col>
        <Col span={8}>
          <p>Địa chỉ</p>
          <Input
            value={data?.DiaChiCT}
            placeholder="Địa chỉ"
            onChange={(e) => applyChange("DiaChiCT", e.target.value)}
            disabled={data?.MaKH?.length > 0}
          />
        </Col>
        <Col span={8}>
          <p>Nguồn khách hàng</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaNguon}
            placeholder="Chọn nguồn khách hàng"
            onChange={(v) => applyChange("MaNguon", v)}
            options={customerSource}
            fieldNames={{ label: "TenNguon", value: "MaNguon" }}
            disabled={data?.MaKH?.length > 0}
          />
        </Col>
        <Col span={8} className="gutter-row">
          <p>Agent</p>
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.TenCongTy?.toLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            style={{
              width: "100%",
            }}
            value={data?.AgentID}
            placeholder="Chọn agent"
            onChange={(v) => applyChange("AgentID", v)}
            options={agents}
            fieldNames={{ label: "TenCongTy", value: "MaKH" }}
          />
          {/* <DebounceSelect
            // mode="multiple"

            value={data?.Agent}
            placeholder="Tìm kiếm"
            fetchOptions={fetchUserList}
            onChange={(newValue) => applyChange("Agent", newValue)}
            style={{
              width: "100%",
            }}
          /> */}
        </Col>
        <Col span={6}>
          {data.HinhAnh2 && (
            <Image src={data.HinhAnh2} />
            // <img alt="example" style={{ width: "100%" }} src={data.HinhAnh2} />
          )}
          <FileUpload
            mode="basic"
            accept="image/*"
            maxFileSize={1000000}
            onSelect={(e) => onBeforeUpload(e)}
            chooseLabel="Tải ảnh"
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
