import React, { useState, useEffect, createContext } from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

const SignalrContext = createContext();
export const SignalrContextProvider = ({ children, user }) => {
  const [hubConnection, setHubConnection] = useState(null);
  useEffect(() => {
    try {
      (async () => {
        let tenctdkvt = localStorage.getItem("MaCTDK");
        console.log(tenctdkvt);

        let connection = new HubConnectionBuilder()
          .withUrl("https://api-poker.beesky.vn/other")
          .withAutomaticReconnect()
          .configureLogging(LogLevel.Information)
          .build();
        console.log(connection);

        connection.onreconnected(async (connectionId) => {
          await connection.invoke("JoinGroup", tenctdkvt.toLowerCase());
        });
        await connection.start();
        connection.invoke("JoinGroup", tenctdkvt.toLowerCase());

        setHubConnection(connection);
        // return () => connection.off();
      })();
    } catch {}
    return () => {
      hubConnection && hubConnection.off();
    };
  }, []);
  return (
    <SignalrContext.Provider value={{ hubConnection }}>
      {children}
    </SignalrContext.Provider>
  );
};
export default SignalrContext;
