import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  InputNumber,
  Select,
  Flex,
  Checkbox,
  Tooltip,
  Popconfirm,
} from "antd";
import _, { set } from "lodash";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CiTrash } from "react-icons/ci";
import { GiaiDauService } from "services/GiaiDauService";
import FormImport from "./FormImport";
import { MdOutlineRemoveRoad } from "react-icons/md";
const FormEntry = (props, ref) => {
  // const { onAfterSubmit } = props;
  const refImport = useRef();
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [idEntry, setIdEntry] = useState(0);
  const loadData = async (idEntry) => {
    let _res = await GiaiDauService.TyLeGiaiThuong.get({ idEntry: idEntry });
    setData(_res.data);
  };
  const [tongTyLeGiai, setTongTyLeGiai] = useState(0);
  useImperativeHandle(ref, () => ({
    show: async (data) => {
      setOpen(true);
      loadData(data?.ID);
      setIdEntry(data?.ID);
      setTongTyLeGiai(data?.TongTyLeGiai);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _res = await GiaiDauService.TyLeGiaiThuong.add({
      List: data,
      idEntry: idEntry,
      TongTyLeGiai: tongTyLeGiai,
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Lưu thành công!`);
      loadData(idEntry);
      // if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const renderCoCauGiai = () => {
    const addCoCau = () => {
      let _data = _.cloneDeep(data);
      _data.push({
        TenGiai: "",
        SoLuongGiai: 0,
        TyLeGiai: 0,
      });
      setData(_data);
    };
    const onChangeCoCau = (p, v, index) => {
      let _data = _.cloneDeep(data);
      let item = _data.slice(index, index + 1);
      if (item?.length > 0) {
        _data.splice(index, 1, {
          ...item[0],
          [p]: v,
        });
      }
      setData(_data);
    };
    const deleteCoCau = (v, index) => {
      let _data = _.cloneDeep(data);
      _data.splice(index, 1);
      setData(_data);
      if (v.ID) {
        GiaiDauService.TyLeGiaiThuong.delete({ ID: v.ID });
      }
    };
    const deleteCoCauAll = () => {
      setData([]);
      GiaiDauService.TyLeGiaiThuong.deleteByKhoangEntry({ ID: idEntry });
    };

    return (
      <>
        <Flex gap={10} justify="space-between" style={{ marginBottom: 10 }}>
          <Button
            loading={loading}
            onClick={() => addCoCau()}
            type="primary"
            ghost
          >
            Thêm
          </Button>
          {/* <Flex gap={15} align="center">
            <p style={{ margin: 0, padding: 0 }}>Tổng tỷ lệ giải:</p>
            <InputNumber
              style={{ width: 100 }}
              max={100}
              // status={data?.PhanTramTraoGiai > 0 ? "" : "error"}
              value={tongTyLeGiai}
              onChange={(v) => setTongTyLeGiai(v)}
            />
          </Flex> */}
        </Flex>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={data}
          // tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            header="Tên giải"
            body={(item, row) => {
              return (
                <Input
                  value={item?.TenGiai}
                  placeholder="Nhập tên giải"
                  onChange={(e) =>
                    onChangeCoCau("TenGiai", e.target.value, row.rowIndex)
                  }
                />
              );
            }}
          />
          <Column
            header="Số lượng giải"
            body={(item, row) => {
              return (
                <InputNumber
                  value={item?.SoLuongGiai}
                  onChange={(v) =>
                    onChangeCoCau("SoLuongGiai", v, row.rowIndex)
                  }
                />
              );
            }}
          />
          <Column
            header="Tỷ lệ giải"
            body={(item, row) => {
              return (
                <InputNumber
                  value={item?.TyLeGiai}
                  onChange={(v) => onChangeCoCau("TyLeGiai", v, row.rowIndex)}
                />
              );
            }}
          />
          <Column
            header={
              <Popconfirm
                title="Xoá tất cả"
                description="Bạn có chắc chắn muốn xoá tất cả?"
                okText="Đồng ý"
                cancelText="Không"
                onConfirm={() => deleteCoCauAll()}
              >
                <Tooltip title="Xóa tất cả">
                  <Button
                    danger
                    ghost
                    icon={<MdOutlineRemoveRoad />}
                  />
                </Tooltip>
              </Popconfirm>
            }
            body={(item, row) => {
              return (
                <Button
                  onClick={() => deleteCoCau(item, row.rowIndex)}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              );
            }}
          />
        </DataTable>
        <Flex justify="space-between" align="center" style={{ width: 470 }}>
          <p style={{ fontWeight: "bold" }}>Tổng tỷ lệ</p>
          <p style={{ fontWeight: "bold" }}>
            {_.sumBy(data, (v) => v.TyLeGiai * v.SoLuongGiai)} %
          </p>
        </Flex>
      </>
    );
  };
  const onAfterImport = () => {
    loadData(idEntry);
  };
  return (
    <Drawer
      title="Tỷ lệ giải"
      width={620}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button onClick={() => refImport.current.show(idEntry)}>
            Import
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu
          </Button>
        </Space>
      }
    >
      {contextHolder}
      {renderCoCauGiai()}
      <FormImport ref={refImport} onAfterSubmit={onAfterImport} />
    </Drawer>
  );
};
export default forwardRef(FormEntry);
