import { Col, Flex } from "antd";
import React from "react";
import { useDrop } from "react-dnd";

const DropZone = ({ onDrop, children,name }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "BOX",
    drop: (item) => {
      // onDrop(item.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <Col span={12} ref={drop}>
      <p style={{ textAlign: "center", fontWeight: "bold" }}>{name}</p>
      <div
        style={{
          background: isOver ? "lightgreen" : "white",
          padding: "8px",
          border: "1px solid #d9d9d9",
          minHeight: "100px",
          marginBottom: "8px",
          borderRadius: "20px",
        }}
      >
        {children}
      </div>
    </Col>
  );
};

export default DropZone;
