import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, Image, Input, Space, Menu } from "antd";
import React, { useState } from "react";
import type { MenuProps } from "antd";
import "./index.scss";
import { OrderService } from "services/OrdersService";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const LoginOrder = ({ setActiveTab, congTy, setKhachHang, setPoint }) => {
  let params = useLocation();
  const queryString = params.search;
  const params2 = new URLSearchParams(queryString);
  const maCT = params2.get("mact");
  const getSDTPlayer = localStorage.getItem("pokerSDTplayer");
  const [sDTPlayer, setSDTPlayer] = useState(getSDTPlayer ? getSDTPlayer : "");
  const [error, setError] = useState("");

  

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    console.log("Click on menu item:", e);
  };
  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^(0[3|5|7|8|9])+([0-9]{8})$/;
    return phoneRegex.test(phoneNumber);
  };
  const handleNextPage = async () => {
    if (!validatePhoneNumber(sDTPlayer)) {
      setError("Số điện thoại không hợp lệ!");
    } else {
      let _res8 = await OrderService.getKhachHang({
        DiDong: sDTPlayer,
        TenCTDK: maCT,
      });
      if (_res8.status === 2000) {
        setKhachHang(_res8.data);
        let _res10 = await OrderService.getDiemTich({
          MaThe: _res8.data?.MaThe,
          MaLT: _res8.data?.MaLT,
          TenCTDK: maCT,
        });
        if (_res10.status === 2000) {
          setPoint(_res10.data);
        }
      }
      setError("");
      localStorage.setItem("pokerSDTplayer", sDTPlayer);
      setActiveTab("1");
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "VIETNAM",
      key: "1",
      icon: (
        <Image
          style={{ paddingRight: "10px" }}
          preview={false}
          src="https://o2o.ipos.vn/static/images/flag/icon_flag_vie.svg"
        />
      ),
    },
    {
      label: "ENGLISH",
      key: "2",
      icon: (
        <Image
          style={{ paddingRight: "10px" }}
          preview={false}
          src="https://o2o.ipos.vn/static/images/flag/icon_flag_eng.svg"
        />
      ),
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div className="container-login-order">
      <div style={{ textAlign: "center" }}>
        <Image
          preview={false}
          src="https://o2o.ipos.vn/static/images/icon_staff.svg"
        />
      </div>
      <h3 className="title-h3">{congTy?.TenCT}. Xin Kính chào bạn</h3>
      <p className="title-p">
        Mời bạn nhập số điện thoại để nhà hàng phục vụ bạn nhanh chóng hơn,
        chính xác hơn
      </p>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Input
          value={sDTPlayer}
          onChange={(e) => setSDTPlayer(e.target.value)}
          className="input-order"
          placeholder="Nhập số điện thoại của bạn"
          style={{ textAlign: "center",  fontSize: "16px"}}
        />
        {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
        <Button onClick={() => handleNextPage()} className="button-order">
          Bắt đầu
        </Button>
        <div>
          <Dropdown menu={menuProps}>
            <Button style={{ marginLeft: "10px" }}>
              <Space>
                <Image
                  preview={false}
                  src="https://o2o.ipos.vn/static/images/flag/icon_flag_vie.svg"
                />{" "}
                VIET NAM
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default LoginOrder;
