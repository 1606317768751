import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Button,
  Carousel,
  Col,
  Drawer,
  Dropdown,
  Flex,
  Image,
  Input,
  Row,
  Space,
} from "antd";
import type { MenuProps } from "antd";
import {
  EnvironmentOutlined,
  UserOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { OrderService } from "services/OrdersService";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Format_Currency } from "layouts/ultis/common";
const HomeOrder = ({
  setActiveTab,
  setLayout,
  showDrawerDG,
  congTy,
  table,
  point,
  khachHang,
}) => {
  const [visible, setVisible] = useState(false);

  const SDTPlayer = localStorage.getItem("pokerSDTplayer");

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const handleNextPage = (value) => {
    setActiveTab(value);
  };
  const getGreeting = () => {
    const currentHour = new Date().getHours(); 
    
  
    if (currentHour < 12) {
      return "Chào buổi sáng";
    } else if (currentHour < 14) {
      return "Chào buổi trưa";
    } else if (currentHour < 18) {
      return "Chào buổi chiều";
    } else {
      return "Chào buổi tối";
    }
  };
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    console.log("Click on menu item:", e);
  };
  const items: MenuProps["items"] = [
    {
      label: "VIETNAM",
      key: "1",
      icon: (
        <Image
          style={{ paddingRight: "10px" }}
          preview={false}
          src="https://o2o.ipos.vn/static/images/flag/icon_flag_vie.svg"
        />
      ),
    },
    {
      label: "ENGLISH",
      key: "2",
      icon: (
        <Image
          style={{ paddingRight: "10px" }}
          preview={false}
          src="https://o2o.ipos.vn/static/images/flag/icon_flag_eng.svg"
        />
      ),
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  const arrImg = [
    "https://image.foodbook.vn/upload/20230711/1689042890359_blob.jpeg",
    "https://image.foodbook.vn/upload/20230711/1689042949593_blob.jpeg",
    "https://image.foodbook.vn/upload/20230711/1689042685656_blob.jpeg",
  ];
  return (
    <div className="container-home-order">
      <>
        <Drawer
          title="Gọi nhân viên"
          placement="bottom"
          closable={false}
          onClose={onClose}
          open={visible}
          key="bottom"
          height="300px"
        >
          <div style={{ marginTop: "-20px" }}>
            {/* <p style={{ paddingBottom: "20px" }}>
              Bạn muốn yêu cầu nhân viên làm gì?
            </p>
            <Input
              className="input-order"
              placeholder=""
              prefix={
                <EditOutlined
                  style={{ color: "rgba(0,0,0,.25)", marginRight: "8px" }}
                />
              }
              style={{
                textAlign: "center",
                marginBottom: "15px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
              }}
            />
            <Button className="button-order">Gửi yêu cầu</Button> */}
            <p style={{ textAlign: "center", marginTop: "50px" }}>
              Chức năng đang được cập nhật, vui lòng thử lại sau!
            </p>
          </div>
        </Drawer>
      </>

      <Row>
        <Col span={16}>
          <h5 style={{ fontWeight: "bold", fontSize: "17px" }}>
            {congTy?.TenCT}
          </h5>
          {/* <Flex align="flex-start"> */}

          <p className="title-home-order-p">
            {" "}
            <EnvironmentOutlined style={{ marginRight: "5px" }} />
            {congTy?.DiaChi}
          </p>
          {/* </Flex> */}
        </Col>
        <Col style={{ textAlign: "right" }} span={8}>
          <Dropdown menu={menuProps} placement="bottomRight">
            <Button>
              <Space>
                <Image
                  preview={false}
                  src="https://o2o.ipos.vn/static/images/flag/icon_flag_vie.svg"
                />{" "}
                VIE
              </Space>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Carousel autoplay arrows>
            {arrImg?.map((src, index) => (
              <div key={index}>
                <Image
                  preview={false}
                  src={src}
                  alt={`Image ${index + 1}`}
                  layout="responsive"
                  width={"100%"}
                  height={"auto"}
                  style={{
                    borderRadius: "10px",
                    objectFit: "cover",
                    backgroundColor: "#f4f7fe",
                  }}
                />
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: "center", marginTop: "20px" }}>
          <Flex justify="center" align="center">
            <Image
              style={{ marginRight: "5px" }}
              preview={false}
              src=" https://o2o.ipos.vn/static/images/home_restaurant/sun.svg"
            />
            <p style={{ fontWeight: "bold", fontSize: "16px" }}>
            {getGreeting()}
              <sapn style={{ color: "#3e74ff",paddingLeft:'5px' }}>
                {khachHang?.TenCongTy ? khachHang?.TenCongTy : SDTPlayer}
              </sapn>
            </p>
            <Image
              style={{ marginLeft: "5px" }}
              preview={false}
              src="https://o2o.ipos.vn/static/images/home_restaurant/edit_pencil.svg"
            />
          </Flex>

          <p
            style={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#363636",
              lineHeight: "23px",
            }}
          >
            Chúng tôi sẽ trả đồ cho bạn tại: {table?.Name}
          </p>
        </Col>
        <Col
          className="container-bonus"
          style={{ marginTop: "20px" }}
          span={24}
        >
          <Flex justify="space-between">
            <div>
              <p className="bonus-points">
                Bạn có {Format_Currency(point?.tongDiem)} điểm
              </p>
              <p
                style={{ fontSize: "12px", marginTop: "15px" }}
                className="bonus-points2"
              >
                Xem ưu đãi giành cho bạn
              </p>
            </div>
            <Image
              width={"80px"}
              height={"auto"}
              preview={false}
              src="https://o2o.ipos.vn/static/images/icon_star_home.png"
            />
          </Flex>
        </Col>
      </Row>
      <Row
        style={{ marginTop: "15px" }}
        gutter={[20, 20]}
        justify="center"
        align="middle"
      >
        <Col span={8} style={{ textAlign: "center" }}>
          <div
            onClick={() => {
              setLayout("history");
              setActiveTab("2");
            }}
            className="box-icons"
          >
            <Image
              preview={false}
              src="https://o2o.ipos.vn/static/images/icon_order_history_v2.svg"
            />
            <p>Lịch sử đơn</p>
          </div>
        </Col>
        <Col span={8} style={{ textAlign: "center" }}>
          <div onClick={showDrawer} className="box-icons">
            <Image
              preview={false}
              src="https://o2o.ipos.vn/static/images/request_service.svg"
            />
            <p>Gọi nhân viên</p>
          </div>
        </Col>
        <Col span={8} style={{ textAlign: "center" }}>
          <div onClick={showDrawerDG} className="box-icons">
            <Image
              preview={false}
              src="https://o2o.ipos.vn/static/images/rate.svg"
            />
            <p>Đánh giá</p>
          </div>
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <div
            onClick={() => {
              setLayout("home");
              setActiveTab("2");
            }}
            className="box-icons2"
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Image
              style={{ paddingLeft: "5px" }}
              preview={false}
              src="https://o2o.ipos.vn/static/images/menu.svg"
            />
            <p style={{ marginLeft: 20, fontSize: "16px", color: "#fff" }}>
              Xem Menu - Gọi món
            </p>
          </div>
        </Col>
      </Row>
      <div style={{ textAlign: "center", marginTop: "20px", color: "#333" }}>
        <h5>
          Powered by <span>BEESKY.vn</span>
        </h5>
      </div>
    </div>
  );
};

export default HomeOrder;
