import { Col, Flex, QRCode, Row } from "antd";
import { Format_Currency } from "layouts/ultis/common";
import * as React from "react";

const BuyinToPrint = (props, ref) => {
  const { data, company, soTT } = props;
  const getDate = () => {
    return (
      <p style={{ textAlign: "center", fontWeight: "400",fontSize:12,marginBottom:15}}>
        Ngày {new Date().getDate()} tháng {new Date().getMonth() + 1} năm{" "}
        {new Date().getFullYear()} | {new Date().getHours()}:
        {new Date().getMinutes()}
      </p>
    );
  };
  function formatDateTime(date) {
    // Lấy giờ và phút
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0"); // Đảm bảo phút có 2 chữ số

    // Lấy ngày, tháng và năm
    const day = date.getDate().toString().padStart(2, "0"); // Đảm bảo ngày có 2 chữ số
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Tháng 0-11 nên cần +1
    const year = date.getFullYear();

    // Tạo chuỗi định dạng mong muốn
    return `${hours}h${minutes} ${day}/${month}/${year}`;
  }
  const renderContent = () => {
    return (
      <div style={{ height: "98vh", padding: "10px 0px" }}>
        <Flex align="center" gap={20}>
          {localStorage.getItem("MaCTDK") === "royal" ? (
            <>
              {company.Logo && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    width="100%"
                    style={{ textAlign: "center" }}
                    src={company.Logo}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              {company.Logo && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // marginBottom: 15,
                  }}
                >
                  <img
                    width={80}
                    height={80}
                    style={{ textAlign: "center" }}
                    src={company.Logo}
                  />
                </div>
              )}
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {company?.TenCT}
              </p>
            </>
          )}
        </Flex>
        <p
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {data?.TenGD?.toUpperCase()}
        </p>
        {getDate()}
        {data?.Type !== "ADDON" && (
          <Flex
            justify="space-around"
            style={{
              borderWidth: 1,
              padding: 10,
              textAlign: "center",
              borderColor: "black",
            }}
          >
            <div>
              <p style={{ fontWeight: "bold" }}>TABLE</p>
              <p style={{ fontWeight: "bold", fontSize: 45, height: 55 }}>
                {data?.TenBan ?? " "}
              </p>
            </div>
            <div>
              <p style={{ fontWeight: "bold" }}>SEAT</p>
              <p style={{ fontWeight: "bold", fontSize: 45, height: 55 }}>
                {data?.TenSeat ?? " "}
              </p>
            </div>
          </Flex>
        )}

        <Row>
          <Col span={6}>
            <p style={{ fontWeight: "bold", fontSize: 18 }}>Name</p>
          </Col>
          <Col span={18}>
            <p style={{ fontWeight: "bold", fontSize: 18 }}>
              {data?.TenCongTy}
            </p>
          </Col>
          <Col span={6}>Tel</Col>
          <Col span={18}>{data?.DiDong}</Col>

          <Col span={6}>CardNo</Col>
          <Col span={18}>
            <p>{data?.SoThe}</p>
          </Col>

          <Col span={6}>Payment</Col>
          <Col span={18}>
            <p>{data?.TenHT}</p>
          </Col>

          <Col span={6}>
            <p style={{ fontSize: 18 }}>{data?.Type}</p>
          </Col>
          <Col span={18}>
            <p style={{ fontSize: 18 }}>{Format_Currency(data?.TongTien)}</p>
          </Col>
        </Row>
        <p
          style={{ textAlign: "center", fontStyle: "italic", fontSize: "10px" }}
        >
          Tôi tự nguyện tài trợ và đóng góp thuê dụng cụ tập luyện cho giải tập
          huấn do {company.TenCT} và tuân theo các quy định của đơn vị tổ chức.
          Phiếu thuê không có giá trị quy đổi thành tiền mặt.
        </p>
        <p style={{ fontSize: "10px", textAlign: "center" }}>{soTT}</p>
      </div>
    );
  };
  const renderQRCode = () => {
    return (
      <div style={{ height: "98vh", padding: "10px 0px" }}>
        <p
          style={{ fontSize: "16px", fontWeight: "bold", textAlign: "center" }}
        >
          {data?.TenGD?.toUpperCase()}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p>Date</p>
          <p>{formatDateTime(new Date(data?.ThoiGianBD))}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p>Table {data?.TenBan}</p>
          <p>Seat {data?.TenSeat}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <QRCode value={localStorage.getItem("MaCTDK") + "_" + data?.MaKHMT} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p>Player</p>
          <p>{data?.TenCongTy}</p>
        </div>
      </div>
    );
  };
  return (
    <div ref={ref} style={{ margin: "0px 5px 5px" }}>
      <div className="page-break" />
      {renderContent()}
      {data?.soLien === 2 && (
        <>
          <div className="page-break" />
          {renderContent()}
        </>
      )}

      {/* <div className="page-break" />
      {renderQRCode()} */}
    </div>
  );
};
export default React.forwardRef(BuyinToPrint);
