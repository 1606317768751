import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Popconfirm, notification, Input, Flex } from "antd";
import { OrderService } from "services/OrdersService";
import { BuyinService } from "services/BuyinService";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import FormAdd from "./FormAdd";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { CardService } from "services/CardService";
import { Format_Datetime } from "layouts/ultis/common";
import { Format_Date } from "layouts/ultis/common";
import { MdEdit } from "react-icons/md";
import Toolbar from "components/Toolbar";
import FormImport from "./FormImport";

export default function Settings() {
  const [data, setData] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(new Date().setDate(new Date().getDate() - 5)),
    DenNgay: new Date(),
    ListMaCT: ",,",
    InputSearch:"",
  });
  useEffect(() => {
    loadCategories();
    loadBuyin(filterCondition);
  }, []);

  const loadBuyin = async (_filter) => {
    setLoading(true);
    let _res = await CardService.getCard(_filter);
    setData(_res.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    let _resCT = await BuyinService.getCompany();
    setCompanies(_resCT.data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const columns = [
    {
      title: "Mã vạch",
      dataIndex: "MaVach",
    },
    {
      title: "Loại thẻ",
      dataIndex: "TenLT",
    },
    {
      title: "Trạng thái",
      dataIndex: "TenTT",
    },
    {
      title: "Ngày phát hàng",
      dataIndex: "NgayPH",
      render: (item) => Format_Date(item),
    },
    {
      title: "Ngày hiệu lực",
      dataIndex: "NgayHL",
      render: (item) => Format_Date(item),
    },
    {
      title: "Ngày hết hạn",
      dataIndex: "NgayHH",
      render: (item) => Format_Date(item),
    },
    {
      title: "Điểm tích luỹ",
      dataIndex: "DiemThe",
    },
    {
      title: "Tổng tiền",
      dataIndex: "TienThe",
      render: (item) => Format_Currency(item),
    },
    {
      title: "Tên khách hàng",
      dataIndex: "TenCongTy",
      width: "200px",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Ngày sinh",
      dataIndex: "NgaySinh",
      render: (item) => Format_Date(item),
    },
    {
      title: "CMND",
      dataIndex: "SoCMND",
    },
    {
      title: "Di động",
      dataIndex: "DiDong",
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChiCT",
    },
    {
      title: "Người nhập",
      dataIndex: "NguoiNhap",
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      render: (item) => Format_Date(item),
    },
    {
      title: "",
      fixed: "right",
      width: "100px",
      render: (item) => {
        return (
          <div>
            <Popconfirm
              title="Xoá bàn"
              description="Bạn có chắc chắn muốn xoá thẻ hay không?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Button danger ghost icon={<CiTrash />} />
            </Popconfirm>
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              icon={<MdEdit />}
              onClick={() => refDetail.current.update(item)}
            />
          </div>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = await CardService.deleteCard({
      ID: value,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thẻ thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const refDetail = useRef(null);
  const refImport = useRef(null);
  const onAfterSubmit = () => {
    loadBuyin(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    loadBuyin(_filter);
    setFilterCondition(_filter);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        loadBuyin(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  return (
    <Box>
      <Toolbar
        left={() => (
          <Input
            style={{ width: "300px", padding: "4px 11px", borderRadius: 10 }}
            placeholder="Tìm kiếm tên, điện thoại, số hoá đơn"
            onChange={(e) => onSearch(e.target.value)}
          />
        )}
        right={() => (
          <Flex gap={5}>
            <Button type="primary" onClick={() => refDetail.current.show()}>
              Thêm
            </Button>
            <Button type="primary" onClick={() => refImport.current.show()}>
              Import
            </Button>
            <FilterModal
              companies={companies}
              filterCondition={filterCondition}
              onChangeFilter={onChangeFilter}
            />
          </Flex>
        )}
      />
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 260,
          x: 2500,
        }}
        size="small"
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 50,
          pageSizeOptions: ["20", "50", "500", "1000", "5000"],
          position: ["bottomRight"],
        }}
      />
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      <FormImport ref={refImport} />
      {contextHolder}
    </Box>
  );
}
