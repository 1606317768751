import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
  useEffect,
} from "react";
import {
  Button,
  Drawer,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Space,
  Table,
  notification,
} from "antd";
import _ from "lodash";
import { TourService } from "services/TourService";
import { CategoryService } from "services/CategoryService";
const FormAdd = (props, ref) => {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [maBan, setMaBan] = useState(0);
  const [openAdd, setOpenAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataSet, setDataSet] = useState({});
  const filter = async (maBan) => {
    let _res = await CategoryService.Seat.get({ MaBan: maBan });
    setData(_res.data);
  };
  useImperativeHandle(ref, () => ({
    show: async (data) => {
      setOpen(true);
      filter(data.ID);
      setMaBan(data.ID);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    setDataSet({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    let _res = null;
    _res = await TourService.updateGiaiDau(_data);
    setData((pre) => {
      return { ...pre, ID: _res.data };
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", ` giải đấu thành công!`);
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "STT",
    },
    {
      title: "Tên seat",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "",
      render: (item) => {
        return (
          <div>
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              ghost
              onClick={() => {
                setDataSet(item);
                setOpenAdd(true);
              }}
            >
              Sửa
            </Button>
            <Popconfirm
              title="Khoá seat"
              description="Bạn có chắc chắn muốn khoá seat?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => handleLock(item)}
            >
              <Button style={{ marginLeft: 10 }} type="primary" ghost>
                {item.IsLock ? "Mở khoá" : "Khoá"}
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Xoá seat"
              description="Bạn có chắc chắn muốn xoá seat?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Button style={{ marginLeft: 10 }} danger ghost>
                Xoá
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const remove = async (id) => {
    let _res = await CategoryService.Seat.delete({ ID: id });
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Xoá seat thành công!`);
      let _data = _.cloneDeep(data);
      let _index = _data.findIndex((x) => x.ID === id);
      _data.splice(_index, 1);
      setData(_data);
    }
  };
  const handleLock = async (item) => {
    let _res = await CategoryService.Seat.add({
      ...item,
      IsLock: !item.IsLock,
    });
    if (_res.status === 2000) {
      openNotification("success", "topRight", `Khoá seat thành công!`);
      filter(maBan);
      //  let _data = _.cloneDeep(data);
      //  let _index = _data.findIndex((x) => x.ID === id);
      //  _data.splice(_index, 1);
      //  setData(_data);
    }
  };
  const renderAdd = () => {
    const handleOk = async () => {
      let _res = await CategoryService.Seat.add({
        ...dataSet,
        MaBan: maBan,
      });
      if (_res.status === 2000) {
        filter(maBan);
        openNotification("success", "topRight", `Thêm seat thành công!`);
        setOpenAdd(false);
        setDataSet({});
      }
    };
    const handleCancel = () => {
      setOpenAdd(false);
    };
    const applyChange = (p, v) => {
      let _data = _.cloneDeep(dataSet);
      _data[p] = v;
      setDataSet(_data);
    };
    return (
      <Modal
        open={openAdd}
        title="Thêm seat"
        onOk={handleOk}
        okText="Lưu"
        width={300}
        cancelText="Huỷ"
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <InputNumber
          style={{ width: "100%" }}
          placeholder="Nhập STT"
          max={20}
          value={dataSet?.STT}
          onChange={(v) => applyChange("STT", v)}
          // formatter={(value) =>
          //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "")
          // }
        />
        <Input
          placeholder="Nhập tên seat"
          value={dataSet.Name}
          onChange={(e) => applyChange("Name", e.target.value)}
        />
      </Modal>
    );
  };
  const [open2,setOpen2] = useState(false);
  const renderAddNhieu = () => {
    const handleOk = async () => {
      let _res = await CategoryService.Seat.addNhieu({
        ...dataSet,
        MaBan: maBan,
      });
      if (_res.status === 2000) {
        filter(maBan);
        openNotification("success", "topRight", `Thêm seat thành công!`);
        setOpen2(false);
        setDataSet({});
      }
    };
    const handleCancel = () => {
      setOpen2(false);
    };
    const applyChange = (p, v) => {
      let _data = _.cloneDeep(dataSet);
      _data[p] = v;
      setDataSet(_data);
    };
    return (
      <Modal
        open={open2}
        title="Thêm nhiều seat"
        onOk={handleOk}
        okText="Lưu"
        width={300}
        cancelText="Huỷ"
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <InputNumber
          style={{ width: "100%" }}
          placeholder="Số lượng seat"
          max={20}
          // value={dataSet?.SoLuong}
          onChange={(v) => applyChange("SoLuong", v)}
          // formatter={(value) =>
          //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "")
          // }
        />
        <Input
          placeholder="Tiền tố"
          // value={dataSet.Name}
          onChange={(e) => applyChange("TienTo", e.target.value)}
        />
      </Modal>
    );
  };
  return (
    <Drawer
      title="SEAT"
      width={520}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button loading={loading} onClick={() => setOpenAdd(true)}>
            Thêm
          </Button>
          <Button loading={loading} onClick={() => setOpen2(true)}>
            Thêm nhiều
          </Button>
        </Space>
      }
    >
      <Table dataSource={data} columns={columns} />
      {renderAdd()}
      {renderAddNhieu()}
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
