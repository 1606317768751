import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  notification,
  Input,
  Flex,
  Row,
  Col,
} from "antd";
import { OrderService } from "services/OrdersService";
import { BuyinService } from "services/BuyinService";
import _ from "lodash";
import "dayjs/locale/vi";
// import FilterModal from "./FilterModal";
// import FormAdd from "./FormAdd";
import { Format_Currency } from "layouts/ultis/common";
import { CiTrash } from "react-icons/ci";
import { CardService } from "services/CardService";
import { Format_Datetime } from "layouts/ultis/common";
import { Format_Date } from "layouts/ultis/common";
import { MdEdit } from "react-icons/md";
import Toolbar from "components/Toolbar";
import { ReportService } from "services/ReportService";
import FilterModal from "./FilterModal";
import { AiOutlineMenuFold } from "react-icons/ai";

export default function Settings() {
  const [data, setData] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("filter") === "true"
  );
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(new Date().setDate(new Date().getDate() - 5)),
    DenNgay: new Date(),
    // ListMaCT: ",,",
    InputSearch: "",
  });
  useEffect(() => {
    loadCategories();
    loadBuyin(filterCondition);
  }, []);

  const loadBuyin = async (_filter) => {
    setLoading(true);
    let _res = await ReportService.getRPT(_filter);
    setData(_res.data);
    setLoading(false);
  };
  const loadCategories = async () => {
    let _resCT = await BuyinService.getCompany();
    setCompanies(_resCT.data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const columns = [
    {
      title: "Tên giải đấu",
      dataIndex: "TenGD",
      width: 300,
    },
    {
      title: "Thời gian BĐ",
      dataIndex: "ThoiGianBD",
      render: (item) => Format_Date(item),
      width: 150,
    },
    {
      title: "Tổng Entry",
      dataIndex: "TotalEntry",
      // render: (item) => Format_Currency(item),
      width: 150,
    },
    {
      title: "Tiền mặt",
      dataIndex: "TienMat",
      render: (item) => Format_Currency(item),
      width: 150,
    },
    {
      title: "Chuyển khoản",
      dataIndex: "ChuyenKhoan",
      render: (item) => Format_Currency(item),
      width: 150,
    },
    {
      title: "Tiền Voucher",
      dataIndex: "TienVoucher",
      render: (item) => Format_Currency(item),
      width: 150,
    },
    {
      title: "Tổng thu",
      dataIndex: "TongThu",
      render: (item) => Format_Currency(item),
      width: 150,
    },
    {
      title: "Tiền Jackpot",
      dataIndex: "TienJackpot",
      render: (item) => Format_Currency(item),
      width: 150,
    },
    {
      title: "Service charge ",
      dataIndex: "ServiceCharge",
      render: (item) => Format_Currency(item),
      width: 150,
    },
    {
      title: "Tổng tiền trao giải",
      dataIndex: "TongTienTraoGiai",
      render: (item) => Format_Currency(item),
      width: 150,
    },
    {
      title: "Tiền Highhand",
      dataIndex: "TienHighhand",
      render: (item) => Format_Currency(item),
      width: 150,
    },
    {
      title: "Lợi nhuận",
      dataIndex: "LoiNhuan",
      render: (item) => Format_Currency(item),
      width: 150,
    },
    {
      title: "Ghi chú",
      dataIndex: "GhiChu",
      width: 150,
    },
    {
      title: "Ngày nhập",
      dataIndex: "NgayNhap",
      render: (item) => Format_Date(item),
      width: 150,
    },
    // {
    //   title: "TotalEntry",
    //   dataIndex: "TotalEntry",
    //   width: "200px",
    // },
    // {
    //   title: "EntryConlai",
    //   dataIndex: "EntryConlai",
    // },
    // {
    //   title: "",
    //   fixed: "right",
    //   width: "100px",
    //   render: (item) => {
    //     return (
    //       <div>
    //         <Popconfirm
    //           title="Xoá bàn"
    //           description="Bạn có chắc chắn muốn xoá thẻ hay không?"
    //           okText="Đồng ý"
    //           cancelText="Không"
    //           onConfirm={() => remove(item.ID)}
    //         >
    //           <Button danger ghost icon={<CiTrash />} />
    //         </Popconfirm>
    //         <Button
    //           style={{ marginLeft: 10 }}
    //           type="primary"
    //           ghost
    //           icon={<MdEdit />}
    //           onClick={() => refDetail.current.update(item)}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];
  const remove = async (value) => {
    let _res = await CardService.deleteCard({
      ID: value,
    });
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá thẻ thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const refDetail = useRef(null);
  const refImport = useRef(null);
  const onAfterSubmit = () => {
    loadBuyin(filterCondition);
  };
  const onChangeFilter = (_filter) => {
    loadBuyin(_filter);
    setFilterCondition(_filter);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        loadBuyin(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  const onHandleHide = () => {
    setCollapsed(!collapsed);
    localStorage.setItem("filter", !collapsed);
  };
  return (
    <Row gutter={15}>
      {contextHolder}

      <Col span={collapsed ? 0 : 4}>
        <FilterModal
          companies={companies}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={collapsed ? 24 : 20}>
        <Toolbar
          left={() => (
            <Flex align="center" gap={10}>
              <AiOutlineMenuFold
                size={20}
                color="primary"
                className="icon-filter"
                onClick={() => onHandleHide()}
              />
              {/* <Input
                style={{
                  width: "300px",
                  padding: "4px 11px",
                  borderRadius: 10,
                }}
                placeholder="Tìm kiếm tên, điện thoại, CCCD, mã vạch"
                onChange={(e) => onSearch(e.target.value)}
              /> */}
            </Flex>
          )}
          right={() => (
            <Flex gap={5}>
              {/* <Button type="primary" onClick={() => refDetail.current.show()}>
              Thêm
            </Button>
            <Button type="primary" onClick={() => refImport.current.show()}>
              Import
            </Button> */}
              {/* <FilterModal
              companies={companies}
              filterCondition={filterCondition}
              onChangeFilter={onChangeFilter}
            /> */}
            </Flex>
          )}
        />
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 260,
            // x: 1500,
          }}
          size="small"
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 50,
            pageSizeOptions: ["20", "50", "500", "1000", "5000"],
            position: ["bottomRight"],
          }}
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    Tổng: {pageData.length}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TotalEntry),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TienMat),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.ChuyenKhoan),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TienVoucher),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TongThu),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TienJackpot),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.ServiceCharge),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TongTienTraoGiai),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.TienHighhand),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10}>
                    <p style={{ fontWeight: "bold" }}>
                      {Format_Currency(
                        pageData?.reduce(
                          (pre, current) => (pre += current.LoiNhuan),
                          0
                        )
                      )}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Col>
    </Row>
  );
}
