import React, {
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Divider,
  message,
  Image,
} from "antd";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import _ from "lodash";
import { CiTrash } from "react-icons/ci";
import { FileUpload } from "primereact/fileupload";
import { HomeService } from "services/HomeService";
import { JackpotService } from "services/JackpotService";
import { UploadService } from "services/UploadService";
import { URL_UPLOAD } from "services/UploadService";
const FormAdd = (props, ref) => {
  const [api, contextHolder] = notification.useNotification();
  const [files, setFiles] = useState([]);
  const defaultData = {
    CoCauGiai: [],
  };
  const [data, setData] = useState(defaultData);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coCau, setCoCau] = useState([]);
  const defaultErrors = {
    TenGD: null,
    PhanTramTraoGiai: null,
    TienGiaiBuyIn: null,
    SoChipBuyin: null,
    GTD: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const loadDetail = async () => {
    let _res = await JackpotService.getJackpotDetail();
    let CoCauGiai = [];
    _res?.data?.coCauGiai?.map((item)=>{
      CoCauGiai.push({
        ID: item.id,
        PhanTram: item.phanTram,
        TenGiai: item.tenGiai,
      });
    });
    setData({
      ID: _res.data?.id,
      PhanTramGiuLai: _res.data?.phanTramGiuLai,
      GTD: _res.data?.gtd,
      LogoJackpot: _res.data?.logoJackpot,
      CoCauGiai: CoCauGiai,
    });
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      loadDetail();
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData(defaultData);
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    
    if (files?.length > 0) {
      var formData = new FormData();
      formData.append("Image", files[0]);
      formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
      let _resupload = await UploadService.upload(formData);
      if (_resupload.status === 2000) {
        _data.LogoJackpot = URL_UPLOAD + _resupload.data;
      }
    }
    let _res = await JackpotService.addUpdateJackpot(_data);
    setData((pre) => {
      return { ...pre, ID: _res.data, LogoJackpot: _data.LogoJackpot };
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `Cập nhật jackpot thành công!`
      );
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onBeforeUpload = (v) => {
    setFiles(v.files);
  };

  const renderCoCauGiai = () => {
    const addCoCau = () => {
      let _data = _.cloneDeep(data);
      _data.CoCauGiai.push({
        TenGiai: "",
        PhanTram: 0,
      });
      setData(_data);
    };
    const onChangeCoCau = (p, v, index) => {
      let _data = _.cloneDeep(data);
      let item = _data.CoCauGiai.slice(index, index + 1);
      if (item?.length > 0) {
        _data.CoCauGiai.splice(index, 1, {
          ...item[0],
          [p]: v,
        });
      }
      setData(_data);
    };
    const deleteCoCau = (v, index) => {
      let _data = _.cloneDeep(data);
      _data.CoCauGiai?.splice(index, 1);
      setData(_data);
    };
    return (
      <>
        <Button
          loading={loading}
          onClick={() => addCoCau()}
          type="primary"
          ghost
          style={{ marginBottom: 10 }}
        >
          Thêm
        </Button>
        <DataTable
          size="small"
          showGridlines
          stripedRows
          value={data?.CoCauGiai}
        >
          <Column
            header="Tên giải"
            body={(item, row) => {
              return (
                <Input
                  value={item?.TenGiai}
                  placeholder="Nhập tên giải"
                  onChange={(e) =>
                    onChangeCoCau("TenGiai", e.target.value, row.rowIndex)
                  }
                />
              );
            }}
          />
          <Column
            header="Phần trăm"
            body={(item, row) => {
              return (
                <InputNumber
                  value={item?.PhanTram}
                  style={{ width: "100%" }}
                  addonAfter="%"
                  max={100}
                  placeholder="Nhập phần trăm"
                  onChange={(e) =>
                    onChangeCoCau("PhanTram", e, row.rowIndex)
                  }
                />
              );
            }}
          />
          <Column
            body={(item, row) => {
              return (
                <Button
                  onClick={() => deleteCoCau(item, row.rowIndex)}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              );
            }}
          />
        </DataTable>
      </>
    );
  };
  return (
    <Drawer
      title="Cài đặt jackpot"
      className="giai-dau-detail"
      width={720}
      onClose={onClose}
      open={open}
      styles={{
        body: {},
      }}
      extra={
        <Space>
          <Button
            loading={loading}
            onClick={() => onSubmit(false)}
            type="primary"
            ghost
          >
            Lưu
          </Button>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 12]}>
        <Divider
          orientation="left"
          style={{ margin: "25px 0px 0px", fontSize: 14 }}
        >
          Thông tin chung
        </Divider>

        <Col span={12}>
          <p>
            Phần trăm giữ lại <span style={{ color: "red" }}>*</span>
          </p>
          <InputNumber
            style={{ width: "100%" }}
            addonAfter="%"
            max={100}
            value={data?.PhanTramGiuLai}
            onChange={(v) => applyChange("PhanTramGiuLai", v)}
          />
          <p style={{ color: "red" }}>{errors?.PhanTramGiuLai}</p>
        </Col>

        <Col span={12}>
          <p>
            GTD <span style={{ color: "red" }}>*</span>
          </p>
          <InputNumber
            style={{ width: "100%" }}
            addonAfter="vnđ"
            value={data?.GTD}
            onChange={(v) => applyChange("GTD", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
          <p style={{ color: "red" }}>{errors?.GTD}</p>
        </Col>
        <Col span={12}>
          {data.LogoJackpot && (
            <Image src={data.LogoJackpot} style={{ width: "100%" }} />
          )}
          <FileUpload
            mode="basic"
            name="demo[]"
            url="/api/upload"
            accept="image/*"
            maxFileSize={1000000}
            onSelect={(e) => onBeforeUpload(e)}
            chooseLabel="Tải background"
          />
        </Col>
      </Row>
      <Divider
        orientation="left"
        style={{ margin: "25px 0px 10px", fontSize: 14 }}
      >
        Cơ cấu giải thưởng
      </Divider>
      {renderCoCauGiai()}
    </Drawer>
  );
};
export default forwardRef(FormAdd);
