import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Input,
  Select,
  InputNumber,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  Flex,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { DatePicker } from "antd/lib";
import vi from "dayjs/locale/vi";
import { JackpotService } from "services/JackpotService";
import { BuyinService } from "services/BuyinService";
const { TextArea } = Input;

const FormEditNote = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = {
    // GhiChu: null,
  };
  const [paymentType, setPaymentType] = useState([]);
  const [banks, setBanks] = useState([]);
  const [voucher, setVoucher] = useState([]);

  const [errors, setErrors] = useState(defaultErrors);
  const loadCategories = async () => {
    let _banks = await BuyinService.getBanks();
    let _vc = await BuyinService.getVoucher();

    let _pm = await BuyinService.getPaymentType();
    setVoucher(_vc?.data ?? []);

    setBanks(_banks?.data ?? []);
    setPaymentType(_pm?.data ?? []);
  };

  useImperativeHandle(ref, () => ({
    show: async (data) => {
      setOpen(true);
      loadCategories();
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const SumTongTien = (_data) => {
    _data.TongTien =
      _data.TienGiai +
      _data.TienPhi -
      (_data.TienVoucher ?? 0) -
      (_data.TruTienGiai ?? 0);
      _data.Tien = _data.TienGiai + _data.TienPhi; 
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    switch (p) {
      case "MaVoucher":
        _data[p] = v;
        let _vc = voucher?.find((d) => d.ID === v);
        _data.TienVoucher = _vc.SoTien;
        _data.TruTienGiai = _vc.TruTienGiai ?? 0;
        SumTongTien(_data);
        break;
      case "TienGiai":
      case "TienPhi":
        _data[p] = v;
        SumTongTien(_data);
        break;
      default:
        _data[p] = v;
        break;
    }
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
      duration: 0.7,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async () => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   setLoading(false);
    //   return;
    // }
    let _res = await BuyinService.editHoaDon(_data);
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Sửa hoá đơn thành công!");
      onAfterSubmit();
      onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  return (
    <Drawer
      title="Sửa hoá đơn"
      width={420}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 30,
        },
      }}
      extra={
        <Space>
          <Button loading={loading} onClick={() => onSubmit()} type="primary">
            Lưu & Đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 12]}>
        <Col span={24} className="gutter-row">
          <p>
            Hình thức thanh toán <span style={{ color: "red" }}>*</span>
          </p>
          <Select
            style={{
              width: "100%",
            }}
            status={data?.MaHinhThuc ? "" : "error"}
            value={data?.MaHinhThuc}
            placeholder="Chọn hình thức"
            onChange={(v) => applyChange("MaHinhThuc", v)}
            options={paymentType}
            fieldNames={{ label: "TenHinhThuc", value: "ID" }}
          />
          <p style={{ color: "red" }}>{errors?.MaHinhThuc}</p>
        </Col>
        {data?.MaHinhThuc === 2 && (
          <Col span={24} className="gutter-row">
            <p>Ngân hàng</p>
            <Select
              style={{
                width: "100%",
              }}
              value={data?.MaNH}
              placeholder="Chọn ngân hàng"
              showSearch
              filterOption={(input, option) =>
                (option?.TenNHVT?.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              onChange={(v) => applyChange("MaNH", v)}
              options={banks}
              fieldNames={{ label: "TenNHVT", value: "ID" }}
            />
          </Col>
        )}
        <Col span={24}>
          <p>Tiền giải</p>
          <InputNumber
            style={{ width: "100%" }}
            addonAfter="vnđ"
            value={data?.TienGiai}
            onChange={(v) => applyChange("TienGiai", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
        <Col span={24}>
          <p>Tiền phí</p>
          <InputNumber
            style={{ width: "100%" }}
            value={data?.TienPhi}
            addonAfter="vnđ"
            onChange={(v) => applyChange("TienPhi", v)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
        <Col span={24} className="gutter-row">
          <p>Voucher</p>
          <Flex align="center">
            <Select
              style={{
                width: "100%",
              }}
              value={data?.MaVoucher}
              placeholder="Chọn voucher"
              onChange={(v) => applyChange("MaVoucher", v)}
              options={voucher}
              fieldNames={{ label: "LyDo", value: "ID" }}
            />
          </Flex>
        </Col>
        <Col span={24}>
          <p>Tổng tiền</p>
          <InputNumber
            style={{ width: "100%", fontSize: "100px" }}
            className="input-number-custom"
            disabled
            value={data?.Tien}
            addonAfter="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
          />
        </Col>
        <Col span={24}>
          <p>Còn lại</p>
          <InputNumber
            className="input-number-custom"
            style={{ width: "100%" }}
            disabled
            value={data?.TongTien}
            addonAfter="vnđ"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormEditNote);
