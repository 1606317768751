import axios from "axios";
import { post } from "./api_helper";
import axiosApi from "./api_helper";
export const BuyinService = {
  print: async (payload) => {
    return await axiosApi()
      .post("api/poker/print", payload)
      .then((res) => res.data);
  },
  editNote: async (payload) => {
    return await axiosApi()
      .post("api/buyin/edit-note", payload)
      .then((res) => res.data);
  },
  editHoaDon: async (payload) => {
    return await axiosApi()
      .post("api/buyin/edit-hoadon", payload)
      .then((res) => res.data);
  },
  getCompany: async () => {
    return await axiosApi()
      .get("api/poker/get-company")
      .then((res) => res.data);
  },
  checkBuyin: async (payload) => {
    return await axiosApi()
      .post("api/poker/check-buyin", payload)
      .then((res) => res.data);
  },
  getDiemTichLuyByMaGD: async (payload) => {
    return await axiosApi()
      .post("api/buyin/get-tichluy-bymagd", payload)
      .then((res) => res.data);
  },
  editBlind: async (payload) => {
    return await axiosApi()
      .post("api/giaidau/edit-blind", payload)
      .then((res) => res.data);
  },
  addOneBlind: async (payload) => {
    return await axiosApi()
      .post("api/giaidau/add-one-blind", payload)
      .then((res) => res.data);
  },

  getCompanyDetail: async () => {
    return await axiosApi()
      .get("api/poker/get-company-detail")
      .then((res) => res.data);
  },
  getBuyin: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-buyin", payload)
      .then((res) => res.data);
  },
  filterCustomer: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-customer-filter", payload)
      .then((res) => res.data);
  },
  getCardByMaKH: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-card-bymakh", payload)
      .then((res) => res.data);
  },
  getBanks: async () => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-banks")
      .then((res) => res.data);
  },
  getPaymentType: async () => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-payment-type")
      .then((res) => res.data);
  },
  getGiaiDau: async () => {
    //MaDA
    return await axiosApi()
      .post("api/poker/get-giai-dau")
      .then((res) => res.data);
  },
  getGiaiDauByDate: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/buyin/get-giaidau-bydate", payload)
      .then((res) => res.data);
  },

  addBuyin: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/add-buyin", payload)
      .then((res) => res.data);
  },
  deleteBuyin: async (payload) => {
    //MaDA
    return await axiosApi()
      .post("api/poker/delete-buyin", payload)
      .then((res) => res.data);
  },

  getVoucher: async () => {
    return await axiosApi()
      .post("api/voucher/get")
      .then((res) => res.data);
  },
  addVoucher: async (payload) => {
    return await axiosApi()
      .post("api/voucher/add", payload)
      .then((res) => res.data);
  },
  deleteVoucher: async (payload) => {
    return await axiosApi()
      .post("api/voucher/delete", payload)
      .then((res) => res.data);
  },
};
