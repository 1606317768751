// import React, { useState, useEffect, useRef } from "react";
// import { Col, Flex, Image, Input, Row } from "antd";
// import {
//   HomeOutlined,
//   MinusCircleOutlined,
//   PlusCircleOutlined,
//   SearchOutlined,
// } from "@ant-design/icons";
// import { Format_Currency } from "layouts/ultis/common";

// const RenderFood = ({
//   data,
//   setActiveTab,
//   handleSearch,
//   removeProduct,
//   increaseCount,
//   searchTerm,
//   handleDetailOrderFood,
//   carts,
// }) => {
//   const [activeSection, setActiveSection] = useState(data[0]?.key);
//   const sections = useRef([]);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         let newActiveSection = activeSection;
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             newActiveSection = entry.target.id;
//           }
//         });
//         if (newActiveSection !== activeSection) {
//           setActiveSection(newActiveSection);
//         }
//       },
//       { threshold: 0.5 }
//     );

//     sections.current.forEach((section) => {
//       if (section) {
//         observer.observe(section);
//       }
//     });

//     return () => {
//       sections.current.forEach((section) => {
//         if (section) {
//           observer.unobserve(section);
//         }
//       });
//     };
//   }, [activeSection, data]);

//   const renderChildren = () => {
//     const filteredData = data.filter(
//       (value) =>
//         value.title.toLowerCase().includes(searchTerm) ||
//         value.data.some((item) => item.tenSP.toLowerCase().includes(searchTerm))
//     );
//     return (
//       <>
//         {filteredData.map((value) => (
//           <div
//             id={value.key}
//             key={value.key}
//             ref={(el) => (sections.current[value.key] = el)}
//           >
//             <p
//               style={{
//                 fontSize: "16px",
//                 fontWeight: "500",
//                 marginBottom: "15px",
//                 marginLeft: "10px",
//               }}
//             >
//               {value.title}
//             </p>
//             {value.data?.map((item) => (
//               <div
//                 onClick={() => handleDetailOrderFood(item)}
//                 className="box-product"
//                 key={item.id}
//                 style={{
//                   boxShadow: "1px 3px 12px 1px rgba(208, 216, 243, 0.6)",
//                   marginBottom: 18,
//                   padding: "10px",
//                   borderRadius: "10px",
//                 }}
//               >
//                 <div
//                   style={{
//                     display: "flex",
//                     gap: 15,
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                   }}
//                 >
//                   <div
//                     style={{
//                       display: "flex",
//                       gap: 15,
//                       alignItems: "center",
//                     }}
//                   >
//                     <Image
//                       style={{ borderRadius: "10px" }}
//                       width={"20%"}
//                       height={"auto"}
//                       preview={false}
//                       src={
//                         item?.linkImage
//                           ? item?.linkImage
//                           : "https://o2o.ipos.vn/static/images/default_food.svg?width=160"
//                       }
//                     />
//                     <div>
//                       <p>{item.tenSP}</p>
//                       <p style={{ fontWeight: "700", color: "#f7941e" }}>
//                         {Format_Currency(item.giaBan)}
//                       </p>
//                     </div>
//                   </div>
//                   {carts?.some((v) => v.id === item.id) ? (
//                     <div
//                       style={{
//                         display: "flex",
//                         gap: 10,
//                         alignItems: "center",
//                       }}
//                     >
//                       <MinusCircleOutlined
//                         style={{ fontSize: "16px" }}
//                         onClick={() => removeProduct(item)}
//                       />
//                       <p>{carts?.find((v) => v.id === item.id)?.quantity}</p>
//                       <PlusCircleOutlined
//                         style={{ fontSize: "16px" }}
//                         onClick={() => increaseCount(item)}
//                       />
//                     </div>
//                   ) : (
//                     <div style={{ color: "#f7941e" }}>
//                       <PlusCircleOutlined style={{ fontSize: "16px" }} />
//                     </div>
//                   )}
//                 </div>
//               </div>
//             ))}
//           </div>
//         ))}
//       </>
//     );
//   };

//   const onPress = (e) => {
//     e.preventDefault();
//     const target = document.getElementById(
//       e.currentTarget.getAttribute("href").substring(1)
//     );
//     if (target) {
//       setActiveSection(target.id);
//       const topOffset =
//         target.getBoundingClientRect().top + window.scrollY - 95;
//       window.scrollTo({ top: topOffset, behavior: "smooth" });
//     }
//   };

//   return (
//     <>
//       <div
//         style={{
//           position: "fixed",
//           backgroundColor: "beige",
//           width: "100%",
//           zIndex: 1000,
//         }}
//       >
//         <div
//           style={{
//             position: "sticky",
//             top: 0,
//             background: "#fff",
//             zIndex: 99,
//           }}
//         >
//           <Row style={{ padding: "10px 10px 0" }}>
//             <Col span={24}>
//               <Flex>
//                 <div
//                   style={{
//                     padding: "5px",
//                     borderRadius: "5px",
//                     border: "1px solid #e2e8f0",
//                     backgroundColor: "#f5f5f5",
//                   }}
//                 >
//                   <HomeOutlined
//                     onClick={() => setActiveTab("1")}
//                     style={{
//                       fontSize: "20px",
//                       color: "#08c",
//                       backgroundColor: "#f5f5f5",
//                     }}
//                   />
//                 </div>

//                 <Input
//                   onChange={handleSearch}
//                   placeholder="Tìm kiếm..."
//                   prefix={<SearchOutlined />}
//                   style={{
//                     width: "100%",
//                     marginLeft: "10px",
//                     backgroundColor: "#f5f5f5",
//                   }}
//                 />
//               </Flex>
//             </Col>
//           </Row>
//         </div>
//         <div className="custom-tab-bar">
//           {data.map((item) => (
//             <a key={item.key} href={`#${item.key}`} onClick={onPress}>
//               <div
//                 style={{
//                   color: activeSection === item.key ? "#f7941e" : "black",
//                 }}
//               >
//                 {item.title}
//               </div>
//             </a>
//           ))}
//         </div>
//       </div>
//       <div style={{ padding: "95px 10px 0px" }}>{renderChildren()}</div>
//     </>
//   );
// };

// export default RenderFood;



import React, { useState, useEffect, useRef } from "react";
import { Col, Flex, Image, Input, Row } from "antd";
import {
  HomeOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Format_Currency } from "layouts/ultis/common";

const RenderFood = ({
  data,
  setActiveTab,
  handleSearch,
  removeProduct,
  increaseCount,
  searchTerm,
  handleDetailOrderFood,
  carts,
}) => {
  const [activeSection, setActiveSection] = useState(data[0]?.key);
  const sections = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let newActiveSection = activeSection;
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            newActiveSection = entry.target.id;
          }
        });
        if (newActiveSection !== activeSection) {
          setActiveSection(newActiveSection);
        }
      },
      { threshold: 0.2 } // Giảm threshold để active sớm hơn
    );

    sections.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sections.current.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, [activeSection, data]);

  const renderChildren = () => {
    const filteredData = data.filter(
      (value) =>
        value.title.toLowerCase().includes(searchTerm) ||
        value.data.some((item) => item.tenSP.toLowerCase().includes(searchTerm))
    );
    return (
      <>
        {filteredData.map((value) => (
          <div
            id={value.key}
            key={value.key}
            ref={(el) => (sections.current[value.key] = el)}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "500",
                marginBottom: "15px",
                marginLeft: "10px",
              }}
            >
              {value.title}
            </p>
            {value.data?.map((item) => (
              <div
                onClick={() => handleDetailOrderFood(item)}
                className="box-product"
                key={item.id}
                style={{
                  boxShadow: "1px 3px 12px 1px rgba(208, 216, 243, 0.6)",
                  marginBottom: 18,
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 15,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 15,
                      alignItems: "center",
                    }}
                  >
                    <Image
                      style={{ borderRadius: "10px" }}
                      width={"20%"}
                      height={"auto"}
                      preview={false}
                      src={
                        item?.linkImage
                          ? item?.linkImage
                          : "https://o2o.ipos.vn/static/images/default_food.svg?width=160"
                      }
                    />
                    <div>
                      <p>{item.tenSP}</p>
                      <p style={{ fontWeight: "700", color: "#f7941e" }}>
                        {Format_Currency(item.giaBan)}
                      </p>
                    </div>
                  </div>
                  {carts?.some((v) => v.id === item.id) ? (
                    <div
                      style={{
                        display: "flex",
                        gap: 10,
                        alignItems: "center",
                      }}
                    >
                      <MinusCircleOutlined
                        style={{ fontSize: "16px" }}
                        onClick={() => removeProduct(item)}
                      />
                      <p>{carts?.find((v) => v.id === item.id)?.quantity}</p>
                      <PlusCircleOutlined
                        style={{ fontSize: "16px" }}
                        onClick={() => increaseCount(item)}
                      />
                    </div>
                  ) : (
                    <div style={{ color: "#f7941e" }}>
                      <PlusCircleOutlined style={{ fontSize: "16px" }} />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </>
    );
  };

  const onPress = (e) => {
    e.preventDefault();
    const target = document.getElementById(
      e.currentTarget.getAttribute("href").substring(1)
    );
    if (target) {
      setActiveSection(target.id);
      const topOffset =
        target.getBoundingClientRect().top + window.scrollY - 95;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          backgroundColor: "beige",
          width: "100%",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            position: "sticky",
            top: 0,
            background: "#fff",
            zIndex: 99,
          }}
        >
          <Row style={{ padding: "10px 10px 0" }}>
            <Col span={24}>
              <Flex>
                <div
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    border: "1px solid #e2e8f0",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <HomeOutlined
                    onClick={() => setActiveTab("1")}
                    style={{
                      fontSize: "20px",
                      color: "#08c",
                      backgroundColor: "#f5f5f5",
                    }}
                  />
                </div>

                <Input
                  onChange={handleSearch}
                  placeholder="Tìm kiếm..."
                  prefix={<SearchOutlined />}
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                    backgroundColor: "#f5f5f5",
                  }}
                />
              </Flex>
            </Col>
          </Row>
        </div>
        <div className="custom-tab-bar">
          {data.map((item) => (
            <a key={item.key} href={`#${item.key}`} onClick={onPress}>
              <div
                style={{
                  color: activeSection === item.key ? "#f7941e" : "black",
                }}
              >
                {item.title}
              </div>
            </a>
          ))}
        </div>
      </div>
      <div style={{ padding: "95px 10px 0px" }}>{renderChildren()}</div>
    </>
  );
};

export default RenderFood;
