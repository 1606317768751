import { Flex } from "antd";
import React from "react";
import { useDrag } from "react-dnd";
import { MdAirlineSeatReclineNormal } from "react-icons/md";

const DraggableBox = ({ id, content,seat }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "BOX",
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        padding: "3px",
        border: "1px solid #d9d9d9",
        borderRadius: "10px",
        marginBottom: "4px",
      }}
    >
      <Flex justify="space-between" gap={5}>
        <p>{content}</p>
        <Flex align="center">
          <MdAirlineSeatReclineNormal />
          <p>{seat}</p>
        </Flex>
      </Flex>
    </div>
  );
};

export default DraggableBox;
