import React, { useState, useEffect } from "react";
import { Select } from "antd";
import _, { set } from "lodash";
import ActiveRoute from "components/ActiveRoute";
import FilterDate from "components/FilterDate";
import { BuyinService } from "services/BuyinService";
import { CategoryService } from "services/CategoryService";
const FilterModal = ({ ...props }) => {
  const { filterCondition, onChangeFilter } = props;
  const [giaiDau, setGiaiDau] = useState([]);
  const [hinhThuc, setHinhThuc] = useState([]);

  const applyChangeFilter = (p, v) => {
    let _filter = _.cloneDeep(filterCondition);
    switch (p) {
      case "MaGD":
        _filter[p] = v;
        break;
      case "MaBan":
        _filter[p] = v?.length > 0 ? "," + v.join(",") + "," : ",,";
        break;
      default:
        _filter[p] = v;
        break;
    }
    onChangeFilter(_filter);
  };
  useEffect(() => {
    BuyinService.getGiaiDau().then((res) => {
      setGiaiDau(res?.data);
    });
    CategoryService.Khac.getTable({}).then((res) => {
      setHinhThuc(res.data);
    });

  }, []);
  const onChangeDate = (v) => {
    let _filter = _.cloneDeep(filterCondition);
    _filter.TuNgay = v.TuNgay;
    _filter.DenNgay = v.DenNgay;
    onChangeFilter(_filter);
  };
  return (
    <div>
      <ActiveRoute />
      <FilterDate onChangeDate={onChangeDate} />
      <div className="box-search">
        <p className="label">Giải đấu</p>
        <Select
          // mode="multiple"
          showSearch
          filterOption={(input, option) =>
            (option?.TenGD?.toLowerCase() ?? "").includes(input.toLowerCase())
          }
          placeholder="Chọn giải đấu"
          onChange={(v) => applyChangeFilter("MaGD", v)}
          style={{
            width: "100%",
          }}
          options={giaiDau}
          fieldNames={{ label: "TenGD", value: "ID" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Bàn</p>
        <Select
          mode="multiple"
          placeholder="Chọn bàn"
          onChange={(v) => applyChangeFilter("MaBan", v)}
          style={{
            width: "100%",
          }}
          options={hinhThuc}
          fieldNames={{ label: "Name", value: "ID" }}
        />
      </div>
      <div className="box-search">
        <p className="label">Loại</p>
        <Select
          // mode="multiple"
          // placeholder="Chọn bàn"
          value={filterCondition.State}
          onChange={(v) => applyChangeFilter("State", v)}
          style={{
            width: "100%",
          }}
          options={[
            { ID: "PLAY", Name: "PLAY" },
            { ID: "OUT", Name: "OUT" },
            { ID: "WAITING", Name: "WAITING" },
            { ID: "DELETE", Name: "DELETE" },
          ]}
          fieldNames={{ label: "Name", value: "ID" }}
        />
      </div>
    </div>
  );
};
export default FilterModal;
