import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import { Button, Space, Drawer, notification, Row, Flex } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Draggable from "react-draggable";
import _ from "lodash";
import { TourService } from "services/TourService";
import { OrderService } from "services/OrdersService";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableBox from "./DraggableBox";
import DropZone from "./DropZone";
import { IoSaveOutline } from "react-icons/io5";
const FormCloseTable = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [droppedBoxes, setDroppedBoxes] = useState({ 0: [] });
  const [users, setUsers] = useState([]);
  const loadSeat = async (maban, mabangd) => {
    let _res = await TourService.Table.getSeatTrong({
      MaBan: maban,
      MaBanGD: mabangd,
    });
    return _res.data;
  };
  const findKeyWithMaxValue = (obj) => {
    let maxKey = null;
    let maxValue = -Infinity;

    // Iterate through the object
    for (const [key, value] of Object.entries(obj)) {
      if (value > maxValue) {
        maxValue = value;
        maxKey = key;
      }
    }

    return maxKey;
  };
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
   function getRandomValueFromArray(arr) {
     if (arr.length === 0) return undefined;
     const randomIndex = Math.floor(Math.random() * arr.length);
     return arr[randomIndex];
   }
  useImperativeHandle(ref, () => ({
    show: async (data, tablerun) => {
      setLoading(true);
      setOpen(true);
      let user = await TourService.Table.getSeat({ MaBan: data.ID });
      let userAdd = {};
      let tb = {};
      let tableEmptySlot = {};
      tablerun.map((item) => {
        tableEmptySlot[item.ID] = item.SoLuongMax - item.Player;
        tb[item.ID] = [];
      });

      let shuffledUsers = shuffleArray(user.data);
      for (let i = 0; i < shuffledUsers?.length; i++) {
        const u = shuffledUsers[i];
        const maxKey = findKeyWithMaxValue(tableEmptySlot);
        let findTable = tablerun.find((v) => v.ID === Number(maxKey));
        if(tableEmptySlot[maxKey] === 0) continue;
        let seats = await loadSeat(findTable?.MaBan, findTable?.ID);
        seats = seats.filter(
          (v) => _.map(tb[maxKey], "MaSeatNew").includes(v.ID) === false
        );
        if (seats.length > 0) {
          let seat = getRandomValueFromArray(seats);
          userAdd[u.ID] = true;
          tb[maxKey].push({
            ...u,
            MaBan: findTable?.MaBan,
            MaSeatNew: seat.ID,
            TenSeatNew: seat.Name,
          });
          tableEmptySlot[maxKey] -= 1;
        }
      }
      setDroppedBoxes(tb);
      setTables(tablerun);
      var userThua = shuffledUsers?.filter(
        (v) => Object.keys(userAdd).includes(String(v.ID)) === false
      );
      setUsers(userThua);
      setLoading(false);
      setData(data);
    },
  }));
  const onClose = () => {
    setOpen(false);
  };

  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };

  const handleDrop = async (boxId, zone, toBox) => {
    // Find the box to move only once.
    if (droppedBoxes[zone].length >= toBox.SoLuongMax - toBox.Player) {
      openNotification("error", "topRight", `Bàn ${toBox.Name} đã đầy`);
      return;
    }

    const boxToMove =
      users.find((box) => box.ID === boxId) ||
      Object.values(droppedBoxes)
        .flat()
        .find((box) => box.ID === boxId);
    if (!boxToMove) return;
    let seats = await loadSeat(toBox.MaBan, Number(zone));
    let seatbox = _.map(droppedBoxes[zone], "MaSeatNew");
    seats = seats.filter((v) => seatbox.includes(v.ID) === false);
    if (seats.length > 0) {
      boxToMove.MaSeatNew = seats[0].ID;
      boxToMove.TenSeatNew = seats[0].Name;
    }
    // Update users by removing the moved box.
    const updatedUsers = users.filter((box) => box.ID !== boxId);

    // Update droppedBoxes by first removing the box from all zones, then adding it to the target zone.
    const updatedDroppedBoxes = Object.keys(droppedBoxes).reduce((acc, key) => {
      const boxes = droppedBoxes[key].filter((box) => box.ID !== boxId); // Remove the box from all zones.
      acc[key] = boxes;
      return acc;
    }, {});

    // Add the box to the target zone, ensuring the zone exists.
    if (!updatedDroppedBoxes[zone]) updatedDroppedBoxes[zone] = [];
    updatedDroppedBoxes[zone].push(boxToMove);

    // Update state.
    setUsers(updatedUsers);
    setDroppedBoxes(updatedDroppedBoxes);
  };
  const onSubmit = async () => {
    let seats = [];
    if (users.length > 0){
       openNotification("error", "topRight", "Số seat chưa đủ để đóng, vui lòng kiểm tra lại!");
       return;
    }
     setLoading(true);
    Object.keys(droppedBoxes).forEach((zone) => {
      let item = tables.find((v) => v.ID === Number(zone));
      droppedBoxes[zone].forEach((v) => {
        seats.push({
          ID: v.ID,
          MaSeatNew: v.MaSeatNew,
          MaSeat: v.MaSeat,
          TenSeat: v.TenSeat,
          TenSeatNew: v.TenSeatNew,
          MaThe: v.MaThe,
          MaKH: v.MaKH,
          MaBanGDNew: Number(zone),
          DienGiai: `Chuyển sang ${item.Name}/${v.TenSeatNew}`,
          DienGiaiNew: `Chuyển từ ${data.Name}/${v.TenSeat}`,
        });
      });
    });

    let _payload = {
      ...data,
      isOpen: false,
      Seats: seats,
    };
    let _res = await TourService.Table.closeOpen(_payload);
    setLoading(false);
    if (_res.status === 2000) {
      onAfterSubmit();
      setData({ isOpen: false });
      openNotification("success", "topRight", "Đóng bàn thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  return (
    <Drawer
      title="Đóng bàn"
      width={720}
      onClose={onClose}
      open={open}
      extra={
        <Space>
          {data?.isOpen && (
            <Button
              loading={loading}
              type="primary"
              icon={<IoSaveOutline />}
              onClick={() => onSubmit()}
            >
              Xác nhận đóng bàn
            </Button>
          )}
        </Space>
      }
    >
      <DndProvider backend={HTML5Backend}>
        <Row gutter={[10, 10]}>
          {Object.keys(droppedBoxes)?.map((zone) => {
            let tb = tables?.find((v) => v.ID === Number(zone));
            if (!tb) {
              console.warn(`No table found for zone: ${zone}`);
              return null;
            }
            return (
              <DropZone
                key={zone}
                // onDrop={(id) => handleDrop(id, zone, tb)}
                name={`${tb?.Name} (Trống ${tb?.SoLuongMax - tb?.Player} ghế)`}
              >
                {droppedBoxes[zone]?.map((box) => (
                  <DraggableBox
                    key={box.ID}
                    id={box.ID}
                    seat={box.TenSeatNew}
                    content={box.TenCongTy}
                  />
                ))}
              </DropZone>
            );
          })}
        </Row>
        <div style={{ marginTop: "16px" }}>
          <p style={{color:"red"}} >Player chưa có seat để chuyển bàn</p>
          {users.map((box) => (
            <DraggableBox
              key={box.ID}
              id={box.ID}
              content={`${box.TenCongTy}`}
            />
          ))}
        </div>
      </DndProvider>
      {contextHolder}
    </Drawer>
  );
};
export default forwardRef(FormCloseTable);
